import { useEffect, useState } from "react";
import { collection, query, onSnapshot, collectionGroup, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import ReactTable from "../../components/Table/ReactTable";
import { Avatar, Box, Button, HStack, IconButton, Tag, Tooltip } from '@chakra-ui/react'
import { useHistory } from "react-router-dom";
import AssigneesAvatarGroup from "../../components/Marketing/AssigneesAvatarGroup";
import Timestamp from "../../components/Timestamp";
import { useAuth } from "../../contexts/AuthContext";
import { FiSettings } from "react-icons/fi";


export default function Escalations( { } ){
    const { SuperAdmin, currentUser } = useAuth()
    const history = useHistory()
    const [escalations, setEscalations] = useState()
    const [cols, setCols] = useState([
        {
            Header: '',
            accessor: 'id',
            footer: '',
            width: '65px',
            Cell: ({ cell: { value } }) => (
                <>
                <Button size='sm' colorScheme='blue' onClick={() => handleRouteChange(`/escalations/${value}`)}>
                    View
                </Button>
                </>
            ),
        },
        {
            Header: 'Owner',
            accessor: 'Owner',
            footer: '',
            Cell: ({ cell: { value } }) => (
                    <>
                    <Tooltip placement='top' label={value.displayName}>
                        <Avatar src={value.photoURL}></Avatar>
                    </Tooltip>
                    </>
                ),
        },
        {
            Header: 'Status',
            accessor: 'Status',
            footer: '',
            Cell: ({ cell: { value }, row }) => (
                <>               
                    <HStack>
                        <Tag 
                            colorScheme={value == 'New' ? 'red' : value == 'In Progress' ? 'orange': 'green'}>
                            {value}
                        </Tag>
                        {value != 'New' &&
                        <AssigneesAvatarGroup assignees={row.original && row.original.Assignees && row.original.Assignees} Status={value}/>
                        }
                        </HStack>
                </>
            ),
        },
        {
            Header: 'Topic',
            accessor: 'topicName',
            footer: ''
        },
        {
            Header: 'Subtopic',
            accessor: 'subtopicName',
            footer: ''
        },
        {
            Header: 'Referenced Loan',
            accessor: 'loanID',
            footer: '',
            Cell: ({ cell: { value } }) => (
                <>
                <Button size='sm' colorScheme='twitter' onClick={() => handleRouteChange(`/loans/${value}`)}>
                    View Loan
                </Button>
                </>
            ),
        },
        {
            Header: 'Date Created',
            accessor: 'Date Created',
            footer: '',
            Cell: ({ cell: { value } }) => (
                <>
                    <Timestamp timestamp={value} />
                </>
            ),
        },

    ])

    const [data, setData] = useState()

    const [topics, setTopics] = useState()
    const [subtopics, setSubtopics] = useState()

    const handleRouteChange = (link) => {
        history.push(link)
    }

    useEffect(() => {
        let q 
        
        if(SuperAdmin){
            q = query(collection(db, "Escalations"), orderBy('Date Created', 'desc'));
        }
        else q = query(collection(db, "Escalations"), where('Owner.uid', '==', currentUser.uid), orderBy('Date Created', 'desc'));
        
        const unsub = onSnapshot(q, (querySnapshot) => {
            const esc = []
            querySnapshot.forEach((doc) => {
                esc.push({...doc.data(), id: doc.id})

            });
            setEscalations(esc)
        });

        const unsubscribeSubtopics = onSnapshot(query(collectionGroup(db, 'subtopics')), (snapshot) => {
            const updatedSubtopics = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setSubtopics(updatedSubtopics);
          });
      
          const unsubscribeTopics = onSnapshot(query(collection(db, 'topics')), (snapshot) => {
            const updatedTopics = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setTopics(updatedTopics);
          });

        return () => {
            unsubscribeTopics()
            unsubscribeSubtopics()
            unsub()
        }
    }, [])

    useEffect(() => {
        if(escalations && topics && subtopics){
            let dataz = []
            escalations.forEach((escalation) => {
                dataz.push({
                    ...escalation,
                    topicName: getTopicName(escalation.topicID),
                    subtopicName: getSubtopicName(escalation.subtopicID),
                    ownerName: escalation.Owner.displayName,

                })
            })
            setData(dataz)
        }
    }, [escalations, topics, subtopics])

    function getTopicName(id){
        let index = topics.findIndex((topic) => topic.id === id)
        if(index > -1){
            return topics[index].title ? topics[index].title : 'Topic Not Found'
        }
        else return 'Topic Not Found'
    }

    function getSubtopicName(id){
        let index = subtopics.findIndex((subtopics) => subtopics.id === id)
        if(index > -1){
            return subtopics[index].title ? subtopics[index].title : 'Subtopic Not Found'
        }
        else return 'Subtopic Not Found'
    }


    return(
        <>
        <Box as="div">
        {SuperAdmin &&
            <>
            <IconButton 
                icon={<FiSettings/>} 
                mb='-80px'
                ml='5px'
                variant='subtle' 
                onClick={() => history.push('/escalation-settings')}>
            </IconButton>
            </>
        }
        {cols && data &&
            <ReactTable cols={cols} data={data} colorScheme={'teal'}/>
            }
        </Box>
        </>
    )
}