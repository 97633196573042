import { Stack, Flex, Link, ScaleFade, Text, useToast} from "@chakra-ui/react"
import AddLink from "./AddLink"
import { IconButton } from "@chakra-ui/react"
import { doc, deleteDoc, query, orderBy, onSnapshot, collection } from "firebase/firestore"
import { db } from "../../utils/init-firebase"
import { FiTrash2 } from "react-icons/fi"
import { ExternalLinkIcon, PhoneIcon, EmailIcon } from "@chakra-ui/icons"
import { useRef } from "react"
import { useState, useEffect } from "react"
import VAEntitlementCalc from "../VAEntitlementCalc"
import BuyDownCalc from "../BuyDownCalc"


export default function LinkList ( { selectedSection, editMode } ){
    const toast = useToast()
    const [links, setLinks] = useState(null)
    const [loading, setLoading] = useState(false)
    const unsubscribeLinksRef = useRef()

    const deleteLink = async (id) => {
        try{
            await deleteDoc(doc(db, 'Helpful Link Sections', selectedSection, 'Links', id))            
            toast({
                title: 'Link Deleted!',
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top'
            })
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    }

    useEffect(() => {
        if(selectedSection){
            setLoading(true)
            unsubscribeLinksRef.current && unsubscribeLinksRef.current()
            const q = query(collection(db, "Helpful Link Sections", selectedSection, "Links"), 
                            orderBy('Date Created', 'desc'));
            
            unsubscribeLinksRef.current = onSnapshot(q, (querySnapshot) => {
                let links = []
                querySnapshot.forEach((doc) => {
                    links.push(doc.data())
                })
                setLinks(links)
            })
            setLoading(false)
            
            return () => {
                unsubscribeLinksRef.current && unsubscribeLinksRef.current()
            }
        }
    }, [selectedSection])

    return(
        <>
            {links && !loading && links.length > 0 ?
            <>
                <Stack key={editMode ? `edit-${selectedSection}` : `view-${selectedSection}`} mt={1} mb={1} p={3} spacing={3}>
                    {selectedSection == 'VA Links' && <VAEntitlementCalc key='va-entitlement-calc'/>}
                    {selectedSection == 'Sales Tools' && <BuyDownCalc key='buy-down-calc'/>}


                    {links.map((link, i) => {
                        /* link: {
                                Date Created: timestamp,
                                id: String,
                                values: {
                                    URL: String,
                                    Name: String,
                                    Type: String (External, Contact, Google Doc Embed),
                                    Phone Number: String (OPTIONAL),
                                    Email: String (OPTIONAL),
                                    Window Size: String (OPTIONAL)
                                }
                            }
*/
                        return(
                    
                            <ScaleFade key={`animate-${link.id}-${i}`} id={link.id} in initialScale={0.75}>
                                <Flex key={`container-${link.id}-${i}`} flexDir='row' justify={'flex-start'}>
                                    {editMode &&
                                        <IconButton
                                            variant={'ghost'}
                                            size='sm'
                                            colorScheme='red'
                                            key={`delete-link-${link.id}-${i}`}
                                            id={`delete-link-${link.id}-${i}`}
                                            icon={<FiTrash2/>}
                                            onClick={() => deleteLink(link.id)}
                                            ml={3}
                                            alignSelf='flex-start'
                                        />
                                    }
                                
                                <Flex pt='1.5' key={`content-${link.id}-${i}`} fontSize={'sm'} flexDir='column' justify={'flex-start'}>
                                    {link.values.URL && link.values.URL !='' ?
                                    <Link key={`name-${link.id}-${i}`} isExternal href={link.values.URL}>
                                        {link.values.Name} <ExternalLinkIcon key={`link-icon-${link.id}-${i}`}/>
                                    </Link>
                                    :
                                    <Text key={`name-${link.id}-${i}`}>{link.values.Name}</Text>
                                    }
                                    {link.values['Phone Number'] && link.values['Phone Number'] != '' &&
                                        <Text key={`phone-${link.id}-${i}`} ml='2' fontSize={'sm'}><a href={`tel:${link.values['Phone Number']}`}><PhoneIcon key={`phone-icon-${link.id}-${i}`}/>&nbsp;{link.values['Phone Number']}</a></Text>
                                    }
                                    {link.values['Email'] && link.values['Email'] != '' &&
                                        <Text key={`email-${link.id}-${i}`} ml='2' fontSize={'sm'}><a href={`mailto:${link.values['Email']}`}><EmailIcon key={`email-icon-${link.id}-${i}`}/>&nbsp;{link.values['Email']}</a></Text>
                                    }
                                </Flex>
                            
                            </Flex>
                            </ScaleFade>
                            
                        )
                    })}
                </Stack>
            </>
            :
            <>
            {!loading &&
                <Text mt={5} fontStyle='italic' fontWeight={'bold'}> No Links Found in {selectedSection}</Text>
            }            
            </>

            }
            {editMode &&
                <AddLink key={`add-link-to-${selectedSection}`} selectedSection={selectedSection} />
            }
        </>
    )
}