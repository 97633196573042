

export default function SalesDashboardPage( { } ){
    return(
        <>
            <iframe width={"100%"}
                    height={"900px"} 
                    src={"https://lookerstudio.google.com/embed/reporting/0994d102-75d1-4843-972c-a6284fd666c8/page/p_de7m63wb5c"}
                    frameBorder="0" 
                    >
            </iframe>
        </>
    )
}