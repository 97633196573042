import { Text } from "@chakra-ui/react";
import { useAuth } from "../../contexts/AuthContext";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ManageActivities from "./Activities/ManageActivities";
import ManageCalendars from "./Calendars/ManageCalendars";

export default function CalendarSettings( { } ){
    const { SuperAdmin } = useAuth()

    return(
        <>
        {SuperAdmin ? 
            <>
            <Tabs variant='enclosed' mt='6'>
                <TabList>
                    <Tab>Calendars</Tab>
                    <Tab>Activities</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ManageCalendars />
                    </TabPanel>
                    <TabPanel>
                        <ManageActivities />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            </>
            :
            <Text>You do not have permission to access this page</Text>
        }
        </>
    )
}