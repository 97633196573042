import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { collection, query, getDocs, orderBy, where, collectionGroup } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import { Center, FormControl } from "@chakra-ui/react";
import {
    Text,
    Wrap,
    WrapItem,
    Stack,
    Button,
    useColorModeValue,
    Flex,
    Box,
    Heading,
    Image
  } from '@chakra-ui/react';
import { useAuth } from "../../contexts/AuthContext";
import ReactDatePicker from "react-datepicker";
import { datepickerDark, datepickerLight } from "../../contexts/GlobalVariables";
import "react-datepicker/dist/react-datepicker.css";
import { Select } from "chakra-react-select";
import ReactLinkify from "react-linkify";
import MarketingReportSummary from "./MarketingReport_Summary";
import RequestCard from "../Marketing/RequestCard";
import TransactionLog from "../TransactionLog/TransactionLog";

export default function MarketingReports( { } ) {
  const requestsCollectionRef = collection(db, "Marketing Requests")
  const transactionsCollectionRef = collectionGroup(db, "Marketing Swag Transactions")
  const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)
  const { currentUser, SuperAdmin, MarketingAdmin } = useAuth()
  const { users } = useAuth()

  const { url, path } = useRouteMatch()

  const [taskList, setTaskList] = useState(null)
  const [filteredTaskList, setFilteredTaskList] = useState(null)

  const [loading, setLoading] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const [owner, setOwner] = useState([])
  const [assignees, setAssignees] = useState([])
  const [coachingCompleted, setCoachingCompleted] = useState(false)
  const [error, setError] = useState()


  let [quickActionExecuted, setQuickActionExecuted] = useState(0)
  
  const [querySummary, setQuerySummary] = useState(null)

  var today = new Date(), y = today.getFullYear(), m = today.getMonth();
  const [startDate, setStartDate] = useState(new Date(y, m, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [dateFilter, setDateFilter] = useState({label: 'Date Created', value: 'Date Created'})

  const [statusFilter, setStatusFilter] = useState('All')
  const [includeRequests, setIncludeRequests] = useState(true)
  const [includeTransactions, setIncludeTransactions] = useState(false)

  const [transactions, setTransactions] = useState()
  const [totalCost, setTotalCost] = useState(0)

  const baseQueryRequests = query(requestsCollectionRef, 
                    orderBy(dateFilter.value, 'desc'),
                    where(dateFilter.value, '>=', startDate), 
                    where(dateFilter.value, '<=', endDate),)

    const baseQueryTransactions = query(transactionsCollectionRef, 
                    orderBy('timestamp', 'desc'),
                    where('timestamp', '>=', startDate), 
                    where('timestamp', '<=', endDate),
                    )

  const reset = () => {
    setLoading(true)
    setCoachingCompleted(false)
    setOwner([])
    setAssignees([])
    setTaskList(null)
    setFilteredTaskList(null)
    setStartDate(null)
    setEndDate(null)
    setError(null)
    setQuerySummary(null)
    setLoading(false)
  }

  const queryYesterday = async () => {
    const setYesterday = async () => {
        var curr = new Date; // get current date
        var first = curr.getDate() - 1; // First day is the day of the month - the day of the week
        var firstDay = new Date(curr.setDate(first));
        firstDay.setHours(0)
        setStartDate(firstDay)

        var today = new Date; // get current date
        var end = today.getDate() - 1; // First day is the day of the month - the day of the week
        var endDay = new Date(today.setDate(end));
        endDay.setHours(23)
        endDay.setMinutes(59)
        setEndDate(endDay)
        setDateFilter({label: 'Date Created', value: 'Date Created'})
        setOwner([])
        setAssignees([])
    }
    reset()
    await setYesterday()
    setQuickActionExecuted(Math.random() + 1)
  }



  //query Monday - Today
  const queryThisWeek = async () => {
    const setThisWeek = async () => {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
        var firstDay = new Date(curr.setDate(first));
        firstDay.setHours(0)
        setStartDate(firstDay)
        setEndDate(new Date())
        setDateFilter({label: 'Date Created', value: 'Date Created'})
        setOwner([])
        setAssignees([])
    }
    reset()
    await setThisWeek()
    setQuickActionExecuted(Math.random() + 1)

  }


  const queryMonthToDate = async () => {
    const setMTD = async () => {
        setStartDate(new Date(y, m, 1))
        setEndDate(new Date())
        setDateFilter({label: 'Date Created', value: 'Date Created'})
        setOwner([])
        setAssignees([])
    }
    reset()
    await setMTD()
    setQuickActionExecuted(Math.random() + 1)
  }

  useEffect( async () => {
    if(quickActionExecuted != 0){
        await getTasks()
    }
  }, [quickActionExecuted])

  useEffect( () => {
    if(taskList){
        setFiltering(true)
        if(statusFilter == 'All'){
            setFilteredTaskList(taskList)
        }
        else{
            const filteredTaskList = taskList.filter((task) => {
                if(statusFilter == 'Needs Review'){
                    return task['Flag For Review'] && !task['Coaching Completed']
                }
                else if(statusFilter == 'Coaching Completed'){
                    return task['Coaching Completed']
                }
                else{
                    return task['Status'] == statusFilter
                }    
            })
            setFilteredTaskList(filteredTaskList)
        }
        setFiltering(false)
    }
  }, [statusFilter])

  useEffect(() => {
    console.log(owner)
  }, [owner])


  const getTasks = async () => {
    try{
        setLoading(true)
        setError(null)
        setTaskList(null)
        setFilteredTaskList(null)
                
        var tasks = []
        var noNew = 0
        var noInProgress = 0
        var noCompleted = 0
        var noNeedsReview = 0
        var noCoachingCompleted = 0

        if(includeRequests){
             // If nobody is selected, fetch all tasks for this date range
            if(owner.length == 0 && assignees.length == 0){
                const querySnapshot = await getDocs(baseQueryRequests)
                querySnapshot.forEach((doc) => {
                    let task = {}
                    task = doc.data()
                    tasks.push(task)
                    if(task['Status'] == 'New'){
                        noNew++
                    }
                    if(task['Status'] == 'In Progress'){
                        noInProgress++
                    }
                    if(task['Status'] == 'Completed'){
                        noCompleted++
                    }
                    if(task['Flag For Review']){
                        if(task['Coaching Completed']){
                            noCoachingCompleted++
                        }
                        else noNeedsReview++
                    }
                })
            }
            else {
                for(const user of owner) {
                    console.log('querying ' + user.label + ' ' + user.value)
                    var thisownerQuery = query(baseQueryRequests, where(`Owner.uid`, '==', user.value))
                        const querySnapshot = await getDocs(thisownerQuery)
                        querySnapshot.forEach((doc) => {
                            let task = {}
                            task = doc.data()
                            tasks.push(task)
                            if(task['Status'] == 'New'){
                                noNew++
                            }
                            if(task['Status'] == 'In Progress'){
                                noInProgress++
                            }
                            if(task['Status'] == 'Completed'){
                                noCompleted++
                            }
                            if(task['Flag For Review']){
                                if(task['Coaching Completed']){
                                    noCoachingCompleted++
                                }
                                else noNeedsReview++
                            }
                        })
                }
                let assigneeArray = []
                for(const assignee of assignees) {
                    console.log('querying ' + JSON.stringify(assignee))
                    let assigneeObj = {
                        Email: assignee.email,
                        Name: assignee.label,
                        photoURL: assignee.photoURL,
                        uid: assignee.value
                    }
                    assigneeArray.push(assigneeObj)
                }
                var thisassigneesQuery = query(baseQueryRequests, where(`Assignees`, 'array-contains-any', assigneeArray))
                const querySnapshot = await getDocs(thisassigneesQuery)
                querySnapshot.forEach((doc) => {
                    console.log('found task: ' + doc.data())
                    let task = {}
                    task = doc.data()
                    tasks.push(task)
                    if(task['Status'] == 'New'){
                        noNew++
                    }
                    if(task['Status'] == 'In Progress'){
                        noInProgress++
                    }
                    if(task['Status'] == 'Completed'){
                        noCompleted++
                    }
                    if(task['Flag For Review'] && !task['Coaching Completed']){
                        noNeedsReview++
                    }
                    if(task['Coaching Completed']){
                        noCoachingCompleted++
                    }
                })
            }
        }

        if(includeTransactions){
            await getTransactions()
        }
       
        setTaskList(tasks)
        setFilteredTaskList(tasks)
        
        setQuerySummary({
            startDate: startDate,
            endDate: endDate,
            dateFilter: dateFilter,
            owner: owner,
            assignees: assignees,
            queryStats: {
                noTasks: tasks.length,
                noNew: noNew,
                noInProgress: noInProgress,
                noCompleted: noCompleted,
                noNeedsReview: noNeedsReview,
                noCoachingCompleted: noCoachingCompleted
            }
       
        })
        setStatusFilter('All')
        setLoading(false)
    }
    catch(err){
        console.log(err)
        setLoading(false)
        setError(err.message)
    }
};

const getTransactions = async () => {
        let transactions = []
      // If nobody is selected, fetch all tasks for this date range
      if(owner.length == 0 && assignees.length == 0){
        const querySnapshot = await getDocs(baseQueryTransactions)
        querySnapshot.forEach((doc) => {
            let transaction = {}
            transaction = doc.data()
            if(!transaction.isAddition){
                transactions.push(transaction)
            }
        })
    }
    else {
        for(const user of owner) {
            console.log('querying ' + user.label + ' ' + user.value)
            var thisownerQuery = query(baseQueryRequests, where(`Owner.uid`, '==', user.value))
                const querySnapshot = await getDocs(thisownerQuery)
                querySnapshot.forEach((doc) => {
                    let transaction = {}
                    transaction = doc.data()
                    if(!transaction.isAddition){
                        transactions.push(transaction)
                    }
                })
        }

        for(const assignee of assignees) {
            console.log('querying ' + assignee.label)

            var thisassigneesQuery = query(baseQueryRequests, where(`Fulfilled By.uid`, '==', assignee.value))
                const querySnapshot = await getDocs(thisassigneesQuery)
                querySnapshot.forEach((doc) => {
                    console.log('found task: ' + doc.data())
                    let transaction = {}
                    transaction = doc.data()
                    if(!transaction.isAddition){
                        transactions.push(transaction)
                    }
                })
        }
    }
    let total = 0
    for(const transaction of transactions){
        total += parseInt(transaction.Amount) * transaction.Cost
    }
    if(total > 0){
        setTotalCost(total)
    }
    setTransactions(transactions)
}


useEffect(() =>{
    setLoading(true)
    const querySummary = JSON.parse(localStorage.getItem('querySummary'))
    if(querySummary){
        setQuerySummary(querySummary)
    }
    const statusFilter = localStorage.getItem('statusFilter')
    if(statusFilter){
        setStatusFilter(statusFilter)
    }
    const taskList = JSON.parse(localStorage.getItem('taskList'))
    if(taskList){
        setTaskList(taskList)
    }
    const filteredTaskList = JSON.parse(localStorage.getItem('filteredTaskList'))
    if(filteredTaskList){
        setFilteredTaskList(filteredTaskList)
    }
    const owner = JSON.parse(localStorage.getItem('owner'))
    if(owner){
        setOwner(owner)
    }
    const assignees = JSON.parse(localStorage.getItem('assignees'))
    if(assignees){
        setAssignees(assignees)
    }
    const startDate = JSON.parse(localStorage.getItem('startDate'))
    if(startDate){
        setStartDate(new Date(startDate))
    }
    const endDate = JSON.parse(localStorage.getItem('endDate'))
    if(endDate){
        setEndDate(new Date(endDate))
    }
    const dateFilter = JSON.parse(localStorage.getItem('dateFilter'))
    if(dateFilter){
        setDateFilter(dateFilter)
    }

    const includeRequests = JSON.parse(localStorage.getItem('includeRequests'))
    if(includeRequests){
        setIncludeRequests(includeRequests)
    }
    const includeTransactions = JSON.parse(localStorage.getItem('includeTransactions'))
    if(includeTransactions){
        setIncludeTransactions(includeTransactions)
    }
    setLoading(false)
}, [])

useEffect(() => {
    localStorage.setItem('querySummary', JSON.stringify(querySummary))
}, [querySummary])
useEffect(() => {
    localStorage.setItem('statusFilter', statusFilter)
}, [statusFilter])
useEffect(() => {
    localStorage.setItem('taskList', JSON.stringify(taskList))
}, [taskList])
useEffect(() => {
    localStorage.setItem('filteredTaskList', JSON.stringify(filteredTaskList))
}, [filteredTaskList])
useEffect(() => {
    localStorage.setItem('owner', JSON.stringify(owner))
}, [owner])
useEffect(() => {
    localStorage.setItem('assignees', JSON.stringify(assignees))
}, [assignees])
useEffect(() => {
    localStorage.setItem('startDate', JSON.stringify(startDate))
}, [startDate])
useEffect(() => {
    localStorage.setItem('endDate', JSON.stringify(endDate))
}, [endDate])
useEffect(() => {
    localStorage.setItem('dateFilter', JSON.stringify(dateFilter))
}, [dateFilter])

useEffect(() => {
    localStorage.setItem('inlcudeRequests', JSON.stringify(includeRequests))
}, [includeRequests])
useEffect(() => {
    localStorage.setItem('includeTransactions', JSON.stringify(includeTransactions))
}, [includeTransactions])
  

  return (
    <>
    {SuperAdmin || MarketingAdmin ? 
        <Flex flexDir={{base: 'column-reverse' , xl: 'row'}}>
          <Box w={{base: '90vw', md: '75vw', xl: '65vw'}}>
            {!loading ?
            <>
            {filteredTaskList && !error && querySummary && 
                <MarketingReportSummary 
                    startDate={querySummary['startDate']}
                    endDate={querySummary['endDate']}
                    dateFilter={querySummary['dateFilter'].value}
                    owner={querySummary['owner']}
                    assignees={querySummary['assignees']}
                    queryStats={querySummary.queryStats}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                />
            }
            <Wrap mt={3}>
                {filteredTaskList && !filtering && filteredTaskList.map((task, index) => {
                    return (         

                        <>
                        <WrapItem w={{base: '100%', md: '45%', xl: '30%'}}   id={`task-${index}`}>
                            <RequestCard 
                                request={task}
                            />
                        </WrapItem>
                        </>
                    )
                }) }
                {filtering &&     
                <>
                    <Text>Filtering...</Text>
                    <Image src={require('../../assets/img/the-future-is-loading-transparent.gif')}/>
                        </>
                }
                {!filteredTaskList && !error && <Text>Start by building a query using the form on the right</Text>}
                {filteredTaskList && filteredTaskList.length == 0 && !error && !transactions && <Text>No tasks found</Text>}

            </Wrap>
            {transactions && 
                <TransactionLog transactions={transactions} totalCost={totalCost}/>
            }
            </>
            :     
            <Center mt='10'>
                <Image src={require('../../assets/img/the-future-is-loading-transparent.gif')}/>
            </Center>
            }
            {error && <ReactLinkify><Text>{error}</Text></ReactLinkify>}
          </Box>
          
          <Box w={{base: '90vw', md:'75vw', xl: '20vw'}} mt={3}>
            <Stack
              mt={1}
              ml={2}
              direction='column'
              maxW={'1200px'}
              spacing='4'
            >
                <Heading textAlign={'center'} size='md' as='em'>Build-A-Query</Heading>
                <Stack spacing={1}>
                    <Stack direction={{base: 'row', xl: 'column'}} >
                        <ReactDatePicker   
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className={datepickerClass}
                            placeholderText='Start Date'
                        />
                        <ReactDatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className={datepickerClass}
                            placeholderText='End Date'
                        />
                    </Stack>
                    <FormControl>
                        <Select
                            options={[{label: 'Date Completed', value: 'Date Completed'}, {label: 'Date Created', value: 'Date Created'}]}
                            value={dateFilter}
                            defaultValue={'Date Created'}
                            onChange={(e) => setDateFilter(e)}
                            placeholder="Select Date Filter"
                            selectedOptionColor="green"
                            classNamePrefix={'date-filter-select'}
                        />
                    </FormControl>
                    {dateFilter.value == 'Date Completed' && <Text color={'purple.400'} fontSize={'xs'}>Querying By Date Completed will exclude all new & in progress tasks</Text>}
                </Stack>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Owner</Text>
                    <Select             
                        options={users}
                        value={owner}
                        onChange={(e) => setOwner(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lo-filter-select'}
                        closeMenuOnSelect='false'
                        isClearable
                        isMulti
                    />
                </FormControl>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Assignees</Text>
                    <Select
                        options={users}
                        value={assignees}
                        onChange={(e) => setAssignees(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lc-filter-select'}
                        isClearable
                        isMulti
                    />
                </FormControl>
  {/*               <FormControl mb={3}>
                    <Checkbox value={includeRequests} defaultChecked={includeRequests} onChange={(e) => setIncludeRequests(e.target.checked)}>
                        Requests
                    </Checkbox>
                </FormControl>

                <FormControl mb={3}>
                    <Checkbox value={includeTransactions} defaultChecked={includeTransactions} onChange={(e) => setIncludeTransactions(e.target.checked)}>
                        Transactions
                    </Checkbox>
                </FormControl> */}
              {/*   <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Reason Parked</Text>
                    <Select
                        options={users}
                        value={assignees}
                        onChange={(e) => setAssignees(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lc-filter-select'}
                        isClearable
                        isMulti
                    />
                </FormControl>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Reason Not Submitted</Text>
                    <Select
                        options={users}
                        value={assignees}
                        onChange={(e) => setAssignees(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lc-filter-select'}
                        isClearable
                        isMulti
                    />
                </FormControl> */}
                <Flex w='100%' flexDir={{base: 'row', xl: 'column'}}>
                    <Button disabled={!includeRequests && !includeTransactions} w={{base: '50%', xl: '100%'}} p={5} pl={7} pr={7} m={1} onClick={getTasks} size='md' colorScheme='green'>Apply</Button>

                {/*   <Heading textAlign={'center'} size='md' as='em'>Filters</Heading>
                    <FormControl mb={3}>
                        <Checkbox onChange={(e) => setLoanWasParked(e.target.checked)}>
                            Loan Was Parked
                        </Checkbox>
                    </FormControl>

    */}

                    <Button w={{base: '50%', xl: '100%'}} p={5} pl={7} pr={7} m={1} onClick={reset} size='md' colorScheme='blue'>Reset</Button>
                </Flex>
                
                <Heading textAlign={'center'} size='md' as={'em'}>Quick Actions</Heading>
                <Flex w='100%' flexDir={{base: 'column', md:'row', xl: 'column'}}>
                    <Button w={{base: '100%', md:'33%', xl: '100%'}} m={1} onClick={queryYesterday}>Yesterday</Button>
                    <Button w={{base: '100%', md:'33%', xl: '100%'}} m={1} onClick={queryThisWeek}>This Week</Button>
                    <Button w={{base: '100%', md:'33%', xl: '100%'}} m={1} onClick={queryMonthToDate}>Month To Date</Button>
                </Flex>

            </Stack>
            </Box>
        </Flex>

        
        :
        <Text>You do not have permission to access this page</Text>
    }
    </>
  );
}
