import { Text, Input, Textarea, Stack, InputGroup } from "@chakra-ui/react"
import { Radio, RadioGroup } from '@chakra-ui/react'


export default function RenderQuestion( { question, answers, handleOnChange } ){
    const { type, id, title } = question
    return(
        <>
  
            {type == 'Short Input' ?
                <InputGroup size='md' flexDir="column" mb='2'>
                    <Text>{title}</Text>
                    <Input 
                        onChange={(e) => handleOnChange(e.target.value, title)} 
                        value={answers.title}
                        id={id}
                        required
                        >
                    </Input>
                </InputGroup>
              
            : type == 'Long Input' ? 
                <InputGroup size='md' flexDir="column" mb='2'>
                    <Text>{title}</Text>
                    <Textarea 
                        onChange={(e) => handleOnChange(e.target.value, title)} 
                        value={answers.title}
                        id={id}
                        required
                        >
                    </Textarea>
                </InputGroup>
                
            : type == 'Yes/No' ?
                  <RadioGroup 
                        required
                        onChange={(e) => handleOnChange(e, title)} 
                        value={answers.title} 
                        flexDir="column" 
                        mb='2'>
                    <Text>{title}</Text>
                    <Stack direction='row'>
                        <Radio value='Yes'>Yes</Radio>
                        <Radio value='No'>No</Radio>
                    </Stack>
                </RadioGroup>
            : null
            }
        </>
    )
}