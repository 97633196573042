import { Heading, Link, Button, Flex, VStack } from "@chakra-ui/react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export default function Logos( {} ){

    return(
        <>        
            <Breadcrumb mb={3} fontSize='xs'>
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to='/'>Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to='/logos'>Logos</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <VStack justify={'flex-start'} align='flex-start'>
            <Heading size='sm'>JPEG/PNG</Heading>
              <Flex flexDir={'column'}>
                    <Button mb={2}>
                    <Link
                        href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2Flogo-white.jpg?alt=media"
                        target="_blank"
                                   
                        >
                        Logo - White Background
                        </Link>
                    </Button>
                    <Button mb={2}>
                    <Link
                      href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2Flogo-transaprent.png?alt=media"
                      target="_blank"
                       >
                      Logo - Transparent
                    </Link>
                    </Button>
                    <Button mb={2}>
                    <Link
                      href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2Fblack-logo-transparent.png?alt=media"
                      target="_blank"
                                       >
                      Black Logo - Transparent
                    </Link>
                    </Button>
                    <Button mb={2}>
                    <Link
                      href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2Flogo-with-color-codes.jpg?alt=media"
                      target="_blank"
                                         >
                      Logo With Color Codes
                    </Link>
                    </Button>
              </Flex>
          <Heading size='sm'>PDF</Heading>
          <Flex flexDir={'column'}>
            <Button mb={2}>
                <Link
                    href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FPDF%2Ffuture.pdf?alt=media"
                  target="_blank"
                >
                  Logo (PDF)
                </Link>
                </Button>
                <Button mb={2}>
              <Link
                  href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FPDF%2Ffuture%20home%20loans%20logo%20-%20Green%20_%20White.pdf?alt=media"
                  target="_blank"
                >
                  Logo (PDF) - Green/White
                </Link>
                </Button>
                <Button mb={2}>
                <Link
                  href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FPDF%2Ffuture%20home%20loans%20logo%20-%20Green%20_%20White%20with%20background%20color.pdf?alt=media"
                  target="_blank"
                    >
                     Logo (PDF) - Green/White with Background Color
                </Link>
                </Button>
                <Button mb={2}>
                <Link
                  href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FPDF%2Ffuture%20home%20loans%20logo%20-%20Blue%20_%20White%20with%20background%20color.pdf?alt=media"
                  target="_blank"
               >
                   Logo (PDF) - Blue/White with Background Color
                </Link>
                </Button>
          </Flex>
          <Heading size='sm'>EPS</Heading>
          <Flex flexDir={'column'}>
                <Button mb={2}>
                  <Link
                    href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FVector%2Ffuture-logo-color%5B1%5D.eps?alt=media"
                    target="_blank"
                     >
                    Logo (EPS) - Color
                  </Link>
                  </Button>
                  <Button mb={2}>
                  <Link
                    href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FVector%2Ffuture-logo-black%5B1%5D.eps?alt=media"
                    target="_blank"
                     >
                    Logo (EPS) - Black
                  </Link>
                  </Button>
              </Flex>
   
          <Heading size='sm'>SVG</Heading>
          <Flex flexDir={'column'}>
                <Button mb={2}>
                  <Link
                    href="https://firebasestorage.googleapis.com/v0/b/future-marketing-hub.appspot.com/o/marketing-vault%2FLogos%2FSVG%20File%20Type.svg?alt=media"
                    target="_blank"
                     >
                    Logo (SVG)
                  </Link>
                  </Button>
                 
              </Flex>
        </VStack>
        </>

    )
}