import { Box } from "@chakra-ui/react"
import moment from "moment"

export default function TimestampSeconds ( { seconds, dateFormat }){

    return(
        <Box 
            color='gray.500'
            letterSpacing='wide'
            fontSize={'12px'}
            >
             {moment(seconds*1000).format(dateFormat ? dateFormat : 'MM/DD/YY h:mm a') }
        </Box>
    )
}