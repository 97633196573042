import { Flex, Box, Divider, useColorModeValue, Text } from '@chakra-ui/react'
import React from 'react'

export default function DividerWithText(props) {
  const { children, ...flexProps } = props
  const text = props.text
  return (
    <Flex align='center' color={'gray.600'} {...flexProps} mb={3} mt={10}>
      <Box flex='1'>
        <Divider borderColor='currentcolor' />
      </Box>
      <Text
        as='span'
        px='3'
        color={useColorModeValue('black', 'gray.300')}
        fontWeight='medium'
        fontSize='lg'
      >
        {text}
      </Text>
      <Box flex='1'>
        <Divider borderColor='currentcolor' />
      </Box>
    </Flex>
  )
}
