import { Box, Flex } from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import CreatableOptions from "../../components/Settings/CreatableOptions";
import { db } from "../../utils/init-firebase";


export default function ManageLenderCategories ( { initialSettings } ){
    
    return(
        <>
            <Flex flexDir={{base: 'column', lg: 'row'}}>
                <Box w={{base: '85vw', md: '42vw'}} mr='1' boxShadow={'lg'} p={5} mt={5}>
                    <CreatableOptions
                        docRef={doc(db, 'Settings', 'yEwRIqrkneqrGnAQhgvs')}
                        settingName='Product Types'
                        addButtonText="Add Product"
                        showSettingNameAsHeading
                        initialSettings={initialSettings['Product Types']}
                    />
                </Box>
                <Box w={{base: '85vw', md: '42vw'}} boxShadow={'lg'} p={5} mt={5}>
                <CreatableOptions
                    docRef={doc(db, 'Settings', 'yEwRIqrkneqrGnAQhgvs')}
                    settingName='Property Types'
                    addButtonText="Add Property"
                    showSettingNameAsHeading
                    initialSettings={initialSettings['Property Types']}

                />
                </Box>
            </Flex>
        </>
    )
}