import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  useColorModeValue,
  Heading,
  Box,
  Text,
  Tfoot,
  IconButton,
  Select,
} from "@chakra-ui/react";
import { useTable, useSortBy, usePagination } from "react-table";
import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

export default function ReactTable_NoPagination({
  cols,
  data,
  title,
  colorScheme,
  maxWidth,
  width,
}) {
  const backgroundHeaderColor = useColorModeValue(
    `${colorScheme}.100`,
    `${colorScheme}.800`
  );
  const columns = React.useMemo(() => cols);

  const { getTableProps, headerGroups, footerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <>
      {title && (
        <>
          <Heading
            m="2"
            size="md"
            fontWeight={"semibold"}
            textTransform="uppercase"
          >
            {title}
          </Heading>
        </>
      )}
      {data && cols && data.length === 0 && cols.length === 0 ? (
        <Text m="2" fontSize="sm" textTransform={"uppercase"} color="gray">
          No data found
        </Text>
      ) : (
        <>
          <Table
            {...getTableProps()}
            minW={!maxWidth && "950px"}
            width={width}
            maxWidth={maxWidth}
            mt="1%"
            overflow={"scroll"}
            boxShadow="md"
            mb="5"
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      fontWeight="bold"
                      backgroundColor={backgroundHeaderColor}
                      userSelect="none"
                      minW={column.minWidth}
                      paddingLeft={column.paddingLeft}
                      paddingRight={column.paddingRight}
                      boxSizing={column.boxSizing}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Flex alignItems="center">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronDownIcon ml={1} w={4} h={4} />
                          ) : (
                            <ChevronUpIcon ml={1} w={4} h={4} />
                          )
                        ) : (
                          ""
                        )}
                        <Box ml={2} as="span">
                          {column?.canFilter ? column.render("Filter") : null}
                        </Box>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            maxWidth: cell.column.maxWidth,
                            minWidth: cell.column.minWidth,
                            fontSize: "14px",
                            paddingTop: 3,
                            paddingBottom: 3,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              {footerGroups.map((group) => (
                <Tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <Td
                      {...column.getFooterProps({
                        style: {
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: 5,
                          paddingBottom: 5,
                        },
                      })}
                    >
                      {column.render("footer")}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tfoot>
          </Table>
        </>
      )}
    </>
  );
}
