import { useAuth } from "../contexts/AuthContext"
import { useState, useEffect } from "react"
import { doc, getDoc } from "firebase/firestore"
import { db } from "../utils/init-firebase"
import { Box } from "@chakra-ui/react"

export default function ApprovalLetterPage(){

    const { currentUser } = useAuth()
    const [NMLS, setNMLS] = useState()
    const [phoneNo, setPhoneNo] = useState()
    const [loading, setLoading] = useState()

    useEffect( async () => {
     
        const userRef = doc(db, 'users', currentUser.uid)
        const docSnap = await getDoc(userRef)
        if (docSnap.exists()) {
            setNMLS(docSnap.data()['Google Admin Sync Data'] ?
                        docSnap.data()['Google Admin Sync Data'].customSchemas ?
                        docSnap.data()['Google Admin Sync Data'].customSchemas['Broker_Information'] ?
                        docSnap.data()['Google Admin Sync Data'].customSchemas['Broker_Information'].NMLS ? 
                        docSnap.data()['Google Admin Sync Data'].customSchemas['Broker_Information'].NMLS
                        : 
                        docSnap.data().NMLS ? 
                        docSnap.data().NMLS  
                        : '' : '': '' : '')
            setPhoneNo(docSnap.data()['Google Admin Sync Data'] ?
                        docSnap.data()['Google Admin Sync Data'].phones ?
                        docSnap.data()['Google Admin Sync Data'].phones[0] ?
                        docSnap.data()['Google Admin Sync Data'].phones[0].value ? 
                        docSnap.data()['Google Admin Sync Data'].phones[0].value.replace(/[^+\d]+/g, "") 
                        : '' : '' : '': '')
        } 
        setLoading(false)

    
    
      }, [])
    return(
        <>
        <Box mt='5'></Box>
        {!loading &&
            <iframe src={`https://docs.google.com/forms/d/e/1FAIpQLSflSZ_C2KI7GP6Z5MQ5QiLKFFhx9zkXk4oJ6Ifby3aJknzpvw/viewform?usp=pp_url&entry.1377812907=${currentUser.displayName.replace(' ', '+')}&entry.1921437270=${phoneNo}&entry.885873446=${NMLS}&entry.1314374842=${currentUser.email}&embedded=true`}
                width={"640"}  height={"2000"} frameBorder="0" marginheight="0" marginwidth="0">
                Loading…
            </iframe>
        }
        </>
    )
}