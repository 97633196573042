import { useState } from 'react'
import { db } from '../../utils/init-firebase';
import { updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import {
    Stack,
    Button,
    Flex,
    useToast,
    Alert,
    AlertTitle,
    AlertIcon,
    CloseButton,
    FormControl  } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom'
import { Select } from "chakra-react-select";

  export default function AssignRequest({ request, showView }) {
      const location = useLocation
    const toast = useToast()
    const { currentUser } = useAuth()
    const { marketingAdmins } = useAuth()
    const [selectedUsers, setSelectedUsers] = useState(null)

    console.log(marketingAdmins)

    const history = useHistory();
    const routeChange = (path) =>{ 
      history.push(`/marketing-requests/${path}`)
      toast.closeAll()
    }

    const handleClaim = async () => {
        let alertTitle = null
        let assignedBy = null
        let assignees = []
        // if user is claiming file
        if(selectedUsers == null){
          assignees.push( {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid
          } )
          assignedBy = 'Claimed'
          alertTitle = 'Request has been claimed'
        }
        // if user is assigning file
        else {
          selectedUsers.forEach((user) => {
            assignees.push( {
                Email: user.email,
                Name: user.label,
                photoURL: user.photoURL,
                uid: user.value
              } )
          })
          assignedBy = {
              Email: currentUser.email,
              Name: currentUser.displayName,
              photoURL: currentUser.photoURL,
              uid: currentUser.uid
          }
          alertTitle = `Request has been assigned`
        }
        try{
            await updateDoc(doc(db, 'Marketing Requests', request.id), {
                'Assignees': assignees,
                'Assigned By': assignedBy,
                id: request.id,
                'Status': 'In Progress',
                'Date In Progress': serverTimestamp(),
                'Last Edited By': {
                    Email: currentUser.email,
                    Name: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    uid: currentUser.uid
                }
            })
            
            toast({
                duration: 5000,
                position: 'top',
                render: () => (
                  <Alert status="success" borderRadius={10}>
                      <Stack direction='column'>
                            <Stack direction='row'>
                                <AlertIcon/>
                                <AlertTitle> {alertTitle} </AlertTitle>
                            </Stack>
                            <Flex direction='row' justifyContent={'space-evenly'}>
                                 {showView &&
                                    <Button onClick={() => routeChange(request.id)} size='sm' colorScheme='blue' >View</Button>
                                 }
                            </Flex>
                        </Stack>
                        <CloseButton/>
                  </Alert>
                ),
              })
        }
        catch(err){
            toast({
                title: 'Error',
                description: err,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        }

    }

    return (
        <>
        <Flex maxW={'300'} flexDirection={'column'} justifyContent={'center'}>
            <FormControl p={2}>
                <Select
                    isMulti
                    options={marketingAdmins}
                    onChange={(e) => setSelectedUsers(e)}
                    placeholder="Select People"
                    closeMenuOnSelect={false}
                    selectedOptionColor="green"
                    classNamePrefix={'assign-request-select'}
                />
                </FormControl>
            <Stack mt={3} direction={'row'} spacing={4}>
            <Button
                disabled={!selectedUsers}
                onClick={() => handleClaim()}
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                _focus={{
                    bg: 'gray.200',
                }}>
                Assign
                </Button> 
                
                <Button
                onClick={() => handleClaim()}
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                boxShadow={
                    '0px 1px 5px -5px rgb(66 153 225 / 48%), 0 7px 7px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                    bg: 'blue.500',
                }}
                _focus={{
                    bg: 'blue.500',
                }}>
                
                Claim
                </Button>
        </Stack> 
       </Flex>
       </>
    );
  }