import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
  useColorMode,
  Center,
  Text,
  IconButton,
  Divider,
  Input,
} from '@chakra-ui/react';
import { db } from '../../utils/init-firebase';
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { FaBullhorn, FaMoon, FaSun } from 'react-icons/fa'
import { FiBell, FiLogIn, FiMenu } from 'react-icons/fi';
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ReadNotificationDrawer from '../ReadNotificationDrawer';

export default function Navbar( { onOpen }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const history = useHistory();
  const { logout, currentUser, navSize, changeNavSize, isAdmin, SuperAdmin, MarketingAdmin, newRequests, inProgressRequests, inProgressCounter } = useAuth()
  const { setupQueueCount, notifications  } = useAuth()
  let christmasLights = require('../../assets/img/christmas-lights.png')
  let newYearsLights = require('../../assets/img/fireworks.png')
  const handleNavbarToggle = () => {
      if (navSize != "small")
          changeNavSize("small")
      else
          changeNavSize("large")
  }
  function handleClick(path) {
    history.push(path);
  }

  async function handleClickNotification(notification){
    if(notification.id == 'Home'){
      history.push('')
      return
    }

    if(notification.route){
      history.push(notification.route)
      return
    }
    if(notification.path){
      history.push(notification.path)
      return
    }
    else {
      let route = '/in-progress/'
      const docRef = doc(db, "Clients", notification.loanID);    
      const docSnap = await getDoc(docRef)
      if(docSnap.data()['Status'] == 'Completed'){
        route = '/completed/'
      }
      history.push(route + notification.loanID)
    }

  }

  async function markAllRead(){
     notifications.forEach( async (notification) => {
        await updateDoc(doc(db, "users", currentUser.uid, 'Notifications', notification.id), {
            read: serverTimestamp()
        })
      })
  }

  return (
    <>
{/*          <Box  
            backgroundImage={`url( ${newYearsLights} )`}
            backgroundSize='contain'
            backgroundRepeat={'repeat'}
            h='45px'
            mt='-20px'
            opacity={'0.6'}
            >
        
          </Box>  */}
    <Box>
      <Box 
        pt={1}
        px={3}
        boxShadow="0 6px 6px 0 rgba(0, 0, 0, 0.05)"
        position='relative'
        h={'48px'}
        //mt='-20px'
        >
          <Input style={{display: 'none'}}></Input>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
       <IconButton
            background="none"
            _hover={{ background: 'none' }}
            size='lg'
            display={{base: 'none', md: 'flex'}}
            variant='ghost'
            icon={navSize == "small" ? <FiMenu /> : <FiMenu />}
            onClick={handleNavbarToggle}
        /> 
        <IconButton
            background="none"
            _hover={{ background: 'none' }}
            size='lg'
            display={{base: 'flex', md: 'none'}}
            icon={navSize == "small" ? <FiMenu /> : <FiMenu />}
            onClick={onOpen}
        />
 
        <Flex alignItems={'center'} >
        <Stack direction={'row'} spacing={{'base' : 5, 'lg' : 7}}>
          <Menu>
            {MarketingAdmin &&
              <IconButton
                position={'relative'}
                py={'2'}
                background={'none'}
                aria-label={'Setup Queue'}
                size={'lg'}
                onClick={() => handleClick('/marketing-requests')}
                icon={<>
                    <FaBullhorn />
                      {newRequests && newRequests.length > 0 &&
                        <Box as={'span'} color={'white'} position={'absolute'} top={'4px'} left={'4px'} fontSize={'0.8rem'}
                            minWidth={'16px'} height={'18px'}
                            bgColor={'#FB404B'} borderRadius={'3xl'} zIndex={2} p={'1px'}>
                            {newRequests.length}
                        </Box>
                      }
                      {inProgressCounter > 0 &&   
                        <>                     
                          <Box as={'span'} color={'white'} position={'absolute'} top={'4px'} right={'4px'} fontSize={'0.8rem'}
                            minWidth={'16px'} height={'18px'}
                            bgColor={'orange'}  borderRadius={'3xl'} zIndex={2} p={'1px'}>
                              {inProgressCounter}
                          </Box>
                        </>
                        }
               
                </>}
              />
              }
            {isAdmin &&
              <IconButton
                position={'relative'}
                py={'2'}
                background={'none'}
                aria-label={'Setup Queue'}
                size={'lg'}
                onClick={() => handleClick('/setup-queue')}
                icon={<>
                    <FiLogIn />
                    {setupQueueCount > 0 ?
                      <Box as={'span'} color={'white'} position={'absolute'} top={'6px'} right={'4px'} fontSize={'0.8rem'}
                          minWidth={'16px'} height={'18px'}
                          bgColor={'#FB404B'} borderRadius={'3xl'} zIndex={2} p={'1px'}>
                          {setupQueueCount}
                      </Box>
                    : null}
                </>}
              />
              }
              <MenuButton
                as={IconButton}
                aria-label='Notifications'
                variant='ghost'
                size='lg'
                icon={<>
                  <FiBell />
                  {notifications &&
                  <>
                    {notifications.length > 0 &&
                      <Box as={'span'} color={'white'} position={'absolute'} top={'6px'} right={'4px'} fontSize={'0.8rem'}
                          minWidth={'16px'} height={'18px'}
                          bgColor={'#FB404B'} borderRadius={'3xl'} zIndex={2} p={'1px'}>
                          {notifications.length}
                      </Box>
                    }
                  </>
                  }
              </>}
                >           
              </MenuButton>
              <MenuList maxH={'35vh'} width={'350px'} overflowX='clip' overflowY={'auto'}>
              {notifications &&
                  <>
                {notifications.length > 0 ?
                  <>  
                    <Center>
                      <Button variant={'ghost'} onClick={markAllRead}>
                        Mark All Read
                      </Button>
                    </Center>
                    
                  {notifications.map(notification => {
                    return(
                      <>
                      <MenuItem
                          onClick={() => handleClickNotification(notification)}>
                            <Avatar mr={2} size='md' src={notification.user ? notification.user.photoURL : 'public/fhl-logo.png'} /> 
                            <Stack spacing='0.5' direction='column'>
                              {notification.notification ? 
                                  <Text fontSize='sm'>{notification.notification} </Text>
                              :
                              notification.title ? 
                                  <Text fontSize='sm'>{notification.title} </Text>
                              :
                              <>
                               {notification.user &&
                                <>
                                {notification.user.displayName && 
                                    <Text fontSize='sm'>{notification.user.displayName}&nbsp;
                                      <span style={{fontWeight:'bold'}}>Mentioned You</span>
                                    </Text>
                                  }
                                  </>
                               }
                              </>
                              }
                             
                              <Box 
                                  color='gray.500'
                                  letterSpacing='normal'
                                  fontSize={{
                                    'base':'10px',
                                    'lg': '12px'
                                  }}
                                 >
                                    {notification?.timestamp ? moment(notification?.timestamp.toDate()).calendar() : null }
                              </Box>
                            
                              <Text fontSize='xs' isTruncated as='em'>{notification.message}</Text>
                            </Stack>
                        </MenuItem >
                        <Divider/>
                        </>
                        )
                  
                  })} 
    
                  </>

              : <>
                <Text m={5} as='cite'>You have no notifcations</Text>
                <ReadNotificationDrawer />
                </>
              }
              </>
              }
              </MenuList>
          </Menu>
          
            <Button variant={'ghost'} size='lg' onClick={toggleColorMode}>
              {colorMode === 'light' ? <FaMoon /> : <FaSun />}
            </Button>

            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={currentUser.photoURL}
                />
              </MenuButton>
              <MenuList alignItems={'center'}>
                <br />
                <Center>
                  <Avatar
                    size={'xl'}
                    src={currentUser.photoURL}
                  />
                </Center>
                <br />
                <Center>
                  <Text fontWeight='bold'>{currentUser.displayName}</Text>
                </Center>
                <br />
                <MenuDivider />
                  <MenuItem
                      onClick={() => handleClick('/profile')}
                  >
                      Profile
                  </MenuItem>
                  <MenuItem
                     onClick={async e => {
                      e.preventDefault()
                      await logout()
                    }}>
                    <Link
                      to='/logout'
                      />
                      Logout
                  </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Flex>
    </Box>
    </Box>

  </>
  )
}
