import { useState } from "react";
import { Button, HStack, FormControl, Input, IconButton, useToast, Text, Box, Heading } from "@chakra-ui/react";
import { FiX } from "react-icons/fi";
import { getDoc, setDoc, updateDoc, DocumentReference } from "firebase/firestore";
/*
    This component will allow the user to edit an array of strings in a specified document

    docRef (firestore document reference), the document reference where the settings are stored (required)
    settingName (string), the name of the setting (required)
    initialSettings (array), an array of strings that contains the current settings (optional)
    uneditableOptions (array), an array of strings that will be shown to the user but are not editable (optional)

    examples:
    docRef = doc(db, "Settings", 'yEwRIqrkneqrGnAQhgvs');
    settingName = 'Loan Type Options'
    initialSettings = ['VA', 'Convential', 'FHA']
    uneditableOptions = ['Other/Non-QM']
*/
interface CreatableOptionsProps {
  docRef: DocumentReference | string; // string added to avoid errors in Cypress tests
  initialSettings?: string[];
  settingName: string;
  uneditableOptions?: string[];
  height?: string,
  showSettingNameAsHeading?: boolean,
  addButtonText?: string
}

export default function CreatableOptions({ docRef, initialSettings, settingName, uneditableOptions, addButtonText, showSettingNameAsHeading, height }: CreatableOptionsProps) {
  const toast = useToast();
  const [options, setOptions] = useState(initialSettings ? initialSettings : []);

  const handleSetOption = (input: string, index: number) => {
    const opts = [...options];
    opts[index] = input;
    setOptions(opts);
  };

  const addOption = () => {
    setOptions([...options, ""]);
  };

  const removeOption = (index: number) => {
    const opts = [...options];
    opts.splice(index, 1);
    setOptions(opts);
  };

  const save = async () => {
    try {
      if(typeof docRef != 'string') {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            [settingName]: options,
          });
        } else {
          await setDoc(docRef, {
            [settingName]: options,
          });
        }

        toast({
          title: `${settingName} Updated!`,
          description: 'Users will have to refresh their browsers to see this change',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (err) {
      toast({
        title: `Error updating ${settingName}`,
        description: err,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <>
      {docRef && settingName ? (
        <>
          {showSettingNameAsHeading && <Heading ml='2' size='xs'>{settingName}</Heading>}
          <Box overflowY={'auto'} height={height ? height : '25vh'}>
          {options &&
            options.map((option, i) => {
              return (
                <HStack data-testid={`option-${i}`} key={`${settingName.toLowerCase()}-option-${i}`} dir="row">
                  <FormControl w={{base: '90%', xl: '75%'}} p={1}>
                    <Input autoFocus={options.length-1 === i && option === ""} data-testid={`option-input-${i}`} value={option} onChange={(e) => handleSetOption(e.target.value, i)} />
                  </FormControl>
                  <IconButton data-testid={`remove-button-${i}`} aria-label="Remove option" icon={<FiX />} onClick={() => removeOption(i)} />
                </HStack>
              );
            })}
          {uneditableOptions &&
            uneditableOptions.map((option, i) => {
              return (
                <HStack data-testid={`disabled-option-${i}`} key={`${settingName.toLowerCase()}-option-${i}`} dir="row">
                  <FormControl w={{base: '90%', lg: '75%'}} p={1}>
                    <Input data-testid={`disabled-option-input-${i}`} disabled value={option} onChange={(e) => handleSetOption(e.target.value, i)} />
                  </FormControl>
                </HStack>
              );
            })}
          </Box>
          <Button data-testid={`add-option-button`} m={2} p={4} mb={5} onClick={addOption}>
            {addButtonText ? addButtonText : 'Add Option'}
          </Button>
          <Button data-testid={`save-button`} colorScheme={'blue'} m={2} p={4} mb={5} onClick={save}>
            Save
          </Button>
        </>
      ) : (
        <>
          <Text>Error displaying settings</Text>
        </>
      )}
    </>
  );
}
