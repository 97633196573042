import { useState, useEffect, useRef } from 'react'
import {
    Flex,
    Heading,
    Box,
    Button,
    useToast,
    Stack,
    IconButton,
    ScaleFade,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Input,
    FormLabel,
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    Tag,
    TagLeftIcon,
    useColorMode,
    FormControl,
    Switch
  } from '@chakra-ui/react'
import { doc, collection, setDoc, serverTimestamp, query, orderBy, onSnapshot, deleteDoc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../utils/init-firebase'
import { useForm } from 'react-hook-form'
import { FiCheckCircle, FiCircle, FiEdit3 } from 'react-icons/fi'
import { useAuth } from '../../contexts/AuthContext'
import LinkList from './LinkList'

export default function HelpfulLinkSections( { noBoxShadow } ){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode } = useColorMode()
    const { SuperAdmin } = useAuth()

    const [sections, setSections] = useState()

    const [loading, setLoading] = useState(true)
    const formRef = useRef()

    const [sectionName, setSectionName] = useState('')
    const [selectedSection, setSelectedSection] = useState(null)

    const toast = useToast()
    const {
		handleSubmit,
        reset
	} = useForm()

    const addSection = async () => {
        try{
            const newDocRef = doc(db, 'Helpful Link Sections', sectionName)

            const docSnap = await getDoc(newDocRef)
            if(docSnap.exists()){
                toast({
                    description: 'This section already exists',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                    position: 'top'
                })
                return;
            }

            await setDoc(newDocRef, {
                'Name': sectionName,
                'Date Created': serverTimestamp(),

            })
            setSectionName(null)
            toast({
                title: 'Section Added!',
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top'
            })
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    }
    const deleteSection = async () => {
        try{
            await deleteDoc(doc(db, 'Helpful Link Sections', selectedSection?.Name))
            
            toast({
                title: 'Section Deleted!',
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top'
            })
            setSelectedSection(null)
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    }

    useEffect(() => {
         const q = query(collection(db, "Helpful Link Sections"), orderBy('Date Created', 'asc'));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let sections = []
          querySnapshot.forEach((doc) => {
              sections.push(doc.data())

          })
          setSections(sections)
          setLoading(false)
        })
        return () => {
            unsubscribe()
        }
    
    }, [])

    const handleToggleAnimate = async (e) => {
        if(selectedSection){
                try{
                    const sectionRef = doc(db, 'Helpful Link Sections', selectedSection?.Name)
        
                    await updateDoc(sectionRef, {
                        animated: e.target.checked
                    })

        
                    toast({
                        title: `${selectedSection?.Name} ${e.target.checked ? 'Animated' : 'Animation Removed'}!`,
                        status: 'success',
                        isClosable: true,
                        duration: 3000,
                        position: 'top'
                    })
                }
                catch(err){
                    toast({
                        description: err,
                        status: 'error',
                        isClosable: true,
                        duration: 5000,
                        position: 'top'
                    })
            }
        }
    }

    return(
        <> 
            <ScaleFade in>
                <Flex p={3} dir='row' justifyContent={'flex-start'}>
                <Heading colorScheme={'gray'} as='em' size='md'>Helpful Links</Heading>
                    {SuperAdmin &&
                    <IconButton
                        variant={'outline'}
                        size='sm'
                        colorScheme='green'
                        id={`toggle-edit-mode`}
                        ml={2}
                        icon={<FiEdit3/>}
                        w='35px'
                        onClick={onOpen}>
                            Edit
                    </IconButton>
                }
                </Flex>
            </ScaleFade>
            <Box p={3} boxShadow={!noBoxShadow && 'lg'} borderRadius={!noBoxShadow && 'lg'}>
                {sections &&
                    <>
                    <Accordion allowMultiple>
                        {sections.map((section) => {
                            return(
                                <AccordionItem key={section.Name}>
                                <AccordionButton className={section?.animated && (colorMode == 'light' ? 'animated-box-light' : 'animated-box-dark')}
>
                                    <Box flex='1' textAlign='left'>
                                    {section.Name}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel p={1}>
                                    <LinkList selectedSection={section.Name} />
                                </AccordionPanel>
                            </AccordionItem>
                            )
                        })}            
                    </Accordion>
                    </>
                }
            </Box>
                <Modal size='6xl' isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Helpful Links</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack mb={3} direction={'row'}>
                            <Input maxW={'500px'} onChange={(e) => setSectionName(e.target.value)} value={sectionName}/>
                            <Button w='150px' colorScheme='blue' m={2} disabled={!sectionName} onClick={addSection}>Add Section</Button>
                            <Button colorScheme='red' mr={3} disabled={!selectedSection} onClick={deleteSection}>
                                Delete {selectedSection?.Name}
                            </Button>
                        </Stack>
                            <FormLabel fontWeight={'bold'} fontSize='large' as='legend'>Sections</FormLabel>
                                <Flex flexDir={'row'} flexWrap='wrap' spacing='24px'>
                                {sections && sections.map((section) => {
                                    return(
                                        <Button 
                                            key={`set-section-${section.id}`} 
                                            m={0.5} 
                                            p={1} 
                                            height={'30px'} 
                                            variant={'ghost'} 
                                            className={section?.animated && (colorMode == 'light' ? 'animated-box-light' : 'animated-box-dark')}
                                            onClick={() => setSelectedSection(section)}>
                                      
                                            <Tag 
                                                p='1'
                                                fontWeight={selectedSection?.Name == section.Name ? 'bold' : 'normal'} 
                                                variant={selectedSection?.Name == section.Name ? 'solid' : 'subtle'}
                                                colorScheme={selectedSection?.Name == section.Name ? 'blue' : 'blue'}
                                                >
                                                {selectedSection?.Name == section.Name ? 
                                                    <TagLeftIcon as={FiCheckCircle}/> :
                                                    <TagLeftIcon as={FiCircle}/>
                                                }
                                                {section?.Name && section.Name}
                                          
                                            </Tag>
                                        </Button>
                                    )
                                })}
                                </Flex>
                            
                            {selectedSection && 
                                <>
                                <FormControl m='3' display={'flex'} key={`section-${selectedSection?.Name}-animation-toggle`}>
                                    <FormLabel fontSize={'sm'}>
                                        <Switch 
                                            defaultChecked={selectedSection?.animated} 
                                            mr={1} 
                                            onChange={(e) => handleToggleAnimate(e)}>
                                        </Switch>
                                        Animate {selectedSection?.Name}
                                    </FormLabel>
                                </FormControl>
                                <LinkList key={`edit-${selectedSection?.Name}`} selectedSection={selectedSection?.Name} editMode={true} />
                                </>
                            }

                    </ModalBody>

                    <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    </ModalFooter>
                </ModalContent>
                </Modal>
              
   </>
       
    )
}