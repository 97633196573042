import { Flex, Stat, StatHelpText, StatArrow, Box, Avatar, Text, useColorModeValue} from "@chakra-ui/react"
import { numberWithCommas } from "../../functions/globalFunctions"
export default function TopPerformers_CompanyStats ( { previousMonthData, data, reviewData, previousReviewData } ){
    return(
          <Box
     
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow='md'
            rounded={'xl'}
            w='100%'
           >
            <Flex flexDir={{base: 'column', lg: 'row'}} align='center' justify={'space-around'}>

                <Avatar textAlign={'center'} background={'none'} size={'lg'}   src={require('../../assets/img/fhl-logo.png')}></Avatar>
                {data['Dollar Volume'] &&
                <Flex ml={3} mr={3} mb={{base: 3, xl: 0}} flexDir={{base: 'column', xl: 'row'}} align={'center'}>
                    <Text fontSize='sm' mr={2} color={'gray.500'}>Volume of Loans Funded&nbsp;</Text>
                    <Text fontSize={'2xl'} fontWeight='semibold'>${numberWithCommas(data['Dollar Volume'])}</Text>
                    {previousMonthData && previousMonthData['Dollar Volume'] &&
                        <Stat>
                        <StatHelpText ml={1}  mb={0}>
                        <StatArrow type={data['Dollar Volume'] - previousMonthData['Dollar Volume'] > 0 ? 'increase' : 'decrease'}/>
                            {((data['Dollar Volume'] - previousMonthData['Dollar Volume']) / previousMonthData['Dollar Volume'] * 100).toFixed(2)}%
                        </StatHelpText>
                        </Stat>
                    }
                </Flex>
                }
                {data['No Transactions'] &&
                <Flex ml={3} mr={3} mb={{base: 3, xl: 0}} flexDir={{base: 'column', xl: 'row'}} align={'center'}>
                    <Text fontSize='sm' mr={2} color={'gray.500'}>Loans Funded&nbsp;</Text>
                    <Text fontSize={'2xl'} fontWeight='semibold'>{numberWithCommas(data['No Transactions'])}</Text>
                    {previousMonthData && previousMonthData['No Transactions'] &&
                        <Stat>
                        <StatHelpText ml={1}  mb={0}>
                        <StatArrow type={data['No Transactions'] - previousMonthData['No Transactions'] > 0 ? 'increase' : 'decrease'}/>
                            {((data['No Transactions'] - previousMonthData['No Transactions']) / previousMonthData['No Transactions'] * 100).toFixed(2)}%
                        </StatHelpText>
                        </Stat>
                    }
                </Flex>
                }
                {reviewData['Company Results'] &&
                <Flex ml={3} mr={3} mb={{base: 3, xl: 0}} flexDir={{base: 'column', xl: 'row'}} align={'center'}>
                    <Text fontSize='sm' mr={2} color={'gray.500'}>Five-Star Reviews&nbsp;</Text>
                    <Text fontSize={'2xl'} fontWeight='semibold'>{numberWithCommas(reviewData['Company Results'])}</Text>
                    {previousReviewData && previousReviewData['Company Results'] &&
                        <Stat>
                        <StatHelpText ml={1}  mb={0}>
                        <StatArrow type={reviewData['Company Results'] - previousReviewData['Company Results'] > 0 ? 'increase' : 'decrease'}/>
                            {((reviewData['Company Results'] - previousReviewData['Company Results']) / previousReviewData['Company Results'] * 100).toFixed(2)}%
                        </StatHelpText>
                        </Stat>
                    }
                </Flex>
                }
            </Flex>

        </Box>

    )
}