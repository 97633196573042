import { TextareaControl, SelectControl, InputControl, RadioGroupControl } from "formik-chakra-ui"
import {
    Flex,
    Center,
    Wrap,
    WrapItem,
    useColorModeValue,
    Text,
    ScaleFade,
    Button,
    FormControl,
    Input,
    Radio
} from "@chakra-ui/react";
import { DatePickerField } from "../DatePicker";
import { useAuth } from "../../contexts/AuthContext";
import { CreatableSelect } from "chakra-react-select";
export default function MarketingSpendFields( { values, categories, owner, setOwner, quickComplete } ){
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")
    const { users } = useAuth()

    return(
        <ScaleFade in initialScale={0.8}>
        <>
            <FormControl w='450px' mr={5} mb='2' dir='row'>
                <CreatableSelect
                    name="Credit To:"
                    options={users}
                    placeholder="Attribute to..."
                    value={owner}
                    onChange={setOwner}
                    closeMenuOnSelect={true}
                    isClearable
                    required
                />
            </FormControl> 
            <InputControl w='250px' mb={2} name="Amount" label="Amount"></InputControl>
            <Text mb='1' fontWeight={'semibold'}>Category</Text>
            <RadioGroupControl
                    radioGroupProps={{style: {fontSize: '40px'}}}
                    name="Spend Category"
                    mb='5'
                    display={'flex'}
                    flexWrap='wrap'
                >
                {categories ? categories?.sort().map((category) => (
                    <Radio value={category}>{category}</Radio>
                ))
                :
                <>                
                    <Radio value="Association Dues">Association Dues</Radio>
                    <Radio value="Event Sponsorships">Event Sponsorships</Radio>
                    <Radio value="Luncheons">Luncheons</Radio>
                    <Radio value="Printables">Printables</Radio>
                    <Radio value="Realtor Drops">Realtor Drops</Radio>
                    <Radio value="Misc">Misc</Radio>
                </>
                } 
            </RadioGroupControl>
            <TextareaControl mb={2} name="Description" label="Notes" />
        </>
        </ScaleFade>
    )
}