import { useState, useEffect } from "react";
import { Center, Heading, useColorModeValue, Flex, SimpleGrid, FormControl } from "@chakra-ui/react";
import { datepickerLight, datepickerDark } from "../../contexts/GlobalVariables";
import { getDocs, limit, orderBy, query, collection } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { useAuth } from "../../contexts/AuthContext";
import TopPerformers_Edit from "./TopPerformers_Edit";
import TopPerformers_IndividualBox from "./TopPerformers_IndividualBox";
import TopPerformers_GoogleReviews from "./TopPerformers_GoogleReviews";
import TopPerformers_CompanyStats from "./TopPerformers_CompanyStats";
import { Select } from "chakra-react-select";
import TopPerformers_ReviewCarousel_V2 from "./TopPerformers_ReviewCarousel_V2";

export default function TopPerformers ( { } ) {
    const { SuperAdmin } = useAuth()
    const [displayedMonth, setDisplayedMonth] = useState(null)
    const [previousMonth, setPreviousMonth] = useState(null)
    const [loading, setLoading] = useState(true)

    const [allMonths, setAllMonths] = useState(null)
    const [options, setOptions] = useState()

    const [reviewList, setReviewList] = useState(null)

    const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)

    const fetchTopPerformers = async () => {
        setLoading(true)
        const q = query(collection(db, "Top Performers"), orderBy('Month Year Timestamp', 'asc'));
        const querySnapshot = await getDocs(q)
        let data = {}
        let options = []
        let index = 0
        querySnapshot.forEach((doc) => {
            data[`${doc.id}`] = doc.data()
            options.push({value: doc.id, label: doc.id, index: index})
            setDisplayedMonth({label: doc.id, value: doc.id, index: index++})
        })
   /*      if(index > 0){
            setPreviousMonth(allMonths[options[displayedMonth.index - 1].value])
        } */
        setOptions(options)
        setAllMonths(data)
        setLoading(false)
    }

    const fetchReviewList = async () => {
        const q = query(collection(db, "Google Reviews Version 2"), orderBy('timestamp', 'desc'), limit(1));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setReviewList(doc.data().reviews)
        });
    
    }
    useEffect( async () => {
        fetchTopPerformers()
        fetchReviewList()
    }, [])

    useEffect(() => {
        console.log(allMonths)
    }, [allMonths])

    return(
        <>
        {!loading && allMonths && displayedMonth &&
            <>

                <Flex mb={5} mt={5} flexDir={{base: 'column-reverse', md: 'row'}} justify='center'>
                        <Flex mb={5} flexDir={{base: 'column', md: 'row'}} align='center' justify='center'>
                            <Heading mr={2} mb={2} textAlign={'center'} as='em' size='md'>
                                Top Performers
                            </Heading>
                            <Heading mr={2} textAlign={'center'} as='em' size='md'>
                                    <FormControl w={'215px'}>        
                                        <Select 
                                            size="md"
                                            onChange={setDisplayedMonth}
                                            value={displayedMonth}
                                            defaultValue={displayedMonth}
                                            options={options}
                                        >
                                        </Select>
                                    </FormControl>
                                
                            </Heading>
                        </Flex>
                        {SuperAdmin && 
                            <TopPerformers_Edit 
                                fetchTopPerformers={fetchTopPerformers} 
                                initialMonthString={displayedMonth.value}/>
                        }
                        
                </Flex>
                <SimpleGrid minChildWidth={'135px'} spacing='20px'>
                    <TopPerformers_IndividualBox awardName={'The Duke'} amount={allMonths[displayedMonth.value]['Duke']['Amount']} user={allMonths[displayedMonth.value]['Duke']['User']}/>
                    <TopPerformers_IndividualBox awardName={'The Rocky'} amount={allMonths[displayedMonth.value]['Rocky']['Amount']} user={allMonths[displayedMonth.value]['Rocky']['User']}/>
                    <TopPerformers_IndividualBox awardName={'The Harry Potter'} amount={allMonths[displayedMonth.value]['Harry Potter']['Amount']} user={allMonths[displayedMonth.value]['Harry Potter']['User']}/>
                    <TopPerformers_IndividualBox awardName={'The Charlie Hustle'} amount={allMonths[displayedMonth.value]['Hustler']['Amount']} user={allMonths[displayedMonth.value]['Hustler']['User']}/>
                </SimpleGrid>

                <TopPerformers_GoogleReviews data={allMonths[displayedMonth.value]['Top Google Reviews']}/>
            
                <TopPerformers_CompanyStats 
                    //previousMonthData={displayedMonth.index > 0 && allMonths[options[displayedMonth.index - 1].value]['Funded Loans - Company']} 
                    //previousReviewData={displayedMonth.index > 0 && allMonths[options[displayedMonth.index - 1].value]['Top Google Reviews']} 
                    data={allMonths[displayedMonth.value]['Funded Loans - Company']} 
                    reviewData={allMonths[displayedMonth.value]['Top Google Reviews']} 
                />
                
                </>
            }
        
        {!loading && Object.keys(allMonths).length === 0 && SuperAdmin && <><Center><Heading size='md' mr='2'>No Top Performers Found</Heading><TopPerformers_Edit initialMonthString={'January 2022'}/></Center></>}
        {reviewList &&
                    <TopPerformers_ReviewCarousel_V2 reviewList={reviewList} />
                } 
        </>
    )

}