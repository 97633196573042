import ReactDatePicker from "react-datepicker";
import { useState, useEffect } from "react";
import { Skeleton, Heading, Center, Box, Input, useColorModeValue, IconButton, useDisclosure, Drawer, DrawerOverlay, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerCloseButton, Flex, HStack } from "@chakra-ui/react";
import { datepickerLight, datepickerDark } from "../../contexts/GlobalVariables";
import { FiEdit3 } from "react-icons/fi";
import { db } from "../../utils/init-firebase";
import { getDoc, doc } from "firebase/firestore";
import TopPerformers_Fields from "./TopPerformers_Fields";
import "react-datepicker/dist/react-datepicker.css";


export default function TopPerformers_Edit ( { initialMonthString, fetchTopPerformers } ) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)

    const [selectedMonth, setSelectedMonth] = useState(new Date(initialMonthString.toLocaleString()))
    const [monthYearString, setMonthYearString] = useState(initialMonthString)
    const [initialValues, setInitialValues] = useState()
    const [loading, setLoading] = useState()
    const [action, setAction] = useState()
    const [showDatePicker, setShowDatePicker] = useState(false)


    useEffect( async () => {
        if(selectedMonth){
            setLoading(true)
            const monthYear = selectedMonth.toLocaleString('default', { month: 'long' , year: 'numeric' });
            setMonthYearString(monthYear)

            try{
                const docRef = doc(db, 'Top Performers', monthYear)
                const docSnap = await getDoc(docRef)
                if(docSnap.exists()){
                    setInitialValues(docSnap.data())
                    setAction('Edit')
                }
                else {
                    setInitialValues(null)
                    setAction('Create')
                }
                setLoading(false)
            }
            catch(err){
                console.log(err)
            }
        }

    }, [selectedMonth])

    return(
        <>
        {selectedMonth &&
        <>
            <IconButton mt={1} maxW={'30px'} colorScheme={'teal'} variant='outline' size='sm' icon={<FiEdit3/>} onClick={onOpen}></IconButton>
            <Drawer size='lg' placement='bottom' isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent minHeight={'65vh'}>
                <DrawerHeader>
                    <Flex mt={{base: '10vh', md: '5vh'}} mb={8} justifyContent={'center'}  flexDir={'row'}>
                        <Center><Heading color={action == 'Edit' ? 'orange.500' : 'teal'} mr={2} as='em' size='md'>{action} Top Performers&nbsp;</Heading></Center>
                        <Box w='175px' border={'1px'} borderRadius='md' borderColor={action == 'Edit' ? 'orange.500' : 'teal.500'}>
                            <ReactDatePicker
                                selected={selectedMonth}
                                onSelect={(date) => setSelectedMonth(date)}
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                className={datepickerClass}
                                placeholderText='Select Month'  
                                autoFocus={false}
                                popperPlacement='right'
                                
                            />
                        </Box>
                    </Flex>
                </DrawerHeader>
                <DrawerCloseButton />
                <DrawerBody ml={{base: '0', md: '8vw'}} mr={{base: '0', md: '8vw'}}>
                    <Input style={{display: 'none'}}></Input>
                    {(!loading && monthYearString) ? 
                        <TopPerformers_Fields 
                            action={action} 
                            onClose={onClose} 
                            initialValues={initialValues} 
                            monthYearString={monthYearString} 
                            fetchTopPerformers={fetchTopPerformers}
                        />
                    :
                    <>
                        <HStack spacing={20} mb={10}>
                            <Skeleton height='200px' width='20%' />
                            <Skeleton height='200px' width='20%' />
                            <Skeleton height='200px' width='20%' />
                            <Skeleton height='200px' width='20%' />

                        </HStack>
                        <HStack spacing={5}>
                            <Skeleton height='200px' width='40%' />
                            <Skeleton height='200px' width='58%' />
                        </HStack>
                    </>
                    }

                </DrawerBody>

                <DrawerFooter>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
            </>
        }
        </>
    )

}