import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { db } from '../utils/init-firebase';
import { doc, getDoc, onSnapshot } from "firebase/firestore"
import { useAuth } from './AuthContext';

const VersionContext = createContext({
  currentVersion: null,
  latestVersion: null
})

export const useVersion = () => useContext(VersionContext)

export default function VersionProvivder ({ children }) {
  const { loading, currentUser } = useAuth()
  const [latestVersion, setLatestVersion] = useState(null)
  const [currentVersion, setCurrentVersion] = useState(null)
  const unsubRef = useRef()

    useEffect( async () => {
        const getCurrentVersion = async () => {
            const docRef = doc(db, "Version", "Version");
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
              setCurrentVersion(docSnap.data().Latest)
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }        
          };
        
        const listenForLatestVersion = async () => {
            unsubRef.current && unsubRef.current()
            unsubRef.current = onSnapshot(doc(db, "Version", "Version"), (doc) => {
                setLatestVersion(doc.data().Latest)
            });
      
        };

        await getCurrentVersion()
        await listenForLatestVersion()

        return () => {
            unsubRef.current && unsubRef.current()
        }
    }, [currentUser])

  const value = {
    currentVersion,
    latestVersion
  }
  return <VersionContext.Provider value={value}>{!loading && children}</VersionContext.Provider>
}
