import {
  Button,
  Heading,
  Stack,
  Image,
  useToast,
  Center,
} from '@chakra-ui/react'
import { FaGoogle } from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router-dom'
import { Card } from '../components/Card'
import { useAuth } from '../contexts/AuthContext'
import useMounted from '../hooks/useMounted'

export default function Loginpage() {
  const history = useHistory()
  const { signInWithGoogle, initUser } = useAuth()
  const toast = useToast()
  // const mounted = useRef(false)
  const location = useLocation()

  // useEffect(() => {
  //   mounted.current = true
  //   return () => {
  //     mounted.current = false
  //   }
  // }, [])

  const mounted = useMounted()

  function handleRedirectToOrBack() {
    history.replace(location.state?.from ?? '/profile')

  }

  return (

      <Card maxW='md' mx='auto' mt={150}>
        <Center>
          <Stack>
            <Image
              boxSize='250px'
              src={require("../assets/img/fhl-logo.png")}
            >
            </Image>
            <Heading textAlign={'center'} size='md'>
              The Future Is One
            </Heading>
            <br></br>

            <Button
              variant='solid'
              isFullWidth
              mt={50}
              colorScheme='teal'
              leftIcon={<FaGoogle />}
              onClick={() =>
                signInWithGoogle()
                  .then( (user) => {
                    handleRedirectToOrBack()
                  })
                  .catch(e => console.log(e.message))
              }
            >
              Sign in with Google
            </Button>
          </Stack>
        </Center>
      </Card>
)
}
