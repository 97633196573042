import { useEffect, useState } from "react"
import { Flex, Image, Center, Text } from "@chakra-ui/react"

export default function Item ( { id, inventory, size } ){

    const [loading, setLoading] = useState(true)
    const [name, setName] = useState()
    const [url, setURL] = useState()
    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        const findItemDetails = () => {

            if(inventory && id){
                const itemIndex = inventory.findIndex(x => x.id === id)
                if(itemIndex > -1){
                   setName(inventory[itemIndex].Name)
                   setURL(inventory[itemIndex].photoURL)
                   setHidden(inventory[itemIndex].Hidden && inventory[itemIndex].Hidden)
                }
                else {
                    setName('Error retreiving item name')
                    setURL('Error retreiving item url')
                } 
            }
        }
        findItemDetails()
        setLoading(false)
    }, [])

    return(
        <>        
        {!loading && name && url && name != 'Error retreiving item name' &&
            <>
            <Flex w='100%' justify={'flex-start'} mt={2}>
            <Image mr={2} src={url} boxSize={size == 'lg' ? '85px' : '35px'}></Image>
                <Center>
                    <Text color={hidden && 'red.400'} fontSize={size ? size : 'sm'}>{name}</Text>
                </Center>
            </Flex>
            </>
        }
        </>
    )
}