import { useState } from "react";
import { Flex, Box, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsletterButton from "./NewsletterButton";

// Settings for the slider
const settings = {
    dots: true,
    arrows: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
export default function MonthlyNewslettersCarousel( { newsletters } ) {
    const boxBg = useColorModeValue('white', 'gray.800')
    // As we have used custom buttons, we need a reference variable to
    // change the state
    const [slider, setSlider] = useState(null);

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    const top = useBreakpointValue({ base: '90%', md: '50%' });
    const side = useBreakpointValue({ base: '30%', md: '3px' });

  return (
    <>
    {newsletters && newsletters.length > 0 &&
        <>
        <Box
            bg={boxBg}
            boxShadow='md'
            rounded={'xl'}
            w='full'
            mt={3}
            position='relative'
            overflow={'hidden'}
            p={3}
        >
{/*  
                <IconButton
                    aria-label="left-arrow"
                    borderRadius="full"
                    position="absolute"
                    left={side}
                    top={top}
                    variant='ghost'
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}>
                    <BiLeftArrowAlt />
                </IconButton>
                <IconButton
                    aria-label="right-arrow"
                    borderRadius="full"
                    position="absolute"
                    variant={'ghost'}
                    right={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}>
                    <BiRightArrowAlt />
                </IconButton>
               <Slider {...settings} ref={(slider) => setSlider(slider)}>
 */}                        {newsletters.map((newsletter, i) => {
                            return(

                                    <Flex
                                        key={i}
                                        flexDir='column'
                                        display={'flex'}
                                        alignItems='flex-start'
                                        justifyContent={'flex-start'}
                                        p={1}
                                        pl={'6%'}
                                        pr={'7%'}
                                    >
                                        <NewsletterButton key={i} newsletter={newsletter}/>
                                </Flex>
                            )
                        })}
{/*                     </Slider>
 */}                 
        </Box> 
        </>
    }
    </>
  );
};