import { useAuth } from "../../contexts/AuthContext"
import { Text } from "@chakra-ui/react"

export default function MarketingDashboard( { } ){
    const { MarketingAdmin, SuperAdmin } = useAuth()
    return(
        <>
        {(MarketingAdmin || SuperAdmin) ? 
      
            <iframe width={"100%"}
                    height={"900px"} 
                    src={"https://lookerstudio.google.com/embed/reporting/3f3b0179-c05e-4175-bdee-356f3271076f/page/p_29dly5uj5c"}
                    frameBorder="0" 
                    >
            </iframe>
            :
            <Text>You do not have permission to access this page</Text>
         }
      </>
    )
}