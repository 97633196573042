import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button
  } from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import { updateDoc, serverTimestamp } from 'firebase/firestore'
import { useAuth } from '../contexts/AuthContext'

// Currently supports New, In Progress & Completed statuses only
export default function StatusChangeDropdown( { currentStatus, statuses, docRef, fieldValue } ){
    const { currentUser } = useAuth()


    const handleChangeStatus = async (newStatus) => {
        const timestamp = serverTimestamp()
        await updateDoc(docRef, {
            [fieldValue]: newStatus,
            [`Date ${newStatus}`]: timestamp,
            'Last Edited By': {
                Email: currentUser.email,
                Name: currentUser.displayName,
                photoURL: currentUser.photoURL,
                uid: currentUser.uid
            }
        })
    }

    return(
        <>
            <Menu>
                <MenuButton 
                    as={Button} 
                    size='xs'
                     colorScheme={currentStatus == 'New' ? 'red' : 
                                     currentStatus == 'In Progress' ? 'orange' : 
                                     'green'} 
                    rightIcon={<FaChevronDown />}
                    textTransform='uppercase'
                    h='4'
                    >
                    {currentStatus}
                </MenuButton>
                <MenuList>
                    {statuses && statuses.map((status) => (
                        <>
                            <MenuItem 
                                onClick={() => handleChangeStatus(status)} 
                                isDisabled={status == currentStatus}
                                fontWeight={'bold'}
                                color={status == 'New' ? 'red' : 
                                        status == 'In Progress' ? 'orange' : 
                                        'green'} >
                                    {status}
                            </MenuItem>   
                       </>
                ))}
                </MenuList>
            </Menu>
       
        </>
    )
}