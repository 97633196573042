import React from "react";
import { Icon, Tooltip } from "@chakra-ui/react";
import { UnlockIcon } from "@chakra-ui/icons";

interface AdminIconIndicatorProps {
  verticalAlign?: string;
  pt?: string;
  tooltip?: string;
}

const AdminIconIndicator: React.FC<AdminIconIndicatorProps> = ({ verticalAlign, pt, tooltip }) => {
  return (
    <>
      <Tooltip placement="top" label={tooltip ? tooltip : "Only Admins Can See This"}>
        <Icon pt={pt && pt} verticalAlign={verticalAlign && verticalAlign} color="black" as={UnlockIcon} />
      </Tooltip>
    </>
  );
};

export default AdminIconIndicator;
