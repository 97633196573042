import ManageGuidelines from "../../components/MarketingSwagGuidelines/ManageGuidelines";
import { query, collection, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { useState, useEffect } from "react";
import GuidelineGrid from "../../components/MarketingSwagGuidelines/GuidelineGrid";
import { Box } from "@chakra-ui/react";

export default function MarketingSwagGuidelines( { } ){
    const [inventory, setInventory] = useState()
    const [guidelines, setGuidelines] = useState()

    useEffect(async () => {
        const q = query(collection(db, "Marketing Swag"), orderBy('Name', 'desc'))
  
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let items = []
          querySnapshot.forEach((doc) => {
              items.push(doc.data())
          });
          setInventory(items)
        });

        const q2 = query(collection(db, "Marketing Swag Guidelines"), orderBy('name', 'desc'))
  
        const unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
          let g = []
          querySnapshot2.forEach((doc) => {
              g.push(doc.data())
          });
          setGuidelines(g)
        });

        return () => {
            unsubscribe()
            unsubscribe2()
        }
    }, [])
    return(
        <>
        {inventory && guidelines &&
            <>
                <Box mt='7'>
                    <ManageGuidelines action='Add' inventory={inventory}/>
                    <GuidelineGrid inventory={inventory} guidelines={guidelines} allowEditing={true}/>
                </Box>
            </>
        }
        </>
    )
}