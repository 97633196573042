// TopicForm.js
import { useFormik } from 'formik';
import { Button, FormControl, FormErrorMessage, Input, Flex, Center } from '@chakra-ui/react';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
});


function TopicForm({ initVals, onSubmit, onCancel, isSubmitting, placeholderText }) {
    let initialValues
    if(initVals){
        initialValues = initVals 
        }
    else initialValues = {
        title: ""
    }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
        <Center mb='2'>
        <Flex w='25vw' align='center' justify={'center'}>
        <FormControl
            id="title"
            isInvalid={formik.touched.title && formik.errors.title}
        
        >
            <Input
            type="text"
            placeholder={placeholderText ? placeholderText : "Add topic"}
            {...formik.getFieldProps('title')}
            />
            <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
        </FormControl>
        <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            colorScheme='blue'
            ml={2}
            mr={2}
        >
            {initVals ? 'Save' : 'Add'}
        </Button>
        </Flex>
        </Center>
    </form>
  );
}

export default TopicForm;
