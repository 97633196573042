import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/init-firebase";
import { useEffect, useState } from "react";
import QuestionToggle from "./QuestionToggle";
import { useSubtopics } from "./useSubtopics";

export default function SubtopicConfig( { topic, subtopic, questions } ){
    const [subtopicDoc, setSubtopicDoc] = useState()
    const { updateSubtopic } = useSubtopics(topic)

    useEffect(async () => {
        setSubtopicDoc(null)
        const unsubscribe = onSnapshot(doc(db, 'topics', topic, 'subtopics', subtopic), (doc) => {
            setSubtopicDoc({...doc.data()})
  
        })
        return () => {
            unsubscribe()
        }     
      }, [subtopic])

      const handleToggleQuestion = async (e, questionID) => {
        console.log(e)
        console.log(questionID)
        try{
            let curQuestions = subtopicDoc.questions ? [...subtopicDoc.questions] : []
            console.log(curQuestions)
            if(e.target.checked){
                curQuestions.push(questionID)
                console.log(curQuestions)
                await updateSubtopic({questions: curQuestions}, subtopic)

            }
            else{
                let index = curQuestions.indexOf(questionID)
                index > -1 && curQuestions.splice(index, 1)
                await updateSubtopic({questions: curQuestions}, subtopic)

            }
        }
        catch(e){
            console.log(e)
        }
      }
    
    return(
        <>
        {subtopicDoc && subtopic && topic &&
        <>   
{/*            <BooleanSwitch 
                key={subtopic}
                docRef={doc(db, 'topics', topic, 'subtopics', subtopic)}
                fieldPath={'Require Loan Reference'}
                initialState={subtopicDoc['Require Loan Reference'] ? subtopicDoc['Require Loan Reference'] : false}
                label='Require Loan Reference'
                id={`require-loan-reference-${subtopic}`}
                successMsg={`The user will be required to reference a loan for ${subtopicDoc.title}`}
                warningMsg={`The user will NOT be required to reference a loan for ${subtopicDoc.title}`}
            />  */}
            {questions?.map((question) => {
                if(!question.deleted){
                    return(
                        <QuestionToggle 
                        question={question} 
                        included={subtopicDoc['questions'] ? subtopicDoc['questions'].indexOf(question.id) > -1 ? true : false : false}
                        handleToggleQuestion={handleToggleQuestion}
                    />
                    )
                }   
            })}
        </>
        }
     </>
    )
}