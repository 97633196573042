import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory
} from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Homepage from '../../pages/Homepage'
import InProgressPage from '../../pages/Loans/InProgressPage'
import Loginpage from '../../pages/Loginpage'
import NotfoundPage from '../../pages/NotfoundPage'
import Profilepage from '../../pages/Profile/Profilepage'
import ClientPage from '../../pages/Loans/ClientPage'
import SetupQueue from '../../pages/Loans/SetupQueue'
import AdminSettingsPage from '../../pages/AdminSettingsPage'
import CompletedSubmissionsPage from '../../pages/Loans/CompletedSubmissionsPage'
import NotSubmitted from '../../pages/Loans/NotSubmittedPage'
import TBDSubmissionsPage from '../../pages/Loans/TBDPage'
import ParkedPage from '../../pages/Loans/ParkedPage'
import MarketingRequestsList from '../../pages/Marketing/MarketingRequestsList'
import MarketingRequestPage from '../../pages/Marketing/MarketingRequestPage'
import { Layout } from './Layout'
import MarketingVault from '../../pages/Marketing/MarketingVault'
import Logos from '../../pages/Marketing/Logos'
import MarketingSettings from '../../pages/Marketing/MarketingSettings'
import SubmissionSettings from '../../pages/Loans/SubmissionSettings'
import LoanReports from '../LoanReports/LoanReports'
import ApprovalLetterPage from '../../pages/ApprovalLetterPage'
import MarketingInventory from '../../pages/Marketing/MarketingInventory'
import SwagItem from '../MarketingSwagV2/SwagItem'
import MarketingSwag from '../../pages/Marketing/MarketingSwag'
import MarketingReports from '../MarketingReports/MarketingReports'
import OpsDashboardPage from '../../pages/OpsDashboardPage'
import MarketingSwagGuidelines from '../../pages/Marketing/MarketingSwagGuidelines'
import MonthlyNewsLetterPage from '../../pages/Marketing/MonthlyNewsletterPage'
import CompDashboardPage from '../../pages/CompDashboardPage'
import SocialMediaCompliance from '../../pages/Marketing/SocialMediaCompliance'
import SalesDashboardPage from '../../pages/SalesDashboardPage'
import MarketingDashboard from '../../pages/Marketing/MarketingDashboard'
import AllEditLogs from '../../pages/Loans/AllEditLogs'
import EscalationSettings from '../../pages/Escalations/EscalationSettings'
import EscalationPage from '../../pages/Escalations/components/EscalationPage'
import Escalations from '../../pages/Escalations/Escalations'
import MyDashboard from '../../pages/MyDashboard/MyDashboard'
import CalendarSettings from '../../pages/CalendarSettings/CalendarSettings'
import RecentSubmissionsPage from '../../pages/RecentSubmissions&Feedback/RecentSubmissionsPage'
import RecentFeedbackPage from '../../pages/RecentSubmissions&Feedback/RecentFeedbackPage'
import LenderMatrixPage from '../../pages/RecentSubmissions&Feedback/LenderMatrixPage'
import MarketingMatrixPage from '../../pages/Marketing/MarketingMatrixPage'

export default function AppRouter(props) {
  const { SuperAdmin } = useAuth()
  const [isFirstRender, setIsFirstRender] = React.useState(true)

  return (
    <>
      <Router>
        <Switch>
            <ProtectedRoute exact path='/login' component={Loginpage} />

            <Layout>
              <ProtectedRoute exact path='/' component={Homepage} />
              <ProtectedRoute exact path='/profile' component={Profilepage} />
              <ProtectedRoute exact path='/profile/:id' component={Profilepage} />
              <ProtectedRoute exact path='/admin' component={AdminSettingsPage} />
              <ProtectedRoute exact path='/loan-reporting' component={LoanReports} />

              <ProtectedRoute exact path='/setup-queue' component={SetupQueue} />
              <ProtectedRoute exact path='/in-progress' component={InProgressPage}/>
              <ProtectedRoute exact path='/in-progress/:id' component={ClientPage}/>
              <ProtectedRoute exact path='/parked' component={ParkedPage}/>
              <ProtectedRoute exact path='/parked/:id' component={ClientPage}/>
              <ProtectedRoute exact path='/tbd' component={TBDSubmissionsPage}/>
              <ProtectedRoute exact path='/tbd/:id' component={ClientPage}/>
              <ProtectedRoute exact path='/completed' component={CompletedSubmissionsPage}/>
              <ProtectedRoute exact path='/completed/:id' component={ClientPage}/>
              <ProtectedRoute exact path='/not-submitted' component={NotSubmitted}/>
              <ProtectedRoute exact path='/not-submitted/:id' component={ClientPage}/>
              <ProtectedRoute exact path='/loans/:id' component={ClientPage}/>
              <ProtectedRoute exact path='/all-edit-logs' component={AllEditLogs}/>

              <ProtectedRoute exact path='/submission-settings' component={SubmissionSettings}/>

              <ProtectedRoute exact path='/marketing-requests' component={MarketingRequestsList}/>
              <ProtectedRoute exact path='/marketing-requests/:id' component={MarketingRequestPage}/>
              <ProtectedRoute exact path='/swag' component={MarketingSwag} />
              <ProtectedRoute exact path='/marketing-matrix' component={MarketingMatrixPage}/>

              <ProtectedRoute exact path='/social-media-compliance' component={SocialMediaCompliance} />
              <ProtectedRoute exact path='/marketing-inventory' component={MarketingInventory} />
              <ProtectedRoute exact path='/marketing-inventory/:name' component={SwagItem} />
              <ProtectedRoute exact path='/marketing-vault' component={MarketingVault}/>
              <ProtectedRoute exact path='/marketing-reports' component={MarketingReports}/>
              <ProtectedRoute exact path='/marketing-dashboard' component={MarketingDashboard}/>
              <ProtectedRoute exact path='/marketing-settings' component={MarketingSettings}/>
              <ProtectedRoute exact path='/marketing-swag-guidelines' component={MarketingSwagGuidelines} />
              <ProtectedRoute exact path='/monthly-newsletters' component={MonthlyNewsLetterPage} />
              <ProtectedRoute exact path='/logos' component={Logos}/>

              <ProtectedRoute exact path='/escalations' component={Escalations}/>
              <ProtectedRoute exact path='/escalations/:id' component={EscalationPage}/>

              <ProtectedRoute exact path='/escalation-settings' component={EscalationSettings}/>

              <ProtectedRoute exact path='/ops-dashboard' component={OpsDashboardPage}/>
              <ProtectedRoute exact path='/sales-dashboard' component={SalesDashboardPage}/>
              <ProtectedRoute exact path='/comp-dashboard' component={CompDashboardPage}/>
              <ProtectedRoute exact path='/my-dashboard' component={MyDashboard}/>

              <ProtectedRoute exact path='/my-dashboard/recent-submissions' component={RecentSubmissionsPage}/>
              <ProtectedRoute exact path='/my-dashboard/recent-feedback' component={RecentFeedbackPage}/>
              <ProtectedRoute exact path='/my-dashboard/lender-matrix' component={LenderMatrixPage}/>

              <ProtectedRoute exact path='/my-dashboard/calendar-settings' component={CalendarSettings}/>

              <ProtectedRoute exact path='/approval-letter' component={ApprovalLetterPage}/>


           {/*     <TrainingLayout>
                  <ProtectedRoute exact path='/training' component={TrainingHome}/>
                  <ProtectedRoute exact path='/training/:slug' component={TrainingPage}/>
              </TrainingLayout>   */}
            </Layout>

            <ProtectedRoute exact path='*' component={NotfoundPage} />

        </Switch>
      </Router>

    </>
  )
}



export function ProtectedRoute(props) {
  const { currentUser, Roles, loading, isFirstRender, setIsFirstRender } = useAuth()
  const { id } = useParams()
  const { path } = props
  const history = useHistory();
  const location = useLocation()

  function handleRedirect(route){
    setIsFirstRender(false);
    return <Redirect to={location.state?.from ?? route} />
  }

  function handleRoute(){
    setIsFirstRender(false);
    return <Route {...props} />
  }
  if (path === '/login') {
    return currentUser ? (
      Roles.includes('Loan Officer') || Roles.includes('Mortgage Broker') || Roles.includes('IT Team Lead') ? 
        handleRedirect('/my-dashboard')
        :
        handleRedirect('/')
    ) : (
      <Route {...props} />
    )
  }

  return currentUser ? (
    location.pathname === '/' && (Roles.includes('Loan Officer') || Roles.includes('Mortgage Broker') || Roles.includes('IT Team Lead')) && isFirstRender ? 
          handleRedirect('/my-dashboard')
        :
          handleRoute()
      ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location.pathname },
        }}
      />
  )
}