import React, { useState, useEffect } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    Button,
    useDisclosure,
    Box,
    Spacer,
    Divider,
    Text,
    Flex,
    FormControl
  } from '@chakra-ui/react'
import { db } from '../../utils/init-firebase'
import { FiEdit3 } from 'react-icons/fi'
import FeedbackBody from './FeedbackBody'
import { useAuth } from '../../contexts/AuthContext'
import { collection, limit, orderBy, where, query, getDocs } from 'firebase/firestore'
import { MdFeedback } from 'react-icons/md'
import { SpinnerIcon } from '@chakra-ui/icons'
import { Select } from 'chakra-react-select'

export default function LOFeedback_Multiple( {  } ){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 2);
    const thisYear = new Date().getFullYear()
    const { currentUser, SuperAdmin, brokers } = useAuth()
    const btnRef = React.useRef()

    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedUser, setSelectedUser] = useState(null)

    useEffect( async () => {
        const queryClientsWithoutFeedback = async () => {
            try {
              setLoading(true)
              let baseQuery = query(collection(db, 'Clients'), 
                                        //where('LO Feedback Given', '==', null),
                                        where(`Process Pipeline.Status`, '==', 'Funded'),
                                        where('Date Completed', '>', oneMonthAgo), // Only include clients completed within the last month
                                        orderBy('Date Completed', 'asc'))
              let feedbackQuery = SuperAdmin ? 
                                    selectedUser ? 
                                    query(baseQuery, where(`Loan Officer.uid`, '==', selectedUser.value ), limit(30))  
                                    :
                                    query(baseQuery, limit(2000)) 
                                  :
                                  query(baseQuery, where(`Loan Officer.uid`, '==', currentUser.uid ), limit(30))  

              const querySnapshot = await getDocs(feedbackQuery);
          
              // Array to store references to 'Clients' documents where 'LO Feedback' doesn't exist
              const clientsWithoutFeedback = [];
          
              querySnapshot.forEach((doc) => {
                if(!doc.data()['LO Feedback Given'])
                  clientsWithoutFeedback.push(doc.data());
              });

              
              setClients(clientsWithoutFeedback)
              //console.log(clientsWithoutFeedback);
            } catch (error) {
              console.error('Error querying clients without feedback:', error);
            } finally { 
              setLoading(false)
            }
            
          };
          
          await queryClientsWithoutFeedback();
    }, [selectedUser])

    const handleRemoveClient = (id) => {
        let newClients = clients.filter((client) => client.id !== id)
        setClients(newClients)
    }

    return(
        <>
        <IconButton
            icon={<>
                <MdFeedback />
                  {clients && clients.length > 0 &&
                    <Box as={'span'} color={'white'} position={'absolute'} top={'2px'} right={'2px'} fontSize={'0.7rem'}
                        minWidth={'14px'} height={'16px'}
                        bgColor={'#FB404B'} borderRadius={'3xl'} zIndex={2} p={'1px'}>
                        {clients.length}
                    </Box>
                  }           
            </>}
            size='md'
            ref={btnRef} 
            onClick={onOpen}
        >
        </IconButton>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
            size='lg'
        >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
              <Flex flexDir={'row'} justify='space-between'>
                Feedback
                {SuperAdmin && brokers &&
                    <FormControl w='250px' mr='5'>
                        <Select
                            options={brokers}
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e)}
                            placeholder="Select User"
                            closeMenuOnSelect={true}
                            selectedOptionColor="green"
                            classNamePrefix={'user-select'}
                            isClearable
                        />
                    </FormControl>
                }    
                </Flex>
              </DrawerHeader>

              <DrawerBody>
                    {clients?.map((client) => 
                        <div key={client.id}>
                            <FeedbackBody client={client} handleRemoveClient={handleRemoveClient} />
                            <Divider mb='5' mt='5'/>
                        </div>
                    )}
                    {loading && <SpinnerIcon />}
                    {!loading && clients?.length === 0 && <Text>No clients found</Text>}
              </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    )
}