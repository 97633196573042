import { TextareaControl, InputControl } from "formik-chakra-ui"
import {
    Wrap,
    WrapItem,
    useColorModeValue,
    ScaleFade
} from "@chakra-ui/react";
import { DatePickerField } from "../DatePicker";
import DividerWithText from "../DividerWithText";

export default function OpenHouseRequestFields( { values } ){
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")

    return(
        <>
        <ScaleFade in initialScale={0.8}>
            <DividerWithText text='PROPERTY INFORMATION' />
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                <WrapItem width={{ base: '100%', md: '48%' }} h={20}>
                    <InputControl 
                        name="Name"
                        label="Name"
                    >
                    </InputControl>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '48%' }} h={20}>
                    <InputControl 
                        name="NMLS#"
                        label="NMLS#"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                <WrapItem width={{ base: '100%', md: '31%' }} h={20}>
                    <InputControl 
                        name="Property.Zillow Link"
                        label="Zillow Link"
                    >
                    </InputControl>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '32%' }} h={20}>                    
                    <InputControl 
                        name="Property.Price"
                        label="Price"
                    >
                    </InputControl>
                </WrapItem>

                <WrapItem width={{ base: '100%', md: '31%' }} h={20}>
                    <InputControl 
                        name="Property.MLS#"
                        label="MLS#"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <InputControl 
                name="Property.Address"
                label="Address"
                mb={5}
            >
            </InputControl>

            <DividerWithText text='REALTOR INFORMATION' />
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                <WrapItem width={{ base: '100%', md: '31%' }} h={20}>
                    <InputControl 
                        name="Realtor.Name"
                        label="Realtor Name"
                    >
                    </InputControl>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '32%' }} h={20}>
                    <InputControl 
                        name="Realtor.SL#"
                        label="Realtor SL#"
                    >
                    </InputControl>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '31%' }} h={20}>
                    <InputControl 
                        name="Realtor.Phone"
                        label="Realtor Phone"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>

                <WrapItem width={{ base: '100%', md: '48%' }} h={20}>                    
                    <InputControl 
                        name="Realtor.Email"
                        label="Realtor Email"
                    >
                    </InputControl>
                </WrapItem>

                <WrapItem width={{ base: '100%', md: '48%' }} h={20}>
                    <InputControl 
                        name="Realtor.Company"
                        label="Realtor Company"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>

            <DividerWithText text='EVENT INFORMATION' />
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                 <WrapItem width={{ base: '100%', md: '31%' }} h={20}>
                    <DatePickerField name="Event Date" label="Start Date" autoSetEndDate={'Event End Time'} className={datepickerClass} dateFormat='Pp' showTimeSelect/>
                </WrapItem>

                <WrapItem width={{ base: '100%', md: '31%' }} h={20}>
                    <DatePickerField name="Event End Time" label="End Date" className={datepickerClass} dateFormat='Pp' showTimeSelect />
                </WrapItem>
            </Wrap>
            <DividerWithText text='ANYTHING ELSE WE SHOULD KNOW' />
            <TextareaControl mb={5} mt={5} name="Notes"  />
        </ScaleFade>
        </>
    )
}