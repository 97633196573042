import { QuestionIcon } from "@chakra-ui/icons";
import { Flex, Tag, TagLeftIcon, TagLabel } from "@chakra-ui/react";
import { FiFacebook, FiHardDrive, FiImage, FiInstagram, FiPrinter } from "react-icons/fi";


export default function DesignRequestBadges( { request } ){


    return(
        <>
            <Flex justify={'flex-start'} wrap flexDir='row' mb={2}>

            {request['Request Details']['Medium'] && request['Request Details']['Medium'] != '' &&
                <>
                <Tag size='md' variant='outline' colorScheme={'gray'} textTransform='uppercase' fontWeight='bold'>
                    <>
                    {request['Request Details']['Medium'] == 'Digital' ?
                        <TagLeftIcon  as={FiHardDrive} />
                    :
                    request['Request Details']['Medium'] == 'Print' ?
                        <TagLeftIcon  as={FiPrinter} />
                    : 
                        null
                    }
                    </>
                    <TagLabel fontSize="14px">{request['Request Details']['Medium']}</TagLabel>
                </Tag> 

                {request['Request Details']['Medium'] == 'Print' &&
                    <Tag size='md' ml={2} variant='outline'fontWeight='bold'>
                        <TagLabel fontSize="14px">{request['Request Details']['Length']}x{request['Request Details']['Width']}</TagLabel>
                    </Tag> 
                }
                {request['Request Details']['Medium'] == 'Digital' &&
                    <Tag size='md' 
                        ml={2} 
                        colorScheme={request['Request Details']['Platform'] == 'Instagram Post' ? 'pink' :
                                    request['Request Details']['Platform'] == 'Facebook Post' ? 'facebook' :
                                    'blue'} 
                        variant='outline' 
                        textTransform='uppercase' 
                        fontWeight='bold'>
                        <TagLeftIcon  as={request['Request Details']['Platform'] == 'Instagram Post' ? FiInstagram :
                                        request['Request Details']['Platform'] == 'Facebook Post' ? FiFacebook :
                                        request['Request Details']['Platform'] == 'Digital Flyer' ? FiImage :
                                        QuestionIcon}  />
                        <TagLabel fontSize="14px">{request['Request Details']['Platform']}</TagLabel>
                    </Tag> 
                    }
                </>
            }
     
          </Flex>  
        </>
    )
}