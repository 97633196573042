import { Text } from "@chakra-ui/react";
import ManageTopics from "../../components/Escalation Settings/Topics/ManageTopics";
import { useAuth } from "../../contexts/AuthContext";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ManageQuestions from "../../components/Escalation Settings/Questions/ManageQuestions";
import EscalationNotificaitonSettings from "./components/EscalationNotificationSettings";

export default function EscalationSettings( { } ){
    const { SuperAdmin } = useAuth()

    return(
        <>
        {SuperAdmin ? 
            
            <Tabs variant='enclosed' mt='5'>
                <TabList>
                    <Tab>Topics</Tab>
                    <Tab>Questions</Tab>
                    <Tab>Settings</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ManageTopics />
                    </TabPanel>
                    <TabPanel>
                        <ManageQuestions />
                    </TabPanel>
                    <TabPanel>
                        <EscalationNotificaitonSettings />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            :
            <Text>You do not have permission to access this page</Text>
        }
        </>
    )
}