import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { collection, query, getDocs, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import { Center, FormControl } from "@chakra-ui/react";
import {
    Text,
    Wrap,
    WrapItem,
    Stack,
    Button,
    useColorModeValue,
    Flex,
    Box,
    Heading,
    Image
  } from '@chakra-ui/react';
import ClientCard from "../../components/Client/ClientCard";
import { useAuth } from "../../contexts/AuthContext";
import ReactDatePicker from "react-datepicker";
import { datepickerDark, datepickerLight } from "../../contexts/GlobalVariables";
import "react-datepicker/dist/react-datepicker.css";
import { Select } from "chakra-react-select";
import ReactLinkify from "react-linkify";
import LoanReportSummary from "./LoanReport_Summary";

export default function LoanReports( { } ) {
  const collectionRef = collection(db, "Clients")
  const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)
  const { currentUser, SuperAdmin } = useAuth()
  const { users } = useAuth()

  const { url, path } = useRouteMatch()

  const [loClients, setLoClients] = useState(null)
  const [lcClients, setLcClients] = useState(null)
  const [clientList, setClientList] = useState(null)
  const [filteredClientList, setFilteredClientList] = useState(null)

  const [loading, setLoading] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const [LO, setLO] = useState([])
  const [LOA, setLOA] = useState([])
  const [LC, setLC] = useState([])
  const [loanWasParked, setLoanWasParked] = useState(false)
  const [loanWasFlagged, setLoanWasFlagged] = useState(false)
  const [coachingCompleted, setCoachingCompleted] = useState(false)
  const [error, setError] = useState()
  const [status, setStatus] = useState()


  let [quickActionExecuted, setQuickActionExecuted] = useState(0)
  
  const [querySummary, setQuerySummary] = useState(null)

  var today = new Date(), y = today.getFullYear(), m = today.getMonth();
  const [startDate, setStartDate] = useState(new Date(y, m, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [dateFilter, setDateFilter] = useState({label: 'Date Added', value: 'Date Added'})

  const [statusFilter, setStatusFilter] = useState('All')
  const [wasParkedFilter, setWasParkedFilter] = useState(false)

  const baseQuery = query(collectionRef, 
                    orderBy(dateFilter.value, 'desc'),
                    where(dateFilter.value, '>=', startDate), 
                    where(dateFilter.value, '<=', endDate),)

  const handleSetWasParkedFilter = () => {
        setWasParkedFilter(!wasParkedFilter)
  }

  const reset = () => {
    setLoading(true)
    setLoanWasFlagged(false)
    setLoanWasParked(false)
    setCoachingCompleted(false)
    setLO([])
    setLOA([])
    setLC([])
    setClientList(null)
    setFilteredClientList(null)
    setStartDate(null)
    setEndDate(null)
    setError(null)
    setQuerySummary(null)
    setLoading(false)
  }

  const queryYesterday = async () => {
    const setYesterday = async () => {
        var curr = new Date; // get current date
        var first = curr.getDate() - 1; // First day is the day of the month - the day of the week
        var firstDay = new Date(curr.setDate(first));
        firstDay.setHours(0)
        setStartDate(firstDay)

        var today = new Date; // get current date
        var end = today.getDate() - 1; // First day is the day of the month - the day of the week
        var endDay = new Date(today.setDate(end));
        endDay.setHours(23)
        endDay.setMinutes(59)
        setEndDate(endDay)
        setDateFilter({label: 'Date Added', value: 'Date Added'})
        setLO([])
        setLOA([])
        setLC([])
    }
    reset()
    await setYesterday()
    setQuickActionExecuted(Math.random() + 1)
  }



  //query Monday - Today
  const queryThisWeek = async () => {
    const setThisWeek = async () => {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
        var firstDay = new Date(curr.setDate(first));
        firstDay.setHours(0)
        setStartDate(firstDay)
        setEndDate(new Date())
        setDateFilter({label: 'Date Added', value: 'Date Added'})
        setLO([])
        setLOA([])
        setLC([])
    }
    reset()
    await setThisWeek()
    setQuickActionExecuted(Math.random() + 1)

  }


  const queryMonthToDate = async () => {
    const setMTD = async () => {
        setStartDate(new Date(y, m, 1))
        setEndDate(new Date())
        setDateFilter({label: 'Date Added', value: 'Date Added'})
        setLO([])
        setLOA([])
        setLC([])
    }
    reset()
    await setMTD()
    setQuickActionExecuted(Math.random() + 1)
  }

  useEffect( async () => {
    if(quickActionExecuted != 0){
        await getClients()
    }
  }, [quickActionExecuted])

  useEffect( () => {
    if(clientList){
        setFiltering(true)
        if(statusFilter == 'All'){
            setFilteredClientList(clientList)
        }
        else{
            const filteredClientList = clientList.filter((client) => {
                if(statusFilter == 'Was Parked'){
                    return client['Date Parked']
                }
                else if(statusFilter == 'Needs Review'){
                    return client['Flag For Review'] && !client['Coaching Completed']
                }
                else if(statusFilter == 'Coaching Completed'){
                    return client['Coaching Completed']
                }
                else if(statusFilter == 'NS Then Completed'){
                    return client['Date Not Submitted'] && client['Status'] == 'Completed'
                }
                else{
                    return client['Status'] == statusFilter
                }    
            })
            setFilteredClientList(filteredClientList)
        }
        setFiltering(false)
    }
  }, [statusFilter])


  const getClients = async () => {
    try{
        setLoading(true)
        setError(null)
        setClientList(null)
        setFilteredClientList(null)
                
        var clients = []
        var noNew = 0
        var noInProgress = 0
        var noTBD = 0
        var noParked = 0
        var noCurrentlyParked = 0
        var noCompleted = 0
        var noNotSubmitted = 0
        var noNotSubmittedThenCompleted = 0
        var noNeedsReview = 0
        var noCoachingCompleted = 0

        // If nobody is selected, fetch all clients for this date range
        if(LO.length == 0 && LOA.length == 0 && LC.length == 0){
            const querySnapshot = await getDocs(baseQuery)
            querySnapshot.forEach((doc) => {
                let client = {}
                client = doc.data()
                clients.push(client)
                if(client['Date Parked']){
                    noParked++
                }
                if(client['Status'] == 'New'){
                    noNew++
                }
                if(client['Status'] == 'In Progress'){
                    noInProgress++
                }
                if(client['Status'] == 'TBD'){
                    noTBD++
                }
                if(client['Status'] == 'Completed'){
                    noCompleted++
                }
                if(client['Status'] == 'Parked'){
                    noCurrentlyParked++
                }
                if(client['Status'] == 'Not Submitted'){
                    noNotSubmitted++
                }
                if(client['Date Not Submitted'] && client['Status'] == 'Completed'){
                    noNotSubmittedThenCompleted++
                }
                if(client['Flag For Review']){
                    if(client['Coaching Completed']){
                        noCoachingCompleted++
                    }
                    else noNeedsReview++
                }
            })
        }
        else {
            for(const loanOfficer of LO) {
                var thisLOQuery = query(baseQuery, where(`Loan Officer.uid`, '==', loanOfficer.value))
                    const querySnapshot = await getDocs(thisLOQuery)
                    querySnapshot.forEach((doc) => {
                        let client = {}
                        client = doc.data()
                        clients.push(client)
                        if(client['Date Parked']){
                            noParked++
                        }
                        if(client['Status'] == 'New'){
                            noNew++
                        }
                        if(client['Status'] == 'In Progress'){
                            noInProgress++
                        }
                        if(client['Status'] == 'TBD'){
                            noTBD++
                        }
                        if(client['Status'] == 'Completed'){
                            noCompleted++
                        }
                        if(client['Status'] == 'Parked'){
                            noCurrentlyParked++
                        }
                        if(client['Status'] == 'Not Submitted'){
                            noNotSubmitted++
                        }
                        if(client['Date Not Submitted'] && client['Status'] == 'Completed'){
                            noNotSubmittedThenCompleted++
                        }
                        if(client['Flag For Review']){
                            if(client['Coaching Completed']){
                                noCoachingCompleted++
                            }
                            else noNeedsReview++
                        }
                    })
            }

            for(const loanOfficerAssistant of LOA) {
                var thisLOAQuery = query(baseQuery, where(`LOA.uid`, '==', loanOfficerAssistant.value))
                    const querySnapshot = await getDocs(thisLOAQuery)
                    querySnapshot.forEach((doc) => {
                        let client = {}
                        client = doc.data()
                        clients.push(client)
                        if(client['Date Parked']){
                            noParked++
                        }
                        if(client['Status'] == 'New'){
                            noNew++
                        }
                        if(client['Status'] == 'In Progress'){
                            noInProgress++
                        }
                        if(client['Status'] == 'TBD'){
                            noTBD++
                        }
                        if(client['Status'] == 'Completed'){
                            noCompleted++
                        }
                        if(client['Status'] == 'Parked'){
                            noCurrentlyParked++
                        }
                        if(client['Status'] == 'Not Submitted'){
                            noNotSubmitted++
                        }
                        if(client['Date Not Submitted'] && client['Status'] == 'Completed'){
                            noNotSubmittedThenCompleted++
                        }
                        if(client['Flag For Review']){
                            if(client['Coaching Completed']){
                                noCoachingCompleted++
                            }
                            else noNeedsReview++
                        }
                    })
            }

            for(const lenderConcierge of LC) {

                var thisLCQuery = query(baseQuery, where(`LC.uid`, '==', lenderConcierge.value))
                    const querySnapshot = await getDocs(thisLCQuery)
                    querySnapshot.forEach((doc) => {
                        let client = {}
                        client = doc.data()
                        clients.push(client)
                        if(client['Date Parked']){
                            noParked++
                        }
                        if(client['Status'] == 'New'){
                            noNew++
                        }
                        if(client['Status'] == 'In Progress'){
                            noInProgress++
                        }
                        if(client['Status'] == 'TBD'){
                            noTBD++
                        }
                        if(client['Status'] == 'Completed'){
                            noCompleted++
                        }
                        if(client['Status'] == 'Parked'){
                            noCurrentlyParked++
                        }
                        if(client['Status'] == 'Not Submitted'){
                            noNotSubmitted++
                        }
                        if(client['Date Not Submitted'] && client['Status'] == 'Completed'){
                            noNotSubmittedThenCompleted++
                        }
                        if(client['Flag For Review']){
                            if(client['Coaching Completed']){
                                noCoachingCompleted++
                            }
                            else noNeedsReview++
                        }
                    })
            }
        }
       
       
        setClientList(clients)
        setFilteredClientList(clients)
        
        setQuerySummary({
            startDate: startDate,
            endDate: endDate,
            dateFilter: dateFilter,
            LO: LO,
            LOA: LOA,
            LC: LC,
            queryStats: {
                noLoans: clients.length,
                noNew: noNew,
                noInProgress: noInProgress,
                noTBD: noTBD,
                noParked: noParked,
                noCompleted: noCompleted,
                noNotSubmitted: noNotSubmitted,
                noNotSubmittedThenCompleted: noNotSubmittedThenCompleted,
                noCurrentlyParked: noCurrentlyParked,
                noNeedsReview: noNeedsReview,
                noCoachingCompleted: noCoachingCompleted
            }
       
        })
        setStatusFilter('All')
        setLoading(false)
    }
    catch(err){
        console.log(err)
        setLoading(false)
        setError(err.message)
    }
};


useEffect(() =>{
    setLoading(true)
    const querySummary = JSON.parse(localStorage.getItem('querySummary'))
    if(querySummary){
        setQuerySummary(querySummary)
    }
    const statusFilter = localStorage.getItem('statusFilter')
    if(statusFilter){
        setStatusFilter(statusFilter)
    }
    const clientList = JSON.parse(localStorage.getItem('clientList'))
    if(clientList){
        setClientList(clientList)
    }
    const filteredClientList = JSON.parse(localStorage.getItem('filteredClientList'))
    if(filteredClientList){
        setFilteredClientList(filteredClientList)
    }
    const LO = JSON.parse(localStorage.getItem('LO'))
    if(LO){
        setLO(LO)
    }
    const LOA = JSON.parse(localStorage.getItem('LOA'))
    if(LOA){
        setLOA(LOA)
    }
    const LC = JSON.parse(localStorage.getItem('LC'))
    if(LC){
        setLC(LC)
    }
    const startDate = JSON.parse(localStorage.getItem('startDate'))
    if(startDate){
        setStartDate(new Date(startDate))
    }
    const endDate = JSON.parse(localStorage.getItem('endDate'))
    if(endDate){
        setEndDate(new Date(endDate))
    }
    const dateFilter = JSON.parse(localStorage.getItem('dateFilter'))
    if(dateFilter){
        setDateFilter(dateFilter)
    }
    setLoading(false)
}, [])

useEffect(() => {
    localStorage.setItem('querySummary', JSON.stringify(querySummary))
}, [querySummary])
useEffect(() => {
    localStorage.setItem('statusFilter', statusFilter)
}, [statusFilter])
useEffect(() => {
    localStorage.setItem('clientList', JSON.stringify(clientList))
}, [clientList])
useEffect(() => {
    localStorage.setItem('filteredClientList', JSON.stringify(filteredClientList))
}, [filteredClientList])
useEffect(() => {
    localStorage.setItem('LO', JSON.stringify(LO))
}, [LO])
useEffect(() => {
    localStorage.setItem('LOA', JSON.stringify(LOA))
}, [LOA])
useEffect(() => {
    localStorage.setItem('LC', JSON.stringify(LC))
}, [LC])
useEffect(() => {
    localStorage.setItem('startDate', JSON.stringify(startDate))
}, [startDate])
useEffect(() => {
    localStorage.setItem('endDate', JSON.stringify(endDate))
}, [endDate])
useEffect(() => {
    localStorage.setItem('dateFilter', JSON.stringify(dateFilter))
}, [dateFilter])
  

  return (
    <>
    {SuperAdmin ? 
        <Flex flexDir={{base: 'column-reverse' , xl: 'row'}} mt='5'>
          <Box w={{base: '90vw', md: '75vw', xl: '65vw'}}>
            {!loading ?
            <>
            {filteredClientList && !error && querySummary && 
                <LoanReportSummary 
                    startDate={querySummary['startDate']}
                    endDate={querySummary['endDate']}
                    dateFilter={querySummary['dateFilter'].value}
                    LO={querySummary['LO']}
                    LOA={querySummary['LOA']}
                    LC={querySummary['LC']}
                    queryStats={querySummary.queryStats}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    wasParkedFilter={wasParkedFilter}
                    setWasParkedFilter={setWasParkedFilter}
                    handleSetWasParkedFilter={handleSetWasParkedFilter}
                />
            }
            <Wrap mt={3}>
                {filteredClientList && !filtering && filteredClientList.map((client, index) => {
                    return (
                        <>
                        <WrapItem id={`client-${index}`}>
                            <ClientCard 
                                client={client}
                                loanOfficer={client['Loan Officer']}
                                lenderConcierge={client['LC']}
                                dateAdded={client['Date Added']}
                                loanDetails={client['Loan Details']}
                                docID={client.id}
                                status={client.Status}
                            />
                        </WrapItem>
                        </>
                    )
                }) }
                {filtering &&     
                <>
                    <Text>Filtering...</Text>
                    <Image src={require('../../assets/img/the-future-is-loading-transparent.gif')}/>
                        </>
                }
                {!filteredClientList && !error && <Text>Start by building a query using the form on the right</Text>}
                {filteredClientList && filteredClientList.length == 0 && !error && <Text>No clients found</Text>}

            </Wrap>
            </>
            :     
            <Center mt='10'>
                <Image src={require('../../assets/img/the-future-is-loading-transparent.gif')}/>
            </Center>
            }
            {error && <ReactLinkify><Text>{error}</Text></ReactLinkify>}
          </Box>
          
          <Box w={{base: '90vw', md:'75vw', xl: '20vw'}} mt={3}>
            <Stack
              mt={1}
              direction='column'
              maxW={'1200px'}
              spacing='4'
            >
                <Heading textAlign={'center'} size='md' as='em'>Build-A-Query</Heading>
                <Stack spacing={1}>
                    <Stack direction={{base: 'row', xl: 'column'}} >
                        <ReactDatePicker   
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className={datepickerClass}
                            placeholderText='Start Date'
                        />
                        <ReactDatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className={datepickerClass}
                            placeholderText='End Date'
                        />
                    </Stack>
                    <FormControl>
                        <Select
                            options={[{label: 'Date Completed', value: 'Date Completed'}, {label: 'Date Added', value: 'Date Added'}]}
                            value={dateFilter}
                            defaultValue={'Date Added'}
                            onChange={(e) => setDateFilter(e)}
                            placeholder="Select Date Filter"
                            selectedOptionColor="green"
                            classNamePrefix={'date-filter-select'}
                        />
                    </FormControl>
                    {dateFilter.value == 'Date Completed' && <Text color={'purple.400'} fontSize={'xs'}>Querying By Date Completed will exclude all Not Submitted Loans</Text>}
                </Stack>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Loan Officer</Text>
                    <Select             
                        options={users}
                        value={LO}
                        onChange={(e) => setLO(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lo-filter-select'}
                        closeMenuOnSelect='false'
                        isClearable
                        isMulti
                    />
                </FormControl>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Loan Officer Assistant</Text>
                    <Select             
                        options={users}
                        value={LOA}
                        onChange={(e) => setLOA(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'loa-filter-select'}
                        closeMenuOnSelect='false'
                        isClearable
                        isMulti
                    />
                </FormControl>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Lender Concierge</Text>
                    <Select
                        options={users}
                        value={LC}
                        onChange={(e) => setLC(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lc-filter-select'}
                        isClearable
                        isMulti
                    />
                </FormControl>
              {/*   <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Reason Parked</Text>
                    <Select
                        options={users}
                        value={LC}
                        onChange={(e) => setLC(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lc-filter-select'}
                        isClearable
                        isMulti
                    />
                </FormControl>
                <FormControl mb={3}>
                    <Text p={1} fontSize={'xs'}>Reason Not Submitted</Text>
                    <Select
                        options={users}
                        value={LC}
                        onChange={(e) => setLC(e)}
                        placeholder="Everyone"
                        selectedOptionColor="green"
                        classNamePrefix={'lc-filter-select'}
                        isClearable
                        isMulti
                    />
                </FormControl> */}
                <Flex w='100%' flexDir={{base: 'row', xl: 'column'}}>
                    <Button w={{base: '50%', xl: '100%'}} p={5} pl={7} pr={7} m={1} onClick={getClients} size='md' colorScheme='green'>Apply</Button>

                {/*   <Heading textAlign={'center'} size='md' as='em'>Filters</Heading>
                    <FormControl mb={3}>
                        <Checkbox onChange={(e) => setLoanWasParked(e.target.checked)}>
                            Loan Was Parked
                        </Checkbox>
                    </FormControl>

                    <FormControl mb={3}>
                        <Checkbox onChange={(e) => setLoanWasFlagged(e.target.checked)}>
                            Flagged For Review
                        </Checkbox>
                    </FormControl>

                    <FormControl mb={3}>
                        <Checkbox onChange={(e) => setCoachingCompleted(e.target.checked)}>
                            Coaching Completed
                        </Checkbox>
                    </FormControl>
    */}

                    <Button w={{base: '50%', xl: '100%'}} p={5} pl={7} pr={7} m={1} onClick={reset} size='md' colorScheme='blue'>Reset</Button>
                </Flex>
                
                <Heading textAlign={'center'} size='md' as={'em'}>Quick Actions</Heading>
                <Flex w='100%' flexDir={{base: 'column', md:'row', xl: 'column'}}>
                    <Button w={{base: '100%', md:'33%', xl: '100%'}} m={1} onClick={queryYesterday}>Yesterday</Button>
                    <Button w={{base: '100%', md:'33%', xl: '100%'}} m={1} onClick={queryThisWeek}>This Week</Button>
                    <Button w={{base: '100%', md:'33%', xl: '100%'}} m={1} onClick={queryMonthToDate}>Month To Date</Button>
                </Flex>

            </Stack>
            </Box>
        </Flex>

        
        :
        <Text>You do not have permission to access this page</Text>
    }
    </>
  );
}
