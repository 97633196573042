import { Center, Icon, Flex, AvatarBadge, Avatar, Text } from "@chakra-ui/react";
import moment from "moment";
import { FiMinus, FiPlus } from "react-icons/fi";
export default function Transaction( { transaction } ){
    const iconW = 6
    const iconH = 6
    var iconColor = null

    if(transaction.isAddition){
        iconColor = 'green'
    }
    else iconColor = 'red'
 
    return(
        
        <>
            <Flex width='inherit' direction='row' justifyContent='space-between' alignItems={'center'} >
                <Flex>
                    {transaction['Fulfilled By'] && transaction['Fulfilled By'].photoURL &&
                        <Avatar mr={3} size='md' src={transaction['Fulfilled By'].photoURL}>
                        </Avatar>  
                    }
                    <Flex maxWidth='30vh' direction='column' justifyItems={'center'} wrap>
                        {!transaction.isAddition && <Text fontSize='xs' style={{marginTop: '2px'}} as='em'>{transaction['Fulfilled By'].Name} fulfilled {transaction['Owner'].Name}'s request</Text>}
                        {transaction.isAddition && <Text fontSize='xs' style={{marginTop: '2px'}} as='em'>{transaction['Fulfilled By'].Name} added to stock</Text>}
                        {transaction.timestamp && <Text 
                            color='gray.500'
                            letterSpacing='normal'
                            fontSize={{
                                'base':'10px',
                            }}
                            style={{marginTop: '2px'}}
                            >
                            {moment(transaction.timestamp.toDate()).calendar()}   </Text>}
                    </Flex>
                </Flex>
                <Flex justifySelf={'flex-end'} ml='5' mr='2'>
                <>
                    <Flex flexDir={'column'}>
                      <Center>
                            <Icon as={transaction.isAddition ? FiPlus : FiMinus} w={iconW} h={iconH} color={transaction.isAddition ? 'green' : 'red'}/>
                            {transaction.Item && transaction.Item.Amount && <Text>{transaction.Item.Amount}</Text>}
                            {transaction.Item && transaction.Item.stock && <Text>&nbsp;({transaction.Item.stock})</Text>}

                        </Center>
                      {transaction.Item && transaction.Item.Amount && transaction.Item.Cost && 
                        <Text mr='1' alignSelf='flex-end' fontSize={'xs'} color='gray.600'>${(parseInt(transaction.Item.Amount) * transaction.Item.Cost).toFixed(2)}</Text>}
                      </Flex>
                        {transaction.Owner && transaction.Owner.uid != '' &&
                            <Avatar ml='2' justifySelf='flex-end' size='md' src={transaction.Owner.photoURL}>
                                <AvatarBadge 
                                    border='none' 
                                    color='white' 
                                    fontSize='11px' 
                                    letterSpacing={'tighter'} 
                                    boxSize='2.25em' 
                                    bg={'red.500'}>
                                        -
                                </AvatarBadge>
                            </Avatar>
                        }
                        {transaction.isAddition &&
                         <Avatar ml='2' justifySelf='flex-end' size='md' src={require('../../assets/img/astronaut-face.png')}>
                            <AvatarBadge 
                                border='none' 
                                color='white' 
                                fontSize='11px' 
                                letterSpacing={'tighter'} 
                                boxSize='2.25em' 
                                bg={'green.500'}>
                                    +
                            </AvatarBadge>
                        </Avatar>
                        }
                    
                    </>

                </Flex>
            </Flex>
    

    </>
    


    )
}
