import {
    Flex,
    Center,
    useColorModeValue,
    Text,
    ScaleFade,
    Image,
    Heading,
    Spacer,
    useToast,
    Tabs,
    TabList,
    Tab} from "@chakra-ui/react";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useReducer, useRef } from "react";
import { collection, query, where, getDocs, setDoc, doc, onSnapshot, serverTimestamp, orderBy } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { Products } from "../../components/MarketingSwagV2/Products";
import { useHistory } from "react-router-dom";
import CreateRequest from "../../components/Marketing/CreateRequest";
import GuidelineGrid from "../../components/MarketingSwagGuidelines/GuidelineGrid";

export default function MarketingSwag( { action, values } ){
    const { currentUser, SuperAdmin, MarketingAdmin } = useAuth()
    const backgroundMenuColor = useColorModeValue('white', '#1A202C')
    const lastCartRef = doc(db, "users", currentUser.uid, "Carts", "Last Cart")

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")
    const functions = getFunctions();
    const [loading, setLoading] = useState(true)
    const [inventory, setInventory] = useState()
    const [filteredInventory, setFilteredInventory] = useState()
    const [categories, setCategories] = useState()

    const [guidelines, setGuidelines] = useState()
    const [description, setDescription] = useState()

    const [showOutOfStock, setShowOutOfStock] = useState(false)
    const getSwagInventory = httpsCallable(functions, 'getSwagInventory')
    const [filter, setFilter] = useState()
    const [cart, setCart] = useState([])
    const [pendingCarts, setPendingCarts] = useState()
    const [pendingCartsUpdated, setPendingCartsUpdated] = useState(0)
    const [cartUpdated, setCartUpdated] = useState(0)
    const history = useHistory()
    const toast = useToast()
    const pendingCartsListenerRef = useRef()
    const CreateRequestRef = useRef()

    const blackWhiteText = useColorModeValue('black', 'white')
    const unsubPending = useRef()

    const handleAddToCart = async (id, value, setAmount, dontDisplayToasts) => {
        let cartIndex = cart.findIndex((item => item.id == id))
        let tempCart = cart
        let itemIndex = inventory.findIndex((item => item.id == id))
        let showLimitToast = false

        // If the item is already in the cart
         if(cartIndex > -1){
            // If the item is being removed from the cart (user selected 0)
            if(value == 0){
                tempCart[cartIndex].selected = 0
                if(!dontDisplayToasts){
                    toast({
                        title: `${cart[cartIndex].Name} Removed From Cart`,
                        status: 'warning',
                        position: 'top',
                        duration: 4000,
                        isClosable: true,
                      })
                }               
           
            }
            else{
                let newValue = parseInt(tempCart[cartIndex].selected) + parseInt(value)
                if(newValue > 20 ){
                    showLimitToast = true
                }
                tempCart[cartIndex].selected = Math.min(newValue, 20)
                if(!dontDisplayToasts){
                    toast({
                        title: 'Added To Cart',
                        description: `${value} ${cart[cartIndex].Name} added`,
                        status: 'success',
                        position: 'top',
                        duration: 4000,
                        isClosable: true,
                    })
                }
                
            }
        }
        else{

            // If item is not found (hidden)
            if(itemIndex > -1){
                if(!inventory[itemIndex].Hidden || inventory[itemIndex].Hidden === false){
                    let selectedItem =    {
                        ...inventory[itemIndex],
                        selected: value
                    }
                    tempCart.push(selectedItem)
                }
              
            }
  
            if(!dontDisplayToasts){
                toast({
                    title: 'Added To Cart',
                    description: `${value} ${inventory[itemIndex].Name} added`,
                    status: 'success',
                    position: 'top',
                    duration: 4000,
                    isClosable: true,
                })
            }
        } 
        if(setAmount){
            setAmount(0)
        }
        await setDoc(lastCartRef, {
            cart: tempCart,
            timestamp: serverTimestamp()
        })
        return showLimitToast
       // forceUpdate() 
    }

    const handleSetSelectedItem = async (id, index, value) => {
        let cartIndex = cart.findIndex((item => item.id == id))
        let tempCart = cart
        // If the item is already in the cart
         if(cartIndex > -1){
            // If the item is being removed from the cart (user selected 0)
            if(value == 0){
                toast({
                    title: `${cart[cartIndex].Name} Removed From Cart`,
                    status: 'warning',
                    position: 'top',
                    duration: 4000,
                    isClosable: true,
                  })
                tempCart[cartIndex].selected = value
                CreateRequestRef.current.setDisableCreate(cartIndex, false)
                }
            else{
                tempCart[cartIndex].selected = value
                toast({
                    title: 'Cart Updated!',
                    description: `${value} ${cart[cartIndex].Name} are in your cart`,
                    status: 'success',
                    position: 'top',
                    duration: 4000,
                    isClosable: true,
                  })
            }
            await setDoc(lastCartRef, {
                cart: tempCart,
                timestamp: serverTimestamp()
            })
        }
    }

    useEffect(() => {
        if(!loading && inventory){
            const result = inventory.filter(item => item.Name.toLocaleLowerCase().includes(filter))
            setFilteredInventory(result)
          }
     
    }, [filter])

    
    useEffect( async () => {
        const queryPending = query(collection(db, "Marketing Requests"), 
                                where(`Request Details.Request Type`, '==', 'Swag'), 
                                where('Status', 'in', ['New', 'In Progress']),
                                where('Cart', '!=', false))

        pendingCartsListenerRef.current && pendingCartsListenerRef.current()
        pendingCartsListenerRef.current = onSnapshot(queryPending, (snapshot) => {
            let carts = []
            snapshot.forEach((doc) => {
                if(doc.data().Cart && doc.data().Cart.length > 0){
                        carts.push(doc.data().Cart)
                }
            })
            setPendingCarts(carts)
            setPendingCartsUpdated(Math.random())
        })
        

        const unsub = onSnapshot(lastCartRef, (doc) => {
          if(doc.exists()){
            setCart(doc.data().cart)
            setCartUpdated(false)
            setCartUpdated(Math.random() + 1)

          }
          else setCart([])
        });

        const q = query(collection(db, "Marketing Swag"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const swag = [];
            querySnapshot.forEach((doc) => {
                if(!doc.data().Hidden){
                    swag.push(doc.data());
                }
            });
            setInventory(swag)
            if(filter){
                const result = inventory.filter(item => item.Name.toLocaleLowerCase().includes(filter))
                setFilteredInventory(result)
            }
            else setFilteredInventory(swag)
            
        });

        
        const q2 = query(collection(db, "Marketing Swag Category"), orderBy('timestamp'));
        const querySnapshot2 = await getDocs(q2);
        const c = []
        querySnapshot2.forEach((doc) => {
            c.push(doc.data())
        });
        setCategories(c)

        const q3 = query(collection(db, "Marketing Swag Guidelines"), orderBy('created'));
        const querySnapshot3 = await getDocs(q3);
        const g = []
        querySnapshot3.forEach((doc) => {
            g.push(doc.data())
        });
        setGuidelines(g)

        setLoading(false)
        

        return () => {
            unsub()
            unsubscribe()
            pendingCartsListenerRef.current && pendingCartsListenerRef.current()
        }

    }, [])

    const scroll = (id) => {
        const section = document.querySelector( `#${id}` );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      };

    const handleRoute = ( route ) => {
        history.push(route)
    }

    return(
        <ScaleFade in initialScale={0.8}>
        <>
            {!loading && inventory && filteredInventory ?
                <>
                    <Flex zIndex='999' overflowX='auto' background={backgroundMenuColor} borderBottom={'1px solid lightgrey'} position={'sticky'} top='0' flexDir={'row'} justify='space-between'>
                   {/*  <Box>
                            <Input
                                onChange={(e) => setFilter(e.target.value.toLocaleLowerCase())} 
                                type='search'
                                value={filter}
                                placeholder="Filter Swag">
                            </Input>
                        </Box> */}
                        <Tabs>
                            <TabList>
                        {categories.map((category, index) => {
                            return(
                             <Tab fontStyle={'italic'} onClick={() => scroll(category.name.replace(' ', '-').toLocaleLowerCase())}>{category.name}</Tab>
                            )
                        })}
                            </TabList>
                        </Tabs>
                        <Spacer/>
                        <CreateRequest 
                            ref={CreateRequestRef} 
                            cartUpdated={cartUpdated} 
                            pendingCartUpdated={pendingCartsUpdated} 
                            pendingCartsListenerRef={pendingCartsListenerRef} 
                            isSwagRequest={true} 
                            description={description}
                            action='Create' 
                            cart={cart} 
                            inventory={inventory} 
                            products={filteredInventory} 
                            handleSetSelectedItem={handleSetSelectedItem} />
                        
                    </Flex>
                    {guidelines && inventory &&
                        <>
                        <Flex mt={5} justify={'center'} flexDir='row'>
                            <Heading fontWeight={'extrabold'} color={'teal.400'}>ULTIMATE SWAG </Heading><Heading fontWeight={'extrabold'} color={blackWhiteText}>&nbsp;GUIDELINES</Heading>
                        </Flex>
                        <GuidelineGrid setDescription={setDescription} handleAddToCart={handleAddToCart} inventory={inventory} guidelines={guidelines}/>
                        </>
                    }
                    <Products 
                        categories={categories} 
                        handleAddToCart={handleAddToCart} 
                        cartUpdated={cartUpdated}
                        pendingCartsUpdated={pendingCartsUpdated} 
                        pendingCarts={pendingCarts} 
                        cart={cart} 
                        inventory={inventory} 
                        products={filteredInventory}/>
                     
                </>
                :
                <Text>No Swag Found!</Text>
            }

            {loading &&      
                <Center mt='10'>
                    <Image src={require('../../assets/img/the-future-is-loading-transparent.gif')}/>
                </Center>} 
        </>
        </ScaleFade>
    )
}