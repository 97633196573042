import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import {
    Text,
    Wrap,
    WrapItem
  } from '@chakra-ui/react';
import NewClientCard from "../../components/Client/NewClientCard";
import { useAuth } from "../../contexts/AuthContext";

export default function SetupQueue() {
  const { isAdmin, SuperAdmin, currentUser, Roles } = useAuth()
  const { url, path } = useRouteMatch()
  const [clientList, setClientList] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect( async () => {
      let q = null
      if(SuperAdmin || isAdmin) {
        q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'New'));
      }
      else if(Roles.includes('Mortgage Broker') || Roles.includes('Loan Officer')){
        q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'New'), where(`Loan Officer.uid`, '==', currentUser.uid ));
      }
      else {
        q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'New'));
      }
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let clients = []
        querySnapshot.forEach((doc) => {
            let client = {}
            client = doc.data()
            clients.push(client)

        });
        setClientList(clients)
        setLoading(false)

      });
          

      return () => {
        setClientList(null)
        unsubscribe()
      }
  }, [])

  return (
    <>
            <Wrap mt={3}>
                {clientList && clientList.map((client, index) => {
                    return (
                        <>
                        <WrapItem>
                            <NewClientCard
                                status={client['Status']}
                                loanOfficer={client['Loan Officer']}
                                dateAdded={client['Date Added']}
                                loanDetails={client['Loan Details']}
                                docID={client.id}
                            />
                            </WrapItem>
                        </>
                    )
                }) }

                {clientList &&
                  <>
                  {clientList.length == 0 && !loading && <Text mt='5'>No clients in the queue</Text>}
                  </>

              }
            </Wrap>
    </>
  );
}
