// useSubtopics.js
import { useState, useEffect } from 'react';
import { db } from '../../../utils/init-firebase';
import { collection, doc, addDoc, updateDoc, deleteDoc, onSnapshot, serverTimestamp, orderBy, query } from 'firebase/firestore';

export function useSubtopics(topicId) {
  const [subtopics, setSubtopics] = useState([]);
  const [isLoadingSubtopics, setIsLoading] = useState(true);

  useEffect(() => {
    if(topicId){
      const unsubscribe = onSnapshot(query(collection(db, 'topics', topicId, 'subtopics'), orderBy('order')), (snapshot) => {
        const updatedSubtopics = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSubtopics(updatedSubtopics);
        setIsLoading(false);
      });
  
      return () => unsubscribe();
    }
   
  }, [topicId]);

  const handleReorderSubtopics = async () => {
    subtopics.forEach(async (subtopic, i) => {
      const subtopicRef = doc(db, 'topics', topicId, 'subtopics', subtopic.id);
      await updateDoc(subtopicRef, {
        order: i
      });
    })
  }

  const createSubtopic = async (title) => {
    topicId && await addDoc(collection(db, 'topics', topicId, 'subtopics'), {
      title,
      createdAt: serverTimestamp(),
      order: subtopics ? subtopics.length : 0
    });
  };

  const updateSubtopic = async (data, subtopicID) => {
    console.log(data)
    topicId && await updateDoc(doc(db, 'topics', topicId, 'subtopics', subtopicID), 
        data
    );
  };

  const deleteSubtopic = async (subtopic) => {
    topicId && await deleteDoc(doc(db, 'topics', topicId, 'subtopics', subtopic.id));
  };


  return { subtopics, 
            setSubtopics,
            isLoadingSubtopics, 
            createSubtopic, 
            updateSubtopic, 
            deleteSubtopic, 
            handleReorderSubtopics
         };
}
