// Topic.js
import { useState } from 'react';
import { Text, Flex, IconButton, Icon, Center, Input, useColorModeValue } from '@chakra-ui/react';
import { FiCheck, FiEdit3, FiTrash2, FiX } from 'react-icons/fi';
import { BiGridVertical } from 'react-icons/bi';

function Topic(props) {
  const { topic, selectedTopic, handleSelectTopic, handleUpdateTopic, handleDeleteTopic } = props
  const [editing, setEditing] = useState(false)
  const [newTitle, setNewTitle] = useState(topic?.title && topic.title)
  const selectedIconColor = useColorModeValue('black', 'white')

  return (
/*     <Reorder.Item key={topic.id} value={topic.id} dragControls={controls}>
 */      <Flex key={topic.id}
            flexDir={'row'}
            width='25vw'
            borderWidth={'1px'}
            fontWeight={selectedTopic === topic.id ? 'bold' : 'normal'}
            justify={'space-between'}
            boxShadow={'sm'}
            p={2}
            cursor="pointer"
            onClick={() => handleSelectTopic(topic.id)}
            >
              <Flex>
            <Center>
              <Icon 
                cursor={'grabbing'}
                className='reorder-handle'
                as={BiGridVertical}
                color={selectedTopic === topic.id ? selectedIconColor : 'gray.400'}
                fontSize={'3xl'}
                key={topic.id}
              />
            </Center>
              {editing ? 
              <Flex flexDir={'row'} align='center'>
                  <Input 
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    autoFocus
                    >
                  </Input>
                  <IconButton
                    size="md"
                    variant='ghost'
                    icon={<FiCheck/>}
                    colorScheme='green'
                    disabled={topic.title == newTitle}
                    onClick={() => {
                        handleUpdateTopic({title: newTitle}, topic.id)
                        setEditing(false)}}
                  ></IconButton>
                   <IconButton
                    size="md"
                    variant='ghost'
                    icon={<FiX/>}
                    colorScheme='red'
                    onClick={() => setEditing(false)}
                  ></IconButton>
                </Flex>
                :
                <Flex flexDir={'row'} align='center'>
                  <Text fontSize='sm'>{topic.title}</Text>
                  <IconButton
                    size="md"
                    variant='ghost'
                    icon={<FiEdit3/>}
                    colorScheme='green'
                    onClick={() => setEditing(true)}
                  ></IconButton>
                </Flex>
              }    
              </Flex>     
              <IconButton
                size="sm"
                variant='ghost'
                icon={<FiTrash2/>}
                colorScheme='red'
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTopic(topic);
                }}
              >
          </IconButton>
      </Flex>
      
    );
}

export default Topic;
