import {
    Heading,
    Avatar,
    Box,
    Text,
    Badge,
    useColorModeValue,
    HStack,
    Tag,
    TagLabel,
    TagLeftIcon,
    Tooltip,
    TagRightIcon,
    Flex,
    Spacer
  } from '@chakra-ui/react';
import Timer from '../Timer';
import { FiHome, FiRotateCcw, FiUser } from 'react-icons/fi';
import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsPiggyBank } from 'react-icons/bs';
import moment from 'moment';
import AssignClaimClient from './AssignClaimClient';
import CountdownTimer from '../CountdownTimer';
import { FaQuestion } from 'react-icons/fa';
import NotesDrawer from '../NotesDrawer';
import { collection } from 'firebase/firestore';
import { db } from '../../utils/init-firebase';

  export default function NewClientCard({ status, loanOfficer, dateAdded, loanDetails, docID }) {
    const dueInMinutes = 60;
    const notesRef = collection(db, 'Clients', docID, 'Notes')
    return (
        <Box
        m={1}
        width={{base: '90vw', md :'300px'}}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'lg'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}>
            
        <Flex mb='5' >
            <HStack>
              <Avatar
                  size={'md'}
                  src={loanOfficer.photoURL}
                  alt={'Avatar Alt'}
                  pos={'relative'}
              />
              <Heading fontSize={'lg'} fontFamily={'body'}>
                  {loanOfficer.Name}
              </Heading>
              <Badge colorScheme='red'>New</Badge>  
            </HStack>
            <Spacer/>
            <NotesDrawer 
              notesRef={notesRef}
              parentID={docID}
            />
        </Flex>
    
          <Heading textAlign={'left'} fontSize={'sm'} mb={3} fontFamily={'body'} textTransform='uppercase' colorScheme={'gray'}>
                <HStack>
                  {loanDetails['First Name'] != '' &&
                    <>
                      <FiUser/>
                      <Text>{loanDetails['First Name']} {loanDetails['Last Name']}</Text>
                    </>
                  }
                  {dateAdded ?
                  <>
{/*                   {dateAdded.seconds && <CountdownTimer end={dateAdded.seconds*1000 + (dueInMinutes*60000)}/> }
 */}                  {dateAdded.seconds && <Timer start={dateAdded.seconds} warningInMinutes={15}/>}
                  </>
                  : null}
                
                </HStack>
                <Box 
                    color='gray.400'
                    letterSpacing='wide'
                    fontSize='12px'
                    mt={1}
                    textTransform='uppercase'
                    >
            {dateAdded ? moment(dateAdded.toDate()).format('MM/DD/YY h:mm a') : null}
            </Box>
            </Heading>
           
          <HStack mb='5' >
            <Tag  size='md' variant='outline' colorScheme='green' textTransform='uppercase' fontWeight='bold'>
                  {loanDetails['Purchase or Refi'] == 'Purchase' && 
                    <TagLeftIcon as={FiHome} />
                  }
                  {loanDetails['Purchase or Refi'] == 'Refi' &&
                    <TagLeftIcon as={FiRotateCcw} /> 
                  }
                  {loanDetails['Purchase or Refi'] == 'Custom' &&
                    <TagLeftIcon as={FaQuestion} /> 
                  }
                <TagLabel fontSize="14px">{loanDetails['Purchase or Refi']}</TagLabel>
            </Tag> 
            {loanDetails['Purchase or Refi'] == 'Custom' &&
              <Tooltip fontSize={'sm'} placement='top' label={loanDetails['Description']}>
              <Tag size='md' variant='ghost' textTransform='uppercase' fontWeight='400'>
                  <TagLabel pr='0' fontSize="14px">Description</TagLabel>
                
                  <TagRightIcon ml='0' mt='-4' fontSize='14px' as={InfoIcon} />               
                </Tag> 
                </Tooltip>
            }
     
              {loanDetails['Target Lender'] == 'Other' &&
                <Tag size='md' variant='outline' textTransform='uppercase' fontWeight='bold'>
                  <TagLeftIcon as={BsPiggyBank} />
                  <TagLabel fontSize="14px">{loanDetails['Other Lender']}</TagLabel>
                </Tag> 
              }
              {loanDetails['Target Lender'] != 'Other' && loanDetails['Target Lender'] != '' &&
                <Tag size='md' variant='outline' textTransform='uppercase' fontWeight='bold'>
                  <TagLeftIcon as={BsPiggyBank} />
                  <TagLabel fontSize="14px">{loanDetails['Target Lender']}</TagLabel>
                </Tag> 
              }             
           
          </HStack>
          <AssignClaimClient
              loanOfficer={loanOfficer}
              loanDetails={loanDetails} 
              docID={docID}
              status={status}
              showUndo={true}
              showView={true}
          />
        </Box>
    );
  }