import React from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { FormLabel, VStack, useColorModeValue } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";

/*
    autoSetEndDate (string) The End Dates field name. This was originally added for use in the event request marketing form.
    There are two datepickers, Start Date & End Date. The user selects the start date & time, and the component will auto 
    set the end date to the same as the start date for ease-of-use

    dontRestrictDates (boolean) The default minimum date is today. Set dontRestrictDates to true to all the user to select any date

    daysNotice (integer) will allow you to set a minimum date x BUSINESS DAYS into the future. It will skip over Saturday and Sunday
*/

export const DatePickerField = ({  daysNotice, autoSetEndDate, dontRestrictDates, label, name, ...props }) => {
    const { setFieldValue, handleBlur, setFieldTouched, validateField, errors, touched } = useFormikContext();
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")
    const [field] = useField(name);
    const isError = touched[name] && errors[name];
    const datepickerWrapperStyle = {
        // Add your styles here for error state
        borderColor: touched[name] && errors[name] ? '#E53E3E' : '',
        boxShadow: touched[name] && errors[name] ? '0 0 0px 2px #E53E3E' : '',
        borderRadius: '5px',
    };
    const today = new Date();
    const minDate = dontRestrictDates ? null : today
    if(daysNotice && minDate){
        const numToAdd = daysNotice;
        for (let i = 1; i <= numToAdd; i++) {
            minDate.setDate(minDate.getDate() + 1);
            if (minDate.getDay() === 6) {
                minDate.setDate(minDate.getDate() + 2);
            }
            else if (minDate.getDay() === 0) {
                minDate.setDate(minDate.getDate() + 1);
            }
        }
    }

  return (
      <>      
      <VStack>
        <FormLabel mb={0} alignSelf={'flex-start'}>{label && label}</FormLabel>
            <div style={datepickerWrapperStyle} >
                <DatePicker
                {...field}
                {...props}
                className={`${datepickerClass} ${isError ? 'border-red-500' : ''}`}

                selected={Object.prototype.toString.call(field.value) === '[object Date]' ?
                            field.value
                : field.value && field.value.toDate() || null}
                
                onChange={val => {
                    setFieldValue(field.name, val);
                    autoSetEndDate && setFieldValue(autoSetEndDate, val);
                }}
                onCalendarClose={val => {
                    // Force-validate onCalendarClose to avoid any potential DatePicker Blur issues
                    // Didn't help
                    validateField(name);
                }} 
                onBlur={e => {
                    // Call Formik's handleBlur
                    // Didn't help
                    handleBlur(e);
                }}           
                onChangeRaw={e => {
                    setFieldTouched(field.name, true, true);
                }}
                showTimeSelect={props.showTimeSelect}
                dateFormat={props.dateFormat ? props.dateFormat : "M/d/yyyy"}
                autoComplete="off"
                minDate={minDate && minDate}
                
                />
            </div>
            <ErrorMessage 
                name={name} 
                component="div" 
                className="chakra-form__error-message css-170ki1a datepicker-errormessage"/>
        </VStack>
    </>

  );
};