import { AvatarGroup, AvatarBadge, Avatar, Tooltip } from "@chakra-ui/react"

export default function LoanTeamAvatars( { LO, LOA, LC, CSC, TC } ){

    return(
        <>
          <AvatarGroup size='sm'>
                {LO && LO.uid &&
                <Tooltip placement='top' label={`${LO.Name}`}>
                    <Avatar src={LO.photoURL}>
                    <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='red.400'>LO</AvatarBadge>

                    </Avatar>
                </Tooltip>
                }
                {LOA && LOA.uid &&
                <Tooltip placement='top' label={`${LOA.Name}`}>
                    <Avatar src={LOA.photoURL}>
                    <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='orange.400'>LOA</AvatarBadge>

                    </Avatar>
                </Tooltip>
                }
                {LC && LC.uid &&

                <Tooltip placement='top' label={`${LC.Name}`}>
                    <Avatar src={LC.photoURL}>
                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='green.500'>LC</AvatarBadge>
                    </Avatar>
                </Tooltip>
                }
                {CSC && CSC.uid &&
                <Tooltip placement='top' label={`${CSC.Name}`}>
                    <Avatar src={CSC.photoURL}>
                    <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='blue.400'>CSC</AvatarBadge>

                    </Avatar>
                </Tooltip>
                }
                {TC && TC.uid &&
                <Tooltip placement='top' label={`${TC.Name}`}>
                    <Avatar src={TC.photoURL}>
                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='purple.400'>TC</AvatarBadge>
                    </Avatar>
                </Tooltip>
                }
            </AvatarGroup>
      </>
    )
}