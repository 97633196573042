// ManageTopics.js
import { useState } from 'react';
import { Spinner, Flex, Text, Icon } from '@chakra-ui/react';
import { useTopics } from './useTopics';
import { useSubtopics } from './useSubtopics';
import TopicForm from './TopicForm';
import { FiArrowRight } from 'react-icons/fi';
import TopicList from './TopicList';
import SubtopicList from './SubtopicList';
import SubtopicConfig from './SubtopicConfig';
import useQuestions from '../Questions/useQuestions';

function ManageTopics() {
  const [selectedTopic, setSelectedTopic] = useState()
  const [selectedSubtopic, setSelectedSubtopic] = useState(null);

  const { topics, setTopics, isLoading, createTopic, updateTopic, deleteTopic, handleReorderTopics } = useTopics();
  const { subtopics, setSubtopics, isLoadingSubtopics, createSubtopic, updateSubtopic, deleteSubtopic, handleReorderSubtopics } = useSubtopics(selectedTopic && selectedTopic);
  const { questions } = useQuestions()

  const [showConfig, setShowConfig] = useState(false)

  const handleCreateTopic = async (values) => {
    await createTopic(values.title);
  };

  const handleSelectTopic = (id) => {
    setSelectedTopic(id);
    setSelectedSubtopic(null);
  };

  const handleSelectSubtopic = (id) => {
    setSelectedSubtopic(id);
  };

  const handleCreateSubtopic = async (values) => {
    await createSubtopic(values.title);
  };

  const getTopicNameById = (id, data) => {
    let index = data.findIndex((val) => val.id == id)
    if(index > -1){
      return data[index].title
    }
    else return 'Error getting topic name'
  }

  return (
    <Flex flexDir='row'>
      {/* Left Column - Topics */}
      <Flex mr='2' flexDir='column' width='25vw' align="start">
        <TopicForm onSubmit={handleCreateTopic} isSubmitting={isLoading} />

        {isLoading ? 
          <Spinner />
         : 
            <>
            {topics && 
                <TopicList 
                    topics={topics}
                    setTopics={setTopics}
                    selectedTopic={selectedTopic}
                    updateTopic={updateTopic}
                    deleteTopic={deleteTopic}
                    handleSelectTopic={handleSelectTopic}
                    handleReorderTopics={handleReorderTopics}
                    />
            }
            </>

        }
      </Flex>

      {/* Middle Column - Subtopics */}
      <Flex mr='2' flexDir='column' width='25vw' align="start">
        {!selectedTopic && <Text>Subtopics will appear here</Text>}
        {selectedTopic && (
          <>
            <TopicForm onSubmit={handleCreateSubtopic} placeholderText='Add subtopic' isSubmitting={isLoading} />
            {isLoading ? 
            <Spinner />
            : 
                <>
                 {subtopics && selectedTopic &&
                  <SubtopicList
                      subtopics={subtopics}
                      setSubtopics={setSubtopics}
                      handleReorderSubtopics={handleReorderSubtopics}
                      selectedTopic={selectedTopic}
                      selectedSubtopic={selectedSubtopic}
                      handleSelectSubtopic={handleSelectSubtopic}
                  />
                } 
                </>

            }
          </>
        )}
      </Flex>

      {/* Right Column - Questions */}
      <Flex mr='2' flexDir='column' width='25vw' align="start">
      {selectedTopic && selectedSubtopic &&
      <>
        <Text>Subtopic Configuration</Text> 
        <Flex  mb='3'>       
          <Text fontWeight={'bold'}>{getTopicNameById(selectedTopic, topics)}</Text>
          <Icon ml='2' mt='1' fontSize={'lg'} as={FiArrowRight}/>
          <Text ml='2'fontWeight={'bold'}>{getTopicNameById(selectedSubtopic, subtopics)}</Text>
        </Flex>
        <SubtopicConfig topic={selectedTopic} subtopic={selectedSubtopic} questions={questions}/>

      </>}
      </Flex>

    </Flex>
  );
}

export default ManageTopics;

