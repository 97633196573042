import { useEffect, useState, useRef } from "react";
import { collection, query, onSnapshot, orderBy, where, limit } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import "react-datepicker/dist/react-datepicker.css";
import TableCardWrapper from "./TableCardWrapper";
import LoanTeamAvatars from "../../components/Client/LoanTeamAvatars";
import { Button, Text } from "@chakra-ui/react";
import TimestampSeconds from "../../components/TimestampSeconds";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function RecentSubmissionsPage() {
  const [clientList, setClientList] = useState(null)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { SuperAdmin } = useAuth()
  const today = new Date();
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 14);
  const [startDate, setStartDate] = useState(twoWeeksAgo)
  const [endDate, setEndDate] = useState(today)
  let unsubscribeRef = useRef()

  const allCols = [
      {
          Header: 'Loan Officer',
          accessor: 'Loan Officer uid',
          footer: '',
          Cell: ({ cell: { value }, row }) => (
              <>  
                  <LoanTeamAvatars 
                      LO={row.original && row.original['Loan Officer uid'] && {uid: row.original['Loan Officer uid'], Name: row.original['Loan Officer Name'], photoURL: row.original['Loan Officer photoURL']}} 
                      // LOA={row.original && row.original[`LOA uid`] && {uid: row.original['LOA uid'], Name: row.original[`LOA Name`], photoURL: row.original[`LOA photoURL`]}} 
                      // LC={row.original && row.original[`LC uid`] && {uid: row.original['LC uid'], Name: row.original[`LC Name`], photoURL: row.original[`LC photoURL`]}} 
                      // CSC={row.original && row.original[`CSC uid`] && {uid: row.original['CSC uid'], Name: row.original[`CSC Name`], photoURL: row.original[`CSC photoURL`]}} 
                      // TC={row.original && row.original[`TC uid`] && {uid: row.original['TC uid'], Name: row.original[`TC Name`], photoURL: row.original[`TC photoURL`]}} 
                  /> 
              </>
          ),
      },
      {
          Header: 'Purpose',
          accessor: 'Loan Details Purchase or Refi',
          footer: ''
      },
      {
          Header: 'Program',
          accessor: 'Loan Details Product',
          filterOptions: {
            type: 'dropdown',
            makeOptionsFromData: true
          },
          footer: ''
      },
      {
          Header: 'Lender',
          accessor: 'Loan Details Lender',
          filterOptions: {
            type: 'dropdown',
            makeOptionsFromData: true
          },
          footer: ''
      },
      {
          Header: 'Property Type',
          accessor: 'Loan Details Property Type',
          filterOptions: {
            type: 'dropdown',
            makeOptionsFromData: true
          },
          footer: ''
      },
      {
          Header: 'Credit Score',
          accessor: 'Loan Details Credit Score',
          footer: ''
      },
      {
          Header: 'Rate',
          accessor: 'Loan Details Rate',
          footer: '',
          Cell: ({ cell: { value } }) => (
              <>  
                  <Text>{value && `${value}%`}</Text>
              </>
          ),
      },
      {
          Header: 'Reason',
          accessor: 'Loan Details Why did you select this Lender?' ,
          footer: '',
          Cell: ({ cell: { value }, row }) => (
              <>  
                  <Text>{value && value != 'Other' ?
                      value
                      :
                      row.original['Loan Details (Other) Why did you select this Lender?'] && row.original['Loan Details (Other) Why did you select this Lender?']
                  }</Text>
              </>
          ),
      },
      {
          Header: 'Date',
          accessor: 'Date Completed seconds',
          footer: '',
          Cell: ({ cell: { value } }) => (
              <TimestampSeconds seconds={value} dateFormat='MM/DD/YY' />
          ),
      },

  ]
  const superAdminCols = [
      {
          Header: '',
          accessor: 'id',
          width: '100px',
          footer: '',
          Cell: ({ cell: { value }, row }) => (
              <Button size='sm' onClick={() => history.push(`/${row?.original?.Status.toLowerCase()}/${row?.original?.id}`)}>
                  View
              </Button>
          ),
      }
  ]
  const cols = SuperAdmin ? [...superAdminCols, ...allCols] : allCols

  const getClients = async () => {
    setLoading(true)
    unsubscribeRef.current && unsubscribeRef.current()
    let q = query(collection(db, "Clients"), 
                    orderBy('Date Completed', 'desc'), 
                    where('Status', '==', 'Completed'), 
                    where('Date Completed', '>=', startDate), 
                    where('Date Completed', '<=', endDate), 
                    limit(200));
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let clients = []
      querySnapshot.forEach((doc) => clients.push(doc.data()));
      unsubscribeRef.current = unsubscribe
      setClientList(clients)
      setLoading(false)

    });
  }

  useEffect(() => {
    return () => {
        unsubscribeRef.current && unsubscribeRef.current()
    }
  }, [])

  useEffect(async() => {
    if(startDate && endDate){
      await getClients()
    }
  }, [startDate, endDate])
  return (
    <>
      {clientList &&
        <TableCardWrapper
            cols={cols} 
            clientList={clientList} 
            getClients={getClients} 
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            loading={loading}
            />
      }
    </>
  );
}