import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Icon
  } from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'


function RateScenariosModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        <Button p='3' variant='ghost' size='lg' onClick={onOpen}>Rate Scenarios &nbsp;<Icon as={FiExternalLink}></Icon></Button>
  
        <Modal size='full' isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <ModalContent height='85vh' >
            <ModalCloseButton />
            <ModalBody height='100%'  display='flex' flexDirection='column' overflow='hidden'>
                <iframe height='100%' flexGrow='1' border='none' margin='0' padding='0' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTGonpN7-ro1lOF0wMWFeR4JDRPFR96IdGcBm3S7SyNJJlDiBRPk5bh4ZMBzcB2dx96dsoKTM4sQMXz/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default RateScenariosModal