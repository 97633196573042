import {
    Box,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    ScaleFade,
    FormControl,
    FormLabel,
    Switch,
    Heading
} from "@chakra-ui/react";
import BooleanSwitch from "../../../components/Settings/BooleanSwitch";
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../utils/init-firebase";
import { updateDoc } from "firebase/firestore";
import { useAuth } from "../../../contexts/AuthContext";
import { useToast } from '@chakra-ui/react'
import GroupChat from "../../../components/Client/GroupChat";
import EscalationSummary from "./EscalationSummary";
import AssignEscalation from "./AssignEscalation";

export default function EscalationBox({ escalation, client, showLoanButton, isClientPageView }) {
    const reviewBGColor = useColorModeValue('orange.200', 'orange.400')
    const reviewedBGColor = useColorModeValue('green.200', 'green.400')
    const thisDocRef = doc(db, 'Escalations', escalation.id)

    const notesForReviewRef = collection(db, 'Escalations', escalation.id, 'Notes For Review')
    const { SuperAdmin, MarketingAdmin, currentUser, branches } = useAuth()
    const toast = useToast()
    console.log(escalation)
    const onCompletion = async () => {
        try{
            await updateDoc(thisDocRef, {
                'Date Completed': serverTimestamp(),
                'Status': 'Completed',
                'Last Edited By': {
                    Email: currentUser.email,
                    Name: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    uid: currentUser.uid
                }
            })
            toast({
                title: 'Escalation Completed!',
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top'
            })
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    };

    const toggleClientSetting = async (e, label) => {
        await updateDoc(doc(db, 'Escalations', escalation.id), {
            [label]: e.target.checked,
            [`Date ${label}`]: serverTimestamp(),
            ['Has Notes For Review']: true
        })
    }

  return (
    <ScaleFade in initialScale={0.85}>
    <Box 
        maxH={isClientPageView && '30vh'}
        overflowY={isClientPageView && 'scroll'}
        width={isClientPageView ? 
            {
                base: '100%',
                xl: '25vw'
            }
            :
            {
            base: '100%',
            xl: '35vw'
        }}  m={0} p={{base: '2', lg: '4'}} borderWidth={!isClientPageView && '1px'} borderRadius='lg'>
        <EscalationSummary escalation={escalation} client={client} showLoanButton={showLoanButton}
/>
          {(SuperAdmin) &&
      <>
        <Accordion mt={4} allowMultiple={true}>
            {(SuperAdmin) &&
                <AccordionItem>
                    <h2>
                    <AccordionButton 
                        backgroundColor={escalation['Coaching Completed'] && SuperAdmin ?
                                            reviewedBGColor
                                            :
                                            escalation['Flag For Review'] && SuperAdmin ? 
                                            reviewBGColor 
                                            : ''}>
                        <Box flex='1' textAlign='left'>
                            {`Notes For Review`}&nbsp;
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pl={0} pr={0}>
                    <FormControl mb={3} display='flex' alignItems='center'>
                        <FormLabel htmlFor='flag-for-review' mb='0'>
                            Flag For Review
                        </FormLabel>
                        <Switch 
                            id='flag-for-review' 
                            disabled={escalation['Coaching Completed']} 
                            isChecked={escalation['Flag For Review']} 
                            onChange={(e) => toggleClientSetting(e, 'Flag For Review')} 
                        />
                    </FormControl>
                    {SuperAdmin &&
                    <FormControl mb={3} display='flex' alignItems='center'>
                        <FormLabel htmlFor='coaching-completed' mb='0'>
                            Coaching Completed
                        </FormLabel>
                        <Switch 
                            id='coaching-completed' 
                            isChecked={escalation['Coaching Completed']} 
                            onChange={(e) => toggleClientSetting(e, 'Coaching Completed')} 
                        />
                    </FormControl>
                    }
                     <GroupChat 
                        collectionRef={notesForReviewRef} 
                        collectionName='Notes For Review'
                        parentID={escalation.id}
                        width='100%' 
                        isNotes
                        isNotesForReview
                        parentDocContent={escalation}
                        />

                    </AccordionPanel>
                </AccordionItem> 
                }
                  <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign Escalation
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <AssignEscalation escalation={escalation} />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Notification Settings
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                <AccordionPanel>     
                        <Heading size='xs' mb='2'>Chat Messages</Heading>
    
                        <BooleanSwitch 
                        docRef={thisDocRef}
                        fieldPath={'Settings.Notify LO'}
                        initialState={escalation.Settings && escalation['Settings']['Notify LO'] && escalation['Settings']['Notify LO']}
                        label={'Notify LO on associated loan'}
                        successMsg={'LOs will be notified when new chat messages are sent'}
                        warningMsg={'LOs will NOT be notified when new chat messages are sent'}
                        id='notify-lo'
                    />
                    <BooleanSwitch 
                        docRef={thisDocRef}
                        fieldPath={'Settings.Notify LOA'}
                        initialState={escalation.Settings && escalation['Settings']['Notify LOA'] && escalation['Settings']['Notify LOA']}
                        label={'Notify LOA on associated loan'}
                        successMsg={'LOAs will be notified when new chat messages are sent'}
                        warningMsg={'LOAs will NOT be notified when new chat messages are sent'}
                        id='notify-loa'
                    />
                    <BooleanSwitch 
                        docRef={thisDocRef}
                        fieldPath={'Settings.Notify LC'}
                        initialState={escalation.Settings && escalation['Settings']['Notify LC'] && escalation['Settings']['Notify LC']}
                        label={'Notify LC on associated loan'}
                        successMsg={'LCs will be notified when new chat messages are sent'}
                        warningMsg={'LCs will NOT be notified when new chat messages are sent'}
                        id='notify-lc'
                    />
                    <BooleanSwitch 
                        docRef={thisDocRef}
                        fieldPath={'Settings.Notify CSC'}
                        initialState={escalation.Settings && escalation['Settings']['Notify CSC'] && escalation['Settings']['Notify CSC']}
                        label={'Notify CSC on associated loan'}
                        successMsg={'CSCs will be notified when new chat messages are sent'}
                        warningMsg={'CSCs will NOT be notified when new chat messages are sent'}
                        id='notify-csc'
                    />
                    <BooleanSwitch 
                        docRef={thisDocRef}
                        fieldPath={'Settings.Notify TC'}
                        initialState={escalation.Settings && escalation['Settings']['Notify TC'] && escalation['Settings']['Notify TC']}
                        label={'Notify TC on associated loan'}
                        successMsg={'TCs will be notified when new chat messages are sent'}
                        warningMsg={'TCs will NOT be notified when new chat messages are sent'}
                        id='notify-tc'
                    />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
        </>
        } 

    </Box>
    </ScaleFade>
      
  );
}
