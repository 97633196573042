import { useEffect, useRef, useState } from "react";
import { doc, getDoc, serverTimestamp, collection, where, query, updateDoc, onSnapshot, getDocs } from "firebase/firestore";
import { useHistory, useLocation } from "react-router-dom";
import { db } from "../../../utils/init-firebase"
import { useAuth } from "../../../contexts/AuthContext";
import {
    Stack,
    Heading,
    ScaleFade
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import GroupChat from "../../../components/Client/GroupChat";
import EscalationBox from "./EscalationBox";

export default function EscalationPage() {
    const [error, setError] = useState(null)
    const [escalation, setEscalation] = useState(null)
    const [topicName, setTopicName] = useState('')
    const [subtopicName, setSubtopicName] = useState('')
    const [client, setClient] = useState(null)
    const [users, setUsers] = useState([])
    const { currentUser } = useAuth()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()
    const location = useLocation()

    let unsubClientRef = useRef()

    useEffect(() => {

       async function updateNotifications(){
          try{
            const notificationsRef = collection(db, "users", currentUser.uid, 'Notifications');
            const q = query(notificationsRef, where("contentID", "==", id));
            const querySnapshot = await getDocs(q);
              querySnapshot.forEach( async (doc) => {
                await updateDoc(doc.ref, {
                    read: serverTimestamp()
                })
              });
          }
          catch(err){
            console.log(err)
          }
      }

      updateNotifications() 
    const unsubescalation = onSnapshot(doc(db, 'Escalations', id), async (docSnap) =>{
        if(docSnap.exists()){
          setError(null)
          setEscalation({
            ...docSnap.data(), 
            id: docSnap.id, 
          })
        }
        else{
          setError('escalation not found')
        }
        setIsLoading(false)
      })         

      return () => {
        unsubescalation()
        unsubClientRef.current && unsubClientRef.current()
      }
  
  }, [id])

  useEffect(() => {
    if(escalation){
      unsubClientRef.current && unsubClientRef.current()
      const q = query(collection(db, "Clients"), where('id', "==", escalation.loanID));

      unsubClientRef = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            setClient(doc.data())
        });
      });
    }
 
  }, [escalation])

  useEffect( async () => {
    const getTopicName = async (docRef, fieldToReturn) => {
      try{
        const topicSnap = await getDoc(docRef)
        console.log(docRef)
        if(topicSnap.exists()) {
          console.log(topicSnap.data())

          return topicSnap.data().title  
        }
      }
      catch(err){
        return 'Error getting Topic Name'
      }
    }

    if(escalation){
      console.log(escalation)
      if(escalation.topicID){
         const topicRef = doc(db, 'topics', `${escalation.topicID}`)
         setTopicName(await getTopicName(topicRef, 'title'))
      }
      if(escalation.subtopicID){
        const subtopicRef = doc(db, 'topics', `${escalation.topicID}`, 'subtopics', `${escalation.subtopicID}`)

        setSubtopicName(await getTopicName(subtopicRef, 'title'))
     }
    }
  }, [escalation])

  return (
      <>
      {!isLoading && 
        <>
          <ScaleFade in initialScale={0.85}>
            <Stack direction={{base: "column-reverse", xl: "row"}} mt='5'>
                {error && <Heading>{error}</Heading>}
                {escalation &&
                    <>
                    <Stack width={{base: '91vw', md: '85vw', xl: '52vw'}} flexDirection={'column'}>
                        <GroupChat 
                            parentDocContent={escalation}
                            referencedLoan={client && client} 
                            width={'100%'} 
                            collectionName='Escalations' 
                            collectionRef={collection(db, 'Escalations', escalation.id, 'Chat')} 
                            parentID={escalation.id} 
                          />
                    </Stack>
                      {escalation &&
                        <EscalationBox 
                            escalation={{...escalation, topicName: topicName, subtopicName: subtopicName}}   
                            client={client}
                            showLoanButton       
                            />
                      }
                    </>

                }
                </Stack>
            </ScaleFade>
        </>
      }
      </>
  );
}
