import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../utils/init-firebase";
import { doc, getDoc } from "firebase/firestore";
import StatusChangeNotificationSettings from "../../components/Settings/StatusChangeNotificationSettings";
import MarketingTaskSettings from "../../components/Marketing/MarketingTaskSettings";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ManageMarketingCategories from "./ManageMarketingCategories";
import ManageMatrixElements from "./ManageMarketingMatrix";
export default function MarketingSettings({}) {
  const { users } = useAuth();
  const settingsRef = doc(db, "Marketing Settings", "yEwRIqrkneqrGnAQhgvs");
  const [initialSettings, setInitialSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const getSettings = async () => {
    try {
      const settings = await getDoc(settingsRef);
      if (settings.exists) {
        setInitialSettings(settings.data());
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(async () => {
    await getSettings();
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Tabs mt="5">
            <TabList>
              <Tab>Requests</Tab>
              <Tab>Matrix</Tab>
              <Tab>Matrix Categories</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <MarketingTaskSettings
                  settingsRef={settingsRef}
                  initialSettings={initialSettings}
                />

                <StatusChangeNotificationSettings
                  heading="New Request Notifications"
                  users={users}
                  settingsRef={settingsRef}
                  initialSettings={initialSettings}
                  settingName={"New Request Notifications"}
                  actionMessage={"a request is created"}
                />
                <StatusChangeNotificationSettings
                  heading="Request Assignment Notifications"
                  omitInputFields={true}
                  users={users}
                  settingsRef={settingsRef}
                  initialSettings={initialSettings}
                  settingName={"Request Assignment Notifications"}
                  actionMessage={"a request is assigned"}
                  emailSwitches={[
                    {
                      name: "Email Assignees",
                      id: `assignees-email-alert-on-task-assignment`,
                      label: "Email Assignees",
                      fieldPath: `Request Assignment Notifications.Email Assignees`,
                      successMsg: `Assignees will be emailed when a task is assigned`,
                      warningMsg: `Assignees will not be emailed when a task is assigned`,
                    },
                  ]}
                  pushSwitches={[
                    {
                      name: "Push Assignees",
                      id: `assignees-push-alert-on-task-assignment`,
                      label: "Push Assignees",
                      fieldPath: `Request Assignment Notifications.Push Assignees`,
                      successMsg: `Assignees will receive push notifications when a task is assigned`,
                      warningMsg: `Assignees will not receive push notifications when a task is assigned`,
                    },
                  ]}
                />
                <StatusChangeNotificationSettings
                  heading="Completed Request Notifications"
                  users={users}
                  settingsRef={settingsRef}
                  initialSettings={initialSettings}
                  settingName={"Completed Request Notifications"}
                  actionMessage={"a request is completed"}
                  emailSwitches={[
                    {
                      name: "Email Assignees",
                      id: `assignees-email-alert-on-task-complete`,
                      label: "Email Assignees",
                      fieldPath: `Completed Request Notifications.Email Assignees`,
                      successMsg: `Assignees will be emailed when a task is completed`,
                      warningMsg: `Assignees will not be emailed when a task is completed`,
                    },
                    {
                      name: "Email Owner",
                      id: `owner-email-alert-on-task-complete`,
                      label: "Email Owner",
                      fieldPath: `Completed Request Notifications.Email Owner`,
                      successMsg: `Owner will be emailed when a task is completed`,
                      warningMsg: `Owner will not be emailed when a task is completed`,
                    },
                  ]}
                  pushSwitches={[
                    {
                      name: "Push Assignees",
                      id: `assignees-push-alert-on-task-complete`,
                      label: "Push Assignees",
                      fieldPath: `Completed Request Notifications.Push Assignees`,
                      successMsg: `Assignees will receive push notifications when a task is completed`,
                      warningMsg: `Assignees will not receive push notifications when a task is completed`,
                    },
                    {
                      name: "Push Owner",
                      id: `owner-push-alert-on-task-complete`,
                      label: "Push Owner",
                      fieldPath: `Completed Request Notifications.Push Owner`,
                      successMsg: `Owner will receive push notifications when a task is completed`,
                      warningMsg: `Owner will not receive push notifications when a task is completed`,
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel>
                <ManageMatrixElements
                  initialSettings={initialSettings}
                  settingsRef={settingsRef}
                />
              </TabPanel>
              <TabPanel>
                <ManageMarketingCategories
                  initialSettings={initialSettings}
                  settingsRef={settingsRef}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </>
  );
}
