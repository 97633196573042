import { Drawer, Button, DrawerBody, DrawerOverlay, DrawerFooter, DrawerHeader, DrawerContent, DrawerCloseButton, Stack, Box, Avatar, Text, Divider, MenuItem, Menu } from "@chakra-ui/react";
import { getDocs, doc, getDoc, query, collection, orderBy, where, limit } from "firebase/firestore";
import { db } from "../utils/init-firebase";
import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";

export default function ReadNotificationDrawer(){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [notifications, setNotifications] = useState([])
    const btnRef = React.useRef()
    const { currentUser } = useAuth()
    const history = useHistory()

    const openDrawer = async () => {
        await getReadNotifications()
        onOpen()
    }

  async function handleClickNotification(notification){
    if(notification.id == 'Home'){
      history.push('')
      
    }
    else if(notification.route){
      history.push(notification.route)
    }
    else if(notification.path){
      history.push(notification.path)
      
    }
    else {
      let route = '/in-progress/'
      const docRef = doc(db, "Clients", notification.loanID);    
      const docSnap = await getDoc(docRef)
      if(docSnap.data()['Status'] == 'Completed'){
        route = '/completed/'
      }
      history.push(route + notification.loanID)
    }
    onClose()

  }

    const getReadNotifications = async () => {
        const q = query(collection(db, "users", currentUser.uid, 'Notifications'), orderBy('read', 'desc'), where('read', '!=', false), limit(25));
        const querySnapshot = await getDocs(q)
        const notifs = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            notifs.push(doc.data())
        });
        setNotifications(notifs)
    }

    return(
        <>
   
        <Divider mt={2} />
        <Button onClick={openDrawer} variant={'ghost'}>View Read</Button>
        <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='sm'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Read Notifications</DrawerHeader>

          <DrawerBody>
           
            {notifications.length > 0 ?
               <Menu>
                  <>                
                  {notifications.map(notification => {
                    return(
                      <>
                      <MenuItem
                          onClick={() => handleClickNotification(notification)}>
                             <Avatar mr={2} size='md' src={notification.user ? notification.user.photoURL : 'public/fhl-logo.png'} /> 
                             <Stack spacing='0.5' direction='column'>
                              {notification.notification ? 
                                  <Text fontSize='sm'>{notification.notification} </Text>
                              :
                              <>
                               {notification.user &&
                                <>
                                {notification.user.displayName && 
                                    <Text fontSize='sm'>{notification.user.displayName}&nbsp;
                                      <span style={{fontWeight:'bold'}}>Mentioned You</span>
                                    </Text>
                                  }
                                  </>
                               }
                              </>
                              }
                              <Box 
                                  color='gray.500'
                                  letterSpacing='normal'
                                  fontSize={{
                                    'base':'10px',
                                    'lg': '12px'
                                  }}
                                  >
                                    {notification.timestamp ? moment(notification.timestamp.toDate()).calendar() : null }
                              </Box>
                            
                              <Text fontSize='xs' isTruncated as='em'>{notification.message}</Text>
                            </Stack>
                        </MenuItem >
                        <Divider/>
                        </>
                        )
                  })} 
                  </>
                  </Menu>
                  : <Text>You have no read notifications</Text>}
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      </>
    )
}