import {
    Flex,
    Text,
    Icon,
    Menu,
    MenuButton,
    useColorModeValue,
    Stack,
    Divider
} from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

export default function DrawerItem({ icon, rightIcon, name, to, navSize, isExternal, isChild, isLastChild, isFirstChild, onClose }) {
    const bgColor = useColorModeValue('#e8e8e8', '#171923')
    const location = useLocation()
    const isActive = location.pathname === to
    const { setupQueueCount } = useAuth()
    return (
        <Link to={isExternal ? {pathname: to} : to}
            onClick={onClose}
            target={isExternal && "_blank"}
        >
            <Flex
                pr={'3%'}
                pt={navSize == 'small' ?  '22%' : isChild ? '6%' : '8%'}
                pb={navSize == 'small' ?  '22%' : isChild ? '6%' : '8%'}
                pl={isChild ? 23 : 3}
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                style={{alignItems: rightIcon? 'center' : null}} 
                borderRight={isActive ? '5px solid lightblue' : ''}
                backgroundColor={isActive ? `${bgColor}` : ''}
            >
                <Menu placement="right">
               
                        <MenuButton w="100%">
                            <Flex position='relative'>
                            {isChild && 
                                     <Stack direction='row' >
                                        {isLastChild ? 
                                            <Divider style={{height: '50%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        :
                                        isFirstChild ?
                                            <Divider style={{height: '450%', borderColor: 'gray', margin: 0, marginTop: '-15px'}}orientation='vertical' />
                                        :
                                            <Divider style={{height: '295%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        }
                                        <Divider style={{width: '10px', borderColor: 'gray', margin: 0, padding: 0, justifySelf: 'flex-start', alignSelf: 'center'}} orientation='horizontal' />                       
                                 </Stack>
                                }               
    
                                {icon ? <Icon as={icon} fontSize="xl" /> : null}
                               
                                <Text ml={rightIcon ? 0 : 2} display={navSize == "small" ? "none" : "flex"}>{name}</Text>
                                {name == 'Setup Queue' &&  setupQueueCount > 0 ? <span className='drawer-notification'>{setupQueueCount}</span> : null}
                                {rightIcon ? <Icon ml={5} as={rightIcon} fontSize="xl" /> : null}
                            </Flex>
                        </MenuButton>
            
                </Menu>
            </Flex>
            </Link>

    )
}