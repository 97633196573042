import React from "react";
import { Tag, TagLabel, TagRightIcon } from "@chakra-ui/react";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";

interface BooleanTagProps {
  value: string | boolean;
  label: string;
  hideTrue?: boolean;
  hideFalse?: boolean;
  mb?: string;
  mt?: string;
}

const BooleanTag: React.FC<BooleanTagProps> = ({
  value,
  label,
  hideTrue,
  hideFalse,
  mb,
  mt,
}) => {

  const valueLowerCase: string = typeof value === 'string' && value.toLowerCase()
  const isYes: boolean = valueLowerCase === "yes" || valueLowerCase === "true" || value === true;
  const colorScheme: string = isYes ? "green" : "red";
  const shouldHide: boolean = (isYes && !!hideTrue) || (!isYes && !!hideFalse);

  if (shouldHide) return null;
  if (!label) return null;
  return (
    <Tag 
      size="md" 
      colorScheme={colorScheme} 
      mb={mb || "1"} 
      mt={mt || "1"} 
      data-testid="boolean-tag"
      >
      <TagLabel>{label}</TagLabel>
      <TagRightIcon as={isYes ? FiCheckCircle : FiXCircle} />
    </Tag>
  );
};

export default BooleanTag;
