import { Avatar, Box, Flex, Text, Image, useColorModeValue } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

export default function TopPerformers_GoogleReviews( { data } ){

    return(
        <Box 
            p={2} 
            w='100%'
            boxShadow='md'
            rounded={'xl'}
            mb={5}
            >
                <Flex flexDir={{base: 'column', md: "row"}} justifyContent='space-evenly' align={'center'} p={3}>
                    <Box
                    bg={useColorModeValue('white', 'gray.800')}
                    overflow={'hidden'}>
                    <Image
                    h={'95px'}
                    w={'full'}
                    src={
                        require('../../assets/img/4-google.png')                }
                    objectFit={'contain'}
                    />
                    </Box>
                    {data['LO'] && data['LO']['User'] && data['LO']['User']['label'] &&
                        <Flex align={'center'} p={2}>
                            <Avatar size={'xl'} src={data['LO']['User']['photoURL']}></Avatar>
                                <Flex p={5} flexDir={'column'} justify={'flex-start'}>
                                    <Text fontSize={'lg'}>{data['LO']['User']['label']}</Text>
                                    <Text fontSize='xs' color='gray.500'>Loan Officer</Text>
                                    <Text fontSize={'sm'}>{data['LO']['Amount']} Reviews&nbsp;
                                        {Array(5)
                                            .fill('')
                                            .map((_, i) => (
                                            <StarIcon
                                                key={i}
                                                color={'yellow.300'}
                                            />
                                            ))}
                                    </Text>
                                </Flex>
                            
                        </Flex>
                    }

                    {data['CSC'] && data['CSC']['User'] && data['CSC']['User']['label'] &&
                        <Flex align={'center'}  p={2}>
                            <Avatar size={'xl'} src={data['CSC']['User']['photoURL']}></Avatar>
                            <Flex p={5} flexDir={'column'} justify={'flex-start'}>
                                <Text fontSize={'lg'}>{data['CSC']['User']['label']}</Text>
                                <Text fontSize='xs' color='gray.500'>Client Success Coordinator</Text>
                                <Text fontSize={'sm'}>{data['CSC']['Amount']} Reviews&nbsp;
                                    {Array(5)
                                        .fill('')
                                        .map((_, i) => (
                                        <StarIcon
                                            key={i}
                                            color={'yellow.300'}
                                        />
                                        ))}
                                </Text>
                            </Flex>
                        </Flex>
                    }
                </Flex>
        </Box>
    )
}