import { Avatar, Flex, Tag, TagLeftIcon, AvatarGroup, AvatarBadge, Tooltip } from "@chakra-ui/react"
import moment from "moment"
import { FiCalendar } from "react-icons/fi"
import TagButton from "./TagButton"


export default function LoanReportSummary( {startDate, endDate, dateFilter, LO, LOA, LC, queryStats, statusFilter, setStatusFilter, wasParkedFilter, setWasParkedFilter, handleSetWasParkedFilter } ){
    return(
        <>
            <Flex mb={3} flexDir={'row'}>
                <AvatarGroup size='lg'>
                    {LO && LO.length > 0 &&
                        <>
                        {LO.map((loanOfficer) => {
                            return(
                                <Tooltip placement='top' label={loanOfficer.label}>
                                    <Avatar name={loanOfficer.label} src={loanOfficer.photoURL} >
                                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='blue.500'>LO</AvatarBadge>
                                    </Avatar>
                                </Tooltip> 
                            )
                        })}
                        </>
                        
                    }
                    {LOA && LOA.length > 0 &&
                        <>
                        {LOA.map((loanOfficerAssistant) => {
                            return(
                                <Tooltip placement='top' label={loanOfficerAssistant.label}>
                                    <Avatar name={loanOfficerAssistant.label} src={loanOfficerAssistant.photoURL} >
                                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='orange.500'>LOA</AvatarBadge>
                                    </Avatar>
                                </Tooltip> 
                            )
                        })}
                        </>
                        
                    }
                    {LC && LC.length > 0 &&
                        <>
                        {LC.map((lenderConcierge) => {
                            return(
                                <Tooltip placement='top' label={lenderConcierge.label}>
                                    <Avatar name={lenderConcierge.label} src={lenderConcierge.photoURL}>
                                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='green.500'>LC</AvatarBadge>
                                    </Avatar>
                                </Tooltip> 
                            )
                        })
                            
                        }
                        </>
                    }
                    {LO && LOA && LC && LO.length == 0 && LOA.length == 0 && LC.length == 0 &&
                        <Tooltip placement='top' label={'All Loans'}>
                            <Avatar textAlign={'center'} background={'none'} size={'lg'}   src={require('../../assets/img/fhl-logo.png')}></Avatar>
                        </Tooltip> 
                    }
                </AvatarGroup>
                <Tag variant={'ghost'} size={'lg'}>
                    <TagLeftIcon as={FiCalendar}/>
                    {moment(startDate).format('l')} - {moment(endDate).format('l')}
                </Tag>
                <Tag 
                    colorScheme={dateFilter == 'Date Added' ? 'blue' : 'green'} 
                    variant={'ghost'} 
                    size={'sm'} 
                    textTransform={'uppercase'}
                    fontStyle='italic'
                >
                    BY {dateFilter}
                </Tag> 
            </Flex>
            <Flex mb={2} flexDir='row' maxW={'90vw'} flexWrap={'wrap'} direction="row" spacing={2}>
              
                {queryStats.noLoans > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='facebook'
                        thisStatusFilter='All'
                        value={queryStats.noLoans}
                        label='loans found'
                    />
                }
                {queryStats.noNew > 0 && 
                   <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='red'
                        thisStatusFilter='New'
                        value={queryStats.noNew}
                        label='new'
                    />
                }
                {queryStats.noInProgress > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='orange'
                        thisStatusFilter='In Progress'
                        value={queryStats.noInProgress}
                        label='in progress'
                    />
                }
                {queryStats.noTBD > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='blue'
                        thisStatusFilter='TBD'
                        value={queryStats.noTBD}
                        label='tbd'
                    />
                }
                {queryStats.noCurrentlyParked > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='yellow'
                        thisStatusFilter='Parked'
                        value={queryStats.noCurrentlyParked}
                        label='parked'
                    />
                }
                {queryStats.noParked > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='yellow'
                        thisStatusFilter='Was Parked'
                        value={queryStats.noParked}
                        label={queryStats.noParked == 1 ? 'was parked' : 'were parked'} 
                    />
                }
                {queryStats.noNotSubmitted > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='purple'
                        thisStatusFilter='Not Submitted'
                        value={queryStats.noNotSubmitted}
                        label={'not submitted'} 
                    />
                }
                {queryStats.noCompleted > 0 && 
                    <TagButton
                       statusFilter={statusFilter}
                       setStatusFilter={setStatusFilter}
                       colorScheme='green'
                       thisStatusFilter='Completed'
                       value={queryStats.noCompleted}
                       label={'completed'} 
                   />
                }
                {queryStats.noNotSubmittedThenCompleted > 0 && 
                    <TagButton
                      statusFilter={statusFilter}
                      setStatusFilter={setStatusFilter}
                      colorScheme='twitter'
                      thisStatusFilter='NS Then Completed'
                      value={queryStats.noNotSubmittedThenCompleted}
                      label={'not submitted then completed'} 
                  />
                }
                {queryStats.noNeedsReview > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='orange'
                        thisStatusFilter='Needs Review'
                        value={queryStats.noNeedsReview}
                        label={queryStats.noNeedsReview == 1 ? 'needs review' : 'need review'}
                    />
                }
                {queryStats.noCoachingCompleted > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='green'
                        thisStatusFilter='Coaching Completed'
                        value={queryStats.noCoachingCompleted}
                        label={'coaching completed'}
                    />
                }
            </Flex>
        </>
    )
}