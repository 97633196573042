import { useEffect, useState, useReducer } from 'react'
import { Text, Flex, Center, TagRightIcon, Heading, Button, Tag, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody, DrawerFooter } from '@chakra-ui/react'
import {
    useDisclosure,
    Image
  } from '@chakra-ui/react'
import { FiCheckCircle, FiChevronDown, FiCircle, FiRefreshCcw } from 'react-icons/fi'
import Timestamp from './Timestamp'
import StackRankLO from './StackRankLO'
import { Reorder } from "framer-motion";
import { Progress } from '@chakra-ui/react'

export default function StackRankLOs_List( { limit, initTimeline, initProperty, timestamp, allData, dataUpdated, initData, setParentProperty, setParentTimeline, disconnected, subscribeToLORankings } ){

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [timeline, setTimeline] = useState(initTimeline ? initTimeline : 'YTD')
    const [property, setProperty] = useState(initProperty ? initProperty : 'Funded')
    const [sorting, setSorting] = useState(true)
    const [sortedData, setSortedData] = useState(initData ? initData : null)
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [currentTime, setCurrentTime] = useState(new Date().getTime())

    const FIVE_MINUTE_MS = 300000;
    useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTime(new Date().getTime())
    }, FIVE_MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
        
     useEffect(() => {
        setSortedData(null)
        if(timeline == 'YTD'){
            if(property == 'Funded'){
                sortArrayByProperty('noFundedYTD')
            }
            else sortArrayByProperty('dollarVolumeYTD')
        }
        else if(timeline == 'MTD'){
            if(property == 'Funded'){
                sortArrayByProperty('noFundedMTD')
            }
            else sortArrayByProperty('dollarVolumeMTD')
        }
        else {
            if(property == 'Funded'){
                sortArrayByProperty('noFundedLifetime')
            }
            else sortArrayByProperty('dollarVolumeLifetime')
        }
     }, [timeline, property, dataUpdated])


     function sortArrayByProperty (prop) {
        let newArray = []
        newArray = allData.sort((a,b) => b[`${prop}`] - a[`${prop}`])
        setSortedData(newArray)
        forceUpdate()
    };

    useEffect(() => {
        return () => {
            setParentProperty && setParentProperty(property)
            setParentTimeline && setParentTimeline(timeline)
        }
    }, []) 
        
    

      return (
            <>   
       
            <Flex p={3} flexDir={'column'}>
                <Center mb={2}>
                    <Image
                    h={'80px'}
                    //backgroundImage={require("../assets/img/FHL-2023.png")}
                    backgroundSize='contain'
                    backgroundPosition={'center'}
                    backgroundRepeat={'no-repeat'}
                    src={require('../assets/img/logo-color.png')}

                    />
                </Center>
                {sortedData && property && timeline &&
                <>
                <Center>
                <Heading mb={5} as='em' size='md'>
                    <Flex flexDir={'column'}>
                        <Center>
                            <Flex mb={3} alignItems='center' flexDir='column'>
                                <Text as='em'>Funded Loans</Text>
                                {timestamp && 
                                    <>
                                    <Timestamp annotation='Updated' timestamp={timestamp}/>
                                        {!disconnected && currentTime && currentTime < (timestamp.seconds*1000 + 600000)&&
                                        <>
                                        <Progress mt='2px' width='100%' height={'2px'} colorScheme={'red'} size='xs' isIndeterminate />
                                        </>
                                        }
                                        {disconnected &&
                                        <>
                                            <Text color={'gray.500'} textAlign={'center'} fontSize={'xs'}>Unsubscribed due to inactivity </Text>
                                            <Button onClick={subscribeToLORankings} variant={'ghost'} size='xs' leftIcon={<FiRefreshCcw />}> Reconnect</Button>
                                        </>
                                        }
                                    </>
                                }
                            </Flex>
                        </Center>
                        {allData &&
                        <Button p={0} size='xs' variant={'ghost'} onClick={() => setTimeline('Lifetime')}>
                            <Tag 
                                borderRadius={'3xl'} 
                                fontWeight={timeline == 'Lifetime' ? 'bold' : 'normal'} 
                                variant={timeline == 'Lifetime' ? 'solid' : 'subtle'}
                                onClick={() => setTimeline('Lifetime')}
                                colorScheme='purple'>
                                    All-Time
                                {timeline == 'Lifetime' ? <TagRightIcon as={FiCheckCircle}/>    
                                :    
                                <TagRightIcon as={FiCircle}/>
                                }
                            </Tag>
                        </Button>}
                        <Flex justify={'space-evenly'} flexDir={'row'}>
                            <Button p={0} variant={'ghost'} onClick={() => setTimeline('YTD')}>
                                <Tag 
                                    borderRadius={'3xl'} 
                                    m={2}
                                    fontWeight={timeline == 'YTD' ? 'bold' : 'normal'} 
                                    variant={timeline == 'YTD' ? 'solid' : 'subtle'}
                                    colorScheme='linkedin'>
                                        YTD
                                    {timeline == 'YTD' ? <TagRightIcon as={FiCheckCircle}/>
                                    :    
                                    <TagRightIcon as={FiCircle}/>
                                    }
                                </Tag>
                            </Button>
                            <Button p={0} variant={'ghost'} onClick={() => setTimeline('MTD')}>
                                <Tag 
                                    borderRadius={'3xl'} 
                                    m={2}
                                    fontWeight={timeline == 'MTD' ? 'bold' : 'normal'} 
                                    variant={timeline == 'MTD' ? 'solid' : 'subtle'}
                                    onClick={() => setTimeline('MTD')}
                                    colorScheme='green'>
                                        MTD
                                    {timeline == 'MTD' ? <TagRightIcon as={FiCheckCircle}/>    
                                    :    
                                    <TagRightIcon as={FiCircle}/>
                                    }
                                </Tag>
                            </Button>
                     
                        </Flex>
                    </Flex>
                </Heading>
                </Center>
                {allData &&
                <Flex mb={2} justify={'space-between'} flexDir='row'>
                    <Button p={0} variant={'ghost'} size='xs' onClick={() => setProperty('dollarVolume')}>
                        <Tag 
                            borderRadius={'3xl'} 
                            m={2}
                            fontSize='xs'
                            fontWeight={property == 'dollarVolume' ? 'bold' : 'normal'} 
                            variant={property == 'dollarVolume' ? 'solid' : 'subtle'}
                            >
                                Dollar Volume
                            {property == 'dollarVolume' && <TagRightIcon as={FiChevronDown}/> }
                        </Tag>
                    </Button>
                    <Button p={0} variant={'ghost'} size='xs' onClick={() => setProperty('Funded')}>
                        <Tag 
                            borderRadius={'3xl'} 
                            m={2}
                            fontSize='xs'
                            fontWeight={property == 'Funded' ? 'bold' : 'normal'} 
                            variant={property == 'Funded' ? 'solid' : 'subtle'}
                            >
                                Funded
                            {property == 'Funded' && <TagRightIcon as={FiChevronDown}/> }
                        </Tag>
                    </Button>
                
                </Flex>}
                    <Flex p={2}>
                    <Reorder.Group as='div' draggable={false} dragControls={false} dragListener={false} axis='y' values={allData}>
                        {sortedData.map((row, i) => {
                            if(!row.isLO){
                                limit && limit++
                                return
                            } 
                            else if(!limit || i < limit){
                                return(
                                    <Reorder.Item 
                                        as='div' 
                                        key={row.email} 
                                        dragListener={false}
                                        draggable={false} 
                                        value={row}>
                                        <StackRankLO row={row} property={property} timeline={timeline} />
                                    </Reorder.Item>
                                )
                            }
                        })}   
                    </Reorder.Group>
                    </Flex>
                </>
                }
                {limit && 
                    <>
                     <Button mt={3} onClick={onOpen}>View All</Button>
                     <Drawer placement='right' size={'sm'} isOpen={isOpen} onClose={onClose}>
                       <DrawerOverlay />
                       <DrawerContent>
                         <DrawerHeader></DrawerHeader>
                         <DrawerCloseButton />
                         <DrawerBody>
                         <>   
                         <Center>
                                <Flex p={3} flexDir={'column'}>
                                    <Center>
                                        <Image
                                        src={require('../assets/img/logo-color.png')}
                                        h={'80px'}
                                        mb={2}
                                        />
                                    </Center>
                                    {sortedData && property && timeline &&
                                    <>
                                    <Center>
                                    <Heading mb={5} as='em' size='md'>
                                        <Flex flexDir={'column'}>
                                            <Center>
                                                <Flex mb={3} alignItems='center' flexDir='column'>
                                                    <Text as='em'>Funded Loans</Text>
                                                    {timestamp && 
                                                        <>
                                                        <Timestamp annotation='Updated' timestamp={timestamp}/>
                                                            {currentTime && currentTime < (timestamp.seconds*1000 + 600000)&&
                                                            <>
                                                            <Progress mt='2px' width='100%' height={'2px'} colorScheme={'red'} size='xs' isIndeterminate />
                                                            </>
                                                            }
                                                        </>
                                                    }
                                                </Flex>
                                            </Center>
                                            {allData &&
                                            <Button p={0} size='xs' variant={'ghost'} onClick={() => setTimeline('Lifetime')}>
                                                <Tag 
                                                    borderRadius={'3xl'} 
                                                    fontWeight={timeline == 'Lifetime' ? 'bold' : 'normal'} 
                                                    variant={timeline == 'Lifetime' ? 'solid' : 'subtle'}
                                                    onClick={() => setTimeline('Lifetime')}
                                                    colorScheme='purple'>
                                                        All-Time
                                                    {timeline == 'Lifetime' ? <TagRightIcon as={FiCheckCircle}/>    
                                                    :    
                                                    <TagRightIcon as={FiCircle}/>
                                                    }
                                                </Tag>
                                            </Button>}
                                            <Flex justify={'space-evenly'} flexDir={'row'}>
                                                <Button p={0} variant={'ghost'} onClick={() => setTimeline('YTD')}>
                                                    <Tag 
                                                        borderRadius={'3xl'} 
                                                        m={2}
                                                        fontWeight={timeline == 'YTD' ? 'bold' : 'normal'} 
                                                        variant={timeline == 'YTD' ? 'solid' : 'subtle'}
                                                        colorScheme='linkedin'>
                                                            YTD
                                                        {timeline == 'YTD' ? <TagRightIcon as={FiCheckCircle}/>
                                                        :    
                                                        <TagRightIcon as={FiCircle}/>
                                                        }
                                                    </Tag>
                                                </Button>
                                                <Button p={0} variant={'ghost'} onClick={() => setTimeline('MTD')}>
                                                    <Tag 
                                                        borderRadius={'3xl'} 
                                                        m={2}
                                                        fontWeight={timeline == 'MTD' ? 'bold' : 'normal'} 
                                                        variant={timeline == 'MTD' ? 'solid' : 'subtle'}
                                                        onClick={() => setTimeline('MTD')}
                                                        colorScheme='green'>
                                                            MTD
                                                        {timeline == 'MTD' ? <TagRightIcon as={FiCheckCircle}/>    
                                                        :    
                                                        <TagRightIcon as={FiCircle}/>
                                                        }
                                                    </Tag>
                                                </Button>
                                            
                                            </Flex>
                                        </Flex>
                                    </Heading>
                                    </Center>
                                    {allData &&
                                    <Flex mb={2} justify={'space-between'} flexDir='row'>
                                        <Button p={0} variant={'ghost'} size='xs' onClick={() => setProperty('dollarVolume')}>
                                            <Tag 
                                                borderRadius={'3xl'} 
                                                m={2}
                                                fontSize='xs'
                                                fontWeight={property == 'dollarVolume' ? 'bold' : 'normal'} 
                                                variant={property == 'dollarVolume' ? 'solid' : 'subtle'}
                                                >
                                                    Dollar Volume
                                                {property == 'dollarVolume' && <TagRightIcon as={FiChevronDown}/> }
                                            </Tag>
                                        </Button>
                                        <Button p={0} variant={'ghost'} size='xs' onClick={() => setProperty('Funded')}>
                                            <Tag 
                                                borderRadius={'3xl'} 
                                                m={2}
                                                fontSize='xs'
                                                fontWeight={property == 'Funded' ? 'bold' : 'normal'} 
                                                variant={property == 'Funded' ? 'solid' : 'subtle'}
                                                >
                                                    Funded
                                                {property == 'Funded' && <TagRightIcon as={FiChevronDown}/> }
                                            </Tag>
                                        </Button>
                                    
                                    </Flex>}
                                        <Flex p={2} justify='space-between'>
                                        <Reorder.Group as='div' draggable={false} dragControls={false} dragListener={false} axis='y' values={allData}>
                                            {sortedData.map((row, i) => {
                                                if(!row.isLO){
                                                    return
                                                } 
                                                else{
                                                    return(
                                                        <Reorder.Item 
                                                            as='div' 
                                                            key={row.email} 
                                                            dragListener={false}
                                                            draggable={false} 
                                                            value={row}>
                                                            <StackRankLO row={row} property={property} timeline={timeline} />
                                                        </Reorder.Item>
                                                    )
                                                }
                                            })}   
                                        </Reorder.Group>
                                        </Flex>
                                    </>
                                    }
                                    
                                </Flex>
                                </Center>
                                </>
                         </DrawerBody>
               
                         <DrawerFooter>
                            <Center>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                           </Center>
                         </DrawerFooter>
                       </DrawerContent>
                     </Drawer>
                     </>
                }
            </Flex>

            </>

      )
}
 