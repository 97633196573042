import { Table, Thead, Tbody, Tr, Th, Td, Flex, useColorModeValue, Heading, Box, Text, Tfoot, IconButton, Select } from '@chakra-ui/react'
import { useTable, useSortBy, usePagination } from 'react-table'
import React from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'

// TODO: Documentation

export default function ReactTable ( { cols, data, title, colorScheme, maxWidth, width, dontUsePagination } ){
    const backgroundHeaderColor = useColorModeValue(`${colorScheme}.100`, `${colorScheme}.800`)
    const columns = React.useMemo(() => cols)

    const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        footerGroups, 
        rows, 
        prepareRow, 
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize, 
        state: { pageIndex, pageSize = 20 }
      } = useTable({ columns, data }, useSortBy, usePagination )

    return(
      <>
        {title && <><Heading m='2' size='md' fontWeight={'semibold'} textTransform='uppercase'>{title}</Heading></>}
        {data && cols && data.length === 0 && cols.length === 0 ? 
          <Text m='2' fontSize='sm'  textTransform={'uppercase'} color='gray'>No data found</Text>
          :
          <>
          <Table {...getTableProps()} minW={!maxWidth && '950px'} width={width} maxWidth={maxWidth} mt='1%' overflow={'scroll'}  boxShadow='md' mb='5'>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr
                {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <Th
                      fontWeight='bold'
                      backgroundColor={backgroundHeaderColor}
                      userSelect="none"
                      width={column.width}
                      paddingLeft={column.paddingLeft}
                      paddingRight={column.paddingRight}
                      boxSizing={column.boxSizing}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Flex alignItems="center">
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronDownIcon ml={1} w={4} h={4} />
                          ) : (
                            <ChevronUpIcon ml={1} w={4} h={4} />
                          )
                        ) : (
                          ""
                        )}
                        <Box ml={2} as="span">
                          {column?.canFilter ? column.render("Filter") : null}
                        </Box>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Td {...cell.getCellProps({
                            style: {
                              width: cell.column.width,
                              minWidth: cell.column.minWidth,
                              fontSize: '14px',
                              paddingTop: 3,
                              paddingBottom: 3
                            },
                          })}
                        >
                          {cell.render("Cell")}       
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              {footerGroups.map(group => (
                <Tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <Td {...column.getFooterProps({
                      style: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        paddingTop: 5,
                        paddingBottom: 5
                      },
                    })} 
                    >{column.render('footer')}</Td>
                  ))}
                </Tr>
              ))}
            </Tfoot>
          </Table>
          {!dontUsePagination && (

             <Flex flexDirection='row' justify='center'>
             <Flex>
               <IconButton
                 mr={2}
                 onClick={() => gotoPage(0)}
                 isDisabled={!canPreviousPage}
                 icon={<FiChevronsLeft fontSize={'2xl'} />}
               />
               <IconButton
                 mr={2}
                 isDisabled={!canPreviousPage}
                 onClick={() => previousPage()}
                 icon={<FiChevronLeft fontSize={'2xl'}/>}
               />
             </Flex>
             <Flex  justifyContent="center" alignItems="center">
               <Text w='150px' mr={4} flexWrap={'nowrap'}>
                 Page{' '}
                 <strong>
                   {pageIndex + 1} of {pageOptions.length}
                 </strong>{' '}
               </Text>
                 <Select
                   value={pageSize}
                   onChange={(e) => {
                     setPageSize(Number(e.target.value))
                   }}
                 >
                   {[2, 5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
                     <option key={pageSize} value={pageSize}>
                       Show {pageSize}
                     </option>
                   ))}
                 </Select>
                 <Text 
                    ml='2'
                    w='65px' 
                    flexWrap={'nowrap'}>
                    of {data.length}
                  </Text>
             </Flex>
             <Flex flexDirection="row">
               <IconButton
                 ml={2}
                 isDisabled={!canNextPage}
                 onClick={() => nextPage()}
                 icon={<FiChevronRight fontSize={'2xl'} />}
               />
               <IconButton
                 ml={2}
                 onClick={() => gotoPage(pageCount ? pageCount - 1 : 1)}
                 isDisabled={!canNextPage}
                 icon={<FiChevronsRight fontSize={'2xl'} />}
               />
             </Flex>
           </Flex>
            )}
           </>
          }
      </>
    )
}