

import React, { useState, useEffect, useCallback, useRef } from "react";
import {  doc, updateDoc, serverTimestamp, setDoc, collection, getDocs, where, query, increment, onSnapshot, arrayRemove, arrayUnion, orderBy } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db, firebase } from "../../utils/init-firebase"
import { Formik } from "formik";
import { FiCircle, FiUpload, FiXCircle } from "react-icons/fi";
import {
  SubmitButton,
  InputControl,
  CheckboxSingleControl
} from "formik-chakra-ui";
import {
    Box,
    useToast,
    Flex,
    IconButton,
    Button,
    FormControl,
    FormLabel,
    InputGroup,
    Icon,
    Input,
    Text,
    Center,
    Tag,
    TagRightIcon,
    Image,
    Grid,
    GridItem,
    Heading} from "@chakra-ui/react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { FiCheckCircle } from "react-icons/fi";
import ManageTags from "./ManageTags";
import ManageCategories from "./ManageCategories";

export default function AddSwagItem({ action, values, docID, quantity, setShowAddSwagItem, itemTags, itemCats }) {
    const { currentUser, NMLS, SuperAdmin, MarketingAdmin } = useAuth()
    const toast = useToast()
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const firstField = React.useRef()
	const inputRef = useRef();
    const formRef = useRef()
    const [quantityToAdd, setQuantityToAdd] = useState('')

    const [tags, setTags] = useState()
    const [cats, setCats] = useState()

    const createRequest = useRef()

    const [files, setFiles] = useState([])
    const [uploadedFile, setUploadedFile] = useState(values ? 
        {
            name: values.photoName ? values.photoName : '',
            url: values.photoURL

        }
        :
        {
            name: '',
            url: ''
        }
    )

    const [uploadProg, setUploadProg] = useState()
    const [uploading, setUploading] = useState(false)

    const [eventDate, setEventDate] = useState('')

    const {
		handleSubmit,
		register,
		setError,
		control,
		formState: { errors, isSubmitting },
	} = useForm()

    const validationSchema = Yup.object({
        Cost: Yup.number().typeError('Must be a number'),
        Quantity: Yup.number().typeError('Must be a number').required('Required'),
        Name: Yup.string().required('Required'),
        Hidden: Yup.boolean()
      });

    let initialValues = []
    if(values == null){
        initialValues = {
            'photoURL': '',
            'photoName': '',
            'Name': '',
            'Category': '',
            'Quantity': '',
            'Cost': '',
        };
    }
    else {
        initialValues = values
    }
    const uploadFiles = useCallback(()=>{
        if(files.length > 0)
        {
            setUploading(true)                    
            const theseFiles = files;
            setFiles([])
            var promises = []
            for (let i = 0; i < theseFiles.length; i++) {         
                    const storageRef = firebase.storage().ref();
                    const file = theseFiles[i];
 
                    promises.push(storageRef
                    .child(`Marketing Swag Pictures/${file.name}`)
                    .put(file).then( async (snapshot) => {
                       snapshot.ref.getDownloadURL().then( async (url) => {
                            setUploadedFile({url: url, name: file.name})
                                    return
                                }
                            )
                        })
                    )
            }
            Promise.all(promises)
            .then( async () => {
                    setUploading(false)
                }
            )
            .catch(err => console.log(err.code));
            
        }
    },[files])

    function removeAttachment(i){
        uploadedFile.splice(i, 1)
        setFiles([])

    }
    const onFileChange = (e) => {
        setFiles(e.target.files)
    }

    useEffect(()=> {
        uploadFiles();
      }, [files])

    const onCreate = async (values) => {
        try{
            const q = query(collection(db, "Marketing Swag"), where("Name", "==", values.Name));

            const querySnapshot = await getDocs(q);
            let itemExists = false
            querySnapshot.forEach((doc) => {
                if(doc.exists()){
                    itemExists = true
                    return
                }
            });

            if(itemExists){
                toast({
                    description: 'An item with that name already exists',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                    position: 'top'
                })
            }
            else{
                const newDocRef = doc(collection(db, 'Marketing Swag'))
                await setDoc(newDocRef, {
                    Name: values.Name,
                    Quantity: parseInt(values.Quantity),
                    Cost: parseFloat(values.Cost),
                    Hidden: values.Hidden ? values.Hidden : false,
                    photoURL: uploadedFile?.url,
                    photoName: uploadedFile?.name ? uploadedFile.name : null,
                    timestamp: serverTimestamp(),
                    user: {
                        displayName: currentUser.displayName,
                        email: currentUser.email,
                        photoURL: currentUser.photoURL,
                        uid: currentUser.uid
                    },
                    id: newDocRef.id
                })
    
                toast({
                    title: 'Item Created',
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                    position: 'top'
                })
            }
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    };

    const onEdit = async (values) => {
        try{
            await updateDoc(doc(db, 'Marketing Swag', values.id), {
                Name: values.Name,
                Cost: parseFloat(values.Cost),
                Quantity: parseInt(values.Quantity),
                photoURL: uploadedFile?.url,
                Hidden: values.Hidden ? values.Hidden : false,
                timestamp: serverTimestamp(),
                user: {
                    displayName: currentUser.displayName,
                    email: currentUser.email,
                    photoURL: currentUser.photoURL,
                    uid: currentUser.uid
                },
                'Last Edited By': {
                    displayName: currentUser.displayName,
                    email: currentUser.email,
                    photoURL: currentUser.photoURL,
                    uid: currentUser.uid
                }
                })
    
                toast({
                    title: 'Item Updated!',
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                    position: 'top'
                })
            
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    };

    const addToStock = async () => {
        const itemRef = doc(db, 'Marketing Swag', docID)
        await updateDoc(itemRef, {
            Quantity: increment(parseInt(quantityToAdd))
        })

        const transactionRef = doc(collection(db, 'Marketing Swag', docID, 'Marketing Swag Transactions'))
        await setDoc(transactionRef, {
            'Fulfilled By': {
                Email: currentUser.email,
                Name: currentUser.displayName,
                photoURL: currentUser.photoURL,
                uid: currentUser.uid
            },
            isAddition: true,
            timestamp: serverTimestamp(),
            taskID: null,
            Item: {
                Cost: values.Cost,
                Amount: parseInt(quantityToAdd),
                id: docID,
                Name: values.Name,
                photoURL: uploadedFile?.url,
                stock: parseInt(values?.Quantity) + parseInt(quantityToAdd)
            },
            id: transactionRef.id
        })

        toast({
            title: 'Stock Updated!',
            description: `${quantityToAdd} added to stock`,
            status: 'success',
            duration: 5000,
            position: 'top',
            isClosable: true
        })
        if(formRef.current.values.Quantity){
            formRef.current.values.Quantity += parseInt(quantityToAdd)
        }
        setQuantityToAdd('')
    }

    const setTag = async (id) => {
        if(itemTags && itemTags.includes(id)){
            await updateDoc(doc(db, 'Marketing Swag', docID), {
                tags: arrayRemove(id)
            })
        }
        else{
            await updateDoc(doc(db, 'Marketing Swag', docID), {
                tags: arrayUnion(id)
            })
        }
    }

    const setCat = async (id) => {
        if(itemCats && itemCats.includes(id)){
            await updateDoc(doc(db, 'Marketing Swag', docID), {
                categories: arrayRemove(id)
            })
        }
        else{
            await updateDoc(doc(db, 'Marketing Swag', docID), {
                categories: arrayUnion(id)
            })
        }
    }

    useEffect(() => {
        const q = query(collection(db, "Marketing Swag Tags"), orderBy('timestamp'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
              t.push(doc.data());
          });
          setTags(t)
        })

        const q2 = query(collection(db, "Marketing Swag Category"), orderBy('timestamp'));
        const unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
          const c = [];
          querySnapshot2.forEach((doc) => {
              c.push(doc.data());
          });
          setCats(c)
        })
          return () => {
            unsubscribe()
            unsubscribe2()
          }
    }, [])


  return (
      <>
        <Formik
            initialValues={initialValues}
            onSubmit={action == 'Add' ? onCreate : onEdit}
            innerRef={formRef}
            validationSchema={validationSchema}
            >
            {({ handleSubmit, values }) => (
                <Box
                borderWidth={{'base': 'none', 'md': "1px"}}
                rounded="lg"
                mb={5}
                w={{base: '80vw', lg: '55vw'}}
                shadow={{'base': 'none', 'md': "1px 1px 1px rgba(0,0,0,0.3)"}}
                p={{'base': '0', 'md': "5"}}
                as="form"
                onSubmit={handleSubmit}
                >
                    <Grid
                      templateColumns='repeat(6, 1fr)'
                      gap={4}>

                    <GridItem colSpan={action != 'Add' ? {base: 6, lg: 4} : 6}>
                    <InputControl 
                        name="Name"
                        label="Name"
                    >
                    </InputControl>
                    <Flex mt={2} flexDir='row' justify={'space-between'}>
                 
                         <InputControl 
                            name="Quantity"
                            label="Quantity"
                            w={'48%'}
                            
                        >
                        </InputControl>
                        
                        
                       
        
                        <InputControl 
                            name="Cost"
                            label="Cost ($)"
                            w={'48%'}

                        >
                        </InputControl>

                    </Flex>
                    <Flex mt='2' flexDir={'row'}>
                    <CheckboxSingleControl name="Hidden">
                        Hidden
                    </CheckboxSingleControl>
                    </Flex>
                    <Flex flexDir={'row'}>
                        <FormControl mt={5} mb={5}>
                            <FormLabel htmlFor="fileUpload">Image</FormLabel>
                            <InputGroup>
                                {!uploadedFile.url && 
                                    <IconButton
                                        onClick={() => inputRef.current.click()}
                                        >
                                        <Icon as={FiUpload} />
                                    </IconButton>
                                }
                                
                                <input type='file'
                                    onChange={onFileChange}
                                    name={'Upload Image'}
                                    ref={inputRef}
                                    style={{display: 'none'}} />
                                {uploadedFile.url &&
                                    <>                                
                                    <Image
                                        src={uploadedFile.url}
                                        maxH='150px'
                                    />
                                    <Flex ml='1' flexDir={'column'} justify='space-between'>
                                        <IconButton variant={'unstyled'} onClick={() => setUploadedFile({name: '', url: ''})}><Icon as={FiXCircle}></Icon></IconButton>
                                        <IconButton
                                            variant={'unstyled'}
                                            onClick={() => inputRef.current.click()}
                                            >
                                            <Icon as={FiUpload} />
                                        </IconButton>

                                    </Flex>
                                    </>

                                }

                            </InputGroup>
                        </FormControl>
                      
                      
                    </Flex>
                   
                    <Flex justify={'space-between'} flexDir='row'>
                        {action != 'Add' &&       
                            <Flex flexDir="column">
                                <Text>Quantity ({quantity})</Text>
                                <Flex mt={2} flexDir='row'>
                                    <Input value={quantityToAdd} onChange={(e) => setQuantityToAdd(e.target.value)}>
                                    
                                    </Input>
                                    <Button ml={2} pl={8} pr={8} disabled={!quantityToAdd} onClick={addToStock}>
                                        Add To Stock
                                    </Button>
                                </Flex>
                            </Flex>}
                        {setShowAddSwagItem && <Button mr={3} onClick={(e) => setShowAddSwagItem(false)}>Close</Button>}
                        <SubmitButton disabled={uploading} alignSelf={'flex-end'}>{action == 'Add' ? 'Add' : 'Save'}</SubmitButton>
                    </Flex>
                    
            
            </GridItem>
            {action != 'Add' &&
            <GridItem borderLeft={'1px solid lightgrey'} colSpan={{base: 6, lg: 2}}>
                <Flex p={2} flexDir={'column'}> 
                <Center mb={3}>
                    <Heading mr={2} size='md'>Categories</Heading>                    
                    <ManageCategories cats={cats} />
                </Center>
                    {cats && cats.map((cat) => {
                            return(
                                <Center>
                                        <Tag 
                                            mb='2'
                                            size={'md'}
                                            _hover={{cursor: 'pointer'}}
                                            onClick={() => setCat(cat.id)}
                                            variant={itemCats && itemCats.includes(cat.id) ? 'solid' : 'outline'}
                                            fontWeight= {itemCats && itemCats.includes(cat.id) ? 'bold' : 'light'}
                                            colorScheme={cat.color}
                                            >
                                                {cat.name}
                                            {itemCats && itemCats.includes(cat.id) ? <TagRightIcon as={FiCheckCircle}/> : <TagRightIcon as={FiCircle} />}
                                        </Tag>
                                </Center>
                            )
                    })}
                    

                <Center mb={3}>
                    <Heading mr={2} size='md'>Tags</Heading>                    
                    <ManageTags tags={tags} />
                </Center>
                    {tags && tags.map((tag) => {
                        return(
                            <Center>
                                    <Tag 
                                        mb='2'
                                        size={'md'}
                                        _hover={{cursor: 'pointer'}}
                                        onClick={() => setTag(tag.id)}
                                        variant={itemTags && itemTags.includes(tag.id) ? 'solid' : 'outline'}
                                        fontWeight= {itemTags && itemTags.includes(tag.id) ? 'bold' : 'light'}
                                        colorScheme={tag.color}
                                        >
                                            {tag.name}
                                        {itemTags && itemTags.includes(tag.id) ? <TagRightIcon as={FiCheckCircle}/> : <TagRightIcon as={FiCircle} />}
                                    </Tag>
                            </Center>
                        )
                    })}
                </Flex>  
            </GridItem>
              }
            </Grid>
        </Box>
            )}
            
        </Formik>

      </>
  );
}
