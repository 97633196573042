import { Button, Flex, FormControl, Input, Box, Heading, Stack, toast, useToast, Textarea } from "@chakra-ui/react"
import { updateDoc } from "firebase/firestore"
import { useState } from "react"
import CreatableOptions from "../Settings/CreatableOptions"

export default function MarketingTaskSettings( { settingsRef, initialSettings } ){
    const toast = useToast()
    const [minNoticeCustom, setMinNoticeCustom] = useState(initialSettings ? initialSettings['Task Settings']? initialSettings['Task Settings']['Minimum Notice Days'] ?  initialSettings['Task Settings']['Minimum Notice Days']['Custom'] ?  initialSettings['Task Settings']['Minimum Notice Days']['Custom'] : null : null : null : null)
    const [minNoticeSwag, setMinNoticeSwag] = useState(initialSettings ? initialSettings['Task Settings']? initialSettings['Task Settings']['Minimum Notice Days'] ?  initialSettings['Task Settings']['Minimum Notice Days']['Swag'] ?  initialSettings['Task Settings']['Minimum Notice Days']['Swag'] : null : null : null : null)
    const [minNoticeDesign, setMinNoticeDesign] = useState(initialSettings ? initialSettings['Task Settings']? initialSettings['Task Settings']['Minimum Notice Days'] ?  initialSettings['Task Settings']['Minimum Notice Days']['Design'] ?  initialSettings['Task Settings']['Minimum Notice Days']['Design'] : null : null : null : null)
    const [swagBannerText, setSwagBannerText] = useState(initialSettings ? initialSettings['Task Settings'] ? initialSettings['Task Settings']['Swag Banner Text'] ? initialSettings['Task Settings']['Swag Banner Text'] : '' : '' : '')
    const [maxSwag, setMaxSwag] = useState(initialSettings ? initialSettings['Task Settings'] ? initialSettings['Task Settings']['Default Monthly Swag Budget'] ? initialSettings['Task Settings']['Default Monthly Swag Budget'] : '' : '' : '')

    const updateMinNotice = async (field) => {
        try{
            await updateDoc(settingsRef, {
                'Task Settings': {
                    'Minimum Notice Days': {
                        Custom: minNoticeCustom,
                        Swag: minNoticeSwag,
                        Design: minNoticeDesign
                    },
                    'Swag Banner Text': swagBannerText,
                    'Default Monthly Swag Budget': maxSwag
                }
                
            })
            toast({
                title: 'Task Settings Updated!',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top'

              })  
        }
        catch(err)
        {
            toast({
                title: err,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top'
              })  
        }
        }
    return(
        <>
            <Box boxShadow={'md'} p={5} mt={5}>
                <Heading mb={5} size='sm'>Task Settings</Heading>
                <Flex justifyContent={'space-between'}>
                    <Box w='30%'>
                        <Stack spacing={3}>
                            <Heading as='em' size='xs'>Minimum Notice Required (Days)</Heading>
                            <FormControl label="Custom Request">
                                Custom <Input htmlSize={1} type='number' width='65px' value={minNoticeCustom} onChange={(e) => setMinNoticeCustom(e.target.value)}/>
                            </FormControl>
                            <FormControl label="Design Request">
                                Swag &nbsp;&nbsp;&nbsp;&nbsp;<Input type='number' htmlSize={2} width='65px' value={minNoticeSwag} onChange={(e) => setMinNoticeSwag(e.target.value)} />
                            </FormControl>         
                            <FormControl label="Swag Request">
                                Design &nbsp;&nbsp;<Input htmlSize={2} width='65px' type='number' value={minNoticeDesign} onChange={(e) => setMinNoticeDesign(e.target.value)} />
                            </FormControl>  
                            <Heading as='em' size='xs'>Default Swag Budget ($)</Heading>    
                            <FormControl label="Default  Swag Budget">
                                <Input htmlSize={2} width='100px' type='number' value={maxSwag} onChange={(e) => setMaxSwag(e.target.value)} />
                            </FormControl>    
                            <Button w={'100px'} colorScheme='blue' onClick={() => updateMinNotice('Task Settings.Minimum Notice Days')}>Update</Button>
                        </Stack>       
                    </Box>   
                    <Box w='30%'>

                        <CreatableOptions
                            docRef={settingsRef}
                            initialSettings={initialSettings?.['Marketing Spend Categories']}
                            settingName='Marketing Spend Categories'
                            showSettingNameAsHeading
                        />
                    </Box>   

                    <Box w='30%'>
                        <Stack spacing={3}>
                            <Heading as='em' size='xs'>Swag Banner Text</Heading>
                            <Textarea 
                                minHeight='150px'
                                value={swagBannerText} 
                                onChange={(e) => setSwagBannerText(e.target.value)}>    
                            </Textarea>
                           
                        </Stack>     
                    </Box>
                </Flex>
            </Box>
        </>
    )
}