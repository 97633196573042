import { Flex, Input, Button, IconButton } from "@chakra-ui/react"
import { useState} from "react";
import {  doc, serverTimestamp, setDoc, collection, updateDoc, deleteDoc  } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import ColorPicker from "../ColorPIcker";
import { FiTrash2 } from "react-icons/fi";

export default function AddEditCategory( { catID, catName, catColor, action, key } ){
    const [newCategoryName, setNewCategoryName] = useState(catName ? catName : '')
    const [newCategoryColor, setNewCategoryColor] = useState(catColor ? catColor : 'green')
    const thisCategoryRef = catID ? doc(db, 'Marketing Swag Category', catID) : null

    const addCategory = async () => {
        const newCategoryRef = doc(collection(db, 'Marketing Swag Category'))
        await setDoc(newCategoryRef, {
            timestamp: serverTimestamp(),
            name: newCategoryName,
            color: newCategoryColor,
            id: newCategoryRef.id
        })
    }

    const updateCategory = async () => {
        await updateDoc(thisCategoryRef, {
            name: newCategoryName,
            color: newCategoryColor,
        })
    }

    const deleteCategory = async () => {
        await deleteDoc(thisCategoryRef)
    }

    return(
        <>
        <Flex key={key} mb={2} flexDir='row'>
            <Input value={newCategoryName} mr={3} htmlSize={3} onChange={(e) => setNewCategoryName(e.target.value)} width='200px' />
            <ColorPicker color={newCategoryColor} mr={3} setColor={setNewCategoryColor} />
            <Button size={'md'} ml={3} textAlign={'center'} width='120px' onClick={action == 'Add' ? addCategory : updateCategory}>{action}</Button>
            {action == 'Update' && <IconButton ml={3} colorScheme={'red'} variant='outline' onClick={() => deleteCategory(catID)} icon={<FiTrash2/>} />}
        </Flex>
        </>
    )
}