import {
  Flex,
  Box,
  Center,
  AspectRatio} from '@chakra-ui/react'
import TopPerformers from '../components/TopPerformers/TopPerformers'
import { useState, useEffect, useRef } from 'react'
import { db } from '../utils/init-firebase'
import { limit, query, collection, orderBy, onSnapshot } from 'firebase/firestore'
import StackRankLOs_List from '../components/StackRankLOs_List'
import HelpfulLinkSections from '../components/HelpfulLinks/HelpfulLinkSections'
import MonthlyNewslettersCarousel from '../components/MonthlyNewsletters/MonthlyNewslettersCarousel'
import { useIdleTimer } from 'react-idle-timer'
import '../../src/assets/css/fireworks.css'
import { useAuth } from '../contexts/AuthContext'

export default function Homepage() {
  const { marketingSettings } = useAuth()

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const [mtd, setMTD] = useState()
  const [ytd, setYTD] = useState()
  const [lifetimeData, setLifetimeData] = useState()
  const [loadingRankData, setLoadingRankData] = useState(true)
  
  const [allData, setAllData] = useState()
  const [timestamp, setTimestamp] = useState()
  const [dataUpdated, setDataUpdated] = useState(1)

  const [monthlyNewsletters, setMonthlyNewsletters] = useState()

  const unsubscribeLORankings = useRef()
  const unsubscribeNewsletters = useRef()

  const [disconnected, setDisconnected] = useState(false)
  const [fireworks, setFireworks] = useState(true)

  const onIdle = () => {
    setDisconnected(true)
    unsubscribeLORankings.current && unsubscribeLORankings.current()
  }
  const idleTimer = useIdleTimer ( { onIdle, timeout: 1000 * 60 * 15 } )

  const subscribeToLORankings = async () => {
    const q = query(collection(db, "Stack Rank LOs Lifetime"), orderBy('timestamp', 'desc'), limit(1));
    unsubscribeLORankings.current && unsubscribeLORankings.current()
    unsubscribeLORankings.current = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setAllData(doc.data().summaryList)
        setTimestamp(doc.data().timestamp)
        setDataUpdated(Math.random() + 1)
      })
    })
    setDisconnected(false)
  }

/*    useEffect( async () => {
      setFireworks(true)
      await delay(8000)
      setFireworks(false)
  
  }, [])  */


  const getLatestNewsletter = async () => {
    const q2 = query(collection(db, "Monthly Newsletters"), orderBy('timestamp', 'desc'), limit(1));
    unsubscribeNewsletters.current = onSnapshot(q2, (querySnapshot2) => {
      let letters = []
      querySnapshot2.forEach((doc) => {
        letters.push(doc.data())
      })
      setMonthlyNewsletters(letters)
    })
  }

  useEffect( async () => {
      setLoadingRankData(true)
      await subscribeToLORankings()

      setLoadingRankData(false)

      return () => {
          unsubscribeLORankings.current && unsubscribeLORankings.current()
          unsubscribeNewsletters.current && unsubscribeNewsletters.current()
      }

  }, [])

  useEffect( async () => {
    if(marketingSettings && marketingSettings['Show Marketing Newsletter']){
      await getLatestNewsletter()
    }
    else {
      unsubscribeNewsletters.current && unsubscribeNewsletters.current()
      setMonthlyNewsletters(null)
    }
  }, [marketingSettings])

 
  return (
    <>
    
      <Flex className='pyro' flexDir={{base: 'column-reverse', xl: 'row'}} justify={{base: 'center', xl: 'space-between'}} mb={3}>
        <Box w={{base: '85vw', md: '45vw', xl: '20vw'}} display={{base: 'none', xl: 'block'}} borderRadius={'10px'}>
         <HelpfulLinkSections /> 
         {monthlyNewsletters && <MonthlyNewslettersCarousel newsletters={monthlyNewsletters}/>}
          <AspectRatio mt={3} ratio={1}>
            <iframe
              title='7 Bridges of Future Home Loans'
              src='https://www.youtube.com/embed/RgBJyOMTG54?si=ZV8gg_chSGqt5yCP' 
              allowFullScreen
              loading='lazy'
            />
          </AspectRatio> 
        </Box>
        <Box w={{base: '85vw', md: '75vw', xl: '48vw'}} padding={1} borderRadius={'10px'}>
          <TopPerformers/> 
        </Box>
{/*         {fireworks && <div className={'before'}></div>}
 */}
        <Flex flexDir={{base: 'column', md: 'row'}} justify='space-between'>
          <Box w={{base: '85vw',md: '35vw', xl: '18vw'}} display={{base: 'block', xl: 'none'}} padding={1} borderRadius={'10px'}>
          <HelpfulLinkSections /> 
            <AspectRatio mt={3} ratio={1}>
            <iframe
              title='7 Bridges of Future Home Loans'
              src='https://www.youtube.com/embed/RgBJyOMTG54?si=ZV8gg_chSGqt5yCP' 
              allowFullScreen
            />
          </AspectRatio> 
          </Box>
{/*           {fireworks && <div className={'after'}></div>}
          <IconButton 
              variant='outline' 
              color='yellow.600' 
              icon={fireworks ? <GiSightDisabled /> : <BsStars />} 
              position='absolute' 
              top='60px' 
              right='1' 
              onClick={() => setFireworks(!fireworks)}
            /> */}
          <Box mb={3} w={{base: '85vw', md: '35vw', xl: '14vw'}} padding={1} borderRadius={'10px'}>
              {!loadingRankData && allData && timestamp &&
              <Center>
                <StackRankLOs_List 
                    allData={allData}
                    timestamp={timestamp}
                    dataUpdated={dataUpdated}
                    disconnected={disconnected}
                    subscribeToLORankings={subscribeToLORankings}
                    limit={10}
                  />
              </Center>
             }        
          </Box> 
        </Flex>
      </Flex>
    </>
  )
}
