// TopicList.js
import { Text, Button } from '@chakra-ui/react';
import Topic from './Topic';
import { ReactSortable } from "react-sortablejs";


function SubtopicList( { subtopics, setSubtopics, updateSubtopic, deleteSubtopic, selectedTopic, selectedSubtopic, handleSelectSubtopic, handleReorderSubtopics } ) {
  return (
    <>
        <ReactSortable
          list={subtopics}
          setList={setSubtopics}
          group="subtopics"

        >          
          {subtopics?.map((topic) => (
            <Topic 
                key={topic.id}
                topic={topic} 
                handleSelectTopic={handleSelectSubtopic}
                selectedTopic={selectedSubtopic}
                handleUpdateTopic={updateSubtopic}
                handleDeleteTopic={deleteSubtopic}
            />
          ))}
        </ReactSortable>
        {!subtopics || subtopics.length == 0 &&
          <Text>No subtopics found. Add one!</Text>
        }

        <Button size='sm' mt='1' onClick={handleReorderSubtopics} colorScheme='green'>Save Order</Button>
     </>
  );
}

export default SubtopicList;

