import { useRef } from "react";
import { Stack } from "@chakra-ui/react";
import Message from "./Message";
import { useAuth } from "../../contexts/AuthContext";

export default function MessageList(props){
    const { SuperAdmin } = useAuth()
    const { messageList, collectionName, fullPageNotes, isDashboard, marketingChat, isNotes, collectionRef } = props

    const messagesEndRef = useRef(null)

    return(
       <Stack
         id='message-list'
         spacing={5}
         direction='column-reverse' 
         p={1}
         overflowY='auto'
         h={fullPageNotes ? '30vh' : 
            (isDashboard && SuperAdmin) ? '32vh' :
            isDashboard ? '38vh' : 
            marketingChat ? '77vh' : 
            !isNotes && '77vh'
        }
         minH={isNotes && '15vh'}
         >
            <div ref={messagesEndRef} />
            {messageList.map((message, i) => {
                return(
                    <Message key={`${collectionName}-message-${i}`} message={message} collectionRef={collectionRef} />
                )
            })}
          
      </Stack>
    )
}