import { Grid, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const ProductGrid = (props) => {
  const borderColor = useColorModeValue('1px solid lightgrey', '1px solid grey')
  const columns = React.useMemo(() => {
    const count = React.Children.toArray(props.children).filter(React.isValidElement).length
    return {
      base: Math.min(2, count),
      md: Math.min(3, count),
      lg: Math.min(4, count),
      xl: Math.min(5, count),
    }
  }, [props.children])

  const cols = { base: `repeat(2, 1fr)`, 
                  md: `repeat(3, 1fr)`, 
                  lg: `repeat(5, 1fr)`, 
                  xl: `repeat(6, 1fr)`
                }

  return (
    <Grid
      templateColumns={cols}
      gap={{ base: '5', md: '7' }}
      {...props}
    />
  )
}
