import * as Yup from "yup";

export const createValidationObj = Yup.object({
    'Request Type': Yup.string().required('Required'),

    // Design, Event, Swag Requests
    'Due Date': Yup.string()
        .when(['Request Type'], {
            is: 'Design',
            then: (schema) => schema.required('Required')
        })
        .when(['Request Type'], {
            is: 'Swag',
            then: (schema) => schema.required('Required')
        })
        .when(['Request Type'], {
            is: 'Custom',
            then: (schema) => schema.required('Required')
        }),
    'Description': Yup.string()
        .when(['Request Type'], {
            is: 'Design',
            then: (schema) => schema.required('Required')
        })
        .when(['Request Type'], {
            is: 'Custom',
            then: (schema) => schema.required('Required')
        }),
    'Medium': Yup.string()
        .when(['Request Type'], {
            is: 'Design',
            then: (schema) => schema.required('Required')
        }),
    'Platform': Yup.string()
        .when(['Medium'], {
            is: 'Digital',
            then: (schema) => schema.required('Required')
        }),
    'Length': Yup.string()
        .when(['Medium'], {
            is: 'Print',
            then: (schema) => schema.required('Required')
        }),
    'Width': Yup.string()
        .when(['Medium'], {
            is: 'Print',
            then: (schema) => schema.required('Required')
        }),
    // END Design, Event, Swag Requests

    // Marketing Spend Requests

    'Amount': Yup.number()
        .when(['Request Type'], {
            is: 'Marketing Spend',
            then: (schema) => schema.required('Required').typeError('Must be a number')
        }),
    'Spend Category': Yup.string()
        .when(['Request Type'], {
            is: 'Marketing Spend',
            then: (schema) => schema.required('Required')
        }),

    // END Marketing Spend Requests

    // Event Requests

    'Venue': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Name of Event': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Event Date': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Partnering Company': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Point of Contact': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Total Cost': Yup.number().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.typeError('Numbers Only').required('Required'),
    }),
    'Address': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required'),
    }),
    "Goal of Event": Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Social Media Needs': Yup.array(),
    'Percentage Requested From Future': Yup.number().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Team Member Needed': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Participant Information Collected': Yup.string().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.required('Required')
    }),
    'Expected Attendance': Yup.number().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.typeError('Numbers Only').required('Required'),
    }),
    'Expected Reach': Yup.number().when(['Request Type'], {
        is: 'Event',
        then: (schema) => schema.typeError('Numbers Only').required('Required'),
    }),
    // END Event Requests


    // Open House Requests

    'NMLS#': Yup.number().when(['Request Type'], {
        is: 'Open House',
        then: (schema) => schema.typeError('Numbers Only').required('Required'),
    }),

    'Property': Yup.object()
    .when('Request Type',({
        is: 'Open House', 
        then: Yup.object({
            'Address': Yup.string().required('Required'), 
            'Zillow Link': Yup.string().required('Required'), 
            'Price': Yup.number().typeError('Numbers Only').required('Required'),
            'MLS#':  Yup.number().typeError('Numbers Only').required('Required'),
        })
    })),

    'Realtor': Yup.object()
    .when('Request Type',({
        is: 'Open House', 
        then: Yup.object({
            'Name': Yup.string().required('Required'), 
            'SL#': Yup.number().typeError('Numbers Only').required('Required'),
            'Phone': Yup.number().typeError('Numbers Only').required('Required'),
            'Email': Yup.string().required('Required'), 
            'Company': Yup.string().required('Required'), 
        })
    })),
    'Event End Time': Yup.string().when(['Request Type'], {
        is: 'Open House',
        then: (schema) => schema.required('Required'),
    }),


    // END Open House Requests
});

