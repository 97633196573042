import { useState } from "react";
import { Avatar, Flex, Text, Box, useColorModeValue, IconButton, useBreakpointValue, Image, Center, Badge } from "@chakra-ui/react";
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';
import { StarIcon } from "@chakra-ui/icons";
import moment from "moment";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Settings for the slider
const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
export default function TopPerformers_ReviewCarousel_V2( { reviewList } ) {
    const [reviews, setReviews] = useState()
    const boxBg = useColorModeValue('white', 'gray.800')
    // As we have used custom buttons, we need a reference variable to
    // change the state
    const [slider, setSlider] = useState(null);
    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    const top = useBreakpointValue({ base: '90%', md: '50%' });
    const side = useBreakpointValue({ base: '30%', md: '3px' });

    /* reviewList is an array of objects containing company Google Reviews, ordered by date (the most recent will be the first in the array)
        The reviews are fetched from a third-party API - https://reviewsmaker.com
        They are fetched once per night via a cloud pubsub function

        reviewList: [
        {
            flagURL: String,
            plusId: String,
            rawDate: String,
            reviewAuthor: String,
            reviewDate: String,
            reviewRating: Number,
            reviewText: String,
            reviewUrl: String
        },
        ...
    ]

    */

    // This function will iterate through reviewList, and push the first five 5-star reviews into an array, and then set the array in state
    // React.useEffect(() => {
    //     console.log(reviewList)
    //     if(reviewList && reviewList.length > 0 && !reviews){
    //         let r = []
    //         let i = 0
    //         while(i < 5){
    //             if(reviewList[i] && reviewList[i].reviewRating && reviewList[i].reviewRating == 5){
    //                 r.push(reviewList[i])
    //                 i++
    //             }
    //             else i++
    //         }
    //         setReviews(r)
    //     } 
    // }, [reviewList])
  return (
    <>
    {reviewList && reviewList.length > 0 &&
        <>
        <Box
            bg={boxBg}
            boxShadow='md'
            rounded={'xl'}
            w='full'
            mt='2'
            position='relative'
            overflow={'hidden'}
        >

                <IconButton
                    aria-label="left-arrow"
                    borderRadius="full"
                    position="absolute"
                    left={side}
                    top={top}
                    variant='ghost'
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}>
                    <BiLeftArrowAlt />
                </IconButton>
                <IconButton
                    aria-label="right-arrow"
                    borderRadius="full"
                    position="absolute"
                    variant={'ghost'}
                    right={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}>
                    <BiRightArrowAlt />
                </IconButton>
                <Slider {...settings} ref={(slider) => setSlider(slider)}>
                        {reviewList.map((review, i) => {
                            if(review.rating == 5)
                            return(

                                    <Flex
                                        key={i}
                                        flexDir='column'
                                        display={'flex'}
                                        alignItems='center'
                                        justifyContent={'center'}
                                        p={5}
                                        pl={'6%'}
                                        pr={'7%'}
                                    >
                                        <Box>
                                            {review.text && <Text mb={2} as='em' fontSize={'sm'}>"{review.text}"</Text>}
        
                                            <Flex mt={3} mb={{base: '3vh', md: 0}} flexDir={{base: 'column', md: 'row'}} justify='space-between'>
                                                <Flex flexDir={'row'}>
                                                    {review.profile_photo_url && <Avatar size={'sm'} mr={2} src={review.profile_photo_url}></Avatar>}
                                                    <Flex flexDir='column' mr={2}>
                                                        <Flex flexDir={'row'}>
                                                            {review.author_name && <Text mr={2} fontSize={'xs'}>{review.author_name}</Text>}                                                        
                                                            {Array(5)
                                                            .fill('')
                                                            .map((_, i) => (
                                                            <StarIcon
                                                                key={i}
                                                                color={'yellow.300'}
                                                            />
                                                            ))}
                                                        </Flex>
                                                        <Flex flexDir={'row'}>
                                                            {review.time && 
                                                                <Text 
                                                                    fontStyle={'italic'} 
                                                                    color='gray.400' 
                                                                    fontSize={'xs'} 
                                                                    mr={3}>
                                                                        {moment(review.time*1000).calendar()}
                                                                </Text>}
                                                            {/* Display NEW badge if the review is less than 24 hours old */}
                                                            {review.time && (review.time + 86400 > moment().unix()) && 
                                                                <Badge variant={'subtle'} colorScheme={'red'}>NEW</Badge>}
                                                        </Flex>
                                                    </Flex>
         
                                                </Flex>
                                                <Flex align={'center'} mt={{base: '3', md: '0'}}>
                                                    {review.location &&
                                                    <Flex flexDir={'column'} mr='2'>
                                                        <Badge 
                                                            mr='2' 
                                                            variant='subtle'
                                                            borderRadius={'lg'} 
                                                            fontSize='sm'
                                                            colorScheme={
                                                                review.location.toUpperCase() == 'FUTURE HOME LOANS SPACE COAST' ? 
                                                                    'green' : 'blue'}>

                                                        {review.total_reviews &&
                                                        <>
                                                            {review.total_reviews}
                                                            <StarIcon
                                                                mr='1'
                                                                ml='1'
                                                                pb='0.5'
                                                                key={i}
                                                                color={'yellow.300'}
                                                            />
                                                        </>
                                                        }
                                                            {review.location}
                                                        </Badge>
                                
                                                    </Flex>}
                                                    <Image
                                                        h={'25px'}                                 
                                                        src={
                                                            require('../../assets/img/g-logo.png')                }
                                                        />
                                                </Flex>        
                                              
                                            </Flex>
                                        </Box>
                                    </Flex>

                            )
                        })}
                    </Slider>
                 
        </Box> 
        </>
    }
    </>
  );
};