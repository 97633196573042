import { useEffect, useState, useRef, useCallback } from "react";
import { limit, query, onSnapshot, doc, setDoc, serverTimestamp, orderBy, updateDoc } from "firebase/firestore";
import { db, firebase } from "../../utils/init-firebase";
import {
    Box,
    Heading,
    IconButton,
    Divider,
    useColorModeValue,
    Progress,
    Flex,
    Switch,
    FormLabel} from "@chakra-ui/react";
import { FormControl } from "@chakra-ui/react";
import { getDefaultKeyBinding } from "draft-js";
import { AiOutlineSend } from "react-icons/ai";
import { FiPlus, FiUpload } from "react-icons/fi";
import { useAuth } from "../../contexts/AuthContext";
import MessageList from "./MessageList";
import { EditorState, convertToRaw} from "draft-js";
import ChatInput from "../ChatInput/ChatInput";
import editorStyles from '../ChatInput/SimpleMentionEditor.module.css';
import { useLocation } from "react-router-dom";
import { MdBlock } from "react-icons/md";
import AddVideoLinkPopover from "../GroupChat/AddVideoLinkPopover";

// Set parentID to 'n/a' if using in a collection
// Set parentID to the parent doc id if using in a subcollection
// Heading defaults to 'CHAT' if null
export default function GroupChat( { 
        fillContainer,
        collectionRef, 
        collectionName, 
        parentID, 
        heading, 
        width, 
        isNotes, 
        isNotesForReview, 
        setNotesForReviewCount, 
        setShowNotesForReviewToast, 
        restrictPosting, 
        fullPageChat, 
        fullPageNotes, 
        parentDocContent, 
        isDashboard, 
        referencedLoan,
        maxMsgs 
    }) {
    const editorClass = useColorModeValue(editorStyles.editor, editorStyles.darkeditor)
    const editorClassDisabled = useColorModeValue(editorStyles.editorDisabled, editorStyles.darkeditorDisabled)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    )
    const { currentUser, loading, SuperAdmin, notifications } = useAuth()

    const [messageList, setMessageList] = useState([])
    const [loadingMessages, setLoadingMessages] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const location = useLocation()

    const [notifyPeople, setNotifyPeople] = useState(true)

    const inputRef = useRef();
    const formRef = useRef()

    const [files, setFiles] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])

    const [uploadProg, setUploadProg] = useState()
    const [uploading, setUploading] = useState(false)

    const uploadFiles = useCallback(()=>{
        if(files.length > 0)
        {
            setUploading(true)                    
            const theseFiles = files;
            setFiles([])
            var promises = []
            for (let i = 0; i < theseFiles.length; i++) {         
                    const storageRef = firebase.storage().ref();
                    const file = theseFiles[i];
 
                    promises.push(storageRef
                    .child(`${collectionName}/${currentUser.displayName}/${parentID}/${file.name}`)
                    .put(file).then( async (snapshot) => {
                       snapshot.ref.getDownloadURL().then( async (url) => {
                            setUploadedFiles(prevState => [...prevState, {url: url, name: file.name}])
                           // setFileURLs(prevState => [...prevState, url]);
                            //setFileNames(prevState => [...prevState, file.name]);
                                    return
                                }
                            )
                        })
                    )
            }
            Promise.all(promises)
            .then( async () => {
                    setUploading(false)
                }
            )
            .catch(err => console.log(err.code));
            
        }
    },[files])

    function removeAttachment(i){
        uploadedFiles.splice(i, 1)
        setFiles([])

    }
    const onFileChange = (e) => {
        setFiles(e.target.files)
    }

    const handleFileDrop = (files) => {
        setFiles(files)
    }

    useEffect(()=> {
        uploadFiles();
      }, [files])


    const handleMsgChange = (e) => {

        setMessage(e.value)
    }

    function renderSuggestion(entry, search, highlightedDisplay, index, focused) {
        // Modify the suggestion dropdown by returning valid JSX
        return (
          <>
            <span>{entry.display}</span>
          </>
        );
      }

      function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
        }

      function onAdd(id, display) {
        // Do something here
      }

      function handleSetNotifyPeople (e) {
          setNotifyPeople(e.target.checked)
      }

    function handleKeyBind(e){
        if (e.keyCode === 13 && !e.shiftKey && !(e.metaKey || e.ctrlKey)) {
          return 'send-message';
        } else if (e.keyCode === 13 && e.shiftKey) {
            return 'split-block';
        }
        return getDefaultKeyBinding(e);
      }
    
      function handleKeyCommand (command) {
        if (command === 'send-message') {
          handleSubmit()
          return 'handled'
        }
        return 'not-handled';
      }

    const handleSubmit = async () => {
        const contentState = editorState.getCurrentContent()
        let message = contentState.getPlainText('\u0001')
        const mentions = convertToRaw(contentState).entityMap
        await addMessage(message, mentions)
    }
    async function addMessage(message, mentions){
        try{
            const newMsgRef = doc(collectionRef)
            let mentioned = []
            let mentionedObj = []
            Object.entries(mentions).forEach(function(mention, i) {
                let thisMention = mention[1]?.data?.mention
                if(thisMention){
                    message = message.replace(thisMention.name, "")
                }
            })
            Object.entries(mentions).forEach( async function(mention) {
                let thisMention = mention[1]?.data?.mention
                if(thisMention){
                    let user = {
                        Name: thisMention.name,
                        Email: thisMention.email,
                        photoURL: thisMention.avatar,
                        uid: thisMention.uid,
                    }
                    
                    mentioned.push(thisMention.name)
                    mentionedObj.push({...user, ...{isMention: true}})
                }
            
/*              const notifRef = doc(collection(db, "users", thisMention.uid, 'Notifications'))
                await setDoc(notifRef, {
                    message: message,
                    user: {
                        uid: currentUser.uid,
                        displayName: currentUser.displayName,
                        photoURL: currentUser.photoURL,
                        email: currentUser.email,
                    },
                    loanID: parentID,
                    messageID: newMsgRef.id,
                    timestamp: serverTimestamp(),
                    id: notifRef.id,
                    read: false
                  }) */

            })

            if((collectionName == 'Marketing Requests' || collectionName == 'Escalations' ) && notifyPeople && parentDocContent){
                if(parentDocContent['Owner']){
                    let owner = parentDocContent['Owner']
                    mentionedObj.push({...owner, isMention: false})
                }
                if(parentDocContent['Assignees']){
                    parentDocContent['Assignees'].forEach((assignee) => {
                        mentionedObj.push({...assignee, isMention: false})
                    })
                }
            }

            // include loan team on notifications if settings say so & notify switch is on
            if(collectionName == 'Escalations' && notifyPeople && parentDocContent && referencedLoan){
                if(parentDocContent.Settings && parentDocContent.Settings['Notify LO'] && referencedLoan['Loan Officer']){
                    mentionedObj.push({...referencedLoan['Loan Officer'], ...{isMention: false}})
                }
                if(parentDocContent.Settings && parentDocContent.Settings['Notify LOA'] && referencedLoan['LC']){
                    mentionedObj.push({...referencedLoan['LOA'], ...{isMention: false}})
                }
                if(parentDocContent.Settings && parentDocContent.Settings['Notify LC'] && referencedLoan['LC']){
                    mentionedObj.push({...referencedLoan['LC'], ...{isMention: false}})
                }
                if(parentDocContent.Settings && parentDocContent.Settings['Notify CSC'] && referencedLoan['CSC']){
                    mentionedObj.push({...referencedLoan['CSC'], ...{isMention: false}})
                }
                if(parentDocContent.Settings && parentDocContent.Settings['Notify TC'] && referencedLoan['TC']){
                    mentionedObj.push({...referencedLoan['TC'], ...{isMention: false}})
                }
            }

            // include loan team if notify switch is on
            if(collectionName == 'Clients' && notifyPeople && parentDocContent){
                if(parentDocContent['Loan Officer']){
                    mentionedObj.push({...parentDocContent['Loan Officer'], ...{isMention: false}})
                }
                if(parentDocContent['LC']){
                    mentionedObj.push({...parentDocContent['LC'], ...{isMention: false}})
                }
                if(parentDocContent['CSC']){
                    mentionedObj.push({...parentDocContent['CSC'], ...{isMention: false}})
                }
                if(parentDocContent['TC']){
                    mentionedObj.push({...parentDocContent['TC'], ...{isMention: false}})
                }
            }

            await setDoc(newMsgRef, {
                message: message,
                parentID: parentID,
                parentSnapshot: parentDocContent ? parentDocContent : null, 
                id: newMsgRef.id,
                user: {
                    uid: currentUser.uid,
                    displayName: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    email: currentUser.email,
                },
                timestamp: serverTimestamp(),
                mentioned: mentioned,
                mentionedObj: mentionedObj,
                notifyPeople: notifyPeople,
                path: location.pathname,
                Files: uploadedFiles
               
            })
            
            setEditorState(EditorState.createEmpty())
            setUploadedFiles([])
        }
        catch(err){
            console.log(err)
        }
    }

    useEffect( async () => {
        const q = query(collectionRef, orderBy("timestamp", 'desc'), limit(maxMsgs ?  maxMsgs : 100));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = []
            querySnapshot.forEach((msg) => {
                messages.push(msg.data())
            })
            setMessageList(messages)

        });
        setLoadingMessages(false)

        return () => {
            unsubscribe();
        };  

            
    }, [collectionRef])

     useEffect( async () => {
        try{
            if(notifications && messageList){
                for(const msg of messageList ){
                    notifications.forEach( async (notif) => {
                        if(notif.messageID == msg.id){
                            const notificationRef = doc(db, 'users', currentUser.uid, 'Notifications', notif.id)
                            await updateDoc(notificationRef, {
                                read: serverTimestamp()
                            })
                        }
                    });
                }
            }
        }
        catch(err){
            console.log(err)
        }
    }, [messageList]) 

    return (
        <>
            <Box width={width ? width 
                :
                {    
                    base: '100%',
                    xl: '65%'
                }}    
                borderWidth={!isDashboard && '1px'} 
                borderTopRadius={!fullPageChat && !isNotes && !isDashboard && 'lg'}
                borderRightRadius={!fullPageChat && !isNotes && !isDashboard && 'lg'}
                position='relative'
            >
      

            <Flex justifyContent={'space-between'}>
                <Box>
                {!isNotes && <Heading p={2} size='sm'>{heading ? heading : 'CHAT'}</Heading>}
                </Box>
                {(collectionName == 'Marketing Requests' || collectionName == 'Clients' || collectionName == 'Escalations') &&
                    <Box p={2} >
                    <FormControl justifySelf={'flex-end'} display='flex' alignItems='center'>
                        <FormLabel fontSize={'sm'} htmlFor='notify-people' mb='0'>
                            Notify People on&nbsp;
                                {collectionName == 'Marketing Requests' ? 'Request' : 
                                    collectionName == 'Escalations' ? 'Escalation' : 'File'}
                        </FormLabel>
                        <Switch id='notify-people' defaultChecked={notifyPeople} onChange={(e) => handleSetNotifyPeople(e)}/>
                    </FormControl>
                    </Box>
                }
            </Flex>
            <Divider/>

            <MessageList 
                marketingChat={collectionName == 'Marketing Requests' && true} 
                messageList={messageList} 
                isNotes={isNotes} 
                isDashboard={isDashboard} 
                fullPageNotes={fullPageNotes} 
                collectionRef={collectionRef} 
                collectionName={collectionName}
            />
               

                 
                  {restrictPosting && !SuperAdmin ? 
                        <></>
                        // <div className={editorClassDisabled}>
                        //     <Text textColor={'gray.500'} as='em' >You do not have permission to post in this channel</Text>
                        // </div>
                    :
                    <>
                        <div className={editorClass}>
                            {uploading && <Progress size='xs' isIndeterminate />}

                            <ChatInput 
                                isDashboard={isDashboard}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                handleKeyBind={handleKeyBind}
                                handleKeyCommand={handleKeyCommand}
                                fullPageChat={fullPageChat}
                                uploadedFiles={uploadedFiles}
                                removeAttachment={removeAttachment}
                                handleDropFiles={handleFileDrop}
                            /> 
                        </div>
                        <IconButton 
                        onClick={handleSubmit}
                        disabled={(!editorState.getCurrentContent().hasText() && !uploadedFiles.length > 0) || uploading}
                        variant='solid'
                        position='absolute'
                        colorScheme={'green'}
                        opacity={'85%'}
                        bottom={2}
                        right={2}
                        border='none'
                        isRound
                        aria-label='Delete message'
                        size='md'
                        isLoading={uploading}
                        spinner={<MdBlock/>}
                        icon={isNotes || fullPageNotes ? 
                            <FiPlus/>
                            :
                            <AiOutlineSend/>
                        }
                        />
                        {SuperAdmin && isDashboard &&
                            <AddVideoLinkPopover 
                                uploading={uploading}
                                collectionRef={collectionRef}
                            />
                    
                        }
                        <input type='file'
                            onChange={onFileChange}
                            multiple='multiple'
                            name={'Upload Files'}
                            ref={inputRef}
                            style={{display: 'none'}} />
                        <IconButton
                            icon={<FiUpload/>}
                            position='absolute'        
                            isLoading={uploading}
                            disabled={uploading}
                            bottom={2}
                            right={12}
                            onClick={() => inputRef.current.click()}
                            variant='ghost'
                        />
                    </>
                    } 

                  
         </Box>
         </>
    );
}
