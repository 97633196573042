import { Reorder } from "framer-motion"
import { useEffect, useState } from "react"
import AddNewsletter from "../../components/MonthlyNewsletters/AddNewsletter"
import { collection, query, orderBy, onSnapshot } from "firebase/firestore"
import { db } from "../../utils/init-firebase"
import { Avatar, Box, Heading, Flex, Image, IconButton } from "@chakra-ui/react"
import { FiTrash2 } from "react-icons/fi"
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import BooleanSwitch from "../../components/Settings/BooleanSwitch"


export default function MonthlyNewsLetterPage ( { } ){
  const [newsletters, setNewsletters] = useState()
  const [activeNewsLetter, setActiveNewsletter] = useState()
  const [initialSettings, setInitialSettings] = useState(null)
  const [loadingSettings, setLoadingSettings] = useState(true)
  const settingsRef = doc(db, 'Marketing Settings', 'yEwRIqrkneqrGnAQhgvs')
  const fieldPath = 'Show Marketing Newsletter'

  const getSettings = async () => {
    try{
      const settings = await getDoc(settingsRef)
      if(settings.exists){
        setInitialSettings(settings.data())
        console.log(settings.data())
      }
    }
    catch(err) {
      console.error(err);
    }
    return
  }

  useEffect(async () => {
        await getSettings().then(() => {
          setLoadingSettings(false)
        })
    }, [])  

  useEffect(() => {
    const q = query(collection(db, "Monthly Newsletters"), orderBy('timestamp', 'desc'));
        
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let letters = []
      querySnapshot.forEach((doc) => {
          letters.push(doc.data())

      })
      setNewsletters(letters)
    })
    return () => {
        unsubscribe()
    }
  }, [])

  const deleteNewsletter = async (id) => {
    await deleteDoc(doc(db, "Monthly Newsletters", id));
    setActiveNewsletter(null)
  }

  return (
    <>
    <Flex flexDir={'row'} mt='7'>
        <Flex flexDir={'column'}>
            {!loadingSettings &&
              <BooleanSwitch
                docRef={doc(db, 'Marketing Settings', 'yEwRIqrkneqrGnAQhgvs')}
                fieldPath={fieldPath}
                initialState={initialSettings[`${fieldPath}`] ? initialSettings[`${fieldPath}`] : false}
                label='Show Latest Marketing Newsletter On Homepage'
                successMsg='The most recent marketing newsletter will be shown on the home page!'
                warningMsg='Marketing newsletters will be NOT shown on the home page'

              />
            }
            <AddNewsletter action='Add'/>
            <Flex flexDir='row'>
                <Flex w={{base: '90vw', lg: '25vw'}} flexDir={'column'}>
                {newsletters && newsletters.map((newsletter) => (
                    <>
                    <Flex flexDir={'row'}>
                        <IconButton alignSelf='center' colorScheme={'red'} variant='ghost' onClick={() => deleteNewsletter(newsletter.id)} icon={<FiTrash2 />}/>
                        <Flex align='middle' flexDir="row" _hover={{boxShadow:'lg', cursor: 'pointer'}} onClick={() => setActiveNewsletter(newsletter)} w='400px' p={3} borderRadius='lg' boxShadow='md'>
                            <Avatar size='sm' src={newsletter.user?.photoURL}></Avatar>
                            <Heading fontWeight={activeNewsLetter ? activeNewsLetter.id == newsletter.id ? 'bold' : 'normal' : 'normal'} ml={2} alignSelf='center' fontSize={'md'}>{newsletter.Name}</Heading>
                        
                        </Flex>
                    </Flex>
                    </>
                ))}
                </Flex>
    
            </Flex>
        </Flex>
            {activeNewsLetter &&
                <Image w={{base: '90vw', lg: '45vw'}} src={activeNewsLetter.photoURL}>

                </Image>
            }
        </Flex>
    </>
  )

}