import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function MarketingVault( {} ){

    return(
        <>        
          <Breadcrumb mb={3} fontSize='xs'>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to='/'>Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to='/marketing-vault'>Marketing Vault</BreadcrumbLink>
            </BreadcrumbItem>

          </Breadcrumb>        
          <iframe 
              src={"https://drive.google.com/a/www.future.loans/embeddedfolderview?id=1-l2aNJDhCJyc89HnHbeDt3xKxn4hkb4Z"} 
              style={{borderWidth:0}}
              width={`100%`} height={`1075`}>
        </iframe>
        </>

    )
}