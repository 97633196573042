import React, { useState } from 'react';
import { Text, Flex, IconButton, Input, FlexProps } from '@chakra-ui/react';
import { FiCheck, FiEdit3, FiX } from 'react-icons/fi';
import { useAuth } from '../contexts/AuthContext';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../utils/init-firebase';

interface FundedUnitsGoalProps {
  goal: string;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
  selectedUser: { value: string; photoURL: string; label: string; email: string };
}

function FundedUnitsGoal({ goal, setGoal, selectedUser }: FundedUnitsGoalProps) {
  const { currentUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [newGoal, setNewGoal] = useState(goal ? goal : '');

  const handleUpdateGoal = async (updatedGoal: string) => {
    await updateDoc(doc(db, 'users', selectedUser.value), {
      'Funded Units Goal': updatedGoal,
    });
  };

  return (
    <Flex  key={'funded-units-goal'} flexDir={'row'} align='flex-start' ml='2'>
      <Text mr='2'>Funded Units Goal: </Text>

      {editing ? (
        <Flex flexDir={'row'}>
          <Input
            value={newGoal}
            onChange={(e) => setNewGoal(e.target.value)}
            autoFocus
            width='75px'
            height='30px'
          ></Input>
          <IconButton
            aria-label='update-goal'
            size="sm"
            variant='ghost'
            icon={<FiCheck />}
            colorScheme='green'
            disabled={goal === newGoal}
            onClick={() => {
              handleUpdateGoal(newGoal);
              setEditing(false);
            }}
          ></IconButton>
          <IconButton
            aria-label='stop-editing-goal'
            size="sm"
            variant='ghost'
            icon={<FiX />}
            colorScheme='red'
            onClick={() => setEditing(false)}
          ></IconButton>
        </Flex>
      ) : (
        <Flex flexDir={'row'}>
          <Text>{newGoal}</Text>
          <IconButton
            aria-label='edit-goal'
            size="sm"
            variant='ghost'
            pb='2'
            icon={<FiEdit3 />}
            colorScheme='green'
            onClick={() => setEditing(true)}
          ></IconButton>
        </Flex>
      )}
    </Flex>
  );
}

export default FundedUnitsGoal;
