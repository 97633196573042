import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button, 
    Box,
    Image,
    useDisclosure, 
    Flex, 
    Avatar
  } from '@chakra-ui/react'
import moment from "moment";

export default function NewsletterButton ( { newsletter } ){
    const { isOpen, onOpen, onClose } = useDisclosure()

    return(
        <>
        <Button variant='ghost' onClick={onOpen}>{newsletter.Name}&nbsp; <ExternalLinkIcon /></Button>
        <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{newsletter.Name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Image w='100%' src={newsletter.photoURL} />
            </ModalBody>

            <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
        <Flex ml='2' flexDir='row'>
            {newsletter.user && <Avatar size={'xs'} mr={2} src={newsletter.user.photoURL}></Avatar>}
            <Box 
                color='gray.500'
                letterSpacing='normal'
                fontSize={'10px'}
                alignSelf='center'
                >
                {newsletter.timestamp.seconds ? 
                moment(newsletter.timestamp.seconds*1000).calendar() 
                :    
                moment(newsletter.timestamp.toDate()).calendar()
                }                                        
            </Box>
        </Flex>
    </>
    )
}