import { Flex, Box, Text, Center, Image, Progress, Avatar, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { functions } from '../utils/init-firebase';
import { FormControl } from "@chakra-ui/react";
import ReactTable from '../components/Table/ReactTable';
import { useAuth } from '../contexts/AuthContext';
import { Select } from "chakra-react-select";
import { currencyFormatter } from '../functions/formatAsCurrency';
import { GrRefresh } from 'react-icons/gr';
import { getApp } from 'firebase/app';

export default function CompDashboardPage() {
    const { SuperAdmin, currentUser } = useAuth()
    const { brokers } = useAuth()

    const [data, setData] = useState()
    const [columns, setColumns] = useState()
    const [nonYearTables, setNonYearTables] = useState()
    const [loading, setLoading] = useState(false)
    const [selectedUser, setSelectedUser] = useState()
    const [error, setError]= useState(false)
    const [selectedYear, setSelectedYear] = useState()
    const [yearOptions, setYearOptions] = useState()

    // const functions = getFunctions(getApp());
    // connectFunctionsEmulator(functions, "127.0.0.1", 9001); 
    // const test  = httpsCallable(functions, 'test')   

    const getPipelineSnapshot = httpsCallable(functions, 'getPipelineSnapshot') 

    const findCurrentUserIndex = () => {
        return brokers.findIndex(x => x.value === currentUser.uid)
    }

    useEffect(() => {
        if(brokers){
            let currentUserIndex = findCurrentUserIndex()
            currentUserIndex > -1 && setSelectedUser(brokers[currentUserIndex])
        }
    }, [brokers])

    useEffect(() => {
        if(selectedUser){
            handleGetPipelineSnapshot()
        }
    }, [selectedUser])
 


    const handleGetPipelineSnapshot = () => {
        setError(false)
        setLoading(true)
        setData(null)
        setNonYearTables(null)
        setColumns(null)
        getPipelineSnapshot({ email: selectedUser.email })
            .then((result) => {
                let yearOpts = []
                let otherTables = []
                Object.keys(result.data).forEach((sheetName) => {
                    if(!isNaN(sheetName)){
                        yearOpts.push({label: sheetName, value: sheetName})
                    }
                    else otherTables.push(sheetName)
                })
                console.log(otherTables)
                if(yearOpts.length > 1){
                    yearOpts?.sort((a, b) => b.value - a.value)
                    setYearOptions(yearOpts)
                    setSelectedYear(yearOpts?.[0] && yearOpts[0])
                }
               
                otherTables.length > 0 && setNonYearTables(otherTables)
                setData(result.data) 
                setColumns(makeColumns(result.data))
            })
            .catch(err => {
                console.log(err)
                setError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // Given an array of objects, return an array of unique properties of all the objects
    function makeUniqueFieldList(table){
        let fields = []
        for(const row of table){
            let thisRowsFields = Object.keys(row)
            fields.push(thisRowsFields)
        }
        return [...new Set([].concat(...fields))]
    }

    /* Given an array of properties, return an array of column objects formatted for react-table
        [
            {
                Header: column1,
                accessor: column1,
                footer: OPTIONAL
                isNumeric: OPTIONAL
                sortMethod: OPTIONAL
            },
            {
                Header: column2,
                accessor: column2
            },
            ...
        ]
    */

    function sumColumn(table, field){
        let sum = 0
        for(const row of table){
            if(row[field]){
                sum = parseFloat(sum) + parseFloat(row[field].replace(/[$,]/g, ''))
            }
        }    
        return sum
    }

    function makeThisTableCols(table, uniqueFieldList){
        let thisTableCols = []
        for(const field of uniqueFieldList){
            let sum
            let fieldLower = field.toLowerCase()
            // sum all values in this field to use in the footer if necessary
            if(fieldLower == 'loan amount' || fieldLower.includes('commission') || fieldLower == 'revenue'){
                sum = sumColumn(table, field)
            }
            if(sum){
                thisTableCols.push({
                    Header: field,
                    accessor: field,
                    isNumeric: true,
                    sortMethod: (a, b) => parseFloat(a.replace(/[$,]/g, ''))-parseFloat(b.replace(/[$,]/g, '')),
                    footer: currencyFormatter(sum)
                })
            }
            else{
                thisTableCols.push({
                    width: field == 'Comp Code' && '250px',
                    minWidth: field == 'Comp Code' && '250px',
                    Header: field,
                    accessor: field,
                    footer: ''
                })
            }
        }
        return thisTableCols
    }

    /* Expected Input/Output of makeColumns
        INPUT:
        data: {
                sheetName: [
                    {
                        column1: val,
                        column2: val,
                        ...
                    },
                    {
                        column1: val,
                        column2: val,
                        ...
                    },
                    ...  
                ],
                sheetName: [
                    {
                        column1: val,
                        column2: val,
                        ...
                    },
                    {
                        column1: val,
                        column2: val,
                        ...
                    },
                    ...  
                ]
            }
        OUTPUT:
        colsByTable: {
                sheetName: [
                    {
                        Header: column1,
                        accessor: column1,
                        footer: OPTIONAL
                        isNumeric: OPTIONAL
                        sortMethod: OPTIONAL
                    },
                    {
                        Header: column2,
                        accessor: column2
                    }
                ],
                sheetName: [
                    {
                        Header: column1,
                        accessor: column1,
                        footer: OPTIONAL
                        isNumeric: OPTIONAL
                        sortMethod: OPTIONAL
                    },
                    {
                        Header: column2,
                        accessor: column2
                    }
                ],
        }
    */

    function makeColumns(data){
        let colsByTable = {}
        // iterate through each table object
        for (const table in data){
            let uniqueFieldList = makeUniqueFieldList(data[table])
            colsByTable[table] = makeThisTableCols(data[table], uniqueFieldList)            
        }
        return colsByTable
    }

    return(
    <>
        <Flex maxW={{base: '90vw', md: '83vw'}} flexDir='row' justify='space-between' flexWrap={'wrap'}>
            <Box order={1}></Box>
            <Flex flexDir={'row'} order={{base: 2, md: 1}}>
            {SuperAdmin && brokers &&
                <FormControl w='250px'>
                    <Select
                        options={brokers}
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e)}
                        placeholder="Select User"
                        closeMenuOnSelect={true}
                        selectedOptionColor="green"
                        classNamePrefix={'user-select'}
                    />
                </FormControl>
            }
            </Flex>
             {/* <IconButton size='lg' icon={<FiActivity/>} ml='2' variant={'ghost'} onClick={() => test()}/> */}
  
            <Flex order={1}>
                {selectedUser && 
                <>
                    <IconButton size='lg' icon={<GrRefresh/>} ml='2' variant={'ghost'} disabled={!selectedUser || loading} onClick={() => handleGetPipelineSnapshot()}/>
  
                    <Flex mr='2' textAlign={'end'} flexDir={'column'}>
                        {selectedUser.label && 
                        <Text fontSize='md'>{selectedUser.label}</Text>}
                        {selectedUser.jobTitle && 
                        <Text fontSize='xs' textTransform='uppercase'>{selectedUser.jobTitle}</Text>}
                        {selectedUser.NMLS && 
                        <Text fontSize='xs' textTransform='uppercase'>NMLS#{selectedUser.NMLS}</Text>}
                    </Flex>
                    {selectedUser.photoURL && 
                        <Avatar size='lg' src={selectedUser.photoURL } />}
                  </>
                }
            </Flex>
        </Flex>
        <Text p='2' fontSize='sm' color='gray' display={{base: 'flex', md: 'none'}}>If on mobile, scroll left to right</Text>
        {!loading && nonYearTables && columns && data && yearOptions &&
        <>
            {nonYearTables?.map((table) => (
                <ReactTable key={table} title={table} cols={columns[table]} data={data[table]} colorScheme='teal' />
            ))}
            <FormControl w='200px'>
                <Select
                    options={yearOptions}
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e)}
                    placeholder="Select Year"
                    closeMenuOnSelect={true}
                    selectedOptionColor="green"
                    classNamePrefix={'year-select'}
                />
            </FormControl>
            {selectedYear &&
            <ReactTable 
                cols={columns[selectedYear?.value]} 
                data={data[selectedYear?.value]} 
                colorScheme='teal' 
                />
            }
            
        </>
        }
        {error &&
            <Center flexDir={'column'} mt='150px'>
                <Image src={require('../assets/img/cat-error.png')} />
                <Text colorScheme={'gray'} fontSize={'sm'}>Error reading pipeline. Please try again.</Text>
            </Center>
        }
        {loading &&
        <>
            <Center flexDir={'column'} mt='250px'>
                <Image src={require('../assets/img/rocket-load.gif')} />
                <Text colorScheme={'gray'} fontSize={'sm'}>LOADING</Text>   
                <Progress w='50px' size={'xs'} isIndeterminate /> 
            </Center>
        </>
        }
      </>
    )
  }