import { useState, useEffect, useRef } from 'react'
import {
    Flex,
    Box,
    ScaleFade,
    useToast,
    Radio,
    IconButton,
    Link
  } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { doc, setDoc, serverTimestamp, collection } from 'firebase/firestore'
import { db } from '../../utils/init-firebase'
import { InputControl, SubmitButton, ResetButton, RadioGroupControl, SelectControl } from 'formik-chakra-ui'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FaQuestionCircle } from 'react-icons/fa'


export default function AddLink ( { selectedSection } ){
    const toast = useToast()
    const {
		handleSubmit,
        reset
	} = useForm()

    const formRef = useRef()
    const validationSchema = Yup.object({
        'Name': Yup.string().required('Required'),
    })

    const initialValues = {
        'Name': '', 
        'URL': '',
        'Phone Number': '',
        'Email': '',
        'Type': 'External',
        'Window Size': 'xl',
       }
    const addLink = async (values) => {
        try{
            const newDocRef = doc(collection(db, 'Helpful Link Sections', selectedSection, 'Links'))

            await setDoc(newDocRef, {
                values,
                'Date Created': serverTimestamp(),
                id: newDocRef.id,

            })

            toast({
                title: 'Link Added!',
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top'
            })
            formRef.current.values['Name'] = ''
            formRef.current.values['URL'] = ''
            formRef.current.values['Email'] = ''
            formRef.current.values['Phone Number'] = ''
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    }

    return(
        <ScaleFade in>
        <Formik
            initialValues={initialValues}
            innerRef={formRef}
            onSubmit={addLink}
            validationSchema={validationSchema}
            
            >
            {({ handleSubmit, values, errors }) => (
                <>
                <Box as='form' mb={4} mt={2} p={2} onSubmit={handleSubmit}>
                <Flex mb={5} alignItems={'center'} flexDir='row' justifyContent='flex-start'>
                    <RadioGroupControl defaultValue={'External'} label='Link Type' labelProps={{ fontWeight: 'bold', fontSize: 'large'}} name='Type'>
                        <Radio defaultChecked value='External'>External</Radio>
                        <Radio value='Contact'>Contact</Radio>
                        <Radio value='Google Doc Embed'>Google Doc Embed</Radio>
                    </RadioGroupControl>
                </Flex>
                <Flex alignItems={'center'} flexDir='row' justifyContent='flex-start'>
                    <InputControl
                        name="Name"
                        label="Name"
                    >
                    </InputControl>
                    &nbsp;
                    <InputControl
                        name="URL"
                        label='URL'
                    >
                    </InputControl>
                    &nbsp;
                    {values['Type'] == 'Contact' &&
                        <>                        
                        <InputControl
                            name="Phone Number"
                            label='Phone Number'
                        >
                        </InputControl>
                        &nbsp;
                        <InputControl
                            name="Email"
                            label='Email'
                        >
                        </InputControl> 
                        </>
                    }
                     {values['Type'] == 'Google Doc Embed' &&
                        <>          
                        <Link w='0' mt={'-85px'} isExternal href='https://chakra-ui.com/docs/components/modal/usage#modal-sizes'><FaQuestionCircle/></Link>          
                        <SelectControl
                            name="Window Size"
                            label={`Window Size`}
                        >
                            <option value='xs'>xs</option>
                            <option value='sm'>sm</option>
                            <option value='md'>md</option>
                            <option value='lg'>lg</option>
                            <option value='xl'>xl</option>
                            <option value='full'>full</option>
                        </SelectControl>
                        </>
                    }
                </Flex>
                <SubmitButton width={'200px'} mt={8}>Add Link</SubmitButton>

                </Box>
                </>
                )}
        </Formik>
    </ScaleFade>
    )
}