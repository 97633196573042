import React from "react";
import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogBody,
    CloseButton
 } from '@chakra-ui/react'
export default function DeleteDialog(props){
  
    return (
      <>
        <AlertDialog
          isOpen={props.isOpen}
          leastDestructiveRef={props.cancelRef}
          onClose={props.onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='md' fontWeight='bold'>
                Delete {props.selectedUser ? props.selectedUser.name : props.item}
              </AlertDialogHeader>
              <CloseButton/>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button style={{alignSelf: 'left'}} ref={props.cancelRef} onClick={props.onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={() => props.handleDelete()} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

