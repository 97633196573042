import { useState, useEffect } from 'react'
import {
    IconButton,
    Textarea,
    Flex,
    Heading,
    Button,
    useToast,
    Text,
    Icon,
    Avatar,
  } from '@chakra-ui/react'
import { BsFillStarFill, BsStar } from 'react-icons/bs'
import { useAuth } from '../../contexts/AuthContext'
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../utils/init-firebase'
import LoanAssigneesGroup from '../LoanAssigneesGroup'
import LoanTeamAvatars from '../Client/LoanTeamAvatars'

export default function FeedbackBody( { client, handleRemoveClient } ){
    const clientRef = doc(db, 'Clients', client.id)
    const docRef = doc(db, 'Clients', client.id, 'Feedback', 'LO Feedback')
    const { lenders, currentUser } = useAuth()
    const toast = useToast()

    const [lenderComments, setLenderComments] = useState()
    const [lenderRating, setLenderRating] = useState()
    const [lenderRelational, setLenderRelational] = useState()
    const [loading, setLoading] = useState(true)

    const handleSetLenderRating = (index) => setLenderRating(index)

    // if there is a lenderID, find the lender name and set it in state. Fallback to Lender as a string
    useEffect(() => {
      const findLenderName = () => {
        if (client?.['Loan Details']?.['lenderID'] && lenders) {
          const foundLender = lenders.find(
            (lender) => lender.id === client['Loan Details']['lenderID']
          );

          if (foundLender) {
            return foundLender.name;
          }
        }

        return client?.['Loan Details']?.['Lender'];
      };

      setLenderRelational(findLenderName());
    }, [client, lenders]); // Include client and lenders in the dependency array

    useEffect( async () => {
        const getFeedback = async () => {
            let feedbackRef = await getDoc(docRef)
            if(feedbackRef.exists()){
                setLenderRating(feedbackRef.data().lenderRating)
                setLenderComments(feedbackRef.data().lenderComments)
            }
        }

        await getFeedback()
        setLoading(false)
    }, [client])

    async function handleSave(){
        try{
            let feedbackRef = await getDoc(docRef)
            if(feedbackRef.exists()){
                await updateDoc(docRef, {
                    clientID: client.id,
                    lenderID: client?.['Loan Details']?.lenderID || '',
                    lenderName: client?.['Loan Details']?.Lender || '',
                    lenderComments: lenderComments,
                    lenderRating: lenderRating,
                    timestamp: serverTimestamp(),
                    editedBy: {
                        name: currentUser.displayName,
                        photoURL: currentUser.photoURL,
                        uid: currentUser.uid,
                        email: currentUser.email
                    }
                })
            }
            else{
              await setDoc(docRef, {
                id: docRef.id,
                clientID: client.id,
                lenderID: client?.['Loan Details']?.lenderID || '',
                lenderName: client?.['Loan Details']?.Lender || '',
                lenderComments: lenderComments,
                lenderRating: lenderRating,
                timestamp: serverTimestamp(),
                createdBy: {
                    name: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    uid: currentUser.uid,
                    email: currentUser.email
                }
              })

              await updateDoc(clientRef, {
                'LO Feedback Given': serverTimestamp()
              })
              
            }

            handleRemoveClient && client.id && handleRemoveClient(client.id)

            toast({
                title: 'Feedback submitted!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        }
        catch(err){
            console.log(err)
            toast({
                title: 'Error submitting feedback',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
       
    }

    return(
        <>
         {!loading &&
            <>
            <Flex flexDir={'row'}>
              <LoanTeamAvatars LO={client?.['Loan Officer']} />
              <Flex flexDir={'column'} ml='3'>
                <Heading size='sm'>Lender Rating: {lenderRelational && lenderRelational}</Heading>
                <Text as='em' fontSize='sm'>Client: {client?.['Loan Details']?.['First Name']} {client?.['Loan Details']?.['Last Name']}</Text>
                <Text as='em' fontSize='sm'>Loan #: {client?.['Loan Details']?.['Loan #']}</Text>

              </Flex>
            </Flex>
            
            <Flex flexDir={'row'}>
                <StarRatingButtonGroup rating={lenderRating} setRating={handleSetLenderRating}/>
            </Flex>
            <Textarea
                value={lenderComments}
                onChange={(e) => setLenderComments(e.target.value)}
                placeholder='Comments'
                style={{height: '150px'}}
                required
            />
            <Button 
                disabled={!lenderComments || !lenderRating}
                mt='2'
                colorScheme='blue' 
                onClick={handleSave}>
                Submit
            </Button>
            </>
            }
        </>
    )
}

export const StarRatingButtonGroup = ({ rating, setRating, disabled, pl }) => {
  
    const [hoveredIndex, setHoveredIndex] = useState(-1);
  
    const handleHover = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(-1);
    };
  
    return (
      <>
      {disabled ?
      <>
        {Array(rating).fill(true).map((_, index) => (
          <Icon
          ml={index === 0 && '-2'}
          pl={'1'}    
          fontSize='xl'        
          variant={'unstyled'}
          _focus={{ outline: 'none' }}
          key={index}
          as={BsFillStarFill}
          color={'yellow.400'}
        />
        ))}
      </>
      :
      <>
        {[1, 2, 3, 4, 5].map((value, index) => (
          <IconButton
            ml={index === 0 && '-2'}
            pl={pl || '3'}            
            variant={'unstyled'}
            _focus={{ outline: 'none' }}
            key={index}
            icon={(value <= rating || value <= hoveredIndex) ? <BsFillStarFill /> : <BsStar />}
            color={(value <= rating || value <= hoveredIndex) && 'yellow.400'}
            onClick={() => setRating(value)}
            onMouseEnter={() => handleHover(value)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </>
      }
       
      </>
    );
  };
  