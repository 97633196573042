import { VStack, Box, Button, Text, Input, Grid, IconButton, Heading, Flex, Accordion, AccordionButton, AccordionPanel, AccordionItem, AccordionIcon, useDisclosure, FormLabel, useColorModeValue, Link } from '@chakra-ui/react';
import { db } from '../../utils/init-firebase';
import { useState, useEffect, useRef } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc, deleteDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { FiCheck, FiEdit, FiPenTool, FiTrash, FiTrash2, FiX } from 'react-icons/fi';
import CreatableOptions from '../../components/Settings/CreatableOptions';
import BooleanSwitch from '../../components/Settings/BooleanSwitch';
import { FaPencilAlt, FaTrashRestore } from 'react-icons/fa';
import { BiPencil } from 'react-icons/bi';
import CreatableOptions_Object from '../../components/Settings/CreateableOptions_ArrayOfObjects';
import CreatableOptions_ArrayOfObjects from '../../components/Settings/CreateableOptions_ArrayOfObjects';
import CreatableOptions_SingleObject from '../../components/Settings/CreateableOptions_SingleObject';
import ReactTable from '../../components/Table/ReactTable';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast
} from '@chakra-ui/react'
import React from "react";
import {
  InputControl,
  RadioGroupControl,
  SelectControl,
  SubmitButton,
  TextareaControl,
  ResetButton,
  CheckboxSingleControl,
  CheckboxControl,
} from "formik-chakra-ui";
import { Formik, useFormikContext } from 'formik'
import * as Yup from "yup";
import DividerWithText from '../../components/DividerWithText';

const handleMakeInactive = async (id, inactiveValue) => {
  try {
    await updateDoc(doc(db, 'lenders', id), {inactive: inactiveValue});
  } catch (error) {
  }
};

export default function ManageLenders( { initialSettings } ){
  const [lenders, setLenders] = useState([]);
  const [columns, setColumns] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const allCols = [
      {
        Header: '',
        accessor: 'id',
        footer: '',
        Cell: ({ cell: { value }, row }) => (
          <>  
              <EditLenderModal lender={row?.original} initialSettings={initialSettings} />
          </>
        ),
      },
      {
        Header: 'Core',
        accessor: 'Core Lender',
        footer: '',
        sortType: ((a, b, id) => {
          if (a.original[id] > b.original[id]) return 1; 
          if (b.original[id] > a.original[id]) return -1;
        }),
        Cell: ({ cell: { value }, row }) => (
          <>  
              <BooleanSwitch 
                docRef={doc(db, 'lenders', row?.original?.id)}
                fieldPath='Core Lender'
                initialState={value ? value : false}
                successMsg={`${row?.original?.name} is a Core Lender`}
                warningMsg={`${row?.original?.name} is NOT a Core Lender`}
            />
          </>
        ),
      },
      {
        Header: 'DPA',
        accessor: 'DPA',
        footer: '',
        sortType: ((a, b, id) => {
          if (a.original[id] > b.original[id]) return 1; 
          if (b.original[id] > a.original[id]) return -1;
        }),
        Cell: ({ cell: { value }, row }) => (
          <>  
              <BooleanSwitch 
                docRef={doc(db, 'lenders', row?.original?.id)}
                fieldPath='DPA'
                initialState={value ? value : false}
                successMsg={`${row?.original?.name} offers DPA`}
                warningMsg={`${row?.original?.name} does NOT offer DPA`}
            />
          </>
        ),
      },

      {
          Header: 'Lender',
          accessor: 'name',
          footer: '',
          Cell: ({ cell: { value }, row }) => (
            <>  
                <Heading size='sm'>{value}</Heading>
            </>
          ),
      },
      // {
      //     Header: 'Account Executive',
      //     accessor: 'Account Executive.Name',
      //     footer: ''
      // },
      // {
      //   Header: 'AE Number',
      //   accessor: 'Account Executive.Number',
      //   footer: ''
      // },
      // {
      //     Header: 'AE Email',
      //     accessor: 'Account Executive.Email',
      //     footer: ''
      // },
      {
        Header: 'Account Executive',
        accessor: 'Account Executive',
        footer: '',
        Cell: ({ cell: { value }, row }) => (
          <>  
              <Text>{row?.original?.['Account Executive']?.Name}</Text>
              <Text>{row?.original?.['Account Executive']?.Number}</Text>
              <Text>{row?.original?.['Account Executive']?.Email}</Text>

          </>
        ),
      },
      {
        Header: 'Website',
        accessor: 'website',
        footer: '',
        Cell: ({ cell: { value }, row }) => (
          <>  
              <Link isExternal size='sm' href={value}>{value}</Link>
          </>
        ),
      },
      {
        Header: 'Guidelines',
        accessor: 'guidelineLink',
        footer: '',
        Cell: ({ cell: { value }, row }) => (
          <>  
              <Link isExternal size='sm' href={value}>{value}</Link>
          </>
        ),
      },
      {
        Header: '',
        accessor: 'inactive',
        footer: '',
        Cell: ({ cell: {value }, row })=> (
            <IconButton 
              variant={'ghost'} 
              color={value ? 'green' : 'red'}  
              icon={value ? <FaTrashRestore/> : <FiTrash2 />} 
              onClick={() => handleMakeInactive(row?.original?.id, !value)}>
            </IconButton>
        )
      }

  
      // {
      //     Header: 'Program',
      //     accessor: 'Loan Details Product',
      //     filterOptions: {
      //       type: 'dropdown',
      //       makeOptionsFromData: true
      //     },
      //     footer: ''
      // },
  ]

  const loadLenders = () => {
    const lendersCollection = collection(db, 'lenders');
  
    const unsubscribe = onSnapshot(lendersCollection, (querySnapshot) => {
      const lenderData = [];
      querySnapshot.forEach((doc) => {
        lenderData.push({ id: doc.id, ...doc.data() });
      });
      //setColumns(makeColumns(lenderData))
      setLenders(lenderData);
      setLoading(false);
    }, (error) => {
      setError(error);
    });
  
    // Return a cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'lenders', id));
      const updatedLenders = lenders.filter((l) => l.id !== id);
      setLenders(updatedLenders);
    } catch (error) {
      setError(error);
    }
  };
  
  useEffect(() => {
    const unsubscribe = loadLenders();
  
    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  

    return(
        <>
        <LenderForm />
        <ReactTable cols={allCols} data={lenders?.filter((lender) => !lender?.inactive)} colorScheme='teal'/>
        <Accordion mt='5' allowToggle>
        <AccordionItem>
            <AccordionButton>
                <Heading size='sm'>Inactive Lenders ({lenders?.filter((lender) => lender?.inactive)?.length})</Heading>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
                    <ReactTable cols={allCols} data={lenders?.filter((lender) => lender?.inactive)} colorScheme='teal'/>
            </AccordionPanel>
            </AccordionItem>

        </Accordion>
        </>
    )
}

const LenderCards = ( { lenders, loading, error, setError } ) => {

  return (
    <>
    <LenderForm />
    <Grid gridTemplateColumns={{base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(3, minmax(0, 1fr))'}}>
      {loading && <Text>Loading...</Text>}
      {error && <Text>Error: {error.message}</Text>}
      {lenders?.filter((lender) => !lender.inactive).map((lender) => (
        <LenderCard lender={lender} />
      ))}
    </Grid>
    <Accordion mt='5' allowToggle>
    <AccordionItem>
        <AccordionButton>
            <Heading size='sm'>Inactive Lenders</Heading>
            <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
            <Grid gridTemplateColumns={{base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(3, minmax(0, 1fr))'}}>
            {lenders?.filter((lender) => lender.inactive).map((lender) => (
                <LenderCard lender={lender}/>
            ))}
            </Grid>
        </AccordionPanel>
        </AccordionItem>

    </Accordion>

    </>
  );
};

const EditLenderModal = ( { lender, initialSettings } ) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton 
        m='1'
        onClick={onOpen}
        icon={<FiEdit/>}
        colorScheme='green'
        size='sm'
        >

      </IconButton>
{/* isOpen={lender.name === 'asdf' ? true : isOpen} */}
      <Modal size='6xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <LenderCard lender={lender} onClose={onClose} initialSettings={initialSettings}/>
        </ModalContent>
      </Modal>
    </>
  )
}

const LenderCard = ( { lender, boxShadow, m, p, onClose, initialSettings } ) => {
  const toast = useToast()
  const aeBackground = useColorModeValue('white none repeat scroll 0 0', '#1A202C none repeat scroll 0 0')

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    website: Yup.string().url('Must be a valid URL'),
    guidelineLink: Yup.string().url('Must be a valid URL')

  });
  
  const initialValues = lender ? lender : {
    name: "",
    'Account Executive': {
      Name: "",
      Number: "",
      Email: "",
    },
    website: "",
    guidelineLink: ""
  };

  const formRef = useRef()

  const onSubmit = async (values) => {
    try {
      // Access form values
  
      // Your logic for updating the document goes here
      await updateDoc(
        doc(db, 'lenders', lender.id), 
          values
        );
  
      toast({
        title: `${values.name} Updated!`,
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
      
      // Optionally, reset the form after successful submission
    } catch (err) {
      console.error(err);
      toast({
        title: `Error updating lender!`,
        status: 'error',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex>
        <ModalHeader display='inline-flex'>
          {`Edit ${lender?.name}`}
        </ModalHeader>
        <Flex flexDir={'column'} mt='5'>
          <BooleanSwitch 
              docRef={doc(db, 'lenders', lender.id)}
              fieldPath='Core Lender'
              label='Core Lender'
              initialState={lender['Core Lender'] ? lender['Core Lender'] : false}
              successMsg={`${lender.name} is a Core Lender`}
              warningMsg={`${lender.name} is NOT a Core Lender`}
            />
          <BooleanSwitch 
              docRef={doc(db, 'lenders', lender.id)}
              fieldPath='DPA'
              label='DPA'
              initialState={lender['DPA'] ? lender['DPA'] : false}
              successMsg={`${lender.name} offers DPA`}
              warningMsg={`${lender.name} does NOT offer DPA`}
          />
        </Flex>
      </Flex>
          <ModalCloseButton />
             <Box m={m} p={p} key={lender.id} boxShadow={boxShadow}>
                <Formik
                  initialValues={initialValues}
                  innerRef={formRef}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, setFieldValue, values, errors }) => (
                      <>
                      <ModalBody overflowY={'auto'} height='65vh'>
                        <Box
                          as="form"
                          onSubmit={handleSubmit}
                        >
                          <Flex flexDir={'row'} justify='space-between'>
                            <Flex flexDir={'column'} w='48%'>
                              <InputControl name="name" label="Lender" mb='2'/>
                              <InputControl name="website" label="Website" labelProps={{fontSize: 'sm', m: 0}}/>
                              <InputControl name="guidelineLink" label="Guideline Link" labelProps={{fontSize: 'sm', m: 0}}/>
                            </Flex>
                          
                            <Flex flexDir={'column'} w='48%'>
                              <Heading 
                                  colorScheme={'gray'} 
                                  //mt={'-9.8em'} 
                                  ml={'1em'} 
                                  mb='3'
                                  background={aeBackground} 
                                  size='xs'>
                                      ACCOUNT EXECUTIVE
                              </Heading>
                              <Box p='3' border={'1px solid lightgray'} borderRadius='15px' mt={'-0.8em'}>
                                <InputControl name="Account Executive.Name" label="Name" labelProps={{fontSize: 'sm', m: 0}}/>
                                <InputControl name="Account Executive.Number" label="Number" labelProps={{fontSize: 'sm', m: 0}}/>
                                <InputControl name="Account Executive.Email" label="Email" labelProps={{fontSize: 'sm', m: 0}}/>
                              </Box>
                            </Flex>
                          </Flex>  
                          <SubmitButton onClick={handleSubmit} colorScheme={'green'}>Save</SubmitButton>

                          <DividerWithText text='Loan Scenarios' />
                          <CreatableOptions_ArrayOfObjects
                              docRef={doc(db, 'lenders', lender.id)}
                              initialSettings={lender?.['Loan Scenarios']}
                              settingName='Loan Scenarios'
                              singularName='Scenario'
                              formValues={values}
                              settingFields={[
                                  {name: 'Product Type', type: 'dropdown', options: initialSettings?.['Product Types']}, 
                                  {name: 'Property Type', type: 'dropdown', options: initialSettings?.['Property Types']}, 
                                  {name: 'Credit Score', type: 'number'}, 
                                  {name: 'Loan Narrative', type: 'longinput', isMulti: true}
                                ]}
                              addButtonText='Add Loan Scenario'
                          />
                          </Box>

                      </ModalBody>
                      {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                          Close
                        </Button>
                    </ModalFooter> */}
                  </>
                  )}
                
              </Formik>
            </Box>

    
     </>
      )
}

const LenderHeader = ({ lender }) => {
    const [editingName, setEditingName] = useState(false)

    return(
    <>
    {editingName ?
        <>
            <LenderForm lender={lender} setEditingName={setEditingName}/>
            <IconButton 
                p='0'
                variant={'ghost'} 
                color='red' 
                onClick={() => setEditingName(false)} 
                icon={<FiX/>}>
            
            </IconButton>

        </>
        :                
        <>
            <Flex m='1'>
                <Heading lineHeight={'2'} size='md'>{lender.name}</Heading>
                <IconButton 
                    p='0'
                    variant='ghost' 
                    colorScheme={'green'} 
                    onClick={() => setEditingName(true)} 
                    icon={<BiPencil/>}>
                </IconButton>
            </Flex>
        </>
        }
    </>
    )
}

const LenderForm = ({ lender, setEditingName }) => {
    const [name, setName] = useState(lender ? lender.name : '');
  
    const handleSave = async () => {
      if (name.trim() === '') {
        alert('Name cannot be empty');
        return;
      }
  
      if (lender) {
        // Update an existing lender
        await updateDoc(doc(db, 'lenders', lender.id), { name });
      } else {
        // Create a new lender
        const docRef = doc(collection(db, 'lenders'))
        await setDoc(docRef, { 
            name: name,
            id: docRef.id,
            inactive: false,
            'Core Lender': false
        });
      }
  
      // Reset the form
      setEditingName && setEditingName(false)
      setName('');
    };
  
    return (
      <Flex>
        <Input
          type="text"
          placeholder="Lender Name"
          value={name}
          width='250px'
          onChange={(e) => setName(e.target.value)}
        />
          {lender ? 
            <IconButton variant={'ghost'} colorScheme={'green'} icon={<FiCheck/>} onClick={handleSave}></IconButton>
            : 
            <Button colorScheme={'green'} ml='2' onClick={handleSave}>
                Create Lender
            </Button>
        }
        
      </Flex>
    );
};
  