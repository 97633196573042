import React, { useEffect, useState, useRef } from "react";
import { collection, doc, deleteDoc, updateDoc, orderBy, query, onSnapshot } from "firebase/firestore";
import { db } from "../utils/init-firebase";
import {
  Flex,
  useColorModeValue,
  IconButton,
  Avatar,
  useDisclosure,
  useToast,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  FormControl,
  Box,
  Tooltip,
  Switch,
  Text,
  HStack,
  VStack,
  Input
} from "@chakra-ui/react";
import { BsFillTrashFill } from "react-icons/bs";
import { CreatableSelect } from "chakra-react-select";
import DeleteDialog from "../components/Dialogs/DeleteDialog";
import { FiCheck } from "react-icons/fi";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";


export default function AdminSettingsPage() {
  const [allUsers, setAllUsers] = useState([])
  const [newSwagBudgets, setNewSwagBudgets] = useState(null)
  const [loading, setLoading] = useState(true)

  const history = useHistory()
  
  const bg = useColorModeValue("white", "gray.800");
  const bg2 = useColorModeValue("white", "gray.800");
  const bg3 = useColorModeValue("gray.100", "gray.700");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState(null)
  const [branchOptions, setBranchOptions] = useState()
  const cancelRef = React.useRef();
  const toast = useToast()

  const emailEditorRef = useRef(null);

  const handleOnClose = () => {
    setSelectedUser(null)
    onClose()
  }

  const handleOnOpen = (uid, name) => {
    setSelectedUser({ uid: uid, name: name})
    onOpen()
  }


  const handleDeleteUser = () => {
    deleteUser()
    handleOnClose()
    //getUsers()
  }

  const handleAdminChange = async (e, fieldDescr, field, uid) => {
    try{
      let status = null
      let title = null
      if(e.target.checked == true){
        status = 'success'
        title = `${fieldDescr} permissions granted for ${e.target.name} `
      }
      else {
        status = 'warning'
        title = `${fieldDescr} permissions revoked for ${e.target.name} `
      }
      await updateDoc(doc(db, "users", uid), {
        [field]: e.target.checked
      })
      toast({
        title: title,
        status: status,
        position: 'top',
        isClosable: true
      })
    }
    catch(err){
        toast({
          title: "Error: " + err,
          status: 'error',
          position: 'top',
          isClosable: true
        })
    } 
  }

    const handleLOLevelChange = async (e, fieldDescr, field, uid) => {
    try{
      let status = null
      let title = null
      let description = null
      if(e.target.checked == true){
        status = 'success'
        title = `${fieldDescr} enabled for ${e.target.name} `
        description = `Gotchas, Loan Narrative & Assets and Income will be required for new loan submissions for this user`
      }
      else {
        status = 'warning'
        title = `${fieldDescr} disabled for ${e.target.name} `
        description = `Gotchas, Loan Narrative & Assets and Income will be NOT required for new loan submissions for this user`

      }
      await updateDoc(doc(db, "users", uid), {
        [field]: e.target.checked
      })
      toast({
        title: title,
        status: status,
        description: description,
        position: 'top',
        isClosable: true
      })
    }
    catch(err){
        toast({
          title: "Error: " + err,
          status: 'error',
          position: 'top',
          isClosable: true
        })
    } 
  }

  const handleSetNewSwagBudget = (value, i) => {
      let updatedBudgets = [...newSwagBudgets]
      updatedBudgets[i]['Swag Budget'] = value
      setNewSwagBudgets(updatedBudgets)
      
  }

  const handleUpdateSwagBudget = async (i, user) => {
    try{
      let status = 'success'
      let title = `Swag budget updated for ${user.name} `

      await updateDoc(doc(db, "users", user.uid), {
        ['Swag Budget']: newSwagBudgets[i]['Swag Budget']
      })
      toast({
        title: title,
        status: status,
        position: 'top',
        isClosable: true
      })
    }
    catch(err){
        toast({
          title: "Error: " + err,
          status: 'error',
          position: 'top',
          isClosable: true
        })
    } 
  }

  const deleteUser = async (uid) => {
    try{
      await deleteDoc(doc(db, "users", selectedUser.uid))
      toast({
        title: "User successfully deleted!",
        status: 'success',
        position: 'top',
        isClosable: true
      })
    }
    catch(err){
        toast({
          title: "Error: " + err,
          status: 'error',
          position: 'top',
          isClosable: true
        })
    }
  }


  const handleSetBranch = async (uid, username, e) => {
      if(e){
        await updateDoc(doc(db, 'users', uid), {
          Branch: e.value
        })
        toast({
          title: username + " assigned to " + e.value + " branch",
          status: 'success',
          position: 'top',
          isClosable: true
        })
      }
      else{
        await updateDoc(doc(db, 'users', uid), {
          Branch: null
        }) 
        toast({
          title: username + "'s branch removed",
          status: 'warning',
          position: 'top',
          isClosable: true
        })
      }
     
  }

  useEffect(async () => {
      setLoading(true)
      const q = query(collection(db, "users"), orderBy('dateJoined', 'desc'));
        const unsub = onSnapshot(q, (querySnapshot) => {
          let users = []
          let branches = []
          let b = []
          let swagBudgets = []
          querySnapshot.forEach((doc) => {
            users.push({
              ...doc.data(),
              'Swag Budget': doc.data()['Swag Budget'] ? doc.data()['Swag Budget'] : ''
            })
            swagBudgets.push({
              uid: doc.data().uid,
              name: doc.data().name,
              'Swag Budget': doc.data()['Swag Budget'] ? doc.data()['Swag Budget'] : ''
            })
            if(doc.data().Branch && !b.includes(doc.data().Branch)){
              branches.push({ label: doc.data().Branch, value: doc.data().Branch })
              b.push(doc.data().Branch)
            }
          })
          //console.log(swagBudgets)
          setNewSwagBudgets(swagBudgets)
          setBranchOptions(branches)
          setAllUsers(users)
        })

      return () => {
        unsub()
        //unsubBranches()
      }
  }, [])  

  useEffect(() => {
    if(newSwagBudgets) setLoading(false)
  }, [newSwagBudgets])

  return (
    <>
       {!loading && allUsers && branchOptions && newSwagBudgets &&
       <>
              <Tabs mt='5'>
                <TabList>
                  <Tab>Users</Tab>
                  {/* <Tab>Settings</Tab> */}
                </TabList>

                <TabPanels>
                  <TabPanel>
                  <Flex mt={5} direction='column' width='1400px' overflowX={'scroll'} as='table'>
                  <Flex width='100%' p={4}  direction={"row"} justifyContent='space-around' bg={bg3} >

                          <Box width='20%'>Name</Box>
                          <HStack width='15%'>
                            <Text>
                              Level 1
                              </Text>
                              <Tooltip fontSize={'sm'} placement='top' label='Level 1 users will be required to fill out Gotchas, Loan Narrative & Assets and Income when submitting new loans'>
                                <InfoOutlineIcon/>
                              </Tooltip>
                          </HStack>
                          <Box width='15%'>Swag Budget</Box>
                          <Box width='25%'>Branch</Box>
                          <HStack width='15%'>
                            <Text>
                              Super Admin
                              </Text>
                              <Tooltip fontSize={'sm'} placement='top' label='Super Admins can manage users'>
                                <InfoOutlineIcon/>
                              </Tooltip>
                          </HStack>

                          <HStack width='15%'>
                            <Text>
                              Submissions Admin
                              </Text>
                          </HStack>

                          <HStack width='15%'>
                            <Text>
                              Marketing Admin
                              </Text>
                          </HStack>
                    </Flex>
                  {allUsers.map((user, i) => {
                    return (
                        <Flex
                          justifyContent='space-between'
                          width='100%'
                          p={3}
                          key={`${i}-row`}
                        >
                          <HStack width='20%' key={`${i}-avatar-group`}>
                              <Flex flexDir='row'>
                                <IconButton
                                    colorScheme="red"
                                    size='sm'
                                    variant='ghost'
                                    icon={<BsFillTrashFill />}
                                    onClick={() => handleOnOpen(user.uid, user.name)}
                                  />
                                 <Avatar style={{marginRight: 5}} size="sm" name={user.name} src={user.photo} key={`${i}-avatar`}/>
                              
                              </Flex> 
                              <VStack align={'flex-start'} spacing={0} verticalAlign={'middle'} key={`${i}-avatar-group-2`}>
                                <Text               
                                  onClick={() => user?.['uid'] && history.push(`/profile/${user['uid']}`)}
                                  _hover={{cursor: 'pointer', textDecoration: 'underline'}}
                                  key={`${i}-name`} 
                                  m={0}>
                                    {user.name}
                                </Text>
                                {user.Roles?.map((role, j) => {
                                  return(
                                    <Text key={`${i}-role-${j}`} fontSize={'xs'}>{role ? role : 'No Role Found'}</Text>
                                  )
                                })}
                                 {user.phoneNo?.map((number, j) => {
                                  return(
                                    <Text key={`${i}-phoneNo-${j}`} fontSize={'xs'}>{number ? number : 'No Number Found'}</Text>
                                  )
                                })}
                              </VStack>
             
                          </HStack>
                            <Box width='15%' pl='9' key={`${i}-level-one-container`}>
                              <Switch 
                                key={`${i}-level-one-switch`}
                                name={`${user.name}`}
                                onChange={(e) => handleLOLevelChange(e, 'Level 1', 'Level1', user.uid)}
                                defaultChecked={user['Level1'] ? user['Level1'] : false}
                              />
                            </Box>
                            <Box ml='1%' width='15%'key={`${i}-swag-budget-parent`}>
                              {newSwagBudgets && newSwagBudgets[i] && newSwagBudgets[i]['Swag Budget'] != undefined &&
                              <FormControl as='flex' flexDir={'row'} maxW='75%'key={`${i}-swag-budget-form-control`}>
                                  <Input
                                    width='80px'
                                    key={`${i}-swag-budget-input`}
                                    type='number'
                                    value={newSwagBudgets[i]['Swag Budget']}
                                    onChange={(e) => handleSetNewSwagBudget(e.target.value, i)}
                                  >
                    
                                  </Input>
                                  <IconButton
                                    icon={<FiCheck/>}
                                    key={`${i}-swag-budget-update`}
                                    variant='ghost'
                                    color='green'
                                    onClick={() => handleUpdateSwagBudget(i, user)}
                                    disabled={newSwagBudgets[i]['Swag Budget'] == user['Swag Budget']}
                                  >

                                  </IconButton>
                                </FormControl>
                              }
                            </Box>
                          <Box width='35%'key={`${i}-branch-select-parent`}>
                            <FormControl maxW='75%'key={`${i}-branch-select-form-control`}>
                                <CreatableSelect
                                  key={`${i}-branch-select`}
                                  value={user.Branch ? {label: user.Branch, value: user.Branch} : null}   
                                  isClearable                             
                                  options={branchOptions}
                                  onChange={(e) => handleSetBranch(user.uid, user.name, e)}
                                  placeholder="Select or create a branch..."
                                />
                              </FormControl>
                            </Box>
                          <Box width='15%' key={`${i}-super-admin-container`}>
                              <Switch 
                                key={`${i}-super-admin-switch`}
                                name={`${user.name}`}
                                onChange={(e) => handleAdminChange(e, 'Super Admin', 'SuperAdmin', user.uid)}
                                defaultChecked={user.SuperAdmin}
                              />
                            </Box>
                            <Box width='15%' pl='2' key={`${i}-submissions-admin-container`}>
                              <Switch 
                                key={`${i}-submissions-admin-switch`}                                
                                name={`${user.name}`}
                                onChange={(e) => handleAdminChange(e, 'Submissions Admin', 'isAdmin', user.uid)}
                                defaultChecked={user.isAdmin}
                              />
                            </Box>
                          <Box width='15%' pl='5' key={`${i}-marketing-admin-container`}>
                            <Switch 
                              key={`${i}-marketing-admin-switch`}
                              name={`${user.name}`}
                              onChange={(e) => handleAdminChange(e, 'Marketing Admin', 'MarketingAdmin', user.uid)}
                              defaultChecked={user.MarketingAdmin}
                            />
                        </Box>

                        {/*   <chakra.span
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            pl={'50px'}
                          >

                            <Switch 
                              id={`${user.uid}`}
                              name={`${user.name}`}
                              onChange={(e) => handleLoanVisChange(e)}
                              defaultIsChecked={user.loanVisibility}
                            >

                            </Switch>
                          </chakra.span> */}
                      
                       
                        </Flex>
                    
                    );
                  })}
              </Flex>
                  </TabPanel>
                  {/* <TabPanel>
                    <ProcessPipelineSettings />
                  </TabPanel> */}

                </TabPanels>
              </Tabs>
            
             
              <DeleteDialog
                handleDelete={handleDeleteUser}
                item="User"
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                cancelRef={cancelRef}
                selectedUser={selectedUser}
              />
             
    </>
       
    }
  </>    
  );
}
