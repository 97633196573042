import { useState } from 'react'
import { db } from '../../utils/init-firebase';
import { updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import {
    Stack,
    Button,
    Flex,
    useToast,
    FormControl  } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom'
import { Select } from "chakra-react-select";

  export default function AssignClaimClientCSC({ status, type, loanOfficer, dateAdded, loanDetails, docID, showUndo, showView }) {
    const location = useLocation
    const toast = useToast()
    const { currentUser } = useAuth()
    const { users } = useAuth()
    const [selectedUser, setSelectedUser] = useState(null)

    const history = useHistory();

    const handleClaim = async (docID, CSC) => {
        let alertTitle = null
        let assignee = null
        let  CSCAssignedBy = null
        // if user is claiming file
        if(CSC == null){
          assignee = {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid
          }
          CSCAssignedBy = 'Claimed'
          alertTitle = 'You have been added to ' + loanDetails['First Name'] + ' ' + loanDetails['Last Name'] + '`s file'
        }
        // if user is assigning file
        else {
          assignee = {
            Email: CSC.email,
            Name: CSC.label,
            photoURL: CSC.photoURL,
            uid: CSC.value
          }
          CSCAssignedBy = {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid
        }
          alertTitle = CSC.label +' has been added to ' + loanDetails['First Name'] + ' ' + loanDetails['Last Name'] + '`s file'
        }
        try{
            await updateDoc(doc(db, 'Clients', docID), {
                'CSC': assignee,
                'CSC Assigned By':  CSCAssignedBy,
                'Date CSC Added': serverTimestamp(),
                'Last Edited By': {
                  Email: currentUser.email,
                  Name: currentUser.displayName,
                  photoURL: currentUser.photoURL,
                  uid: currentUser.uid
              }
            })
            
            toast({
                duration: 5000,
                position: 'top',
                title: alertTitle,
                status: 'success'
              })
        }
        catch(err){
            toast({
                title: 'Error',
                description: err,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        }

    }

    return (
        <>
        <Flex maxW={'300'} flexDirection={'column'} justifyContent={'center'}>
            <FormControl p={2}>
                <Select
                    options={users}
                    onChange={(e) => setSelectedUser(e)}
                    placeholder="Select CSC"
                    closeMenuOnSelect={true}
                    selectedOptionColor="green"
                    classNamePrefix={'assign-client-select'}
                />
                </FormControl>
            <Stack mt={3} direction={'row'} spacing={4}>
            <Button
                disabled={!selectedUser}
                onClick={() => handleClaim(docID, selectedUser)}
                m='auto'
                maxW={'150'}
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                _focus={{
                    bg: 'gray.200',
                }}>
                Assign
                </Button> 
                
              {/*   <Button
                onClick={() => handleClaim(docID)}
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                boxShadow={
                    '0px 1px 5px -5px rgb(66 153 225 / 48%), 0 7px 7px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                    bg: 'blue.500',
                }}
                _focus={{
                    bg: 'blue.500',
                }}>
                
                Claim
                </Button> */}
        </Stack> 
       </Flex>
       </>
    );
  }