import { AvatarGroup, Avatar, Tooltip } from "@chakra-ui/react"

export default function AssigneesAvatarGroup( { assignees, Status } ){

    return(
        <>
        {assignees &&
          <AvatarGroup size="sm" >
          {assignees.map((assignee) => {
              return(
              <Tooltip key={`assignee-${assignee.uid}`} placement='top' label={`${assignee.Name}`}>
                <Avatar border={Status == 'In Progress' ? '2px solid orange' : Status == 'New' ? '2px solid red' : '2px solid green'} src={assignee.photoURL}>
                </Avatar>
              </Tooltip>
            )
          })}
          </AvatarGroup>
      }
      </>
    )
}