import {
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Icon,
  Box,
} from "@chakra-ui/react";
import {
  FiHome,
  FiLogIn,
  FiSettings,
  FiCheckCircle,
  FiDownload,
  FiTruck,
  FiXCircle,
  FiClipboard,
  FiImage,
  FiShoppingCart,
  FiDollarSign,
  FiTrendingUp,
  FiDatabase,
} from "react-icons/fi";
import { useEffect, useState } from "react";
import { BsBank, BsClockHistory, BsFillMoonStarsFill } from "react-icons/bs";
import { useAuth } from "../../contexts/AuthContext";
import DrawerItem from "./DrawerItem";
import NavButton from "./NavButton";
import SubmissionDrawer from "../../pages/Loans/SubmissionDrawer";
import { installable, handleInstallClick } from "../../App";
import {
  FaBullhorn,
  FaFileInvoiceDollar,
  FaNewspaper,
  FaPoll,
  FaRegQuestionCircle,
  FaStoreAlt,
  FaTshirt,
} from "react-icons/fa";
import CreateRequest from "../Marketing/CreateRequest";
import { GrCompliance } from "react-icons/gr";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidPlanet } from "react-icons/bi";

export default function DrawerBar({ isOpen, onOpen, onClose }) {
  const { isAdmin, SuperAdmin, MarketingAdmin } = useAuth();
  const [submissionsMenuIndex, setSubmissionsMenuIndex] = useState();
  const [marketingMenuIndex, setMarketingMenuIndex] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const mmi = localStorage.getItem("marketingMenuIndex");
    if (mmi) {
      setMarketingMenuIndex(parseInt(mmi));
    }
    const smi = localStorage.getItem("submissionsMenuIndex");
    if (smi) {
      setSubmissionsMenuIndex(parseInt(smi));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    localStorage.setItem("marketingMenuIndex", marketingMenuIndex);
  }, [marketingMenuIndex]);

  useEffect(() => {
    localStorage.setItem("submissionsMenuIndex", submissionsMenuIndex);
  }, [submissionsMenuIndex]);

  return (
    <Drawer
      onClose={onClose}
      autoFocus={false}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      onCloseComplete
      trapFocus
      closeOnEsc
      placement={"left"}
      isOpen={isOpen}
      size={"xs"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Future Home Loans</DrawerHeader>
        <DrawerBody m={0} p={0} display="flex" flexDir={"column"}>
          <Divider />
          <DrawerItem onClose={onClose} icon={FiHome} name="Home" to="/" />
          <Divider />
          <DrawerItem
            onClose={onClose}
            icon={AiFillDashboard}
            name="My Dashboard"
            to="/my-dashboard"
          />
          <Divider />
          <DrawerItem
            onClose={onClose}
            icon={FiDollarSign}
            name="Comp Dashboard"
            to="/comp-dashboard"
          />
          <Divider />
          <DrawerItem
            onClose={onClose}
            icon={FiTrendingUp}
            name="Sales Dashboard"
            to="/sales-dashboard"
          />
          <Divider />
          <Accordion
            w="100%"
            onChange={(index) => setSubmissionsMenuIndex(index)}
            allowToggle
            defaultIndex={submissionsMenuIndex}
          >
            <AccordionItem>
              <h2>
                <AccordionButton pt={"8%"} pb={"8%"} w="100%">
                  <Icon as={FaFileInvoiceDollar} />
                  <Box pl="2" textAlign="left">
                    Submissions
                  </Box>

                  <AccordionIcon alignSelf="flex-end" />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                <Divider />
                <SubmissionDrawer
                  isFirstChild
                  isChild
                  action="Add"
                  isDrawerButton={true}
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={FiLogIn}
                  name="Setup Queue"
                  to="/setup-queue"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={BsClockHistory}
                  name="In Progress"
                  to="/in-progress"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={FiTruck}
                  name="Parked"
                  to="/parked"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={FaRegQuestionCircle}
                  name="TBD"
                  to="/tbd"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={FiCheckCircle}
                  name="Completed"
                  to="/completed"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  isLastChild={!isAdmin && !SuperAdmin ? true : false}
                  icon={FiXCircle}
                  name="Not Submitted"
                  to="/not-submitted"
                />
                {(isAdmin || SuperAdmin) && (
                  <>
                    <Divider />
                    <DrawerItem
                      onClose={onClose}
                      isLastChild
                      isChild
                      icon={FiSettings}
                      name="Settings"
                      to="/submission-settings"
                    />
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion
            w="100%"
            onChange={(index) => setMarketingMenuIndex(index)}
            allowToggle
            defaultIndex={marketingMenuIndex}
          >
            <AccordionItem>
              <h2>
                <AccordionButton pt={"8%"} pb={"8%"} w="100%">
                  <Icon as={FaBullhorn} />
                  <Box pl="2" textAlign="left">
                    Marketing
                  </Box>
                  <AccordionIcon alignSelf="flex-end" />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                <Divider />
                <CreateRequest
                  isFirstChild
                  isChild
                  action="Create"
                  isDrawerButton={true}
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={FiClipboard}
                  name="Requests"
                  to="/marketing-requests"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={FiShoppingCart}
                  name="Swag"
                  to="/swag"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={BiSolidPlanet}
                  name="Matrix"
                  to="/marketing-matrix"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isExternal
                  isChild
                  icon={BsBank}
                  name="Vault"
                  to="https://drive.google.com/drive/folders/1-l2aNJDhCJyc89HnHbeDt3xKxn4hkb4Z"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isExternal
                  isChild
                  icon={FaTshirt}
                  name="SquadLocker"
                  to="https://teamlocker.squadlocker.com/#/lockers/future-home-loans"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  icon={GrCompliance}
                  name="SM Compliance"
                  to="/social-media-compliance"
                />
                <Divider />
                <DrawerItem
                  onClose={onClose}
                  isChild
                  isLastChild={!SuperAdmin && !MarketingAdmin ? true : false}
                  icon={FiImage}
                  name="FHL Logos"
                  to="/logos"
                />

                {(MarketingAdmin || SuperAdmin) && (
                  <>
                    <Divider />
                    <DrawerItem
                      onClose={onClose}
                      isChild
                      icon={FaNewspaper}
                      name="Newsletters"
                      to="/monthly-newsletters"
                    />
                    <Divider />
                    <DrawerItem
                      onClose={onClose}
                      isChild
                      icon={FaPoll}
                      name="Reports (beta)"
                      to="/marketing-reports"
                    />
                    <Divider />
                    <DrawerItem
                      onClose={onClose}
                      isChild
                      icon={FiDatabase}
                      name="Dashboard"
                      to="/marketing-dashboard"
                    />
                    <Divider />
                    <DrawerItem
                      onClose={onClose}
                      isChild
                      icon={FaStoreAlt}
                      name="Inventory"
                      to="/marketing-inventory"
                    />
                    <Divider />
                    <DrawerItem
                      onClose={onClose}
                      isLastChild
                      isChild
                      icon={FiSettings}
                      name="Settings"
                      to="/marketing-settings"
                    />
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Divider />
          <DrawerItem
            onClose={onClose}
            icon={FiClipboard}
            name="Approval Letter"
            to="/approval-letter"
          />
          {SuperAdmin == true ? (
            <>
              <Divider />
              <DrawerItem
                onClose={onClose}
                icon={FiSettings}
                name="Admin"
                to="/admin"
              />
              <Divider />
              <DrawerItem
                onClose={onClose}
                icon={FaPoll}
                name="Loan Reporting"
                to="/loan-reporting"
              />
            </>
          ) : null}
          {installable && (
            <>
              <Divider />
              <NavButton
                onOpen={handleInstallClick}
                name="Install App"
                label="Install App"
                icon={FiDownload}
              >
                Install App
              </NavButton>
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
