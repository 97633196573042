import { Button, Flex, Avatar, Heading, Badge, HStack, Box, Text } from "@chakra-ui/react"
import { db } from "../../../utils/init-firebase"
import { doc } from "firebase/firestore"
import { useState } from "react"
import AssigneesAvatarGroup from "../../../components/Marketing/AssigneesAvatarGroup"
import moment from "moment"
import { useAuth } from "../../../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import LoanTeamAvatars from "../../../components/Client/LoanTeamAvatars"
import StatusChangeDropdown from "../../../components/StatusChangeDropdown"

export default function EscalationSummary( { escalation, client, showLoanButton  } ){
    const [showDescr, setShowDescr] = useState(false)
    const { SuperAdmin, MarketingAdmin } = useAuth()
    const history = useHistory()
 
    return(
        <>
        <Flex flexDir={'row'} justify={'space-between'} mb='5' >
          <Flex flexDir={'row'} align={'center'}>
            <Avatar
                size={'md'}
                src={escalation['Owner']['photoURL']}
                alt={'Avatar Alt'}
            />
            <Heading alignSelf='center' fontSize={'sm'} textAlign='left' ml={'1'} fontFamily={'body'} whiteSpace='pre-wrap'>
                {escalation['Owner']['Name'] && escalation['Owner']['Name'].split(' ').join('\n')}
            </Heading>
          </Flex>
          {escalation['Assignees'] && <AssigneesAvatarGroup assignees={escalation['Assignees']} Status={escalation['Status']}/>   }

          {client && 
            <LoanTeamAvatars 
                LO={escalation.Settings && escalation.Settings['Notify LO'] && client['Loan Officer']}
                LOA={escalation.Settings && escalation.Settings['Notify LOA'] && client['LOA']}
                LC={escalation.Settings && escalation.Settings['Notify LC'] && client['LC']}
                CSC={escalation.Settings && escalation.Settings['Notify CSC'] && client['CSC']}
                TC={escalation.Settings && escalation.Settings['Notify TC'] && client['TC']}

            />}
      </Flex>
      <Heading textAlign={'left'} fontSize={'sm'} mb={5} fontFamily={'body'} textTransform='uppercase' colorScheme={'gray'}>
            <HStack>
        {/*       {escalation['topicID'] != '' && escalation['subtopicID'] != '' &&
                  <><Text>{escalation['topicID']} / {escalation['subtopicID']}</Text></>
              } */}
               {escalation['topicName'] != '' && escalation['subtopicName'] != '' &&
                  <><Text>{escalation['topicName']} / {escalation['subtopicName']}</Text></>
              }    
              <StatusChangeDropdown
                currentStatus={escalation.Status}
                statuses={['New', 'In Progress', 'Completed']}
                docRef={doc(db, 'Escalations', escalation.id)}
                fieldValue='Status'
              />
            </HStack>
          
            <Flex mt={1}  direction='row' justifyContent={'space-between'}>
              <Box 
                  color='gray.400'
                  letterSpacing='wide'
                  fontSize='12px'
                  textTransform='uppercase'
                  >

              {escalation['Date Created'] &&
              <>
                  {escalation['Date Created'].seconds ? 
                    moment(escalation['Date Created'].seconds*1000).format('MM/DD/YY h:mm a') 
                  :
                  
                  moment(escalation['Date Created'].toDate()).format('MM/DD/YY h:mm a') 
                  }
                  </>  
                }
  
              </Box>
            </Flex>
        </Heading> 

         {escalation['Flag For Review'] && !escalation['Coaching Completed'] && (SuperAdmin || MarketingAdmin) && 
             <Badge 
                colorScheme={'orange'}>
                NEEDS REVIEW
            </Badge>  
            }
            {escalation['Coaching Completed'] && (MarketingAdmin || SuperAdmin) && 
             <Badge 
                colorScheme={'green'}>
                COACHING COMPLETED
            </Badge>  
            }
         {escalation.answers && Object.entries(escalation.answers).map(([key, value]) => (
            <>
                <div key={key}>
                  <Text fontWeight={'bold'}>{key}</Text>
                  <Text>{value}</Text>
                </div>
            </>
         ))}

        {escalation.loanID && showLoanButton &&
          <Button 
              colorScheme="blue"
              onClick={() => history.push(`/loans/${escalation.loanID}`)} mt='2'>
              View Loan
          </Button>
        }     
        </>
    )   
}