import {
    Flex,
    Text,
    Icon,
    Menu,
    MenuButton,
    useColorModeValue,
    Divider,
    Center,
    Stack,
    useColorMode
} from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'

export default function NavItem({ icon, rightIcon, name, to, navSize, setupQueueCount, isExternal, isChild, isLastChild, isFirstChild, animated }) {
    const bgColor = useColorModeValue('#e8e8e8', '#171923')
    const location = useLocation()
    const { colorMode } = useColorMode()

    const isActive = location.pathname === to
    return (
        <Link to={isExternal ?  {pathname: to} : to}
                border={isActive ? '3px solid' : ''}
                style={{width: '100%'}}
                target={isExternal && "_blank"}
                className={animated && (colorMode == 'light' ? 'animated-box-light' : 'animated-box-dark')}
            >
            <Flex
                flexDir="column"
                w="100%"
                pl={navSize == "small" ? 0 : isChild ? '13%' : 3}
                alignItems={navSize == "small" ? "center" : "flex-start"}
                style={{alignItems: rightIcon? 'center' : null}} 
                borderRight={isActive ? '5px solid lightblue' : ''}
                backgroundColor={isActive ? bgColor : ''}
            >
                <Menu placement="right">
                
                        <MenuButton w="100%">
                            <Flex justifyContent={navSize == 'small' && 'center'}
>
                                {isChild && 
                                     <Stack direction='row' >
                                        {isLastChild ? 
                                            <Divider style={{height: '50%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        :
                                        isFirstChild ?
                                            <Divider style={{height: '150%', borderColor: 'gray', margin: 0, marginTop: '-15px'}}orientation='vertical' />
                                        :
                                            <Divider style={{height: '105%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        }
                                        <Divider style={{width: '10px', borderColor: 'gray', margin: 0, padding: 0, justifySelf: 'flex-start', alignSelf: 'center'}} orientation='horizontal' />                       
                                 </Stack>
                                }               
                                <Flex                
                                    p={'3%'}
                                    pt={navSize == 'small' ?  '15%' : isChild ? '5%' : '9%'}
                                    pb={navSize == 'small' ?  '15%' : isChild ? '5%' : '9%'}
                                    >
                                    
                                    {icon ? <Center><Icon as={icon} fontSize={isChild && 'sm'} /></Center> : null}
                                
                                    <Text fontSize={isChild && 'sm'} ml={rightIcon ? 0 : 2} display={navSize == "small" ? "none" : "flex"}>{name}</Text>
                                    {name == 'Setup Queue' &&  setupQueueCount > 0 ? <span className='notification'>{setupQueueCount}</span> : null}
                                    {rightIcon ? <Icon ml={5} as={rightIcon} fontSize="xl" /> : null}
                                </Flex>                            
                            </Flex>

                        </MenuButton>
            
                </Menu>
            </Flex>
        </Link>

    )
}