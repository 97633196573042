import { Flex, Select, Button, useToast } from "@chakra-ui/react"
import { useState } from "react"


export default function QuantitySelect(props){
    const { name, id, handleAddToCart, items, setDescription } = props

    const toast = useToast()
    const [amount, setAmount] = useState()

    const handleAddGuidelineToCart = () => {
        let showLimitToast = []
        if(items && amount > 0){
            for(const item of items){
                showLimitToast.push(handleAddToCart(item, amount, false, true))
            }
        }
        Promise.all(showLimitToast).then((values) => {
            if(values.includes(true)){
                toast({
                    title: `There is a limit of 20 items per request.`,
                    description: `For any requests over 20 items, please contact marketing.`,
                    status: 'warning',
                    position: 'top',
                    duration: 8000,
                    isClosable: true,
                })
            }
            else{
                toast({
                    title: `${amount} ${name} Added To Cart!`,
                    status: 'success',
                    position: 'top',
                    duration: 4000,
                    isClosable: true,
                  })
            }
        })

  
        setDescription && setDescription(`${name} x${amount}`)
        setAmount(0)

    }

    return(
        <Flex bottom='0' borderRadius='lg' key={id} justify={'flex-start'} flexDir={'row'}>
            <Select 
                value={amount}
                onChange={(e) => setAmount(e.target.value)} 
                maxW="75px"
                aria-label="Select quantity"
                mr={3} 
                id={`select-${id}`}>
                {Array(parseInt(11)).fill(null).map((value, index) => {
                    return(
                    <option key={index} value={index}>{index}</option>
                    )
                }  
                )}

            </Select>
            <Button disabled={!amount || amount < 1} onClick={() => handleAddGuidelineToCart()} colorScheme="blue" >
            Add to cart
            </Button>
        </Flex>
    )
}