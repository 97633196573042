import {
  IconButton,
  Flex,
  FormControl,
  Tag,
  useColorModeValue,
  Center,
  Spinner,
  Heading,
  Image,
  ButtonGroup,
  Button,
  Stack,
  Skeleton,
  Input,
} from "@chakra-ui/react";
import { db } from "../../utils/init-firebase";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { SimpleGrid, Box, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  FaArrowRight,
  FaChevronDown,
  FaChevronRight,
  FaList,
  FaRegFileImage,
  FaRegFilePdf,
  FaTable,
  FaTh,
} from "react-icons/fa";
import CreateRequest from "../../components/Marketing/CreateRequest";
import { FiArrowRight } from "react-icons/fi";
import { ArrowRightIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

export default function MarketingMatrixPage({
  initialSettings,
  selectedElementID,
}) {
  const [rawElements, setRawElements] = useState([]);
  const [allElements, setAllElements] = useState([]);
  const [filteredElements, setFilteredElements] = useState([]);
  const [view, setView] = useState("List");

  const [productOptions, setProductOptions] = useState();
  const [selectedProductType, setSelectedProductType] = useState();

  const [contentOptions, setContentOptions] = useState();
  const [selectedContentType, setSelectedContentType] = useState();

  const [nameFilter, setNameFilter] = useState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [contentTypes, setContentTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    const fetchContentTypes = async () => {
      const q = query(
        collection(db, "Marketing Content Types"),
        orderBy("timestamp")
      );
      const querySnapshot = await getDocs(q);
      const t = querySnapshot.docs.map((doc) => doc.data());
      setContentTypes(t);
    };

    const fetchProductTypes = async () => {
      const q2 = query(
        collection(db, "Marketing Product Types"),
        orderBy("timestamp")
      );
      const querySnapshot2 = await getDocs(q2);
      const c = querySnapshot2.docs.map((doc) => doc.data());
      setProductTypes(c);
    };

    fetchContentTypes();
    fetchProductTypes();
  }, []);

  function deepCopy(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      const arrCopy = [];
      for (let i = 0; i < obj.length; i++) {
        arrCopy[i] = deepCopy(obj[i]);
      }
      return arrCopy;
    }

    const objCopy = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        objCopy[key] = deepCopy(obj[key]);
      }
    }
    return objCopy;
  }

  function makeData() {
    let filteredElements = deepCopy(allElements)
      .filter(
        (element) =>
          (!selectedContentType ||
            element?.contentTypeIDs?.includes(selectedContentType.value)) &&
          (!selectedProductType ||
            element?.productTypeIDs?.includes(selectedProductType.value)) &&
          (!nameFilter ||
            element?.name?.toLowerCase().includes(nameFilter.toLowerCase()))
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    return { filteredElements };
  }

  function addOptionWithoutDuplicate(opt, arr) {
    let index = arr.findIndex((p) => p.value === opt.id);
    if (index === -1) {
      arr.push({ label: opt.name, value: opt.id });
    }
  }
  function makeOptions(filteredElements) {
    let productOpts = [];
    let contentOpts = [];
    filteredElements?.forEach((element, i) => {
      element?.productTypes?.forEach((type) =>
        addOptionWithoutDuplicate(type, productOpts)
      );
      element?.contentTypes?.forEach((type) =>
        addOptionWithoutDuplicate(type, contentOpts)
      );
    });
    return { productOpts, contentOpts };
  }

  useEffect(() => {
    let { filteredElements } = makeData();
    let { productOpts, contentOpts } = makeOptions(filteredElements);

    setFilteredElements(filteredElements);
    setContentOptions(contentOpts); // Assuming setProperties function is used to set channel options
    setProductOptions(productOpts); // Assuming setProducts function is used to set campaign options
  }, [allElements, selectedProductType, selectedContentType, nameFilter]);

  // Additional functions and useEffect hooks adapted for marketing context...
  // For brevity, assume similar structural adjustments and logic adaptations as above.

  const loadElements = async () => {
    const elementsCollection = collection(db, "matrixElements");

    try {
      const querySnapshot = await getDocs(
        query(elementsCollection, where("inactive", "==", false))
      );
      const elementDocs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRawElements(
        elementDocs.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
      );
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    loadElements();
  }, []);

  useEffect(() => {
    if (rawElements && productTypes && contentTypes) {
      // Create a deep copy to avoid mutating the original elements
      let newElements = rawElements.map((element) => ({
        ...element,
        // Use .map() with .find() for more efficient lookup and direct assignment
        contentTypes: element.contentTypeIDs?.map(
          (typeID) =>
            contentTypes.find((contentType) => contentType.id === typeID) ||
            null
        ),
        productTypes: element.productTypeIDs?.map(
          (typeID) =>
            productTypes.find((productType) => productType.id === typeID) ||
            null
        ),
      }));
      setAllElements(newElements);
    }
  }, [rawElements, productTypes, contentTypes]);

  return (
    <>
      <Flex mt="6" flexWrap={"wrap"} mb="1">
        <ButtonGroup variant="outline" spacing="0" mr="1">
          <Button
            leftIcon={<FaList />}
            colorScheme={view === "List" ? "blue" : "gray"}
            onClick={() => setView("List")}
            borderRightRadius={"none"}
          >
            List
          </Button>
          <Button
            leftIcon={<FaTh />}
            colorScheme={view === "Cards" ? "blue" : "gray"}
            onClick={() => setView("Cards")}
            borderLeftRadius={"none"}
          >
            Cards
          </Button>
        </ButtonGroup>
        <FormControl alignSelf="center" w="225px" mr="1">
          <Select
            placeholder={`Filter By Content`}
            value={selectedContentType}
            options={contentOptions}
            onChange={setSelectedContentType}
            isClearable
            chakraStyles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "sm",
              }),
            }}
          ></Select>
        </FormControl>
        <FormControl alignSelf="center" w="225px" mx="1">
          <Select
            placeholder={`Filter By Product`}
            value={selectedProductType}
            options={productOptions}
            onChange={setSelectedProductType}
            isClearable
            chakraStyles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "sm",
              }),
            }}
          ></Select>
        </FormControl>
        <FormControl alignSelf="center" w="225px" mx="1">
          <Input
            placeholder={`Filter By Name`}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            chakraStyles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "sm",
              }),
            }}
          ></Input>
        </FormControl>
      </Flex>
      {filteredElements && (
        <>
          {view === "List" ? (
            <SimpleGrid
              // minChildWidth="120px"
              columns={1}
              spacing="5px"
              mt="1"
            >
              {filteredElements?.map((element) => (
                <ElementCardNew element={element} key={`${element?.id}-new`} />
              ))}
            </SimpleGrid>
          ) : (
            <SimpleGrid
              // minChildWidth="120px"
              columns={{ base: 1, lg: 2, xl: 3, "2xl": 4 }}
              spacing="20px"
              mt="2"
            >
              {filteredElements?.map((element) => (
                <ElementCard element={element} key={element?.id} />
              ))}
            </SimpleGrid>
          )}
        </>
      )}
      {filteredElements && filteredElements.length == 0 && !loading && (
        <Text mt="2">No Marketing Elements Found</Text>
      )}
      {loading && (
        <Stack>
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
        </Stack>
      )}
    </>
  );
}

export const ElementCardNew = ({
  element: initialElement,
  elementID,
  hideRequestButton,
  showHeading,
}) => {
  const [element, setElement] = useState(initialElement);
  const [expanded, setExpanded] = useState(false);
  const responsiveGrey = useColorModeValue("gray.100", "gray.700");

  // Fetch element details if element is not provided but elementID is
  useEffect(() => {
    const fetchElement = async () => {
      if (!element && elementID) {
        const docRef = doc(db, "matrixElements", elementID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setElement(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchElement();
  }, [element, elementID]);
  if (!element) {
    return <Spinner />;
  }
  return (
    <>
      {showHeading && element && (
        <Heading mb="2" fontSize="sm">
          Customizing {element?.name}
        </Heading>
      )}

      <>
        <>
          <Box
            key={element.id} // Assuming each element has a unique id
            bg={responsiveGrey}
            boxShadow="sm"
            borderRadius="md"
            borderColor={responsiveGrey}
            p="0.5"
            mb="1.5" // Add margin between each list item
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                {element?.photoURL && (
                  <IconButton
                    icon={expanded ? <FaChevronDown /> : <FaChevronRight />}
                    variant="ghost"
                    size="sm"
                    onClick={() => setExpanded(!expanded)}
                  />
                )}
                <Text
                  ml="2"
                  fontSize="md"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  mr="2"
                >
                  {element.name}
                </Text>
                <Flex alignItems="center" flexWrap={"wrap"}>
                  {element?.productTypes &&
                    element.productTypes.map((type) => (
                      <Tag
                        key={type.name}
                        borderRadius="lg"
                        mr="1"
                        mb="0.5"
                        size="sm"
                        height="15px"
                        colorScheme={type?.color}
                        variant="solid"
                        flexShrink="0"
                      >
                        {type.name}
                      </Tag>
                    ))}
                  {element?.contentTypes &&
                    element.contentTypes.map((type) => (
                      <Tag
                        key={type.name}
                        borderRadius="lg"
                        mr="1"
                        mb="0.5"
                        size="sm"
                        height="15px"
                        colorScheme={type?.color}
                        variant="solid"
                        flexShrink="0"
                      >
                        {type.name}
                      </Tag>
                    ))}
                </Flex>
              </Flex>
              <Flex>
                {element.website && (
                  <IconButton
                    variant="ghost"
                    size="md"
                    mr="1"
                    pt="1"
                    onClick={() => window.open(element.website, "_blank")}
                    icon={<ExternalLinkIcon size="md" />}
                  />
                )}
                {element.pdfURL && (
                  <IconButton
                    variant="ghost"
                    size="sm"
                    mr="2"
                    pt="1"
                    onClick={() => window.open(element.pdfURL, "_blank")}
                    icon={<FaRegFilePdf size="md" />}
                  />
                )}
                {element.photoURL && (
                  <IconButton
                    variant="ghost"
                    size="sm"
                    pt="1"
                    mr="2"
                    onClick={() => window.open(element.photoURL, "_blank")}
                    icon={<FaRegFileImage size="md" />}
                  />
                )}
                {!hideRequestButton && (
                  <CreateRequest
                    useIconButton={true}
                    action="Create"
                    selectedElement={element}
                  />
                )}
              </Flex>
            </Flex>
            {expanded && element?.photoURL && (
              <Box w="100%" maxH="500px" overflowY="auto" borderRadius="md">
                <Image
                  src={element.photoURL}
                  alt="Descriptive text about the image"
                  fit="cover"
                  w="100%"
                  borderRadius="md"
                />
              </Box>
            )}
          </Box>
        </>
      </>
    </>
  );
};

export const ElementCard = ({
  element: initialElement,
  elementID,
  hideRequestButton,
  showHeading,
  dontShowPreview,
}) => {
  const [element, setElement] = useState(initialElement);
  const responsiveGrey = useColorModeValue("gray.200", "gray.600");

  // Fetch element details if element is not provided but elementID is
  useEffect(() => {
    const fetchElement = async () => {
      if (!element && elementID) {
        const docRef = doc(db, "matrixElements", elementID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setElement(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchElement();
  }, [element, elementID]);
  if (!element) {
    return <Spinner />;
  }
  return (
    <>
      {showHeading && element && (
        <Heading mb="2" fontSize="sm">
          Customizing {element?.name}
        </Heading>
      )}
      {!dontShowPreview && (
        <Box
          key={element.id} // Assuming each element has a unique id
          position="relative"
          height={"200px"}
          width="100%"
          maxW={"500px"}
          bg={responsiveGrey}
          border="1px solid"
          borderColor={responsiveGrey}
        >
          <Box
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            height="50px"
            p="2"
          >
            {/* Top Bar - You can customize this part as needed */}
            <Flex
              height="100%"
              flexWrap="nowrap" // Ensure items can wrap
              justifyContent="space-between" // Justify content for spacing between items
            >
              <Flex maxW="75%" overflowX={"auto"}>
                <Text
                  mr="1"
                  fontSize="sm"
                  whiteSpace={"nowrap"}
                  flexWrap={"nowrap"}
                >
                  {element.name}
                </Text>
                {element?.productTypes &&
                  element.productTypes.map((type) => (
                    <Tag
                      key={type.name} // Ensure you have a key for list items
                      borderRadius="lg"
                      mr="1"
                      size="sm"
                      height="20px"
                      colorScheme={type?.color}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                      variant="solid"
                      flexShrink={"0"}
                    >
                      {type.name}
                    </Tag>
                  ))}
                {element?.contentTypes &&
                  element.contentTypes.map((type) => (
                    <Tag
                      key={type.name} // Ensure you have a key for list items
                      borderRadius="lg"
                      mr="1"
                      size="sm"
                      height="20px"
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                      colorScheme={type?.color}
                      variant="solid"
                      flexShrink={"0"}
                    >
                      {type.name}
                    </Tag>
                  ))}
              </Flex>
              <Flex ml="auto" mt="-1">
                {" "}
                {/* This pushes the buttons to the right */}
                {element.website && (
                  <IconButton
                    variant="ghost"
                    size="md"
                    p="1"
                    onClick={() => window.open(element.website, "_blank")}
                    icon={<ExternalLinkIcon size="md" />}
                  />
                )}
                {element.pdfURL && (
                  <IconButton
                    variant="ghost"
                    size="sm"
                    p="1"
                    onClick={() => window.open(element.pdfURL, "_blank")}
                    icon={<FaRegFilePdf size="md" />}
                  />
                )}
                {element.photoURL && (
                  <IconButton
                    variant="ghost"
                    size="sm"
                    p="1"
                    onClick={() => window.open(element.photoURL, "_blank")}
                    icon={<FaRegFileImage size="md" />}
                  />
                )}
                {!hideRequestButton && (
                  <CreateRequest
                    useIconButton={true}
                    action="Create"
                    selectedElement={element}
                  />
                )}
              </Flex>
            </Flex>
          </Box>
          {element?.photoURL ? (
            <ImagePanner photoURL={element.photoURL} />
          ) : (
            <Center position="absolute" top="50%" left="30%">
              <Text textTransform={"uppercase"} fontSize={"sm"}>
                Preview Not Available
              </Text>
            </Center>
          )}
        </Box>
      )}
    </>
  );
};

const ImagePanner = ({ photoURL }) => {
  const [backgroundPosition, setBackgroundPosition] = useState("center");

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  return (
    <Box
      position="absolute"
      top="20%"
      right="0"
      bottom="0"
      left="0"
      backgroundImage={`url(${photoURL})`}
      backgroundSize="cover"
      backgroundPosition={backgroundPosition}
      onMouseMove={handleMouseMove}
      style={{ cursor: "move" }} // Optional: Changes cursor to indicate panning is possible
    />
  );
};
