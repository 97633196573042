import {
  Link,
  Flex,
  Avatar,
  Heading,
  Badge,
  HStack,
  Box,
  Spacer,
  Icon,
  Text,
  Collapse,
  Tag,
  TagRightIcon,
  TagLabel,
  Image,
  Center,
  Button,
} from "@chakra-ui/react";
import { db } from "../../utils/init-firebase";
import { doc } from "firebase/firestore";
import { useState } from "react";
import AssigneesAvatarGroup from "./AssigneesAvatarGroup";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";
import { IconButton } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import DesignRequestBadges from "./DesignRequest_Badges";
import EventRequestBadges from "./EventRequest_Badges";
import CreateRequest from "./CreateRequest";
import OpenHouseRequestBadges from "./OpenHouseRequest_Badges";
import AddToGoogleCalendar from "../AddToGoogleCalendar";
import { useEffect } from "react";
import { formatUsersForReactSelect } from "../../functions/globalFunctions";
import { SiGooglecalendar } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import FileList from "../FileList";
import Linkify from "react-linkify";
import { useAuth } from "../../contexts/AuthContext";
import StatusChangeDropdown from "../StatusChangeDropdown";
import { useHistory } from "react-router-dom";
import { ElementCard } from "../../pages/Marketing/MarketingMatrixPage";

export default function RequestSummary({
  request,
  expandDescription,
  showAllInfo = false,
}) {
  const [showDescr, setShowDescr] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);
  const numberOfItems =
    request["Cart"] &&
    request?.["Cart"].filter((item) => item.selected && item.selected > 0)
      .length;
  const { SuperAdmin, MarketingAdmin } = useAuth();
  const history = useHistory();
  function getAttendees(request) {
    let attends = [];
    {
      request["Assignees"]?.forEach((assignee) => {
        attends.push({
          label: assignee.Name,
          value: assignee.uid,
          email: assignee.Email,
          photoURL: assignee.photoURL,
        });
      });
    }

    {
      request["Owner"] &&
        attends.push({
          label: request["Owner"].Name,
          value: request["Owner"].uid,
          email: request["Owner"].Email,
          photoURL: request["Owner"].photoURL,
        });
    }
    return attends;
  }

  return (
    <>
      <Flex direction={"row"} mb="5">
        <Button
          onClick={() =>
            request?.["Owner"]?.["uid"] &&
            history.push(`/profile/${request["Owner"]["uid"]}`)
          }
          variant={"unstyled"}
        >
          <Avatar
            size={"md"}
            src={request["Owner"]["photoURL"]}
            alt={"Avatar Alt"}
          />
          <Heading
            onClick={() =>
              request?.["Owner"]?.["uid"] &&
              history.push(`/profile/${request["Owner"]["uid"]}`)
            }
            alignSelf="center"
            fontSize={"sm"}
            textAlign="left"
            float="right"
            _hover={{ textDecoration: "underline" }}
            mt="1"
            ml={"1"}
            fontFamily={"body"}
            whiteSpace="pre-wrap"
          >
            {request["Owner"]["Name"].split(" ").join("\n")}
          </Heading>
        </Button>

        <Spacer />
        {request["Request Details"]["Request Type"] == "Event" && (
          <>
            {request["Google Calendar Link"] ? (
              <Link
                mr={3}
                p={0}
                isExternal
                href={request["Google Calendar Link"]}
              >
                <Tag
                  verticalAlign={"middle"}
                  variant={"subtle"}
                  colorScheme="telegram"
                >
                  <TagLabel>Calendar Link</TagLabel>
                  <TagRightIcon as={FcGoogle}></TagRightIcon>
                </Tag>
              </Link>
            ) : (
              <AddToGoogleCalendar
                initialValues={{
                  Summary: request["Request Details"]["Name of Event"],
                  Location: request["Request Details"]["Address"],
                  Description: request["Request Details"]["Notes"],
                  "Event Date": request["Request Details"]["Event Date"],
                  "Event End Time": request["Request Details"]["Event Date"],
                }}
                docRef={doc(db, "Marketing Requests", request.id)}
                attends={getAttendees(request)}
              />
            )}
          </>
        )}
        {request["Assignees"] && (
          <AssigneesAvatarGroup
            assignees={request["Assignees"]}
            Status={request["Status"]}
          />
        )}
        {console.log(request?.["Request Details"]?.selectedElementID)}
        {showAllInfo && (
          <>
            <Spacer />
            <CreateRequest
              action="Submit"
              request={request}
              selectedElementID={
                request?.["Request Details"]?.selectedElementID
              }
              isSwagRequest={
                request["Request Details"]["Request Type"] == "Swag"
                  ? true
                  : false
              }
              cart={request["Cart"] ? request["Cart"] : null}
              status={request.Status}
              initialValues={request["Request Details"]}
              docID={request.id}
            />
          </>
        )}
      </Flex>
      <Heading
        textAlign={"left"}
        fontSize={"sm"}
        mb={5}
        fontFamily={"body"}
        textTransform="uppercase"
        colorScheme={"gray"}
      >
        <HStack>
          {request["Request Details"]["Request Type"] != "" && (
            <>
              <Text>{request["Request Details"]["Request Type"]} Request</Text>
            </>
          )}

          <StatusChangeDropdown
            currentStatus={request["Status"]}
            statuses={["New", "In Progress", "Completed"]}
            docRef={doc(db, "Marketing Requests", request.id)}
            fieldValue="Status"
          />
          {/* <Badge 
                mr={2} 
                colorScheme={
                    request['Status'] == 'Completed'? 'green' : 
                    request['Status'] == 'In Progress' ? 'orange' : 
                            'red' 
                          }>
                {request['Status']}
              </Badge>      */}
        </HStack>

        <Flex mt={1} direction="row" justifyContent={"space-between"}>
          <Box
            color="gray.400"
            letterSpacing="wide"
            fontSize="12px"
            textTransform="uppercase"
          >
            {request["Date Created"] && (
              <>
                {request["Date Created"].seconds
                  ? moment(request["Date Created"].seconds * 1000).format(
                      "MM/DD/YY h:mm a"
                    )
                  : moment(request["Date Created"].toDate()).format(
                      "MM/DD/YY h:mm a"
                    )}
              </>
            )}
          </Box>
          {request["Request Details"]["Due Date"] ? (
            <>
              <Flex flexDir={"row"}>
                <Icon color={"gray.400"} as={FiCalendar} />
                <Box
                  ml={1}
                  color="gray.400"
                  letterSpacing="wide"
                  fontSize="12px"
                  textTransform="uppercase"
                >
                  {request["Request Details"]["Due Date"].seconds
                    ? moment(
                        request["Request Details"]["Due Date"].seconds * 1000
                      ).format("MM/DD/YY")
                    : moment(
                        request["Request Details"]["Due Date"].toDate()
                      ).format("MM/DD/YY")}
                </Box>
              </Flex>
            </>
          ) : null}
        </Flex>
      </Heading>
      {!showAllInfo && request?.["Request Details"]?.selectedElementID && (
        <>
          <ElementCard
            elementID={request?.["Request Details"]?.selectedElementID}
            hideRequestButton
            showHeading
            dontShowPreview
          />
        </>
      )}
      {showAllInfo && request?.["Request Details"]?.selectedElementID && (
        <>
          <ElementCard
            elementID={request?.["Request Details"]?.selectedElementID}
            hideRequestButton
            showHeading
          />
        </>
      )}
      {request["Files"] && request["Files"].length > 0 && (
        <FileList files={request["Files"]} />
      )}
      {request["Request Details"]["Request Type"] &&
        request["Request Details"]["Request Type"] == "Marketing Spend" &&
        request?.["Request Details"]?.["Amount"] &&
        request?.["Request Details"]?.["Amount"] != "" && (
          <>
            <Text fontSize="sm">
              {request?.["Request Details"]?.["Spend Category"]}
            </Text>
            <Text fontSize="sm">
              ${request?.["Request Details"]?.["Amount"]}
            </Text>
          </>
        )}
      {request["Request Details"]["Request Type"] &&
        request["Request Details"]["Request Type"] == "Design" && (
          <DesignRequestBadges request={request} />
        )}
      {request["Request Details"]["Request Type"] &&
        request["Request Details"]["Request Type"] == "Event" && (
          <EventRequestBadges request={request} showAllInfo={showAllInfo} />
        )}
      {request["Request Details"]["Request Type"] &&
        request["Request Details"]["Request Type"] == "Open House" && (
          <OpenHouseRequestBadges request={request} showAllInfo={showAllInfo} />
        )}
      {request["Flag For Review"] &&
        !request["Coaching Completed"] &&
        (SuperAdmin || MarketingAdmin) && (
          <Badge colorScheme={"orange"}>NEEDS REVIEW</Badge>
        )}
      {request["Coaching Completed"] && (MarketingAdmin || SuperAdmin) && (
        <Badge colorScheme={"green"}>COACHING COMPLETED</Badge>
      )}
      {showAllInfo && (
        <>
          {request["Request Details"]["Shipping"] &&
            request["Request Details"]["Shipping"] != "" && (
              <>
                <Text mt={2} fontSize="sm" as="em">
                  Shipping Address
                </Text>
                <Linkify>
                  <Text mb={2} maxWidth="90%">
                    {request["Request Details"]["Shipping"]}
                  </Text>
                </Linkify>
              </>
            )}
          {request["Request Details"]["Request Relation"] &&
            request["Request Details"]["Request Relation"] != "" && (
              <>
                <Text mt={2} fontSize="sm" as="em">
                  Is this related to another request?
                </Text>
                <Text maxWidth="90%">
                  {request["Request Details"]["Request Relation"]}
                </Text>
              </>
            )}
        </>
      )}
      {console.log({ request })}
      {request["Request Details"]["Description"] != "" && (
        <>
          {expandDescription ? (
            <Linkify>
              <Text maxWidth="90%" color="gray.500" fontSize="sm">
                {request["Request Details"]["Description"]}
              </Text>
            </Linkify>
          ) : (
            <>
              <Flex mb={5} flexDir={"row"} justifyContent={"space-between"}>
                <Text wordBreak={"break-word"} color="gray.500" fontSize="sm">
                  <Collapse in={showDescr} startingHeight={20}>
                    <Linkify>
                      {request["Request Details"]["Description"]}
                    </Linkify>
                  </Collapse>
                </Text>
                <IconButton
                  variant={"unstyled"}
                  icon={showDescr ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => setShowDescr((value) => !value)}
                />
              </Flex>
            </>
          )}
        </>
      )}

      {expandDescription ? (
        <>
          {request["Cart"] &&
            request["Cart"].map((item, i) => {
              if (item.selected && item.selected > 0) {
                return (
                  <Flex
                    mt={2}
                    flexDir={"row"}
                    key={item?.id ? `${item.id}-${i}` : i}
                  >
                    <Image
                      mr={2}
                      src={item.photoURL ? item.photoURL : null}
                      boxSize="35px"
                    ></Image>
                    <Center>
                      <Text fontSize="sm">
                        {item.selected} {item.Name}
                      </Text>
                    </Center>
                  </Flex>
                );
              }
            })}
        </>
      ) : (
        <>
          {request?.["Cart"] && (
            <Flex mb={5} flexDir={"row"} justifyContent={"space-between"}>
              <Text wordBreak={"break-word"} color="gray.500" fontSize="sm">
                <Collapse in={showAllItems} startingHeight={50}>
                  {request["Cart"] &&
                    request["Cart"].map((item, i) => {
                      if (item.selected && item.selected > 0) {
                        return (
                          <Flex
                            mt={2}
                            flexDir={"row"}
                            key={item?.id ? `${item.id}-${i}` : i}
                          >
                            <Image
                              mr={2}
                              src={item.photoURL ? item.photoURL : null}
                              boxSize="35px"
                            ></Image>
                            <Center>
                              <Text fontSize="sm">
                                {item.selected} {item.Name}
                              </Text>
                            </Center>
                          </Flex>
                        );
                      }
                    })}
                </Collapse>
                {!showAllItems && numberOfItems > 1 && (
                  <Text
                    onClick={() => setShowAllItems((value) => !value)}
                    _hover={{ textDecoration: "underline", cursor: "pointer" }}
                    fontSize="xs"
                    colorScheme={"gray"}
                  >
                    {numberOfItems - 1} more items...
                  </Text>
                )}
              </Text>
              <IconButton
                variant={"unstyled"}
                icon={showAllItems ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={() => setShowAllItems((value) => !value)}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
}
