import { Flex, Input, Button, IconButton } from "@chakra-ui/react"
import { useState} from "react";
import {  doc, serverTimestamp, setDoc, collection, updateDoc, deleteDoc  } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import ColorPicker from "../ColorPIcker";
import { FiTrash2 } from "react-icons/fi";

export default function AddEditTagItem( { tagID, tagName, tagColor, action, key } ){
    const [newTagName, setNewTagName] = useState(tagName ? tagName : '')
    const [newTagColor, setNewTagColor] = useState(tagColor ? tagColor : 'green')
    const thisTagRef = tagID ? doc(db, 'Marketing Swag Tags', tagID) : null

    const addTag = async () => {
        const newTagRef = doc(collection(db, 'Marketing Swag Tags'))
        await setDoc(newTagRef, {
            timestamp: serverTimestamp(),
            name: newTagName,
            color: newTagColor,
            id: newTagRef.id
        })
    }

    const updateTag = async () => {
        await updateDoc(thisTagRef, {
            name: newTagName,
            color: newTagColor,
        })
    }

    const deleteTag = async () => {
        await deleteDoc(thisTagRef)
    }

    return(
        <>
        <Flex key={key} mb={2} flexDir='row'>
            <Input value={newTagName} mr={3} htmlSize={3} onChange={(e) => setNewTagName(e.target.value)} width='200px' />
            <ColorPicker color={newTagColor} mr={3} setColor={setNewTagColor} />
            <Button size={'md'} ml={3} textAlign={'center'} width='120px' onClick={action == 'Add' ? addTag : updateTag}>{action}</Button>
            {action == 'Update' && <IconButton ml={3} colorScheme={'red'} variant='outline' onClick={() => deleteTag(tagID)} icon={<FiTrash2/>} />}
        </Flex>
        </>
    )
}