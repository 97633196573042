import { TextareaControl, SelectControl, InputControl } from "formik-chakra-ui"
import {
    Flex,
    Center,
    Wrap,
    WrapItem,
    useColorModeValue,
    Text,
    ScaleFade
} from "@chakra-ui/react";
import { DatePickerField } from "../DatePicker";
import { useAuth } from "../../contexts/AuthContext";
export default function CustomRequestFields( { values } ){
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")
    const { marketingSettings } = useAuth()

    return(
        <ScaleFade in initialScale={0.8}>
        <>
        {values['Request Type'] == 'Design' && 
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={3}>
                <WrapItem width={{ base: '43%', md: '23%' }} h={20}>
                    <SelectControl 
                        name="Medium"
                        selectProps={{ placeholder: "Select a medium" }}
                    >
                        <option value="Digital">Digital</option>
                        <option value="Print">Print</option>
                    </SelectControl>
                </WrapItem>
                {values['Medium'] == 'Digital' &&
                    <WrapItem width={{ base: '43%', md: '23%' }} h={20}>
                      <SelectControl 
                          name="Platform"
                          selectProps={{ placeholder: "Select a platform" }}
                      >
                          <option value="Instagram Post">Instagram Post</option>
                          <option value="Facebook Post">Facebook Post</option>
                          <option value="Digital Flyer">Digital Flyer</option>
                          <option value="Other">Other (specify below)</option>
                      </SelectControl>
                  </WrapItem>
                }
              
                {values['Medium'] == 'Print' ? 
                <>

                <WrapItem width={{ base: '65%', md: '40%' }} h={20}>
                <Center><Text pt={1}>Dimensions&nbsp;</Text></Center>

                        <InputControl
                            name="Length"
                            inputProps={{ placeholder: 'Length'}}
                        >
                        </InputControl>
                        &nbsp;
                        <InputControl
                            name="Width"
                            inputProps={{ placeholder: 'Width'}}
                        >
                        </InputControl>
                </WrapItem></> : null }
            </Wrap>
  
        }
            <TextareaControl mb={2} name="Description" label="Describe your request" />
            <Flex w='300px'>
                <DatePickerField 
                    daysNotice={
                        values['Request Type'] == 'Design' ?
                            marketingSettings['Task Settings']['Minimum Notice Days']['Design'] ?
                            marketingSettings['Task Settings']['Minimum Notice Days']['Design'] :
                            2
                        :
                        values['Request Type'] == 'Custom' ?
                            marketingSettings['Task Settings']['Minimum Notice Days']['Custom'] ?
                            marketingSettings['Task Settings']['Minimum Notice Days']['Custom'] :
                            2
                        :
                        values['Request Type'] == 'Swag' ?
                            marketingSettings['Task Settings']['Minimum Notice Days']['Swag'] ?
                            marketingSettings['Task Settings']['Minimum Notice Days']['Swag'] :
                            2
                        :
                        2
                    } 
                    w='300px' 
                    name="Due Date" 
                    label="Due Date" 
                    className={datepickerClass}/>
            </Flex>
        </>
        </ScaleFade>
    )
}