import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Text,
  Wrap,
  WrapItem,
  Stack,
  Input,
  Button,
  useColorModeValue,
  Flex,
  Box,
  HStack,
  Tag,
  ButtonGroup,
} from "@chakra-ui/react";
import ClientCard from "../../components/Client/ClientCard";
import { useAuth } from "../../contexts/AuthContext";
import ReactDatePicker from "react-datepicker";
import {
  datepickerDark,
  datepickerLight,
} from "../../contexts/GlobalVariables";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "../../components/Table/ReactTable";
import { FaTable, FaTh } from "react-icons/fa";
import LoanTeamAvatars from "../../components/Client/LoanTeamAvatars";
import TimestampSeconds from "../../components/TimestampSeconds";

export default function LoanViewWrapper({
  maxWidth,
  clientList,
  getClients,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  loading,
  hideFilters,
}) {
  const datepickerClass = useColorModeValue(datepickerLight, datepickerDark);
  const [view, setView] = useState("Cards");
  const { currentUser, Roles, SuperAdmin, isAdmin } = useAuth();
  const { url, path } = useRouteMatch();
  const [flatClientList, setFlatClientList] = useState(null);
  const [filteredClientList, setFilteredClientList] = useState(null);
  const [loFilter, setLOFilter] = useState("");
  const [lcFilter, setLCFilter] = useState("");
  const [cscFilter, setCSCFilter] = useState("");
  const [tcFilter, setTCFilter] = useState("");
  const [borrowerFilter, setBorrowerFilter] = useState("");
  const history = useHistory();

  useEffect(() => {
    let v = localStorage.getItem("view");
    if (v) setView(v);
  }, []);

  useEffect(() => {
    localStorage.setItem("view", view);
  }, [view]);

  const handleRouteChange = (link) => {
    history.push(link);
  };
  const cols = [
    {
      Header: "",
      accessor: "id",
      footer: "",
      width: "65px",
      Cell: ({ cell: { value } }) => (
        <>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => handleRouteChange(`/completed/${value}`)}
          >
            View
          </Button>
        </>
      ),
    },
    {
      Header: "Loan Team",
      accessor: "Loan Officer uid",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <>
          <LoanTeamAvatars
            LO={
              row.original &&
              row.original["Loan Officer uid"] && {
                uid: row.original["Loan Officer uid"],
                Name: row.original["Loan Officer Name"],
                photoURL: row.original["Loan Officer photoURL"],
              }
            }
            LOA={
              row.original &&
              row.original[`LOA uid`] && {
                uid: row.original["LOA uid"],
                Name: row.original[`LOA Name`],
                photoURL: row.original[`LOA photoURL`],
              }
            }
            LC={
              row.original &&
              row.original[`LC uid`] && {
                uid: row.original["LC uid"],
                Name: row.original[`LC Name`],
                photoURL: row.original[`LC photoURL`],
              }
            }
            CSC={
              row.original &&
              row.original[`CSC uid`] && {
                uid: row.original["CSC uid"],
                Name: row.original[`CSC Name`],
                photoURL: row.original[`CSC photoURL`],
              }
            }
            TC={
              row.original &&
              row.original[`TC uid`] && {
                uid: row.original["TC uid"],
                Name: row.original[`TC Name`],
                photoURL: row.original[`TC photoURL`],
              }
            }
          />
        </>
      ),
    },
    {
      Header: "Status",
      accessor: "Status",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <>
          <HStack>
            <Tag
              colorScheme={
                value == "New"
                  ? "red"
                  : value == "In Progress"
                  ? "orange"
                  : "green"
              }
            >
              {value}
            </Tag>
          </HStack>
        </>
      ),
    },
    {
      Header: "Borrower",
      accessor: "Loan Details First Name",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <>
          <Text>
            {row.original["Loan Details First Name"] +
              " " +
              row.original["Loan Details Last Name"]}
          </Text>
        </>
      ),
    },
    {
      Header: "Loan Type",
      accessor: "Loan Details Purchase or Refi",
      footer: "",
    },
  ];
  if (!hideFilters) {
    cols.push(
      {
        Header: "Lender",
        accessor: "Loan Details Lender",
        footer: "",
      },
      {
        Header: "Date Added",
        accessor: "Date Added seconds",
        footer: "",
        Cell: ({ cell: { value } }) => <TimestampSeconds seconds={value} />,
      },
      {
        Header: "Date Completed",
        accessor: "Date Completed seconds",
        footer: "",
        Cell: ({ cell: { value } }) => <TimestampSeconds seconds={value} />,
      }
    );
  }

  const resetFilters = () => {
    setLOFilter("");
    setLCFilter("");
    setCSCFilter("");
    setTCFilter("");
    setBorrowerFilter("");
  };

  function merge(source, target = {}, ...parents) {
    for (let [key, value] of Object.entries(source)) {
      const path = (parents || []).concat(key);
      if (typeof value === "object") {
        merge(value, target, ...path);
        continue;
      }
      target[path.join(" ")] = value;
    }
    return target;
  }

  useEffect(() => {
    if (!loading) {
      const filteredClients = clientList.filter((client) => {
        return (
          client["Loan Officer"].Name.toLocaleLowerCase().includes(loFilter) &&
          client["LC"].Name.toLocaleLowerCase().includes(lcFilter) &&
          client["CSC"].Name.toLocaleLowerCase().includes(cscFilter) &&
          client["TC"].Name.toLocaleLowerCase().includes(tcFilter) &&
          client["Borrower"].toLocaleLowerCase().includes(borrowerFilter)
        );
      });
      setFilteredClientList(filteredClients);
      setFlatClientList(filteredClients.map((client) => merge(client)));
    }
  }, [clientList, loFilter, lcFilter, cscFilter, borrowerFilter, tcFilter]);

  return (
    <Flex flexDir={"row"} mt={"5"}>
      <Box>
        <Stack mt={1} direction={"column"} maxW={"1600px"}>
          <Stack direction={{ base: "column", md: "row" }}>
            <ButtonGroup variant="outline" spacing="0">
              <Button
                leftIcon={<FaTable />}
                colorScheme={view === "Table" ? "blue" : "gray"}
                onClick={() => setView("Table")}
                borderRightRadius={"none"}
              >
                Table
              </Button>
              <Button
                leftIcon={<FaTh />}
                colorScheme={view === "Cards" ? "blue" : "gray"}
                onClick={() => setView("Cards")}
                borderLeftRadius={"none"}
              >
                Cards
              </Button>
            </ButtonGroup>

            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className={datepickerClass}
            />
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className={datepickerClass}
            />
          </Stack>
          {!hideFilters && (
            <Stack direction={{ base: "column", md: "column", xl: "row" }}>
              <Stack direction={{ base: "column", md: "row" }}>
                <Input
                  type="search"
                  placeholder="Filter By LO"
                  value={loFilter}
                  onChange={(e) =>
                    setLOFilter(e.target.value.toLocaleLowerCase())
                  }
                ></Input>
                <Input
                  type="search"
                  placeholder="Filter By LC"
                  value={lcFilter}
                  onChange={(e) =>
                    setLCFilter(e.target.value.toLocaleLowerCase())
                  }
                ></Input>
              </Stack>
              <Stack direction={{ base: "column", md: "row" }}>
                <Input
                  type="search"
                  placeholder="Filter By CSC"
                  value={cscFilter}
                  onChange={(e) =>
                    setCSCFilter(e.target.value.toLocaleLowerCase())
                  }
                ></Input>
                <Input
                  type="search"
                  placeholder="Filter By TC"
                  value={tcFilter}
                  onChange={(e) =>
                    setTCFilter(e.target.value.toLocaleLowerCase())
                  }
                ></Input>
                <Input
                  type="search"
                  placeholder="Filter By Borrower"
                  value={borrowerFilter}
                  onChange={(e) =>
                    setBorrowerFilter(e.target.value.toLocaleLowerCase())
                  }
                ></Input>
                <Button
                  p={5}
                  pl={7}
                  pr={7}
                  onClick={resetFilters}
                  size="md"
                  colorScheme="blue"
                >
                  Reset
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
        {flatClientList && cols && view == "Table" && (
          <ReactTable maxWidth={maxWidth} data={flatClientList} cols={cols} />
        )}
        {view == "Cards" && (
          <Wrap mt={3}>
            {filteredClientList &&
              filteredClientList.map((client, index) => {
                return (
                  <>
                    <WrapItem id={`client-${index}`}>
                      <ClientCard
                        client={client}
                        loanOfficer={client["Loan Officer"]}
                        lenderConcierge={client["LC"]}
                        borrower={client["Borrower"]}
                        dateAdded={client["Date Added"]}
                        loanDetails={client["Loan Details"]}
                        docID={client.id}
                        status={client.Status}
                      />
                    </WrapItem>
                  </>
                );
              })}
            {filteredClientList &&
              filteredClientList.length == 0 &&
              !loading && <Text>No clients found</Text>}
          </Wrap>
        )}
      </Box>
    </Flex>
  );
}
