import { db } from '../../utils/init-firebase';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import {
    Heading,
    Avatar,
    Box,
    Text,
    Button,
    Badge,
    Flex,
    useColorModeValue,
    HStack,
    Tag,
    TagLabel,
    TagLeftIcon,
    useToast,
    Spacer,
    AvatarBadge,
    AvatarGroup,
    Tooltip,
    TagRightIcon,
    ScaleFade
  } from '@chakra-ui/react';
import { FiHome, FiRotateCcw, FiUser } from 'react-icons/fi';
import { BsPiggyBank } from 'react-icons/bs';
import moment from 'moment';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom'
import { FaQuestion, FaQuestionCircle } from 'react-icons/fa';
import { InfoIcon } from '@chakra-ui/icons';
import Timer from '../Timer';
import LoanTimestamps from './LoanTimestamps';
import NotesDrawer from '../NotesDrawer';
import CreateEscalationDrawer from '../../pages/Escalations/components/CreateEscalationDrawer';
import LOFeedback from '../LOFeedback/LOFeedbackDrawer';
import LOFeedbackDrawer from '../LOFeedback/LOFeedbackDrawer';
import { useEffect, useState } from 'react';

  export default function ClientCard({ client, loanOfficer, dateAdded, loanDetails, docID, lenderConcierge, status, dateClaimed, initialScale }) {
    const boxBG = useColorModeValue('white', 'gray.900')
    const flaggedBG = useColorModeValue('orange.200', 'orange.700')
    const notesForReviewRef = collection(db, 'Clients', docID, 'Notes For Review')
    const editLogCollectionRef = collection(db, 'Clients', docID, 'Edit Log')

    const [processPipelineSyncResult, setProcessPipelineSyncResult] = useState()

    const location = useLocation()
    const toast = useToast()
    const { currentUser, SuperAdmin, isAdmin, lenders } = useAuth()
    const dueInMinutes = 60;

    const history = useHistory();
    const [lenderRelational, setLenderRelational] = useState()

    // if there is a lenderID, find the lender name and set it in state. Fallback to Lender as a string
    useEffect(() => {
        const findLenderName = () => {
          if (client?.['Loan Details']?.['lenderID'] && lenders) {
            const foundLender = lenders.find(
              (lender) => lender.id === client['Loan Details']['lenderID']
            );
      
            if (foundLender) {
              return foundLender.name;
            }
          }
          if (client?.['Loan Details']?.['Target Lender'] && lenders) {
            const foundLender = lenders.find(
              (lender) => lender.id === client['Loan Details']['Target Lender']
            );
      
            if (foundLender) {
              return foundLender.name;
            }
          }
          if (client?.['Loan Details']?.['Other Lender'] && lenders) {
            const foundLender = lenders.find(
              (lender) => lender.id === client['Loan Details']['Other Lender']
            );
      
            if (foundLender) {
              return foundLender.name;
            }
          }
      
          return client?.['Loan Details']?.['Lender'];
        };
      
        setLenderRelational(findLenderName());
      }, [client, lenders]); // Include client and lenders in the dependency array
      
    useEffect( async () => {
      if(status === 'Completed'){
        // If the loan is old (created before 1/12/24) then this field will not exist, so query the edit log for the latest sync result
        if(!client['Process Pipeline Sync Result']){
          console.log('querying the edit log for sync result...')
          // Check for the latest error log, if any exist
          const q = query(editLogCollectionRef, 
                      orderBy('Process Pipeline Sync Timestamp', 'desc'),
                      limit(1))
          const querySnapshot = await getDocs(q)
          if(!querySnapshot.empty){
            querySnapshot.forEach((doc) => {
              console.log('Latest edit log for loan ID: ' + docID)
              console.log(doc.data())
              if(doc.data()?.['Process Pipeline Sync Result']){
                setProcessPipelineSyncResult(doc.data()['Process Pipeline Sync Result'])
              }
            })
          }
          else{
            console.log('No pipeline sync status found')
            setProcessPipelineSyncResult('Error')
          }
        }
        else {
          console.log('sync result found in client document...')
          setProcessPipelineSyncResult(client['Process Pipeline Sync Result'])
        }
        
      }

    }, [docID])
  
    const routeChange = (path) =>{ 
      history.push(`${status.replace(' ', '-').toLowerCase()}/${path}`)
      toast.closeAll()
    }

    return (
      <ScaleFade in initialScale={0.85}>
        <Box
          m={1}
          width={{base: '90vw', md :'300px'}}
          boxShadow={client['Coaching Completed'] && SuperAdmin ? '0px 0px 5px 5px #34eb83' :
                     client['Flag For Review'] && SuperAdmin ? '0px 0px 5px 5px #f5c971': 
                     client?.['Status'] === 'Completed' && 
                      // (!client?.['Process Pipeline Sync Result'] || client?.['Process Pipeline Sync Result'] === 'Error') && SuperAdmin 
                      processPipelineSyncResult === 'Error' ? '0px 0px 5px 5px #f44444a3': 

                     'lg'}
          rounded={'lg'}
          p={6}
          textAlign={'center'}>
              
        <Flex direction={'row'} mb='5' >
            <Tooltip placement='top' label={`${client['Loan Officer'].Name}`}>
              <Avatar
                size={'md'}
                src={loanOfficer.photoURL}
                alt={'Avatar Alt'}>
                <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='red.300'>LO</AvatarBadge>
              </Avatar>
            </Tooltip>

  {/*           <Heading alignSelf='center' fontSize={'sm'} textAlign='left' ml={'1'} fontFamily={'body'} whiteSpace='pre-wrap'>
                {loanOfficer.Name.split(' ').join('\n')}
            </Heading> */}
            <Spacer />
            <AvatarGroup size="sm" alignItems={'flex-end'}>
                {client['LOA'] && client['LOA'].uid &&
                <Tooltip placement='top' label={`${client['LOA'].Name}`}>
                    <Avatar src={client['LOA'].photoURL}>
                    <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='orange.400'>LOA</AvatarBadge>

                    </Avatar>
                </Tooltip>
                }
                <Tooltip placement='top' label={`${lenderConcierge.Name}`}>
                    <Avatar src={lenderConcierge.photoURL}>
                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='green.500'>LC</AvatarBadge>
                    </Avatar>
                </Tooltip>

                {client['CSC'] && client['CSC'].uid &&
                <Tooltip placement='top' label={`${client['CSC'].Name}`}>
                    <Avatar src={client['CSC'].photoURL}>
                      <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='blue.400'>CSC</AvatarBadge>
                    </Avatar>
                </Tooltip>
                }

                {client['TC'] && client['TC'].uid &&
                <Tooltip placement='top' label={`${client['TC'].Name}`}>
                    <Avatar src={client['TC'].photoURL}>
                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='purple.400'>TC</AvatarBadge>
                    </Avatar>
                </Tooltip>
                }
            </AvatarGroup>
          </Flex>
          <Flex mb={2} align={'flex-start'}>
          <Badge 
                mr={2} 
                colorScheme={
                    status == 'Completed'? 'green' : 
                    status == 'In Progress' ? 'orange' : 
                    status == 'Not Submitted' ? 'purple' : 
                    status == 'TBD' ? 'blue' :
                                'yellow'
                            }>
                {status}
            </Badge>     
            {client['Flag For Review'] && !client['Coaching Completed'] && SuperAdmin && 
             <Badge 
                mr={2} 
                colorScheme={'orange'}>
                NEEDS REVIEW
            </Badge>  
            }
            {client['Coaching Completed'] && SuperAdmin && 
             <Badge 
                mr={2} 
                colorScheme={'green'}>
                COACHING COMPLETED
            </Badge>  
            }

            {dateAdded && status=='In Progress'?
                <>
{/*                {dateAdded.seconds && <CountdownTimer end={dateAdded.seconds*1000 + (dueInMinutes*60000)}/> }
 */}               {dateAdded.seconds && <Timer start={dateAdded.seconds} warningInMinutes={60}/> }
                </>
              : null}

          </Flex>
          {client['Reason Parked'] && client['Reason Parked'] != '' &&
            <Text textTransform='uppercase' fontWeight='bold' fontSize={'small'} textAlign={'left'} overflowWrap={'break-word'}>
              Reason Parked: <Text as={'span'} color='gray'>{client['Reason Parked']}</Text>
              </Text>
          }
            {client['Reason Not Submitted'] && client['Reason Not Submitted'] != '' &&
            <Text textTransform='uppercase' fontWeight='bold' fontSize={'small'} textAlign={'left'} overflowWrap={'break-word'}>
              Reason Not Submitted: <Text as={'span'} color='gray'>{client['Reason Not Submitted']}</Text>
              </Text>
          }
          <LoanTimestamps client={client} />
          <Heading textAlign={'left'} fontSize={'sm'} mb={3} fontFamily={'body'} textTransform='uppercase' colorScheme={'gray'}>
                <HStack>
                  {loanDetails['Purchase or Refi'] != 'Custom' &&
                      <><FiUser/><Text>{loanDetails['First Name']} {loanDetails['Last Name']}</Text></>
                  }
                </HStack>
                <Flex direction='row' justifyContent={'space-between'}>
                  <Box 
                      color='gray.400'
                      letterSpacing='wide'
                      fontSize='12px'
                      mt={1}
                      textTransform='uppercase'
                      >
              
                  {dateAdded && 
                    <>
                    {dateAdded.seconds ?
                      moment(dateAdded.seconds*1000).format('MM/DD/YY h:mm a') 
                    :
                      <Text>{dateAdded}</Text>
                    }
                    </>
                  }
      
                  </Box>
                  <Box 
                      color='green.400'
                      letterSpacing='wide'
                      fontSize='12px'
                      mt={1}
                      textTransform='uppercase'
                      >
                  {client['Status'] == 'Completed' && client['Date Completed'] &&
                        <>
                        {client['Date Completed'].seconds ?
                          moment(client['Date Completed'].seconds*1000).format('MM/DD/YY h:mm a')
                        :
                          <Text>{client['Date Completed']}</Text>
                        }
                        </>
                      
                        
                  }  
                  </Box>
                </Flex>
            </Heading>
           
          <HStack mb={5}>
            <Tag size='md' variant='outline' colorScheme='green' textTransform='uppercase' fontWeight='bold'>
                  {loanDetails['Purchase or Refi'] == 'Purchase' && 
                    <TagLeftIcon as={FiHome} />
                  }
                  {loanDetails['Purchase or Refi'] == 'Refi' &&
                    <TagLeftIcon as={FiRotateCcw} /> 
                  }
                  {loanDetails['Purchase or Refi'] == 'Custom' &&
                    <TagLeftIcon as={FaQuestion} /> 
                  }
                  {loanDetails['Purchase or Refi'] == 'TBD' &&
                    <TagLeftIcon as={FaQuestionCircle} /> 
                  }
                <TagLabel fontSize="14px">{loanDetails['Purchase or Refi']}</TagLabel>
            </Tag> 
            {loanDetails['Purchase or Refi'] == 'Custom' &&
              <Tooltip fontSize={'sm'} placement='top' label={loanDetails['Description']}>
              <Tag size='md' variant='ghost' textTransform='uppercase' fontWeight='400'>
                  <TagLabel pr='0' fontSize="12px">Description</TagLabel>
                
                  <TagRightIcon ml='0' mt='-4' fontSize='14px' as={InfoIcon} />               
                </Tag> 
                </Tooltip>
            }
            {lenderRelational ?
                <Tag size='md' variant='outline' textTransform='uppercase' fontWeight='bold'>
                  <TagLeftIcon as={BsPiggyBank} />
                  <TagLabel fontSize="14px">{lenderRelational}</TagLabel>
                </Tag> 
            :
              <>
                {loanDetails['Target Lender'] == 'Other' &&
                <Tag size='md' variant='outline' textTransform='uppercase' fontWeight='bold'>
                  <TagLeftIcon as={BsPiggyBank} />
                  <TagLabel fontSize="14px">{loanDetails['Other Lender']}</TagLabel>
                </Tag> 
                }
                {loanDetails['Target Lender'] != 'Other' && loanDetails['Target Lender'] != '' &&
                  <Tag size='md' variant='outline' textTransform='uppercase' fontWeight='bold'>
                    <TagLeftIcon as={BsPiggyBank} />
                    <TagLabel fontSize="14px">{loanDetails['Target Lender']}</TagLabel>
                  </Tag> 
                }  
              </>
            }
       
           
        </HStack>
        <Flex justify={'space-around'}>
          {client['Status'] != 'New' &&
            <Button
                onClick={() => routeChange(docID)}
                fontSize={'sm'}
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                boxShadow={
                  '0px 1px 5px -5px rgb(66 153 225 / 48%), 0 7px 7px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                  bg: 'blue.500',
                }}
                _focus={{
                  bg: 'blue.500',
                }}>
                View Details
              </Button>
              }
              {SuperAdmin && client['Has Notes For Review'] &&
                <NotesDrawer 
                  notesRef={notesForReviewRef}
                  parentID={docID}
                  title='Notes For Review'
                />
              }
              {client &&
              <CreateEscalationDrawer loanID={client.id} loanNumber={client['Loan Details']['Loan #']}/>}
              {/* <LOFeedbackDrawer client={client} /> */}
          </Flex>
        </Box>
      </ScaleFade>
    );
  }