import { Flex, Select, useColorModeValue, Text, useToast } from "@chakra-ui/react"
import { useEffect } from "react"


export default function QuantitySelectInCart(props){
    const { product, inventory, rootProps, Quantity, handleSetSelectedItem, handleUpdateCartInTask, amountInPendingCarts, totalAvailable, amountInCart, disabled, setDisableCreate, handleAddToCart, index, cart, taskID } = props
    const { Name, photoURL, Cost, id } = product

    const toast = useToast()
    
    useEffect(() => {
        if(cart && cart[index] && cart[index].id && cart[index].selected && Quantity > -1){
            if((parseInt(Quantity) == 0) || (parseInt(Quantity) < parseInt(cart[index].selected))){
                setDisableCreate(index, true)
            }
            else setDisableCreate(index, false)
        }
    }, [cart[index].selected, Quantity])

    return(
        <>
            <Flex  _hover={Quantity < 1 &&{ cursor: 'not-allowed'}} mr='3' borderRadius='lg' key={id} justify={'flex-start'} flexDir={'column'}>
            {cart && (parseInt(Quantity) < parseInt(cart[index].selected)) ? 
                <Text className="error" color='red.400'>{Math.min(Quantity, 20)} available now</Text>
                :
                null
            }
        {/*  <Text>{totalAvailable} (in stock) - {amountInPendingCarts} (pending carts) - {cart[index].selected} (in cart)</Text>
            <Text>{Quantity}</Text> */}
            <Select 
                borderColor={cart && (parseInt(Quantity) < parseInt(cart[index].selected)) && 'red.400'}
                value={cart[index].selected}
                onChange={taskID && handleUpdateCartInTask ? 
                            (e) => handleUpdateCartInTask(id, index, e.target.value)
                            :
                            (e) => handleSetSelectedItem(id, index, e.target.value)} 
                maxW="80px"
                aria-label="Select quantity"
                focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
                mr={3} 
                id={`cart-select-${index}`}
                disabled={disabled || Quantity < 1}>
                {Array(parseInt(Quantity < 1 ? 0 : Quantity < 20 ? Quantity + 1 : 21)).fill(null).map((value, index) => {
                    return(
                    <option key={index} value={index}>{index}</option>
                    )
                }
                
                )}
            </Select>
        </Flex>
    </>
    )
}