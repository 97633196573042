import { useState, useEffect } from 'react';
import CountUp from 'react-countup'
import { Text, Icon, HStack, Badge } from "@chakra-ui/react";
import { FiArrowRight, FiClock } from "react-icons/fi";

export default function TimeDifference( { start, end, description, includeArrow, warningTimeMinutes }){
    var warning = false
    var SECONDS = end.seconds - start.seconds
    if(SECONDS > warningTimeMinutes * 60){
        warning = true
    }
    var time = ''
    if(SECONDS < 3600){
        time = new Date(SECONDS * 1000).toISOString().substring(14, 19)
    }
    else{
        time = new Date(SECONDS * 1000).toISOString().substr(11, 8);
    } 

    return(
        <>
            <Badge variant='subtle' mr='1' colorScheme={warning ? 'red' : 'green'}>
                <HStack>
                    <FiClock/>{includeArrow && <FiArrowRight/>}<span>{description} {time}</span>
                </HStack>
            </Badge>
        </>

    )
}