import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { collection, query, onSnapshot, updateDoc, orderBy, where, getDocs, serverTimestamp } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import {
    Stack,
    Flex,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    useColorModeValue,
    Box
  } from '@chakra-ui/react';
import RequestCard from "../../components/Marketing/RequestCard";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { datepickerDark, datepickerLight } from "../../contexts/GlobalVariables";
import "react-datepicker/dist/react-datepicker.css";


export default function MarketingRequestList() {
  const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)
  const { currentUser, SuperAdmin, MarketingAdmin, newRequests, inProgressRequests } = useAuth()
  const { url, path } = useRouteMatch()
  const [newRequestsLO, setNewRequestsLO] = useState(null)
  const [inProgressRequestsLO, setInProgressRequestsLO] = useState(null)
  const [completedRequests, setCompletedRequests] = useState(null)

  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)))
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)))

  const [loading, setLoading] = useState(true)

  const unsubscribeRef = React.useRef()
  const unsubscribeCompletedRef = React.useRef()

  const getCompletedRequests = async () => {
      unsubscribeCompletedRef.current && unsubscribeCompletedRef.current()

      let qCompleted = null
      if(SuperAdmin || MarketingAdmin){
        qCompleted = query(collection(db, "Marketing Requests"), 
                      orderBy('Date Completed', 'desc'), 
                      where(`Status`, '==', 'Completed'), 
                      where('Date Completed', '>=', startDate), 
                      where('Date Completed', '<=', endDate));
      }
      else{
        qCompleted = query(collection(db, "Marketing Requests"), 
                      orderBy('Date Completed', 'desc'), 
                      where(`Status`, '==', 'Completed'), 
                      where(`Owner.uid`, '==', currentUser.uid), 
                      where('Date Completed', '>=', startDate), 
                      where('Date Completed', '<=', endDate));
      }
      
      const unsubCompleted = onSnapshot(qCompleted, (querySnapshot) => {
        let completedRequests = []

        querySnapshot.forEach((doc) => {
            completedRequests.push(doc.data())
        });
        setCompletedRequests(completedRequests)
        setLoading(false)

      });
      unsubscribeCompletedRef.current = unsubCompleted

  }

  const getRequests = async () => {
    unsubscribeRef.current && unsubscribeRef.current()
    let q = null
    if(SuperAdmin || MarketingAdmin){
      q = query(collection(db, "Marketing Requests"), 
            orderBy('Status'),
            orderBy('Date Created', 'desc'),
            where(`Status`, '!=', 'Completed'), 
          )
    } 
    else{
      q = query(collection(db, "Marketing Requests"), 
            orderBy('Status'),
            orderBy('Date Created', 'desc'), 
            where(`Status`, '!=', 'Completed'), 
            where(`Owner.uid`, '==', currentUser.uid),
          )
    }
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let newRequestsLO = []
      let inProgressRequests = []

      querySnapshot.forEach((doc) => {
          let request = {}
          request = doc.data()
          if(request['Status'] == 'New'){
            newRequestsLO.push(request)
          }
          else if(request['Status'] == 'In Progress'){
            inProgressRequests.push(request)
          }
      });
      setNewRequestsLO(newRequestsLO)
      setInProgressRequestsLO(inProgressRequests)
      setLoading(false)
    });
    unsubscribeRef.current = unsubscribe
}

  useEffect( async () => {
    if(!MarketingAdmin){
      await getRequests()
    }
      await getCompletedRequests()
  }, [])

  // Unsubscribe from changes when component unmounts
  useEffect(() => {
    return () => {
        setNewRequestsLO(null)
        setInProgressRequestsLO(null)
        setCompletedRequests(null)
        unsubscribeRef.current && unsubscribeRef.current()
        unsubscribeCompletedRef.current && unsubscribeCompletedRef.current()  
    };
  }, []);

  useEffect( async () => {
    if(!MarketingAdmin){
      await getRequests()
    }
    await getCompletedRequests()
    return () => {
      setCompletedRequests(null)
      unsubscribeCompletedRef.current()       
    }
}, [startDate, endDate])

  useEffect(() => {
    async function updateNotifications(){
      try{
        const notificationsRef = collection(db, "users", currentUser.uid, 'Notifications');
        const q = query(notificationsRef, where("route", "==", path));
        const querySnapshot = await getDocs(q);
          querySnapshot.forEach( async (doc) => {
            await updateDoc(doc.ref, {
                read: serverTimestamp()
            })
          });
      }
        catch(err){
          console.log(err)
        }
    }

    updateNotifications()
  }, [])

  return (
    <>
      <Flex w='82vw' flexDir={{base: 'column', lg: 'row'}} justify='space-between'>
        <Breadcrumb mb={2} fontSize='xs'>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to='/'>Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to='/marketing-requests'>Marketing Requests</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex>
              <Box w='150px'>
              <ReactDatePicker   
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className={datepickerClass}
                />
              </Box>  &nbsp;
              <Box w='150px'>

                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className={datepickerClass}
                />
              </Box>  

          </Flex>
      </Flex>
      <Stack direction={{base: 'column', xl: 'row'}}>
          <Stack direction="column" width={{base: '100%', xl: '27vw'}} >
           {MarketingAdmin ? 
                <>
                {newRequests && newRequests.map((request, index) => {
                    return (
                        <>
                            <RequestCard 
                                request={request}
                            />
                        </>
                    )
                }) }
                </>
                :
                <>
                {newRequestsLO && newRequestsLO.map((request, index) => {
                    return (
                        <>
                            <RequestCard 
                                request={request}
                            />
                        </>
                    )
                }) }
                </>
              }
            
          </Stack>
          <Stack direction="column"  width={{base: '100%', xl: '27vw'}} >
            {MarketingAdmin ? 
                <>
                {inProgressRequests && inProgressRequests.map((request, index) => {
                    return (
                        <>
                            <RequestCard 
                                request={request}
                            />
                        </>
                    )
                }) }
                </>
                :
                <>
                {inProgressRequestsLO && inProgressRequestsLO.map((request, index) => {
                    return (
                        <>
                            <RequestCard 
                                request={request}
                            />
                        </>
                    )
                }) }
                </>
              }
          </Stack>
          <Stack dir="column" width={{base: '100%', xl: '27vw'}} >

              {completedRequests && completedRequests.map((request, index) => {
                  return (
                      <>
                          <RequestCard 
                              request={request}
                          />

                      </>
                  )
              }) }
          </Stack>
      </Stack>
    </>
  );
}
