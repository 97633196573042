import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Center,
    IconButton,
    Divider
  } from '@chakra-ui/react';

import { FiEdit3 } from 'react-icons/fi';
import AddEditCategory from './AddEditCategory';

export default function ManageCategories( { cats, showButtonText } ){
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
      <>
        <Center>
          {showButtonText ? 
            <Button mr={2} size='sm' variant={'outline'} colorScheme={'teal'} icon={<FiEdit3/>} onClick={onOpen}> Manage Categories</Button>
            :
            <IconButton size='sm' variant={'outline'} colorScheme={'teal'} icon={<FiEdit3/>} onClick={onOpen}></IconButton>

        }
        </Center>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Manage Categories</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {cats && cats.map((cat, i) => {
                    return(
                        <AddEditCategory key={cat.id} catID={cat.id} catName={cat.name} catColor={cat.color} action='Update'/>
                    )
                })}
                <Divider mb={5} mt={5} />
                <AddEditCategory key='add' catID={null} action='Add' />
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}