// useQuestions.js
import { useState, useEffect } from 'react';
import { collection, addDoc, deleteDoc, doc, getDocs, onSnapshot, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../utils/init-firebase';

const useQuestions = () => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'questions'), (snapshot) => {
      const updatedQuestions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuestions(updatedQuestions);
    });

    return () => unsubscribe();
  }, []);

  const createQuestion = async (question) => {
    try {
      const newDocRef = doc(collection(db, 'questions'))
      await setDoc(newDocRef, {
          ...question, 
          deleted: false, 
          id: newDocRef.id
        });
    } catch (error) {
      console.error('Error creating question:', error);
    }
  };

  const updateQuestion = async (questionId, question) => {
    try {
      const questionRef = doc(db, 'questions', questionId);
      await updateDoc(questionRef, question);
    } catch (error) {
      console.error('Error updating question:', error);
    }
  };

  const deleteQuestion = async (questionId) => {
    try {
      const questionRef = doc(db, 'questions', questionId);
      await deleteDoc(questionRef);
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  const markQuestionDeleted = async (questionId) => {
    try {
      const questionRef = doc(db, 'questions', questionId);
      await updateDoc(questionRef, {deleted: true});
    } catch (error) {
      console.error('Error updating question:', error);
    }
  };

  const restoreQuestion = async (questionId) => {
    try {
      const questionRef = doc(db, 'questions', questionId);
      await updateDoc(questionRef, {deleted: false});
    } catch (error) {
      console.error('Error updating question:', error);
    }
  };

  return {
    questions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    markQuestionDeleted,
    restoreQuestion
  };
};

export default useQuestions;
