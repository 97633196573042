import { useEffect, useState, useRef } from "react";
import { query, onSnapshot, orderBy, where, limit, collectionGroup, parent, getDoc } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import "react-datepicker/dist/react-datepicker.css";
import TableCardWrapper from "./TableCardWrapper";
import { StarRatingButtonGroup } from "../../components/LOFeedback/FeedbackBody";
import { Button, Text } from "@chakra-ui/react";
import LoanTeamAvatars from "../../components/Client/LoanTeamAvatars";
import TimestampSeconds from "../../components/TimestampSeconds";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function RecentFeedbackPage() {
  const [feedbackList, setFeedbackList] = useState(null)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { SuperAdmin } = useAuth()
  const today = new Date();
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 14);
  const [startDate, setStartDate] = useState(twoWeeksAgo)
  const [endDate, setEndDate] = useState(today)
  let unsubscribeRef = useRef()

  const allCols = [
      {
          Header: 'Loan Officer',
          accessor: 'Loan Officer uid',
          footer: '',
          Cell: ({ cell: { value }, row }) => (
              <>  
                  <LoanTeamAvatars 
                      LO={row.original && row.original['Loan Officer uid'] && {uid: row.original['Loan Officer uid'], Name: row.original['Loan Officer Name'], photoURL: row.original['Loan Officer photoURL']}} 
                      // LOA={row.original && row.original[`LOA uid`] && {uid: row.original['LOA uid'], Name: row.original[`LOA Name`], photoURL: row.original[`LOA photoURL`]}} 
                      // LC={row.original && row.original[`LC uid`] && {uid: row.original['LC uid'], Name: row.original[`LC Name`], photoURL: row.original[`LC photoURL`]}} 
                      // CSC={row.original && row.original[`CSC uid`] && {uid: row.original['CSC uid'], Name: row.original[`CSC Name`], photoURL: row.original[`CSC photoURL`]}} 
                      // TC={row.original && row.original[`TC uid`] && {uid: row.original['TC uid'], Name: row.original[`TC Name`], photoURL: row.original[`TC photoURL`]}} 
                  /> 
              </>
          ),
      },
      {
          Header: 'Rating',
          accessor: 'lenderRating',
          calculateAvg: true,
          filterOptions: {
            type: 'slider',
            options: [
              {label: 1, value: 1},
              {label: 2, value: 2},
              {label: 3, value: 3},
              {label: 4, value: 4},
              {label: 5, value: 5}
            ],
            defaultValue: [1,5]
          },
          footer: '',
          Cell: ({ cell: { value } }) => (
              <StarRatingButtonGroup 
                  disabled
                  rating={parseInt(value)}
                  setRating={null}
              />
          ),
      },
      {
          Header: 'Comments',
          accessor: 'lenderComments',
          width: '350px',
          footer: '',
          Cell: ({ cell: { value } }) => (
              <Text fontSize='sm'>{value}</Text>
          ),
      },
      {
          Header: 'Purpose',
          accessor: 'Loan Details Purchase or Refi',
          footer: ''
      },
      {
          Header: 'Program',
          accessor: 'Loan Details Product',
          filterOptions: {
            type: 'dropdown',
            makeOptionsFromData: true
          },
          footer: ''
      },
      {
          Header: 'Lender',
          accessor: 'Loan Details Lender',
          filterOptions: {
            type: 'dropdown',
            makeOptionsFromData: true
          },
          footer: ''
      },
      {
          Header: 'Property Type',
          accessor: 'Loan Details Property Type',
          filterOptions: {
            type: 'dropdown',
            makeOptionsFromData: true
          },
          footer: ''
      },
      {
          Header: 'Rate',
          accessor: 'Loan Details Rate',
          footer: '',
          Cell: ({ cell: { value } }) => (
              <>  
                  <Text>{value && `${value}%`}</Text>
              </>
          ),
      },
      {
          Header: 'Date',
          accessor: 'timestamp seconds',
          footer: '',
          Cell: ({ cell: { value } }) => (
              <TimestampSeconds seconds={value} dateFormat='MM/DD/YY' />
          ),
      },

  ]
  const superAdminCols = [
      {
          Header: '',
          accessor: 'id',
          width: '100px',
          footer: '',
          Cell: ({ cell: { value }, row }) => (
              <Button size='sm' onClick={() => history.push(`/${row?.original?.Status?.toLowerCase() || 'loans'}/${row?.original?.clientID}`)}>
                  View
              </Button>
          ),
      }
  ]
  const cols = SuperAdmin ? [...superAdminCols, ...allCols] : allCols


  const getFeedback = async () => {
    setLoading(true)
    unsubscribeRef.current && unsubscribeRef.current()
    let q = query(collectionGroup(db, "Feedback"), 
                    where('timestamp', '>=', startDate), 
                    where('timestamp', '<=', endDate), 
                    orderBy('timestamp', 'desc'), 
                    limit(200));
  
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let feedback = [];
    
      // Mapping all promises for parent document fetching
      const parentDocsPromises = querySnapshot.docs.map(async (doc) => {
        const parentRef = doc.ref.parent.parent
        const parentDoc = await getDoc(parentRef);
        return { parentData: parentDoc.data(), feedbackData: doc.data() };
      });
    
      // Waiting for all promises to resolve using Promise.all()
      const results = await Promise.all(parentDocsPromises);
    
      // Add resolved data to feedback array
      feedback = results.map(({ parentData, feedbackData }) => ({
        ...parentData,
        ...feedbackData,
      }));
    
      // Update state and stop loading indicator
      setFeedbackList(feedback);
      setLoading(false);
  
      unsubscribeRef.current = unsubscribe;
    });

  }

  useEffect(() => {
    return () => {
      unsubscribeRef.current && unsubscribeRef.current()
    }
  }, [])

  useEffect(async() => {
    if(startDate && endDate){
      await getFeedback()
    }
  }, [startDate, endDate])
  return (
    <>
      {feedbackList &&
        <TableCardWrapper
            cols={cols} 
            clientList={feedbackList} 
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            loading={loading}
          />
      }
    </>
  );
}