import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  FormControl,
  Input,
  IconButton,
  useToast,
  Text,
  Box,
  Heading,
  Textarea,
  Flex,
  Select,
} from "@chakra-ui/react";
import { FiTrash, FiTrash2, FiX } from "react-icons/fi";
import {
  getDoc,
  setDoc,
  updateDoc,
  DocumentReference,
} from "firebase/firestore";
import { TextareaControl } from "formik-chakra-ui";
import DividerWithText from "../DividerWithText";

/*
    This component will allow the user to edit an array of OBJECTS in a specified document

    
    docRef (firestore document reference), the document reference where the settings are stored (required)
    settingName (string), the name of the setting (required)
    initialSettings (array), an array of strings that contains the current settings (optional)
    uneditableOptions (array), an array of strings that will be shown to the user but are not editable (optional)

    examples:
    docRef = doc(db, "Settings", 'yEwRIqrkneqrGnAQhgvs');
    settingName = 'Loan Type Options'
    initialSettings = ['VA', 'Convential', 'FHA']
    uneditableOptions = ['Other/Non-QM']
*/

interface CreatableOptionsProps {
  docRef: DocumentReference | string;
  initialSettings?: Setting[]
  settingFields: Field[];
  singularName: string;
  settingName: string;
  height?: string;
  showSettingNameAsHeading?: boolean;
  addButtonText?: string;
  formValues: any;
}

interface Setting {
  [key: string]: string[] | string
}

interface Field{
  name: string,
  type: 'number' | 'dropdown' | 'longinput',
  isMulti?: boolean,
  options?: string[]

}

export default function CreatableOptions_ArrayOfObjects({
  docRef,
  initialSettings,
  settingFields,
  singularName,
  settingName,
  showSettingNameAsHeading,
  addButtonText,
  formValues,
  height,
}: CreatableOptionsProps) {
  const toast = useToast();
  const [options, setOptions] = useState(
    initialSettings ? initialSettings : [{}]
  );

  const handleSetOption = (input: string, index: number, field: string, innerIndex?: number) => {
    const opts = [...options];
    if(innerIndex || innerIndex === 0){
      {/*@ts-expect-error*/}
      opts[index][`${field}`][innerIndex] = input;
    }
    else opts[index][field] = input;
    setOptions(opts);
  };

  const addOption = () => {
    setOptions([...options, {}]);
  };

  const addOptionMulti = (index: number, key: string) => {
    let opts = [...options]
    if(opts[index]?.[key] && Array.isArray(opts[index]?.[key])){
      {/*@ts-expect-error*/}
      opts[index][key].push("")
    }
    else opts[index][key] = [""]
    setOptions(opts)
  };

  const removeOptionMulti = (index: number, key: string, innerIndex: number) => {
    const opts = [...options];
    {/*@ts-expect-error*/}
    opts[index]?.[key].splice(innerIndex, 1);
    setOptions(opts);
  };

  const removeOption = (index: number) => {
    const opts = [...options];
    opts.splice(index, 1);
    setOptions(opts);
  };

  const save = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault()
      if (typeof docRef !== "string") {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            [settingName]: options,
          });
        } else {
          await setDoc(docRef, {
            [settingName]: options,
          });
        }

        toast({
          title: `${settingName} Updated!`,
          description: "Users will have to refresh their browsers to see this change",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      toast({
        title: `Error updating settings`,
        description: err,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      {docRef ? (
        <>
          {showSettingNameAsHeading && settingName && (
            <Heading ml="2" size="xs">
              {settingName}
            </Heading>
          )}
          <Box >
            {options &&
              options.map((option, i) => {
                return (
                  <>
                  <Flex mt='1'>
                    <IconButton
                        aria-label="Remove option"
                        icon={<FiTrash2 />}
                        variant='ghost'
                        size='sm'
                        colorScheme='red'
                        onClick={() => removeOption(i)}
                      />
                    <Heading textTransform={'uppercase'} mt='2.5' lineHeight={'1'} size='xs'>{`${singularName} ${i+1}`}</Heading>
                  </Flex>
                  <Flex
                    data-testid={`option-${i}`}
                    key={`settings-option-${i}`}
                    flexWrap='wrap'
                    flexDir={'row'}
                    boxShadow='md'
                    border='1px solid lightgrey'
                  >
                    {settingFields.map((field, j) => (
                      <>
                        {field?.isMulti ?
                        <>
                        {/*@ts-expect-error*/}
                          {Array.isArray(option?.[field.name]) && option?.[field.name]?.map((f: string, index: number) => (
                            <>
                             {field.type === 'longinput' ? (
                                <Textarea
                                  m='1'
                                  autoFocus={options.length - 1 === i}
                                  data-testid={`option-input-${i}-${field?.name}`}
                                  placeholder={field.name}
                                  w='90%'
                                  value={option?.[field.name][index] || ""}
                                  onChange={(e) =>
                                    handleSetOption(e.target.value, i, field.name, index)
                                  }
                                />
                              ) : field.type === 'dropdown' && field.options ? (
                                <FormControl key={field.name} m='1' w={{ base: "90%", xl: "30%" }}>
                                  <Select
                                    data-testid={`option-input-${i}-${field?.name}`}
                                    placeholder={field.name}
                                    value={option[field.name] || ""}
                                    onChange={(e) => handleSetOption(e.target.value, i, field.name)}
                                  >
                                    {field?.options.map((optionValue) => (
                                      <option key={optionValue} value={optionValue}>
                                        {optionValue}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <Input
                                  m='1'
                                  autoFocus={options.length - 1 === i}
                                  data-testid={`option-input-${i}-${field?.name}`}
                                  placeholder={field.name}
                                  w={{ base: "90%", xl: "30%" }}
                                  type={field.type === 'number' ? 'number' : 'text'}
                                  value={option?.[field.name][index] || ""}
                                  onChange={(e) =>
                                    handleSetOption(e.target.value, i, field.name, index)
                                  }
                                />
                              )}
                              <IconButton
                                aria-label="Remove option"
                                icon={<FiX />}
                                variant='ghost'
                                onClick={() => removeOptionMulti(i, field.name, index)}
                              />
                            </>
                          ))}
                            <Button
                                data-testid={`add-option-multi-button-${field.name}`}
                                m={2}
                                p={4}
                                mb={5}
                                onClick={() => addOptionMulti(i, field.name)}
                              >
                                {`Add ${field.name}`}
                              </Button>
                            </>
                        :
                        <>
                        {field.type === 'longinput' ? (
                          <Textarea
                            m='1'
                            autoFocus={options.length - 1 === i}
                            data-testid={`option-input-${i}-${field?.name}`}
                            placeholder={field.name}
                            w='90%'
                            value={option[field.name] || ""}
                            onChange={(e) =>
                              handleSetOption(e.target.value, i, field.name)
                            }
                          />
                          ) : field.type === 'dropdown' && field.options ? (
                            <FormControl key={field.name} m='1' w={{ base: "90%", xl: "30%" }}>
                              <Select
                                data-testid={`option-input-${i}-${field?.name}`}
                                placeholder={field.name}
                                value={option[field.name] || ""}
                                onChange={(e) => handleSetOption(e.target.value, i, field.name)}
                              >
                                {field?.options.map((optionValue) => (
                                  <option key={optionValue} value={optionValue}>
                                    {optionValue}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                        ) : (
                          <Input
                            m='1'
                            autoFocus={options.length - 1 === i}
                            data-testid={`option-input-${i}-${field?.name}`}
                            placeholder={field.name}
                            w={{ base: "90%", xl: "30%" }}
                            type={field.type === 'number' ? 'number' : 'text'}
                            value={option[field.name] || ""}
                            onChange={(e) =>
                              handleSetOption(e.target.value, i, field.name)
                            }
                          />
                        )}
                        </>
                        }
                      </>
                    ))}

                  </Flex>
                  </>
                );
              })}
          </Box>
          <Button
            data-testid={`add-option-button`}
            m={2}
            p={4}
            mb={5}
            onClick={addOption}
          >
            {addButtonText ? addButtonText : "Add Option"}
          </Button>
          <Button
            data-testid={`save-button`}
            colorScheme={"blue"}
            m={2}
            p={4}
            mb={5}
            onClick={(e) => save(e)}
          >
            Save {settingName}
          </Button>
        </>
      ) : (
        <>
          <Text>Error displaying settings</Text>
        </>
      )}
    </>
  );
}
