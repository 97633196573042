import { useState, useEffect } from 'react';
import CountUp from 'react-countup'
import { Text, Icon, HStack, Badge } from "@chakra-ui/react";
import { FiClock } from "react-icons/fi";
import { useStopwatch } from 'react-timer-hook'

export default function Timer( { start, warningInMinutes }){

    const [warning, setWarning] = useState(false)

    var currentSeconds = new Date().getTime() / 1000;
    const stopwatchOffset = new Date()
    const offsetSeconds = currentSeconds - start
    const warningSeconds = 
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offsetSeconds)
     const {
        seconds,
        minutes,
        hours,
        days
      } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

    useEffect(() => {
        if(warningInMinutes){
            if( (days*60*24) + (hours*60) + minutes >= warningInMinutes){
                setWarning(true)
            }
        }
    }, [minutes])
    
    return(
        <>

            <Badge variant='subtle' mr='1' colorScheme={ warning ? 'red' : 'orange'}>
                <HStack>
                    <FiClock/><span></span>
                                {days > 0 && <><span style={{margin: '0'}}>{days}</span><span style={{margin: '0'}}>:</span></>}
                                {hours > 0 && <><span style={{margin: '0'}}>{hours}</span><span style={{margin: '0'}}>:</span></>}
                                <span style={{margin: '0'}}>{('0' + minutes).slice(-2)}</span><span style={{margin: '0'}}>:</span>
                                <span style={{margin: '0'}}>{('0' + seconds).slice(-2)}</span>
                </HStack>
            </Badge>
            {warning && <Badge variant='solid' colorScheme='red'>OVERDUE</Badge>}
        </>

    )
}