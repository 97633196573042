import firebase from "firebase/compat/app"
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import "firebase/compat/firestore";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore"
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFunctions } from "firebase/functions";
import { useLocation } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

const app = firebase.initializeApp(firebaseConfig);
const functions = getFunctions(app)
const db = getFirestore(app)
/* 
if(window.location.hostname === 'localhost'){
  connectFirestoreEmulator(db, 'localhost', 8080);
}   */
 
const auth = getAuth(app);
const messaging = getMessaging(app);

export { app, db, firebase, auth, messaging, firebaseConfig, functions };

export const requestForToken = async (setCurrentToken) => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        //console.log('current token for client: ', currentToken);
        setCurrentToken(currentToken)
      } else {
        // Show permission request UI
        //console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      //console.log('An error occurred while retrieving token. ', err);
    });
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      //console.log("payload", payload)
      resolve(payload);
    });
  });

  