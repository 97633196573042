import { increment, updateDoc, doc, getDoc } from "firebase/firestore"
import { db } from "../utils/init-firebase"

const countersRef = doc(db, 'Counters', 'FeS869ZmgQFluQz3VwsI')
export const settingsRef = doc(db, 'Settings', 'yEwRIqrkneqrGnAQhgvs')

export const incrementSetupQueueCount = async () => {
    const countersSnap = await getDoc(countersRef)
    if(countersSnap.exists()){
        await updateDoc(doc(db, 'Counters', 'FeS869ZmgQFluQz3VwsI'), {
            'Setup Queue': increment(1)
        })
    }
    else{
        await updateDoc(doc(db, 'Counters', 'FeS869ZmgQFluQz3VwsI'), {
            'Setup Queue': 1
        })
    }

}

export const decrementSetupQueueCount = async () => {
    await updateDoc(doc(db, 'Counters', 'FeS869ZmgQFluQz3VwsI'), {
        'Setup Queue': increment(-1)
    })
}

// Expected input - array of user objects ex  [ { Name: Brian, Email: bphelan@future.loans, uid: xxx, photoURL: me.jpg } ]
export const formatUsersForReactSelect = (users) => {
    let formattedUsers = []
    users.forEach((user) => {
        let newUser = {
            email: user.Email,
            value: user.Email
        }
        formattedUsers.push(newUser)
    })
    return formattedUsers
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function merge( source, target = {}, ...parents) {
    for (let [key, value] of Object.entries( source ) ) {
      const path = (parents || []).concat( key );
      if (typeof value === 'object') {
        merge( value, target, ...path );
        continue;
      }
      target[path.join(' ')] = value;
    }
    return target;
  }