import { useState, useEffect, useCallback, useRef } from "react";
import {  doc, updateDoc, serverTimestamp, setDoc, collection } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db, firebase } from "../../utils/init-firebase"
import { FiEdit } from "react-icons/fi";
import {
    useDisclosure,
    useToast,
    Flex,
    IconButton,
    Button,
    FormControl,
    FormLabel,
    InputGroup,
    Icon,
    Input,
    Image,
    Textarea,
    Select,
    CloseButton} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { FiUpload, FiXCircle } from "react-icons/fi";
import Item from "./Item";

export default function ManageGuidelines ( { inventory, action, initialValues } ){
    const newDocRef = doc(collection(db, 'Marketing Swag Guidelines'))
    const { currentUser } = useAuth()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [name, setName] = useState('')
    const [id, setID] = useState('')
    const [descr, setDescr] = useState('')
    const [uploadedFiles, setUploadedFiles] = useState({ })
    const [items, setItems] = useState([ ])

    const [itemToAdd, setItemToAdd] = useState()
    const [uploading, setUploading] = useState(false)
    const [files, setFiles] = useState([])
    const inputRef = useRef()
    const toast = useToast()

    const onFileChange = (e) => {
        setFiles(e.target.files)
    }

    useEffect(() => {
        if(initialValues){
            if(initialValues.id){
                setID(initialValues.id)
            }
            if(initialValues.name){
                setName(initialValues.name)
            }
            if(initialValues.descr){
                setDescr(initialValues.descr)
            }
            if(initialValues.photo){
                setUploadedFiles(initialValues.photo)
            }
            if(initialValues.items){
                setItems(initialValues.items)
            }
        }
    }, [])

    useEffect(()=> {
        uploadFiles();
      }, [files])
 
      const uploadFiles = useCallback(()=>{
        if(files.length > 0)
        {
            setUploading(true)                    
            const theseFiles = files;
            setFiles([])
            var promises = []
            for (let i = 0; i < theseFiles.length; i++) {         
                    const storageRef = firebase.storage().ref();
                    const file = theseFiles[i];
 
                    promises.push(storageRef
                    .child(`Marketing Swag Guidelines/${newDocRef.id}/${file.name}`)
                    .put(file).then( async (snapshot) => {
                       snapshot.ref.getDownloadURL().then( async (url) => {
                            setUploadedFiles({url: url, name: file.name})
                           // setFileURLs(prevState => [...prevState, url]);
                            //setFileNames(prevState => [...prevState, file.name]);
                                    return
                                }
                            )
                        })
                    )
            }
            Promise.all(promises)
            .then( async () => {
                    setUploading(false)
                }
            )
            .catch(err => console.log(err.code));
            
        }
    },[files])

    const handleRemoveItem = (id) => {
        let itemz = items
        itemz = itemz.filter(function(item) {
            return item !== id
        })
        setItems(itemz)
    }

    const handleAddItem = () => {
        let itemz = items
        itemz.push(itemToAdd)
        setItems(itemz)
        setItemToAdd('')
    }

    const createGuideline = async () =>{
        try{
            await setDoc(newDocRef, {
                name: name,
                photo: uploadedFiles,
                descr: descr,
                items: items,
                id: newDocRef.id,
                created: serverTimestamp()

            })
    
            toast({
                title: 'Guideline Created!',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
            onClose()
        }
        catch(err){
            toast({
                title: 'Error',
                description: err,
                status: 'error',
                duration: 9000,
                position: 'top',
                isClosable: true,
            })
        }
        
    }

    const saveGuideline = async () =>{
        try{
            await updateDoc(doc(db, 'Marketing Swag Guidelines', id), {
                name: name,
                photo: uploadedFiles,
                descr: descr,
                items: items,
                created: serverTimestamp()
            })
    
            toast({
                title: 'Guideline Updated!',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            })
            onClose()
        }
        catch(err){
            toast({
                title: 'Error',
                description: err,
                status: 'error',
                duration: 9000,
                position: 'top',
                isClosable: true,
            })
        }
        
    }

    return(
        <>
        {action == 'Add' ?
            <Button colorScheme={'teal'} onClick={onOpen}>{action} Guideline</Button>
        :
            <IconButton colorScheme={'teal'} size='sm' onClick={onOpen} icon={<FiEdit/>}></IconButton>
        }

      <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{action} Guideline</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
                <Flex flexDir={'row'}>
                    <Flex w='69%' mr={5} m={2} flexDir={'column'}>

                        <FormControl mb={2}>
                            <FormLabel>Name</FormLabel>
                            <Input value={name} onChange={(e) => setName(e.target.value)}></Input>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea value={descr} onChange={(e) => setDescr(e.target.value)}></Textarea>
                        </FormControl>
                    </Flex>
                    <FormControl m={2} w='30%'>
                        <FormLabel htmlFor="fileUpload">Image</FormLabel>
                        <InputGroup>
                            {!uploadedFiles.url && 
                                <IconButton
                                    onClick={() => inputRef.current.click()}
                                    >
                                    <Icon as={FiUpload} />
                                </IconButton>
                            }
                            
                            <input type='file'
                                onChange={onFileChange}
                                name={'Upload Image'}
                                ref={inputRef}
                                style={{display: 'none'}} />
                                {uploadedFiles.url &&
                                <>                                
                                <Image
                                    src={uploadedFiles.url}
                                    maxH='150px'
                                />
                                <Flex ml='1' flexDir={'column'} justify='space-between'>
                                    <IconButton variant={'unstyled'} onClick={() => setUploadedFiles({name: '', url: ''})}><Icon as={FiXCircle}></Icon></IconButton>
                                    <IconButton
                                        variant={'unstyled'}
                                        onClick={() => inputRef.current.click()}
                                        >
                                        <Icon as={FiUpload} />
                                    </IconButton>
                                </Flex>
                                </>
                            }
                        </InputGroup>
                    </FormControl>
                </Flex>
                <Flex mb={3} flexDir={'column'}>
                    <FormLabel>Items <span style={{color: 'grey', fontSize: '12px'}}>(items in red are hidden and will not be shown to users in the storefront)</span></FormLabel>
                    {items && inventory && items.map((item, index) => {
                            return(
                                <Flex
                                    direction={'row'}
                                    justify="space-between"
                                    align="center"
                                    key={`container-${item}`}
                                    >       
                                        <Item id={item} inventory={inventory} size='lg'/>
                                        <Flex
                                        width='lg'
                                        justify="space-between"
                                        display={'flex'}
                                        key={`button-container-${item}`}
                                        >
                                        <CloseButton key={`delete-button-${item}`} onClick={ () => handleRemoveItem(item) } aria-label={`Delete ${item} from cart`}/>
                                    </Flex>  
                                </Flex>
                            )
                        }
                    )}
                </Flex>
                <Flex flexDir={'row'}>
                    <FormControl w='50%'>                      
                        <Select placeholder="Select an item..." value={itemToAdd} onChange={(e) => setItemToAdd(e.target.value)}>
                            {inventory && inventory.map((inventoryItem) => {
                                return(
                                    <option value={inventoryItem.id}>{inventoryItem.Name}</option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Button onClick={() => handleAddItem(itemToAdd)} disabled={!itemToAdd} ml={2}>
                        Add
                    </Button>       
                </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            {action == 'Add' ?
                <Button onClick={() => createGuideline()} disabled={!name || !items || items.length < 0} variant='ghost'>Create</Button>
            :
                <Button onClick={() => saveGuideline()} variant='ghost'>Save</Button>
            }

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
    )
}