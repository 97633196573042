import React, { useEffect, useState, useMemo } from "react";
import {
    Text,
    Wrap,
    WrapItem,
    Input,
    Button,
    useColorModeValue,
    Flex,
    Box,
    HStack,
    Tooltip,
    Tag,
    ButtonGroup,
    Heading,
    Avatar,
    Stack,
    TagLabel,
    TagLeftIcon,
    useToast,
    Spacer,
    AvatarBadge,
    TagRightIcon,
    ScaleFade,
    VStack,
    Badge,
    FormControl,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    FormLabel,
    SliderMark,
    RangeSliderMark,
  } from '@chakra-ui/react';
import { useAuth } from "../../contexts/AuthContext";
import ReactDatePicker from "react-datepicker";
import { datepickerDark, datepickerLight } from "../../contexts/GlobalVariables";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "../../components/Table/ReactTable";
import { FaTable, FaTh } from "react-icons/fa";
import { FiHome, FiRotateCcw, FiExternalLink, FiUser, FiBarChart2 } from 'react-icons/fi';
import LoanTeamAvatars from "../../components/Client/LoanTeamAvatars";
import TimestampSeconds from "../../components/TimestampSeconds";
import { BsPiggyBank } from 'react-icons/bs';
import moment from 'moment';
import { FaQuestion, FaQuestionCircle } from 'react-icons/fa';
import Rate_FloatedOrLocked from "../../components/Client/Rate_FloatedOrLocked";
import {
    Select,
  } from "chakra-react-select";
import { useHistory } from "react-router-dom";
import { StarIcon } from "@chakra-ui/icons";

const DELIMITER = ' - '

export default function TableCardWrapper( { maxWidth, clientList, cols, startDate, setStartDate, endDate, setEndDate, loading, hideFilters } ){
    const { SuperAdmin } = useAuth()
    const history = useHistory()
    const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)
    const [view, setView] = useState('Cards')
    
    const [flatClientList, setFlatClientList] = useState(null)
    const [filteredClientList, setFilteredClientList] = useState(null)

    const [options, setOptions] = useState()
    const [filters, setFilters] = useState({})
    const [averages, setAverages] = useState()
    // Example options: 
    //  options = {
    //     Lender: ['UWM', 'Cardinal'],
    //     Program: ['Conventiional', 'USDA', 'VA']
    // }

    // Example filters: users can change the filters. Dropdown/slider supported 
    // filters = {
    //     Lender: {label: 'UWM', value: 'UWM'},
    //     Program: ''
    // }

    useEffect(() => {
        let v = localStorage.getItem('view')
        if(v) setView(v)
    }, [])

    useEffect(() => {
        localStorage.setItem('view', view)
    }, [view])
    
    const resetFilters = () => {
        const newFilters = Object.keys(options).reduce((acc, key) => {
            acc[key] = options?.[key]?.defaultValue || null
            return acc;
        }, {});
       //console.log(newFilters)
        setFilters(newFilters);
      }
  
    function merge( source, target = {}, ...parents) {
      for (let [key, value] of Object.entries( source ) ) {
        const path = (parents || []).concat( key );
        if (typeof value === 'object') {
          merge( value, target, ...path );
          continue;
        }
        target[path.join(' ')] = value;
      }
      return target;
    }
  
    useEffect( () => {
      if(!loading && flatClientList && filters){
        const filteredClients = flatClientList?.filter((client) => {
           return Object.keys(filters)?.every((option) => {
                return filters[`${option}`]?.value ? 
                            client[`${option}`]?.split(DELIMITER).map(option => option.toLowerCase()).includes(filters[`${option}`]?.value.toLowerCase()) 
                            : 
                        Array.isArray(filters[option]) ?
                            client[option] >= filters[`${option}`][0] && client[option] <= filters[`${option}`][1]
                            :   
                            client       
           })
        })
        setFilteredClientList(filteredClients)
      }
    }, [filters])

    // Calculate the averages of the given data
    // Set calculateAvg = true in cols to add a field to the averages calculation
    // Expected output: {
    //      lenderRating: 3.8
    // }
    useEffect(() => {
        if (filteredClientList && cols) {
            let averages = {};
            for (const col of cols) {
                if (col?.calculateAvg) {
                    let thisSum = filteredClientList.reduce((prev, curr) => {
                        // Ensure the value being added is a number
                        const currentValue = parseInt(curr[col.accessor]) || 0;
                        return prev + currentValue;
                    }, 0); // <-- Provide initial value 0 for the reduce function
        
                    averages[col.accessor] = thisSum / filteredClientList.length;
                }
            }
            setAverages(averages)
        }
    }, [filteredClientList])

    useEffect(() => {
        function makeOptions(flatClientList){
            let opts = {}
            cols.filter((col) => col.filterOptions).forEach((col) => {
                if(col.filterOptions.makeOptionsFromData){
                    let thisFieldOptions = []
                    flatClientList?.forEach((client) => {
                        const fields = client?.[`${col.accessor}`]?.split(DELIMITER);
                        fields?.forEach((field) => {
                            if (field && field.length > 1 && !thisFieldOptions.includes(field)) {
                                thisFieldOptions.push(field);
                            }
                        })
                       
                    })
                    opts[`${col.accessor}`] = {
                        options: thisFieldOptions.sort().map((opt) => ({label: opt, value: opt})),
                        type: col?.filterOptions?.type,
                        defaultValue: col?.filterOptions?.defaultValue,
                    }
                }
                else if(col.filterOptions.options){
                    opts[`${col.accessor}`] = { 
                        options: col?.filterOptions?.options,
                        type: col?.filterOptions?.type,
                        defaultValue: col?.filterOptions?.defaultValue,
                    }
                }
            })

            return opts
        }
        if(!loading && clientList){
            let flatClientList = clientList.map((client) => merge(client))
            setFlatClientList(flatClientList)
            setFilteredClientList(flatClientList)
            setOptions(makeOptions(flatClientList))
        }
     
    }, [loading])

    useEffect(() => {
        console.log(filters)
        console.log(options)
    }, [filters])

    return(
    
        <Flex 
          flexDir={'column'}
          mt={'5'}
          >
            <Flex justify={'flex-start'} flexDir={{base: 'column', md: 'row'}}>
                <Flex flexDir={{base: 'column', '2xl': 'row'}}>
                    <Flex>
                        <ButtonGroup mr='1' mb='1' variant="outline" spacing="0">
                            <Button
                            leftIcon={<FaTable />}
                            colorScheme={view === 'Table' ? 'blue' : 'gray'}
                            onClick={() => setView('Table')}
                            borderRightRadius={'none'}
                            >
                                Table
                            </Button>
                            <Button
                            leftIcon={<FaTh />}
                            colorScheme={view === 'Cards' ? 'blue' : 'gray'}
                            onClick={() => setView('Cards')}
                            borderLeftRadius={'none'}
                            >
                                Cards
                            </Button>
                        </ButtonGroup>
                        <Flex justify={'flex-start'} flexDir={'row'} mb='1' mr='1'>
                            <ReactDatePicker   
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className={datepickerClass}
                            />
                            <Box mr='1'></Box>
                            <ReactDatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className={datepickerClass}
                            />
                        </Flex>
                    </Flex>
                    <Flex flexWrap={'wrap'}>
                        {options && filters && Object.keys(options).map((key) => {
                            return(
                                <FormControl 
                                    px={options?.[`${key}`]?.type === 'slider' && '3'} 
                                    alignSelf='center'
                                    w='225px' 
                                    mx='1'
                                    mb='1' 
                                    key={`${key}-filter`}
                                    >
                                    {options?.[`${key}`].type === 'dropdown' ?
                                        <Select
                                            placeholder={`Filter By ${key.replace('Loan Details ', '')}`}
                                            value={filters?.[`${key}`]}
                                            options={options?.[`${key}`]?.options}
                                            onChange={(newValue) => setFilters({...filters, [`${key}`]: newValue})}
                                            isClearable
                                            
                                            chakraStyles={{placeholder: (provided) => ({
                                                    ...provided,
                                                    fontSize: 'sm'
                                                })
                                            }}
                                        >
                                        </Select>
                                    :
                                    options?.[`${key}`]?.type === 'slider' ?
                                    <>
                                        <RangeSlider 
                                            defaultValue={options?.[key].defaultValue} 
                                            min={1} 
                                            max={5} 
                                            step={1} 
                                            value={filters?.[`${key}`]}
                                            onChange={(newValue) => setFilters({...filters, [`${key}`]: newValue})}
                                        >
                                            {options[`${key}`]?.options.map((option) => (
                                                <RangeSliderMark 
                                                    key={`slider-${key}-mark-${option.label}`}
                                                    borderRadius={'full'} 
                                                    backgroundColor={filters?.[key] && (filters?.[key][0] >= option.value || filters?.[key][1]) <= option.value ? 
                                                                        'gray.200' : 
                                                                        'yellow.300'}
                                                    zIndex='5'
                                                    pl='1.5' 
                                                    pr='1.5' 
                                                    mt='-2.5' 
                                                    color='gray.700' 
                                                    fontSize='sm' 
                                                    value={option.value}>
                                                        {option.label}
                                                </RangeSliderMark>
                                            ))}
                                            <RangeSliderTrack>
                                                <RangeSliderFilledTrack backgroundColor='yellow.300' />
                                            </RangeSliderTrack>
                                            <RangeSliderThumb ml='1.5' zIndex='6' boxSize={7} index={0}>
                                                <Box color='yellow.300' as={StarIcon}>
                                                </Box>
                                            </RangeSliderThumb>
                                            <RangeSliderThumb ml='1.5' zIndex='6' boxSize={7} index={1}>
                                                <Box color='yellow.300' as={StarIcon} />
                                            </RangeSliderThumb>
                                        </RangeSlider>
                                        {averages && averages[key] !== undefined && !isNaN(averages[key]) &&                                            <Text 
                                                mt='-2' 
                                                fontSize='sm' 
                                                textAlign={'center'}>
                                                    AVG {averages[key].toFixed(1)}
                                                    <Box ml='0.5' mb='1' color='yellow.300' as={StarIcon}>
                                                    </Box>
                                            </Text>
                                        }
                                    </>
                                    :
                                        <Select
                                            placeholder={`Filter By ${key.replace('Loan Details ', '')}`}
                                            value={filters?.[`${key}`]}
                                            options={options?.[`${key}`]?.options}
                                            onChange={(newValue) => setFilters({...filters, [`${key}`]: newValue})}
                                            isClearable
                                            
                                            chakraStyles={{placeholder: (provided) => ({
                                                    ...provided,
                                                    fontSize: 'sm'
                                                })
                                            }}
                                        >
                                        </Select>
                                    }
                                    
                                </FormControl>
                            )                               
                            })}

                        <Button mt='1' p={5} pl={7} pr={7} onClick={resetFilters} size='md' colorScheme='blue'>Reset</Button>
                    </Flex>
                </Flex>
            </Flex>
        {!loading &&
            <>
           {filteredClientList && cols && view == 'Table' &&
            <ScaleFade in initialScale={0.85}>
                <ReactTable 
                    width='86vw' 
                    colorScheme={'teal'} 
                    maxWidth={maxWidth} 
                    data={filteredClientList} 
                    cols={cols} />
             </ScaleFade>
          } 
          {view == 'Cards' &&
            <Wrap mt={3}>
                {filteredClientList?.map((client, index) => {
                    return (
                        <>
                        <WrapItem key={`client-${client.id}`} id={`client-${client.id}`}>
                            <ClientCard 
                                client={client}
                                loanOfficer={client['Loan Officer']}
                                lenderConcierge={client['LC']}
                                docID={client.id}
                                status={client.Status}
                            />
                            </WrapItem>
                        </>
                    )
                }) }
                {filteredClientList?.length == 0 && !loading  && <Text>No clients found</Text>}
            </Wrap>
          }
          </>
        }
      </Flex>
    )
}


function ClientCard({ client }) {
    return (
      <ScaleFade in initialScale={0.85}>
        <Box
          m={1}
          width={{base: '90vw', md :'300px'}}
          boxShadow={'lg'}
          rounded={'lg'}
          p={5}
          textAlign={'center'}>
              
        <Flex direction={'row'} mb='3' >
            <Tooltip placement='top' label={`${client['Loan Officer Name']}`}>
              <Avatar
                size={'md'}
                src={client['Loan Officer photoURL']}
                alt={'Avatar Alt'}>
                <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='red.300'>LO</AvatarBadge>
              </Avatar>
            </Tooltip>

             <Heading alignSelf='center' fontSize={'sm'} textAlign='left' ml={'3'} fontFamily={'body'} whiteSpace='pre-wrap'>
                {client['Loan Officer Name'].split(' ').join('\n')}
            </Heading> 
            <Spacer />
            <VStack align={'flex-end'}>
                <Tag borderRadius={'xl'} size='sm' variant='subtle' colorScheme='green' textTransform='uppercase' fontWeight='bold'>
                    {client['Loan Details Purchase or Refi'] == 'Purchase' && 
                        <TagLeftIcon as={FiHome} />
                    }
                    {client['Loan Details Purchase or Refi'] == 'Refi' &&
                        <TagLeftIcon as={FiRotateCcw} /> 
                    }
                    {client['Loan Details Purchase or Refi'] == 'Custom' &&
                        <TagLeftIcon as={FaQuestion} /> 
                    }
                    {client['Loan Details Purchase or Refi'] == 'TBD' &&
                        <TagLeftIcon as={FaQuestionCircle} /> 
                    }
                    <TagLabel fontSize="14px">{client['Loan Details Purchase or Refi']}</TagLabel>
                </Tag> 
              {client['Loan Details Lender'] &&
                <Tag size='sm' variant='subtle' borderRadius={'xl'} textTransform='uppercase' fontWeight='bold'>
                  <TagLeftIcon as={BsPiggyBank} />
                  <TagLabel fontSize="14px">{client['Loan Details Lender']}</TagLabel>
                </Tag> 
              }  
            </VStack>
           
          </Flex>
            <Flex flexDir={'column'}>
                <Flex flexDir={'row'} flexWrap={'wrap'} justifyContent={'space-between'} mb={1}>
                    {client['Loan Details Credit Score'] && 
                    <Badge borderRadius='full' px='2' colorScheme='telegram'>
                        
                            <TagLeftIcon mt='1' as={FiBarChart2} />
                            {client['Loan Details Credit Score']}
                    </Badge>
                    }
                    {client['Loan Details Rate'] && 
                        <Rate_FloatedOrLocked 
                            rate={client['Loan Details Rate']} 
                            floatedOrLocked={client['Loan Details Floated or Locked']} />
                    }
                </Flex>
                <Flex flexDir={'row'} flexWrap={'wrap'} justifyContent={'space-between'} mb={1}>
                    {client['Loan Details Product'] &&
                        <Badge borderRadius='full' px='2' colorScheme='teal'>
                            {client['Loan Details Product']}
                        </Badge>
                        }
                    <Box 
                        letterSpacing='wide'
                        fontSize='12px'
                        mt={1}
                        textTransform='uppercase'
                        >
                    {client['Date Completed seconds'] &&
                            moment(client['Date Completed seconds']*1000).format('MM/DD/YY')               
                    }  
                    </Box>
                </Flex>
            </Flex>
        </Box>
      </ScaleFade>
    );
}