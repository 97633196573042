import Guideline from "./Guideline";
import { Grid } from '@chakra-ui/react'

export default function GuidelineGrid ( { inventory, guidelines, handleAddToCart, setDescription, allowEditing } ){
    const cols = { base: `repeat(1, 1fr)`, 
        md: `repeat(2, 1fr)`, 
        xl: `repeat(3, 1fr)`
    }
    return(
        <>
        <Grid
            maxW={'fit-content'} 
            mt={'3'}
            templateColumns={cols}
            gap={{ base: '5', md: '7' }}            >
            {guidelines && guidelines.map((guideline, index) => {
                return(
                    <>
                        <Guideline allowEditing={allowEditing} setDescription={setDescription} guideline={guideline} inventory={inventory} handleAddToCart={handleAddToCart} />
                    </>
                )
            })}
        </Grid>        
        </>
    )
}
