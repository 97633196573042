import { Flex, Image, Box, ScaleFade, Text, useColorModeValue, Heading } from "@chakra-ui/react"
import Item from "./Item"
import QuantitySelect from "./QuantitySelect"
import ManageGuidelines from "./ManageGuidelines"

export default function Guideline ( { guideline, inventory, handleAddToCart, setDescription, allowEditing } ){
    const { name, photo, items, descr} = guideline

    return(
        <>
             <ScaleFade in initialScale={0.85}>
                <Box
                    m={1}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow={'lg'}
                    rounded={'lg'}
                    p={6}
                    width='100%'
                    height='100%'
                    >
                        <Flex mb={2} justify={'space-between'}>
                            {name && <Heading color='teal.400' textTransform={'uppercase'} fontSize='xl' mb={2}>{name}</Heading>}
                            {allowEditing ?
                                <ManageGuidelines action={'Edit'} initialValues={guideline} inventory={inventory} />
                                :
                                <QuantitySelect name={name} setDescription={setDescription} items={items} handleAddToCart={handleAddToCart} id={guideline.id} />
                            }
                        </Flex>
                        {descr &&
                  
                            <Flex mb={5}  flexDir={'row'} justifyContent={'space-between'}>
                                <Text color='gray.500' fontSize='sm'>
                                   {descr}
                                </Text>
                            </Flex> 
                        }
                        <Flex minH='250px' justify={'space-between'}>
                            <Flex flexDir='column' maxH={'fit-content'} overflowX='auto'>

                            {items && items.map((item) => {
                                return(
                                    <Item id={item} inventory={inventory} />
                                )})

                            }
                            </Flex>
                        {photo && photo.url &&
                            <>
                            <Image maxW={'50%'} objectFit='cover' height='auto' width={'auto'} align={'center'} src={photo.url}>

                            </Image>
                            </>
                        }
                        </Flex>
                </Box>
            </ScaleFade>
        
        </>
    )
}

