// TopicList.js
import useQuestions from './useQuestions';
import Question from './Question'
import { Box, Text } from '@chakra-ui/react';

function QuestionList( { } ) {
  const { questions } = useQuestions();

  return (
    <>
        <Box mt='3'>
            <Text m='3' as='em'>Manage Questions</Text>
            {questions?.map((question) => (
                <Question 
                    key={question.id}
                    question={question} 
                    
                />

            ))}
        </Box>
    </>
  );
}

export default QuestionList;

