import { FiArrowRight, FiCheck, FiX } from "react-icons/fi"
import { Box, Avatar, Stack, Text, Divider, Icon, AvatarBadge, Center } from "@chakra-ui/react"
import moment from "moment"

export default function EditLog( { log, showLoanNumber }){
    return(
        log.map((logItem) => {
            let print = false
            {logItem.Differences.map((difference) => {
                if(difference.path.includes('Loan Details') || 
                    difference.path.includes('Status') || 
                    difference.path.includes('CSC') || 
                    difference.path.includes('LOA') || 
                    difference.path.includes('Loan Officer') || 
                    difference.path.includes('LC') || 
                    difference.path.includes('TC')){
                        print = true
                    }
                })
            }
            if(!print){
                return
            }
            return(
            <>
                <Box mb={3} key={`edit-log-${log?.id}`}>
                    {showLoanNumber && logItem['Loan #'] && 
                        <Text>Loan Number: {logItem['Loan #']}</Text>
                    }
                    <Stack spacing='0.5' direction='row'>
                    <Avatar mr={2} size='md' src={logItem['Edited By'].photoURL} />
                        <Stack spacing='0.5' direction='column'>
                            <Stack spacing='0.5' direction='row'>
                                {logItem['Edited By']['Name'] && 
                                <>
                                    <Text fontSize='sm'>{logItem['Edited By']['Name']}&nbsp;  </Text>
                                    <Box 
                                        color='gray.500'
                                        letterSpacing='normal'
                                        fontSize={{
                                            'base':'10px',
                                            'lg': '12px'
                                        }}
                                        >
                                        {logItem?.timestamp ? moment(logItem?.timestamp.toDate()).calendar() : null }&nbsp;
                                    </Box>
                                </>
                                }
                            </Stack>
                
                            {logItem.Differences.map((difference, index) => {
                                if(difference.path.includes('uid') || difference.path.includes('photoURL') || difference.path.includes('lenderID')){
                                    return
                                }
                                if(difference.path.includes('Loan Details') || 
                                    difference.path.includes('Status') || 
                                    difference.path.includes('CSC') || 
                                    difference.path.includes('LOA') || 
                                    difference.path.includes('Loan Officer') || 
                                    difference.path.includes('LC') || 
                                    difference.path.includes('TC'))                    
                                return(
                                <>

                                <Stack alignItems='center' wrap={'wrap'} spacing='0.5' direction='row' key={`edit-log-${log.id}-item-${index}`}>
                                    {difference.oldVal ? 
                                        <><Text fontSize='sm' as='em' color='gray.500'fontWeight='bold' textTransform={'uppercase'}>{difference.op}&nbsp;</Text></>
                                        :
                                        <><Text fontSize='sm' as='em' color='gray.500'fontWeight='bold' textTransform={'uppercase'}>add&nbsp;</Text></>
                                    }
                                    {difference.path?.map((p, pathIndex) => {
                                        if(p != 'Loan Details' && p != '_seconds'){
                                            return(
                                                <span key={`edit-log-${log.id}-item-${index}-span-${pathIndex}`} style={{fontSize:'13px'}}>{p}&nbsp;</span>
                                                )   
                                        }     
                                        }                 
                                    )}
                                    {difference?.path.includes('Closing Date') || difference?.path.includes('App Date') ?
                                        <>                                    
                                        {difference?.val?.seconds ?
                                            <>
                                                {difference?.oldVal?.seconds && 
                                                <>
                                                    <Text fontSize='sm' textDecoration={'line-through'} style={{fontWeight:'bold', textTransform: 'uppercase'}}>
                                                    {moment(difference.oldVal.seconds*1000).format('MM/D/YY')}&nbsp;
                                                    </Text><FiArrowRight />
                                                </> 
                                            }
                                                <Text fontSize='sm' style={{fontWeight:'bold', textTransform: 'uppercase'}}>
                                                    &nbsp;{moment(difference.val.seconds*1000).format('MM/D/YY')}
                                                </Text>
                                            </>
                                            :
                                            <>
                                            {difference.oldVal && 
                                                <>
                                                    <Text fontSize='sm' textDecoration={'line-through'} style={{fontWeight:'bold', textTransform: 'uppercase'}}>
                                                        {moment(difference.oldVal*1000).format('MM/D/YY')}&nbsp;
                                                    </Text>
                                                    <FiArrowRight />
                                                </> 
                                            }
                                                <Text fontSize='sm' style={{fontWeight:'bold', textTransform: 'uppercase'}}>&nbsp;
                                                    {moment(difference.val*1000).format('MM/D/YY')}
                                                </Text>
                                            </>
                                        }
                                      
                                        </>
                                    :
                                        <>
                                        {difference.oldVal && 
                                            <>
                                            <Text textDecoration={'line-through'} 
                                                fontSize='sm' 
                                                style={{fontWeight:'bold', textTransform: 'uppercase'}}>
                                                    {(typeof difference.oldVal === 'object' &&  Object.getPrototypeOf(difference.oldVal).isPrototypeOf(Object)) ?
                                                        <>
                                                            {() => {
                                                                for(let property in difference.oldVal){
                                                                    <span>{property}: {difference.oldVal[property]} </span>
                                                                }
                                                            }}
                                                        </>
                                                    :
                                                    Array.isArray(difference.oldVal) ?
                                                        <>{difference.oldVal.toString()}</>
                                                    :
                                                        <>{difference.oldVal}</>
                                                    }
                                            </Text>
                                            <FiArrowRight />
                                            </> 
                                        }
                                        {difference.val && 
                                            <>
                                            <Text fontSize='sm' 
                                                style={{fontWeight:'bold', textTransform: 'uppercase'}}>
                                                    {(typeof difference.val === 'object' && Object.getPrototypeOf(difference.val).isPrototypeOf(Object)) ?
                                                             <>
                                                                {() => {
                                                                    for(let property in difference.val){
                                                                        <span>{property}: {difference.val[property]} </span>
                                                                    }
                                                                }}
                                                            </>
                                                        :
                                                        Array.isArray(difference.val) ?
                                                            <>{difference.val.toString()}</>
                                                        :
                                                            <>{difference.val}</>
                                                        }
                                            </Text>
                                            </>
                                        }
                                        </>
                                    }

                                    </Stack>
                    
                
                            </>
                            )
                        })}
                        </Stack>
                    
                    </Stack>
                    <Stack ml='60px' direction="row">
                        <>
                        {logItem['Process Pipeline Sync Result'] && logItem['Process Pipeline Sync Result'] != 'Error' && 
                            <>
                            <Center>
                                <Stack direction={'column'}>
                                    <Text mr='1' as='em' fontSize={'sm'}>{logItem['Process Pipeline Sync Result']} Process Pipeline</Text>
                                {/*        {logItem['Process Pipeline Sync Timestamp'] && 
                                        <Box 
                                            color='gray.500'
                                            letterSpacing='normal'
                                            fontSize={{
                                                'base':'10px',
                                                'lg': '12px'
                                            }}
                                            >{moment(logItem['Process Pipeline Sync Timestamp'].toDate()).calendar()}
                                        </Box>
                                    } */}
                                </Stack>
                            </Center>
                            <Avatar size='sm' src={require('../../../src/assets/img/astronaut-thumbup.png')}>
                                <AvatarBadge boxSize='1.25em' bg='green.500'>
                                    <Icon color='white' as={FiCheck}></Icon>
                                </AvatarBadge>

                            </Avatar>
                            </>
                        }
                        {logItem['Process Pipeline Sync Result'] && logItem['Process Pipeline Sync Result'] == 'Error' && 
                            <>
                            <Center>
                                <Stack direction={'column'}>
                                    <Text mr='1' as='em' fontSize={'sm'}>Error Updating Process Pipeline</Text>
                             {/*        {logItem['Process Pipeline Sync Timestamp'] && 
                                        <Box 
                                            color='gray.500'
                                            letterSpacing='normal'
                                            fontSize={{
                                                'base':'10px',
                                                'lg': '12px'
                                            }}
                                            >{moment(logItem['Process Pipeline Sync Timestamp'].toDate()).calendar()}
                                        </Box>
                                    } */}
                                </Stack>
                            </Center>
                            <Avatar size='sm' src={require('../../../src/assets/img/astronaut-error.png')}>
                                <AvatarBadge boxSize='1.25em' bg='red.500'>
                                    <Icon color='white' as={FiX}></Icon>
                                </AvatarBadge>
                            </Avatar>

                            </>
                        }
                        </>
                       
                    </Stack> 
                   
                </Box>
        
            </>
        )
        })
    )
}