import { CloseButton, Flex, useToast } from '@chakra-ui/react'
import { PriceTag } from './PriceTag'
import { CartProductMeta } from './CartProductMeta'
import QuantitySelectInCart from './QuantitySelect_InCart'
import { db } from '../../utils/init-firebase'
import { updateDoc, doc } from 'firebase/firestore'
import { useState, useEffect } from 'react'

export const CartItem = (props) => {
    const { pendingCarts, pendingCartsUpdated, products, inventory, setDisableCreate, id, handleSetSelectedItem, index, cart, item, taskID, disableEditing } = props
    const toast = useToast()
    const [itemInInventory, setItemInInventory] = useState()
    const [loading, setLoading] = useState(true)
    const [amountInCart, setAmountInCart] = useState(0)
    const [amountInPendingCarts, setAmountInPendingCarts] = useState()

    useEffect(async() => {
      if(!id){
        let tempCart = [...cart]
        tempCart[index].selected = 0
        setDisableCreate(index, false)
        await updateDoc(doc(db, 'Marketing Requests', taskID), {
          Cart: tempCart,
      })
      }
      else if(inventory){
        let inventoryIndex = inventory.findIndex((item => item.id == id))
        if(inventoryIndex > -1){
          setItemInInventory(inventory[inventoryIndex])
        }
        else{
          setItemInInventory(null)
        }        
      }
     
    }, [cart, inventory]) 

    
     // subtract amount in pending requests from selection options
  useEffect(() => {
    if(pendingCarts && pendingCarts.length > 0){
      let amount = 0
      pendingCarts.forEach((cart) => {
        const cartIndex = cart.findIndex((item => item.id == id))
        if(cartIndex > -1){
          amount += parseInt(cart[cartIndex].selected)
        }
      })
      setAmountInPendingCarts(amount)
    }
    else setAmountInPendingCarts(0)
  }, [pendingCarts, pendingCarts.length, pendingCartsUpdated])
        
        
    const handleUpdateCartInTask = async (id, index, value) => {
      if(taskID){
          let cartIndex = cart.findIndex((item => item.id == id))
          let tempCart = [...cart]
          // If the item is already in the cart
          if(cartIndex > -1){
              // If the item is being removed from the cart (user selected 0)
              if(value == 0){
                  toast({
                      title: `${cart[cartIndex].Name} Removed From Cart`,
                      status: 'warning',
                      position: 'top',
                      duration: 4000,
                      isClosable: true,
                  })
                  tempCart[cartIndex].selected = value
                  setDisableCreate(cartIndex, false)
              }
              else{
                  tempCart[cartIndex].selected = value
                  toast({
                      title: 'Cart Updated!',
                      description: `${value} ${cart[cartIndex].Name} are in your cart`,
                      status: 'success',
                      position: 'top',
                      duration: 4000,
                      isClosable: true,
                  })
              }
              await updateDoc(doc(db, 'Marketing Requests', taskID), {
                  Cart: tempCart,
              })

          }
          else{
              toast({
                  title: 'Error updating task',
                  status: 'error',
                  position: 'top',
                  duration: 4000,
                  isClosable: true,
                })
          }
      }
  }

  return (
    
    <Flex
      direction={'row'}
      justify="space-between"
      align="center"
    >{itemInInventory &&
        <>
        <CartProductMeta
          Name={itemInInventory.Name}
          photoURL={itemInInventory.photoURL}
        />
        <Flex
          width="full"
          justify="flex-end"
          display={'flex'}
        >
          <QuantitySelectInCart 
              Quantity={parseInt(itemInInventory.Quantity) - parseInt(amountInPendingCarts)}
              totalAvailable={itemInInventory.Quantity}
              amountInPendingCarts={amountInPendingCarts}
              setDisableCreate={setDisableCreate}
              id={id}
              cart={cart}
              index={index}
              disabled={disableEditing}
              handleSetSelectedItem={handleSetSelectedItem}
              handleUpdateCartInTask={handleUpdateCartInTask}
              product={itemInInventory}
              taskID={taskID}
          />
          <PriceTag price={cart[index].selected * itemInInventory.Cost} />
          <CloseButton disabled={disableEditing} ml='2' mt='1' aria-label={`Delete ${itemInInventory.Name} from cart`} onClick={
              taskID ? 
              () => handleUpdateCartInTask(id, index, 0)
              : 
              () => handleSetSelectedItem(id, index, 0)}
              />
        </Flex>
        </>
     
    }
    </Flex>
    
  )
}