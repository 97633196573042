import { FiUpload, FiVideo } from "react-icons/fi";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    IconButton,
    Input,
    Textarea,
    Stack,
    Radio,
    Image,
    Flex,
    RadioGroup,
    Box
  } from '@chakra-ui/react'
import { useState } from "react";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useRef, useCallback, useEffect } from "react";
import { firebase } from "../../utils/init-firebase";
import { useAuth } from "../../contexts/AuthContext";
import { serverTimestamp, doc, setDoc } from "firebase/firestore";

export default function AddVideoLinkPopover( { collectionRef } ){
    const { currentUser } = useAuth()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const storage = getStorage();
    const listRef = ref(storage, 'Video Link Thumbnails');
    const storageRef = firebase.storage().ref("Video Link Thumbnails");

    const [url, setURL] = useState()
    const [selectedThumbnail, setSelectedThumbnail] = useState()
    const [message, setMessage] = useState()
    const inputRef = useRef();
    const formRef = useRef()

    const [bucketFiles, setBucketFiles] = useState([])

    const [files, setFiles] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])

    const [uploading, setUploading] = useState(false)

    const handleDeleteFile = (url) => {
        // Create a reference to the file to delete
        const desertRef = ref(storage, `Video Link Thumbnails/${url}`);

        // Delete the file
        deleteObject(desertRef).then(() => {
        }).catch((error) => {
        // Uh-oh, an error occurred!
        });
    }
    async function addMessage(){
        try{
            const newMsgRef = doc(collectionRef)
            
            await setDoc(newMsgRef, {
                message: message,
                type: 'Video Link',
                url: url,
                thumbnail: selectedThumbnail,
                id: newMsgRef.id,
                user: {
                    uid: currentUser.uid,
                    displayName: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    email: currentUser.email,
                },
                timestamp: serverTimestamp(),
               
            })
            
            onClose()
        }
        catch(err){
            console.log(err)
        }
    }
    useEffect(() => {
        // Now we get the references of these images
        storageRef.listAll().then(function(result) {
            let promises = []
            result.items.forEach(function(imageRef) {
                promises.push(getURL(imageRef));
            });
            Promise.all(promises).then((results) => {
                setBucketFiles(results)
            })
        }).catch(function(error) {
            // Handle any errors
        });
    
        function getURL(imageRef) {
            return new Promise((resolve, reject) => {
                imageRef.getDownloadURL().then(function(url) {
                        resolve(url)
                    }).catch(function(error) {
                        reject(error)
                    });
            })
           
        }
    }, [])

    useEffect(()=> {
        uploadFiles();
      }, [files])

    const onFileChange = (e) => {
        setFiles(e.target.files)
    }
    const uploadFiles = useCallback(()=>{
        if(files.length > 0)
        {
            setUploading(true)                    
            const theseFiles = files;
            setFiles([])
            var promises = []
            for (let i = 0; i < theseFiles.length; i++) {         
                    const storageRef = firebase.storage().ref();
                    const file = theseFiles[i];
 
                    promises.push(storageRef
                    .child(`Video Link Thumbnails/${file.name}`)
                    .put(file).then( async (snapshot) => {
                       snapshot.ref.getDownloadURL().then( async (url) => {
                            setBucketFiles(prevState => [...prevState, url])
                                    return
                                }
                            )
                        })
                    )
            }
            Promise.all(promises)
            .then( async () => {
                    setUploading(false)
                }
            )
            .catch(err => console.log(err.code));
            
        }
    },[files])

    return(
        <>
            <IconButton
                position='absolute'        
                icon={<FiVideo />}
                variant='ghost'
                isLoading={uploading}
                disabled={uploading}
                bottom={2}
                right={20}
                onClick={onOpen}
            >
    
            </IconButton>
            <Modal size='xl' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Add Video Link</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Input 
                    mb='2'
                    value={url}
                    onChange={(e) => setURL(e.target.value)}
                    placeholder='Enter video URL' />
                <Textarea 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Enter message'
                />
                <Stack dir='column' spacing='2'>
                    <RadioGroup value={selectedThumbnail} onChange={setSelectedThumbnail}>
                    {bucketFiles && bucketFiles.map((file, i) => (
                        <Flex flexDir='row' mb='2' key={`image-wrapper-select-${i}`}>
                            <Radio mr='2' value={file}></Radio>
                            <Image
                                boxSize='150px'
                                objectFit='cover'
                                src={file}
                                alt={`thumbnail-${i}`}
                                mr='2'
                            />
                            {/* <IconButton
                                alignSelf={'center'}
                                icon={<FiTrash2 />}
                                colorScheme="red"
                                variant='ghost'
                                mr='2'
                                onClick={() => handleDeleteFile(file)}
                            >

                            </IconButton> */}
                        </Flex>

                    ))}
                    </RadioGroup>
                    
                </Stack>
            </ModalBody>

            <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <input type='file'
                        onChange={onFileChange}
                        accept="image/jpeg, image/jpg, image/png"
                        name={'Upload Files'}
                        ref={inputRef}
                        style={{display: 'none'}} />
                    <IconButton
                        size='md'
                        mr='2'
                        colorScheme='blue'
                        icon={<FiUpload/>}
                        isLoading={uploading}
                        disabled={uploading}
                        onClick={() => inputRef.current.click()}
                    />
                <Box>
       
                   <Button colorScheme='gray' mr={3} onClick={onClose}>
                    Close
                </Button>
                    <Button onClick={addMessage} disabled={!url || !selectedThumbnail} colorScheme='green'>Send</Button>
                </Box>
            </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    )
}