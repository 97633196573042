import React, {useState, useEffect} from 'react'
import { requestForToken, onMessageListener } from './utils/init-firebase'
import { useToast, Avatar, Link, Alert, AlertIcon, AlertTitle, Button, Spacer, Flex, Stack, CloseButton, AlertDialogBody, Center, AlertDescription, IconButton } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom';
import { FiX } from 'react-icons/fi';

const Notification = () => {
  const toast = useToast()
  const toastRefs = React.useRef([]);
  const history = useHistory()
  const [notification, setNotification] = useState({title: '', body: '', link: '', username: '', userPhotoURL: ''});
    
  const handleRouteChange = (link) =>{
      history.push(link)
  }

  useEffect(() => {
    if (notification?.title ){
    toast({
        duration: 3000,
        position: 'top',
        isClosable: true,
        variant: 'solid',
        
        render: ( { onClose } ) => {
            toastRefs.current.push(onClose)
            return(
            <Alert status='info' borderRadius={10}>
                <AlertIcon/>
                <Avatar name={notification?.username} src={notification?.userPhotoURL} mr={3} />
                <AlertTitle mr={3}> {notification?.title}<br/>{notification?.body} </AlertTitle>
                <AlertDescription>{notification?.body}</AlertDescription>
                {notification?.link && <Button colorScheme='green' mr={3} variant='solid' width='4em' onClick={() => handleRouteChange(notification?.link)} size='sm' >View</Button>}
                <IconButton 
                    onClick={() => {
                        toastRefs.current.forEach(cb => cb());
                        toastRefs.current = [];
                    }}
                    icon={<FiX/>}
                    variant='ghost'
                />
            </Alert>
            )
        }
       
        })
    } 
  }, [notification])


  onMessageListener()
    .then((payload) => {
      setNotification({
          title: payload?.notification?.title || payload?.data?.title, 
          body: payload?.notification?.body || payload?.data?.body, 
          link: payload?.notification?.link || payload?.data?.link, 
          username: payload?.notification?.username || payload?.data?.username, 
          userPhotoURL: payload?.notification?.icon || payload?.data?.icon || "src/assets/img/fhl-logo.png", 
        });     
    })
    .catch((err) => console.log('failed: ', err));

   

  return null
}

export default Notification