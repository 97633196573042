

import React, { useState, useEffect, useCallback, useRef } from "react";
import {  doc, updateDoc, serverTimestamp, setDoc, collection, getDocs, where, query } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db, firebase } from "../../utils/init-firebase"
import { Formik } from "formik";
import { FiUpload, FiXCircle } from "react-icons/fi";
import {
  SubmitButton,
  InputControl
} from "formik-chakra-ui";
import {
    Box,
    useDisclosure,
    useToast,
    Flex,
    IconButton,
    useColorModeValue,
    FormControl,
    FormLabel,
    InputGroup,
    Icon,
    Image,
    Grid,
    GridItem} from "@chakra-ui/react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

export default function AddNewsletter({ action, values, docID, quantity, setShowAddSwagItem, itemTags, itemCats }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const titleCompanyBorderColor = useColorModeValue('gray.300', 'gray.600')
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")
    const fileBackgroundColor = useColorModeValue('gray.100', 'gray.600')
    const { currentUser, NMLS, SuperAdmin, MarketingAdmin } = useAuth()
    const toast = useToast()
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const firstField = React.useRef()
	const inputRef = useRef();
    const formRef = useRef()
    const [quantityToAdd, setQuantityToAdd] = useState('')

    const [tags, setTags] = useState()
    const [cats, setCats] = useState()

    const createRequest = useRef()

    const [files, setFiles] = useState([])
    const [uploadedFile, setUploadedFile] = useState(values ? 
        {
            name: values.photoName ? values.photoName : '',
            url: values.photoURL

        }
        :
        {
            name: '',
            url: ''
        }
    )

    const [uploadProg, setUploadProg] = useState()
    const [uploading, setUploading] = useState(false)

    const [eventDate, setEventDate] = useState('')

    const {
		handleSubmit,
		register,
		setError,
		control,
		formState: { errors, isSubmitting },
	} = useForm()

    const validationSchema = Yup.object({
        Name: Yup.string().required('Required')
      });

    let initialValues = []
    if(values == null){
        initialValues = {
            'photoURL': '',
            'Name': '',
        };
    }
    else {
        initialValues = values
    }
    const uploadFiles = useCallback(()=>{
        if(files.length > 0)
        {
            setUploading(true)                    
            const theseFiles = files;
            setFiles([])
            var promises = []
            for (let i = 0; i < theseFiles.length; i++) {         
                    const storageRef = firebase.storage().ref();
                    const file = theseFiles[i];
 
                    promises.push(storageRef
                    .child(`Monthly Newsletters/${file.name}`)
                    .put(file).then( async (snapshot) => {
                       snapshot.ref.getDownloadURL().then( async (url) => {
                            setUploadedFile({url: url, name: file.name})
                                    return
                                }
                            )
                        })
                    )
            }
            Promise.all(promises)
            .then( async () => {
                    setUploading(false)
                }
            )
            .catch(err => console.log(err.code));
            
        }
    },[files])

    const onFileChange = (e) => {
        setFiles(e.target.files)
    }

    useEffect(()=> {
        uploadFiles();
      }, [files])

    const onCreate = async (values) => {
        try{
            const q = query(collection(db, "Monthly Newsletters"), where("Name", "==", values.Name));

            const querySnapshot = await getDocs(q);
            let itemExists = false
            querySnapshot.forEach((doc) => {
                if(doc.exists()){
                    itemExists = true
                    return
                }
            });

            if(itemExists){
                toast({
                    description: 'A newsletter with that name already exists',
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                    position: 'top'
                })
            }
            else{
                const newDocRef = doc(collection(db, 'Monthly Newsletters'))
                await setDoc(newDocRef, {
                    Name: values.Name,
                    photoURL: uploadedFile?.url,
                    photoName: uploadedFile?.name ? uploadedFile.name : null,
                    timestamp: serverTimestamp(),
                    user: {
                        displayName: currentUser.displayName,
                        email: currentUser.email,
                        photoURL: currentUser.photoURL,
                        uid: currentUser.uid
                    },
                    id: newDocRef.id
                })
    
                toast({
                    title: 'Newsletter Added!',
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                    position: 'top'
                })
            }
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    };

    const onEdit = async (values) => {
        try{
            await updateDoc(doc(db, 'Monthly Newsletters', values.id), {
                    Name: values.Name,
                    photoURL: uploadedFile?.url,
                    timestamp: serverTimestamp(),
                    user: {
                        displayName: currentUser.displayName,
                        email: currentUser.email,
                        photoURL: currentUser.photoURL,
                        uid: currentUser.uid
                    },
                    'Last Edited By': {
                        displayName: currentUser.displayName,
                        email: currentUser.email,
                        photoURL: currentUser.photoURL,
                        uid: currentUser.uid
                    }
                })
    
                toast({
                    title: 'Newsletter Updated!',
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                    position: 'top'
                })
            
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    };

   

  return (
      <>
        <Formik
            initialValues={initialValues}
            onSubmit={action == 'Add' ? onCreate : onEdit}
            innerRef={formRef}
            validationSchema={validationSchema}
            >
            {({ handleSubmit, values }) => (
                <Box
                borderWidth={{'base': 'none', 'md': "1px"}}
                rounded="lg"
                mb={5}
                w={{base: '80vw', lg: '35vw'}}
                shadow={{'base': 'none', 'md': "1px 1px 1px rgba(0,0,0,0.3)"}}
                p={{'base': '0', 'md': "5"}}
                as="form"
                onSubmit={handleSubmit}
                >
                    <Grid
                      templateColumns='repeat(6, 1fr)'
                      gap={4}>

                    <GridItem colSpan={action != 'Add' ? {base: 6, lg: 4} : 6}>
                    <InputControl 
                        name="Name"
                        label="Name"
                    >
                    </InputControl>
                    <Flex flexDir={'row'}>
                        <FormControl mt={5} mb={5}>
                            <FormLabel htmlFor="fileUpload">Newsletter Image</FormLabel>
                            <InputGroup>
                                {!uploadedFile.url && 
                                    <IconButton
                                        onClick={() => inputRef.current.click()}
                                        >
                                        <Icon as={FiUpload} />
                                    </IconButton>
                                }
                                
                                <input type='file'
                                    onChange={onFileChange}
                                    name={'Upload Image'}
                                    ref={inputRef}
                                    style={{display: 'none'}} />
                                {uploadedFile.url &&
                                    <>                                
                                    <Image
                                        src={uploadedFile.url}
                                        maxH='150px'
                                    />
                                    <Flex ml='1' flexDir={'column'} justify='space-between'>
                                        <IconButton variant={'unstyled'} onClick={() => setUploadedFile({name: '', url: ''})}><Icon as={FiXCircle}></Icon></IconButton>
                                        <IconButton
                                            variant={'unstyled'}
                                            onClick={() => inputRef.current.click()}
                                            >
                                            <Icon as={FiUpload} />
                                        </IconButton>

                                    </Flex>
                                    </>

                                }

                            </InputGroup>
                        </FormControl>
                      
                      
                    </Flex>
                   
                    <Flex justify={'flex-end'} flexDir='row'>
                        <SubmitButton isLoading={uploading} disabled={uploading} alignSelf={'flex-end'}>{action == 'Add' ? 'Add' : 'Save'}</SubmitButton>
                    </Flex>
                    
            
            </GridItem>
           
            </Grid>
        </Box>
            )}
            
        </Formik>

      </>
  );
}
