import BooleanSwitch from "../Settings/BooleanSwitch";


export default function DefaultClientSettings( { docRef, initialSettings } ){

    return(
        <>
        <BooleanSwitch
            docRef={docRef}
            fieldPath={'Default Client Settings.Email LO When Parked'}
            initialState={initialSettings ? initialSettings['Email LO When Parked'] ? initialSettings['Email LO When Parked'] : false : false}
            label='Send Email Reminders to LO every 3 hours when Parked'
            id='email-lo-when-parked'
            successMsg={'New clients will have LO email reminders when parked'}
            warningMsg={'New clients will NOT have LO email reminders when parked'}
            description={'Admins can control this setting on individual clients'}
        />
      
        <BooleanSwitch
            docRef={docRef}
            fieldPath={'Default Client Settings.Push LO When Parked'}
            initialState={initialSettings ? initialSettings['Push LO When Parked'] ? initialSettings['Push LO When Parked'] : false : false}
            label='Send Push Notification to LO every 3 hours when Parked'
            id='push-lo-when-parked'
            successMsg={'New clients will have LO push notifications when parked'}
            warningMsg={'New clients will NOT have LO push notifications when parked'}
            description={'Admins can control this setting on individual clients'}
        />
        </>
    )
}