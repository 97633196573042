import {
    Flex,
    Wrap,
    WrapItem,
    Text,
    ScaleFade,
    Stack,
    Heading,
    Box,
    Icon,
    Divider,
    Tooltip,
} from "@chakra-ui/react";
import { FaCartPlus } from "react-icons/fa";
import { CartItem } from "../MarketingSwagV2/CartItem";
import { useState, useEffect, useRef } from "react";
import { db } from "../../utils/init-firebase";
import { collection, collectionGroup, doc, getDoc, getDocs } from "firebase/firestore";
import { onSnapshot, where, query } from "firebase/firestore";
import { InputControl } from "formik-chakra-ui";
import { PriceTag } from "../MarketingSwagV2/PriceTag";
import { useAuth } from "../../contexts/AuthContext";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useBudget } from "../../pages/Profile/useBudget";
import SwagBudget from "../SwagBudget/SwagBudget";

let today = new Date(), y = today.getFullYear(), m = today.getMonth();
const firstDayOfMonth = new Date(y, m, 1, 0, 0, 0, 0)
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0)

export default function SwagRequestFields( { pendingCartsListenerRef, products, inventory, setDisableCreate, cart, handleSetSelectedItem, taskID, values, request, owner } ){
    const { currentUser, swagBudget, users } = useAuth()
    const { transactions, budget } = useBudget( {
        value: currentUser.uid,
        photoURL: currentUser.photoURL,
        name: currentUser.displayName
    }, y)
    const [pendingCarts, setPendingCarts] = useState()
    const [inv, setInv] = useState()
    const [loading, setLoading] = useState(true)
    const [pendingCartsUpdated, setPendingCartsUpdated] = useState(false)
    const [cartTotal, setCartTotal] = useState()
    const [monthlySwagTotal, setMonthlySwagTotal] = useState()
    const [monthlyBudget, setMonthlyBudget] = useState()


    const unsubscribe = useRef()

    const pendingCartsRef = useRef()

    useEffect( async () => {

        function calculateMonthlySwagTotal(transactions){
            // console.log(`transactions:`)

            // console.log(transactions)
            let total = 0
            transactions.forEach((transaction) => {
                total += parseInt(transaction?.Item?.Amount) * parseFloat(transaction?.Item?.Cost)
            })
            return total
        }
        if(!request || request?.Status != 'Completed'){
            const queryCurrentMonthTransactions = query(collectionGroup(db, "Marketing Swag Transactions"), 
                where(`Owner.uid`, '==', owner?.value ? owner.value : currentUser.uid),
                where('timestamp', '>=', firstDayOfMonth), 
                where('timestamp', '<=', lastDayOfMonth));
        
            const querySnapshot = await getDocs(queryCurrentMonthTransactions)
            let currentMonthTransactions = []
            querySnapshot.forEach((doc) => currentMonthTransactions.push(doc.data()))
            setMonthlySwagTotal(calculateMonthlySwagTotal(currentMonthTransactions))

            // if user has an individual swagBudget, use that. If not, use the default in Marketing Settings. Fallback to 250
            if(owner && owner.swagBudget){
                setMonthlyBudget(owner.swagBudget)
            }
            // currentUser's swagBudget
            else if(swagBudget){
                setMonthlyBudget(swagBudget)
            }
            else {
                const marketingSettingsSnap = await getDoc(doc(db, 'Marketing Settings', 'yEwRIqrkneqrGnAQhgvs'))
                if(marketingSettingsSnap.exists()) {
                    setMonthlyBudget(marketingSettingsSnap.data()['Task Settings'] ? 
                                        marketingSettingsSnap.data()['Task Settings']['Default Monthly Swag Budget'] ? 
                                        marketingSettingsSnap.data()['Task Settings']['Default Monthly Swag Budget'] : 250 : 250)
                }
            }
            
        }
    }, [owner])

    useEffect(() => {
        console.log(`Swag Budget: ${monthlyBudget}`)
    }, [monthlyBudget])

    useEffect( async () => {       
        if(!inventory) {
            unsubscribe.current && unsubscribe.current()
            const q = query(collection(db, "Marketing Swag"));
            unsubscribe.current = onSnapshot(q, (querySnapshot) => {
                const swag = [];
                querySnapshot.forEach((doc) => {
                    swag.push(doc.data());
                });
                setInv(swag)
            });
        }

        setLoading(false)
        return () => {
            unsubscribe.current && unsubscribe.current()
        }

    }, [])

    useEffect( async () => {
        const queryPending = query(collection(db, "Marketing Requests"), 
                                where(`Request Details.Request Type`, '==', 'Swag'), 
                                where('Status', 'in', ['New', 'In Progress']),
                                where('Cart', '!=', false))
        pendingCartsListenerRef && pendingCartsListenerRef.current && pendingCartsListenerRef.current()
        pendingCartsRef && pendingCartsRef.current && pendingCartsRef.current()
        pendingCartsRef.current = onSnapshot(queryPending, (snapshot) => {
            let carts = []
            snapshot.forEach((doc) => {
                if(doc.data().Cart && doc.data().Cart.length > 0 &&  (doc.data().id != taskID)){
                        carts.push(doc.data().Cart)
                }          
            })
            setPendingCarts(carts)
            setPendingCartsUpdated(Math.random())
        })    
        return () => {
            pendingCartsRef && pendingCartsRef.current && pendingCartsRef.current()
        }

    }, [])

    useEffect(() => {
        if(cart && cart.length > 0 && inventory){
            let total = 0
            cart.forEach((cartItem) => {
                let inventoryIndex = inventory.findIndex((item => item.id == cartItem?.id))
                if(inventoryIndex > -1){
                    total += cartItem?.selected * inventory[inventoryIndex]?.Cost
                }
            })
            setCartTotal(total)
        }
    }, [cart])

    return(
        <ScaleFade in initialScale={0.8}>
        <>
            {!loading && pendingCarts &&
            <Box p={3} mb={5} w='auto' borderRadius={'md'} border='1px solid lightgrey'>
                <Heading mb={2} size='md'><Icon as={ FaCartPlus }></Icon> Shopping Cart</Heading>
                <Stack>
                {cart && cart.length > 0 && cart.map((item, i) => {
                    if(item.selected && item.selected > 0){
                        return(
                            <>
                            <CartItem  
                                pendingCarts={pendingCarts}
                                pendingCartsUpdated={pendingCartsUpdated}
                                handleSetSelectedItem={handleSetSelectedItem}
                                setDisableCreate={setDisableCreate}
                                disableEditing={request?.Status == 'Completed' ? true : false}
                                products={products}
                                inventory={inv ? inv : inventory}
                                item={item}
                                index={i}
                                id={item.id}
                                cart={cart}
                                taskID={taskID}
                                key={item.id}
                            />
                            </>
                        )
                    }
                })
                
                }
                {!cart || cart.length == 0 &&<Text>There are no items in your cart</Text>}
                <SwagBudget 
                    user={{value: currentUser.uid, name: currentUser.displayName, photoURL: currentUser.photoURL}} 
                    cartTotal={cartTotal}
                    transactions={transactions} 
                    selectedYear={y} 
                    budget={budget}
                    />
                </Stack>
            </Box>
            }
            {!loading &&
            <>
             <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                <WrapItem width={{ base: '100%' }} h={20}>
                    <InputControl 
                        name="Shipping"
                        label="Shipping Address (if applicable)"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                  <WrapItem width={{ base: '100%' }} h={20}>
                      <InputControl 
                          name="Request Relation"
                          label="Is this related to another request?"
                      >
                      </InputControl>
                  </WrapItem>
              </Wrap>
              </>
            }
        </>
        </ScaleFade>
    )
}