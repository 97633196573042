import { FormControl, FormLabel, Switch, useToast } from "@chakra-ui/react";
import { updateDoc, setDoc, getDoc } from "firebase/firestore";

//In fieldPath - Seperate parent and child field with .
//Example Parent Field.Child Field
export default function BooleanSwitch( { docRef, fieldPath, initialState, label, id, successMsg, warningMsg, description, preventDefault}){
    const toast = useToast()
    const handleToggle = async (e, docRef, fieldPath) => {
      preventDefault && e.preventDefault()
        try{
          let status = null
          let title = null
          if(e.target.checked == true){
            status = 'success'
            title = successMsg
          }
          else {
            status = 'warning'
            title = warningMsg
          }
          const doc = await getDoc(docRef)
          //If the settings document exists, update it. Else create it and add data
          if(doc.exists()){
            await updateDoc(docRef, {
              [fieldPath]:  e.target.checked
        
            });
          }
          else{
              //setDoc will not create an object using a nested field (i.e Parent Field.Child Field)
            //So, split them and explicity create the object
            let nestedFields = fieldPath.split('.')
            await setDoc(docRef, {
              [nestedFields[0]]: {
                [nestedFields[1]]: e.target.checked
              }
            });
          }
          toast({
            title: title,
            description: description,
            status: status,
            position: 'top',
            isClosable: true
          })
        }
        catch(err){
            console.log(err)
            toast({
              title: "Error: " + err,
              status: 'error',
              position: 'top',
              isClosable: true
            })
        } 
      }

    return(
      <>
      {docRef &&
        <FormControl mb={2} display='flex' alignItems='center'>
     
            <Switch id={id}
                    onChange={(e) => handleToggle(e, docRef, fieldPath)}
                    defaultChecked={initialState}
                    mr={2}
            />
            <FormLabel mb='0'>
              {label}
            </FormLabel>
      </FormControl>       
    }
    </>
    )
}