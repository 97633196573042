// TopicList.js
import Topic from './Topic';
import { ReactSortable } from "react-sortablejs";
import { Button } from '@chakra-ui/react';

function TopicList( { topics, setTopics, updateTopic, deleteTopic, selectedTopic, handleSelectTopic, handleReorderTopics } ) {
  return (
    <>
    {topics &&
    <>
        <ReactSortable
          // here they are!
          list={topics}
          setList={setTopics}
          group="topics"
          

        >      
        {topics?.map((topic) => (
            <Topic 
              key={topic.id}
              topic={topic} 
              handleSelectTopic={handleSelectTopic}
              selectedTopic={selectedTopic}
              handleUpdateTopic = {updateTopic}
              handleDeleteTopic = {deleteTopic}
            />

      ))}
      </ReactSortable>
       <Button size='sm' mt='1' onClick={handleReorderTopics} colorScheme='green'>Save Order</Button>
    </>
    }
    </>
  );
}

export default TopicList;

