import React from "react";
import { WrapItem, Wrap, useColorModeValue } from "@chakra-ui/react";

interface File {
  name: string;
  url: string;
}

interface FileListProps {
  files: File[];
}

const FileList: React.FC<FileListProps> = ({ files }) => {
  const linkColor = useColorModeValue('blue.400', 'blue.200');

  return (
    <Wrap justify="flex-start" mb={2}>
      {files.map((file, i) => (
        <WrapItem key={i} color={linkColor} _hover={{ textDecoration: 'none' }} textDecoration="underline" fontSize="12px">
          <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default FileList;
