import { Input, FormControl, FormLabel, InputGroup, InputLeftElement, FormErrorMessage, Icon } from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { useController } from "react-hook-form";
import { useRef } from "react";

export const FileUpload = ({ onChange, name, placeholder, acceptedFileTypes, control, children, isRequired = false }) => {
	const inputRef = useRef();
	const {
		field: { ref, value, ...inputProps },
		fieldState: { invalid, isTouched, isDirty },
	} = useController({
		name,
		control,
		rules: { required: isRequired },
	});

	return (
		<FormControl>
			<FormLabel htmlFor="fileUpload">{children}</FormLabel>
			<InputGroup>
				<InputLeftElement
					pointerEvents="none">
					<Icon as={FiFile} />
				</InputLeftElement>
				<input type='file'
					   onChange={() => onChange()}
					   accept={acceptedFileTypes}
                       multiple='multiple'
					   name={name}
					   ref={inputRef}
					   {...inputProps}
					   style={{display: 'none'}} />
				<Input
					placeholder={placeholder || "Click to upload files..."}
					onClick={() => inputRef.current.click()}
					// onChange={(e) => {}}
					readOnly={true}
					value={value && value.name || ''}
				/>
			</InputGroup>
			<FormErrorMessage>
				{invalid}
			</FormErrorMessage>
		</FormControl>
	);
}

FileUpload.defaultProps = {
	acceptedFileTypes: '',
};

export default FileUpload;