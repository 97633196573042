// question.js
import { Text, Flex, Switch } from '@chakra-ui/react';

function QuestionToggle(props) {
  const { question, included, handleToggleQuestion } = props

  return (
      <Flex key={question.id}
            flexDir={'row'}
            mb='2'
            width='25vw'
            justify={'flex-start'}
            align='center'
            >
            <Switch 
                mr='2'
                id={question.id}
                defaultChecked={included}
                onChange={(e) => handleToggleQuestion(e, question.id)}
                >           
            </Switch>
            <Flex>
                <Text>{question.title}</Text>
                <Text>&nbsp;({question.type})</Text>
            </Flex>
      </Flex>
  );
}

export default QuestionToggle;
