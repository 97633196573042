import React from "react";
import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogBody,
    Text
 } from '@chakra-ui/react'
export default function DeleteDocDialog( {isOpen, onOpen, onClose, type, content, handleDelete} ){
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader >
                <Text fontSize='lg' fontWeight='bold'>
                  Delete {type}?
                </Text>
                <Text mt={2} fontSize='md' fontWeight='normal' fontStyle='italic'>
                {content}
                </Text>
              
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button style={{alignSelf: 'left'}} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' ml={3} onClick={() => handleDelete()}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }