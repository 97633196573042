import { Box, Container } from '@chakra-ui/react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import DrawerBar from './DrawerBar'
import { useAuth } from '../../contexts/AuthContext'
import { useDisclosure } from '@chakra-ui/react'
import Notification from '../../Notification'
import VersionListenerNotification from '../../VersionListenerNotification'
import { Redirect, useLocation } from 'react-router-dom'
import Breadcrumbs from '../Breadcrumbs'

export function Layout({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentUser } = useAuth()
  const location = useLocation()


  return (
    <>
    {currentUser ?
        <>
        <Notification />
        <VersionListenerNotification />
        <Navbar onOpen={onOpen} />
        <Box mb={16} display='flex' flexDir={'row'}>
          <DrawerBar isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          <Sidebar navSize='large'/>
          <Container  ml={{base: 0, md: 2}} mt='3' maxW='full'>
              <Breadcrumbs />
              <Box>
                {children}

              </Box>
          </Container>
        </Box>
       </>
    :
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location.pathname },
        }}
    />
    
    }
    </>
  )
}
