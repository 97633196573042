import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Breadcrumbs( { } ){
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState();
    useEffect(() => {
        console.log(location.pathname)
        if(location.pathname != '/'){
            setCurrentPath(location.pathname.split('/'));
        }
        else setCurrentPath(null)
    }, [location]);

    const makePageNameFromPath = (str) => {
        if(str.includes('-')){
            let returnValue = ""
            str.split('-').forEach((s) => {
                returnValue += s.charAt(0).toUpperCase() + s.slice(1) + ' '
            })
            return returnValue
        }
        else return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const makePath = (str, index) => {
        let path = ''
        for(let i = 0; i <= index; i++){
            path += str[i]
            if(i < index) path += '/'
        }
        return path
    }
    if(currentPath){
        return(    
            <Breadcrumb position='absolute' fontSize='xs'>
                <BreadcrumbItem>
                    <BreadcrumbLink as={RouterLink} to='/'>Home</BreadcrumbLink>
                </BreadcrumbItem>
                {currentPath && currentPath.map((path, i) => {
                    if(path != ""){
                        return(
                            <BreadcrumbItem key={`breadcrumb-${i}`}>
                                <BreadcrumbLink 
                                    key={`breadcrumb-link-${i}`} 
                                    as={RouterLink} 
                                    to={makePath(currentPath, i)}>                                   
                                        {makePageNameFromPath(path)}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        )
                    }
    
                })}
            </Breadcrumb>
        )      
    }
    else return null

}