// CalendarForm.js
import { useState } from 'react';
import { Button, Input, Flex, Center, Text } from '@chakra-ui/react';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  lower: Yup.number('Must be a number').required('Required'),
  upper: Yup.number('Must be a number').required('Required'),

});

function CalendarForm({ initVals, onSubmit, onCancel, isSubmitting, placeholderText }) {
    const [lower, setLower] = useState(initVals && initVals.lower && initVals.lower)
    const [upper, setUpper] = useState(initVals && initVals.upper && initVals.upper)

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({lower: lower, upper: upper})
    setLower('')
    setUpper('')
  }

  return (
        <Center mb='2'>
        <Flex w='20vw' align='center' justify={'center'}>

            <Flex flexDir={'row'} align={'center'}>
                <Input
                type="number"
                value={lower}
                onChange={(e) => setLower(e.target.value)}
                placeholder='Lower'
                _placeholder={{fontSize: '14px'}}
                required
                />
                <Text align='center'>&nbsp;-&nbsp;</Text>
                <Input
                type="number"
                required
                placeholder='Upper'
                _placeholder={{fontSize: '14px'}}
                value={upper}
                onChange={(e) => setUpper(e.target.value)}
                />
          </Flex>
        
        <Button
            type='submit'
            isLoading={isSubmitting}
            onClick={(e) => handleSubmit(e)}
            disabled={isSubmitting || (!lower || !upper)}
            colorScheme='blue'
            ml={2}
            mr={2}
        >
            {initVals ? 'Save' : 'Add'}
        </Button>
        </Flex>
        </Center>
  );
}

export default CalendarForm;
