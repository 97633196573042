import {
    Flex,
    Text,
    Icon,
    Menu,
    MenuButton,
    Stack,
    Divider
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export default function DrawerButton({ icon, rightIcon, name, to, navSize, onOpen, isChild, isFirstChild, isLastChild }) {
    const location = useLocation()
    const isActive = location.pathname === to
    return (
            <Flex
                pr={'3%'}
                pt={navSize == 'small' ?  '22%' : isChild ? '6%' : '8%'}
                pb={navSize == 'small' ?  '22%' : isChild ? '6%' : '8%'}
                pl={isChild ? 23 : 3}
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                style={{alignItems: rightIcon? 'center' : null}} 
                borderRight={isActive ? '5px solid lightblue' : ''}
                backgroundColor={isActive ? '#e8e8e8' : ''}
                onClick={onOpen}
                >
                <Menu placement="right">
                    <Flex 
                        backgroundColor={isActive && "#AEC8CA"}
                        border={isActive ? '3px solid' : ''}
                        borderRadius={8}
                        w={navSize == "large" && "100%"}
                    >
                        <MenuButton w="100%">
                            <Flex>
                                {isChild && 
                                     <Stack direction='row' >
                                        {isLastChild ? 
                                            <Divider style={{height: '50%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        :
                                        isFirstChild ?
                                            <Divider style={{height: '450%', borderColor: 'gray', margin: 0, marginTop: '-45px'}}orientation='vertical' />
                                        :
                                            <Divider style={{height: '275%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        }
                                        <Divider style={{width: '10px', borderColor: 'gray', margin: 0, padding: 0, justifySelf: 'flex-start', alignSelf: 'center'}} orientation='horizontal' />                       
                                 </Stack>
                                }               
                                {icon ? <Icon as={icon} fontSize="xl"  /> : null}
                                <Text style={{letterSpacing: '-1px'}}size='md' ml={rightIcon ? 0 : 2} display={navSize == "small" ? "none" : "flex"}>{name}</Text>
                                {rightIcon ? <Icon ml={5} as={rightIcon} fontSize="xl" /> : null}
                            </Flex>
                        </MenuButton>
                    </Flex>
            
                </Menu>
            </Flex>
    )
}