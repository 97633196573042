import { useEffect, useState } from "react";
import GroupChat from "../Client/GroupChat";
import AddSwagItem from "./AddEditSwagItem";
import { db } from "../../utils/init-firebase";
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import TransactionLog from "../TransactionLog/TransactionLog";

export default function SwagItem( {  } ){
    const { name } = useParams()
    const [item, setItem] = useState()
    const [quantity, setQuantity] = useState()
    const [transactions, setTransactions] = useState()
    const [itemTags, setItemTags] = useState()
    const [itemCats, setItemCats] = useState()

    useEffect(async () => {

        const q = query(collection(db, "Marketing Swag"), where("Name", "==", name));

        const unsub = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setItem(doc.data())
                setQuantity(doc.data().Quantity)
                setItemTags(doc.data().tags)
                setItemCats(doc.data().categories)
            });
        })           
    
        return () => {
            unsub()
        }
      
    }, [name])

    useEffect( async () => {
        if(item){
            const q = query(collection(db, "Marketing Swag", item.id , "Marketing Swag Transactions"), orderBy('timestamp', 'desc'));
            const unsub = onSnapshot(q, (querySnapshot) => {
                let trans = []
                querySnapshot.forEach((doc) => {
                    trans.push(doc.data())
                })
                setTransactions(trans)
            })
            return( () => {
                unsub()
            })
        }
        
     
    }, [item])

    return(
        <>
            {item && name &&
            <>
                <Flex mt='5' flexDir={{base: 'column', xl: 'row'}}>
                    <Flex m={2} w={{base: '90vw', md:'80vw', xl: '55vw'}} flexDir={'column'}>
                        <AddSwagItem values={item} quantity={quantity} docID={item.id} itemTags={itemTags} itemCats={itemCats}/>
                        <GroupChat 
                            width={'100%'}
                            parentID={item.id} 
                            parentDocContent={item} 
                            collectionName='Marketing Swag' 
                            collectionRef={collection(db, 'Marketing Swag', item.id, 'Comments')} 
                            heading={'COMMENTS'}/>
                    </Flex>
                    <Flex m={2} w={{base: '90vw', md:'80vw', xl: '30vw'}}>
                        <TransactionLog transactions={transactions} />

                    </Flex>
                </Flex>
            
            </>
            }
           
        </>
    )

} 