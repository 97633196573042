import {
    Image,
    Container,
    Center,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react'



  export default function LoadingPage() {

    const [isRelaunch, setIsRelaunch] = useState(false)
    useEffect(() => {
        const isRel = localStorage.getItem('isRelaunch')
        if(isRel == 'yes'){
            setIsRelaunch(true)
        }
        return () => {
            localStorage.setItem('isRelaunch', 'no')
        }
    }, [])
    return (
        <>
        <Center>
            <Container marginTop='30vh' >        
                <Image
                    src={isRelaunch ? require("../assets/img/rocket-launch.gif") : require("../assets/img/the-future-is-loading-transparent.gif")}
                    />
            </Container>
        </Center>
        </>
    )
  }
  