import { Tag, Button, TagRightIcon } from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";

export default function TagButton ( { setStatusFilter, statusFilter, thisStatusFilter, colorScheme, value, label } ){
    return(
        <Button  mr={3} p={0} variant={'ghost'} onClick={() => setStatusFilter(thisStatusFilter)}>
            <Tag 
                fontWeight={statusFilter == thisStatusFilter ? 'bold' : 'normal'} 
                size={statusFilter == thisStatusFilter ? 'lg' : 'md'}  
                variant={statusFilter == thisStatusFilter ? 'solid' : 'subtle'}
                colorScheme={colorScheme}>
                    {value} {label}
                {statusFilter == thisStatusFilter &&<TagRightIcon as={FiCheckCircle}/>}
            </Tag>
        </Button>
    )
}