import React, { useEffect, useState, useRef } from "react";
import { collection, getDoc, getDocs, doc, deleteDoc, updateDoc, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import {
  useColorModeValue,
  Button,
  Stack,
  useDisclosure,
  useToast,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  FormControl,
  FormLabel,
  Box,
  Tooltip,
  HStack,
  Heading,
  Grid,
  GridItem,
  Textarea,
} from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import { useAuth } from "../../contexts/AuthContext";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import DefaultClientSettings from "../../components/Client/DefaultClientSettings";
import StatusChangeNotificationSettings from "../../components/Settings/StatusChangeNotificationSettings";
import CreatableOptions from "../../components/Settings/CreatableOptions";
import ProcessPipelineSyncSettings from "../../components/Client/ProcessPipelineSyncSettings";
import ManageLenders from "./ManageLenders";
import ManageLenderCategories from "./ManageLenderCategories";



export default function SubmissionSettings() {
  const [allUsers, setAllUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const { currentUser } = useAuth()
  const { users } = useAuth()
  
  const bg = useColorModeValue("white", "gray.800");
  const bg2 = useColorModeValue("white", "gray.800");
  const bg3 = useColorModeValue("gray.100", "gray.700");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState(null)
  const cancelRef = React.useRef();
  const toast = useToast()

  const [initialSettings, setInitialSettings] = useState(null)

  const [subEmailRecipients, setSubEmailRecipients] = useState(null)
  const [comEmailRecipients, setComEmailRecipients] = useState(null)
  const [userRegRec, setUserRegRec] = useState(null)
  const [subBannerText, setSubBannerText] = useState(null)


  const settingsRef = doc(db, "Settings", 'yEwRIqrkneqrGnAQhgvs');

  const emailEditorRef = useRef(null);

  const handleOnClose = () => {
    setSelectedUser(null)
    onClose()
  }

  const handleSetSubEmailRecipients = (e) =>{
    e.forEach((entry) => {
      delete entry['__isNew__']
      if(entry.email == null)
        entry.email = entry.value
    })
    setSubEmailRecipients(e)

  }

  const handleSetComEmailRecipients = (e) =>{
    e.forEach((entry) => {
      delete entry['__isNew__']
      if(entry.email == null)
        entry.email = entry.value
    })
    setComEmailRecipients(e)

  }

  const handleSetUserRegRec = (e) =>{
    e.forEach((entry) => {
      delete entry['__isNew__']
      if(entry.email == null)
        entry.email = entry.value
    })
    setUserRegRec(e)

  }

  const getSettings = async () => {
    try{
      const settings = await getDoc(doc(db, "Settings", 'yEwRIqrkneqrGnAQhgvs'))
      if(settings.exists){
        setSubBannerText(settings.data()['Sub Banner Text'] ? settings.data()['Sub Banner Text'] : null)
        setSubEmailRecipients(settings.data()['Submission Email Recipients'] ? settings.data()['Submission Email Recipients'] : null)
        setComEmailRecipients(settings.data()['Completed Email Recipients'] ? settings.data()['Completed Email Recipients'] : null)
        setUserRegRec(settings.data()['User Registration Email Recipients'] ? settings.data()['User Registration Email Recipients'] : null)
        setInitialSettings(settings.data())
      }
      else setInitialSettings(null)
    }
    catch(err) {
      console.error(err);
    }
  }

  const updateSubBannerText = async () => {
    await updateDoc(settingsRef, {
      'Sub Banner Text': subBannerText
    })
    toast({
      title: "Submission Banner Text successfully updated!",
      status: 'success',
      position: 'top',
      isClosable: true
    })
  }

  async function updateRecipientSetting(field, value){
    try{
      const settingsSnap = await getDoc(settingsRef);

      //If the settings document exists, update it. Else create it and add data
      if(settingsSnap.exists()){
        await updateDoc(settingsRef, {
          [field]: value
        });
      }
      else{
        //setDoc will not create an object using a nested field (i.e Parent Field.Child Field)
        //So, split them and explicity create the object
        let nestedFields = field.split('.')
        await setDoc(settingsRef, {
          [nestedFields[0]]: {
            [nestedFields[1]]: value
          }
        });

      }
      toast({
        title: `${field} were updated!`,
        status: 'success',
        position: 'top',
        isClosable: true
      })
    }
    catch(err) {
      console.error(err);
    }
  }


   const getAllUsers = async () => {
    setAllUsers([])
    try{
      const q = query(collection(db, "users"), orderBy('dateJoined', 'desc'));

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        setAllUsers(users => [...users, doc.data()])
      })
    }
    catch(err) {
      console.error(err);
    }
  }

  useEffect(async () => {
      setLoading(true)
      await getAllUsers();
      await getSettings()
      setLoading(false)
  }, [])  

  return (
    <>
       {!loading &&
       <>
       <Tabs mt='5'>
        <TabList>
          <Tab>Options</Tab>
          <Tab>Notifications</Tab>
          <Tab>Lenders</Tab>
          <Tab>Lender Categories</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid 
                h='85vh'
                templateRows={{base: 'repeat(7, 1fr)', xl: 'repeat(7, 1fr)'}}
                templateColumns={{base: 'repeat(1, 1fr)', xl: 'repeat(3, 1fr)'}}
                gap='2'
              >
              <GridItem rowSpan={1} colSpan={1}>
                <Box w='100%' h='92%' boxShadow={'lg'} p={5} mt={5} mr={5}>
                  <HStack mb={3}>
                    <Heading  size='sm'>Process Pipeline Sync Settings</Heading>          
                  </HStack>
                  <ProcessPipelineSyncSettings 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Process Pipeline Sync Settings'] ? initialSettings['Process Pipeline Sync Settings'] : null : null}
                  />
                </Box>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <Box w='100%' h='92%' boxShadow={'lg'} p={5} mt={5} mr={5}>
                  <HStack mb={3}>
                    <Heading  size='sm'>Default Settings For New Clients</Heading>          
                    <Tooltip placement='top' label='New clients will use these settings when they are created. Admins can toggle these settings individually'>
                      <InfoOutlineIcon/>
                    </Tooltip>
                  </HStack>
                  <DefaultClientSettings 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Default Client Settings'] ? initialSettings['Default Client Settings'] : null : null}
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1}>
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                <Stack spacing={3}>
                      <Heading size='sm'>Submission Banner Text</Heading>
                      <Textarea 
                          minHeight='100px'
                          value={subBannerText} 
                          onChange={(e) => setSubBannerText(e.target.value)}>    
                      </Textarea>
                      <Button w='125px' colorScheme="blue" onClick={updateSubBannerText}>Save</Button>
                  </Stack>   
                </Box>
              </GridItem> 
              <GridItem colSpan={1} rowSpan={1}>
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading  size='sm'>Cost For Credit Report Options</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Cost For Credit Report Options'] ? initialSettings['Cost For Credit Report Options'] : null : null}
                    settingName='Cost For Credit Report Options'
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1}>
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading size='sm'>Loan Type Options (LO Side)</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Loan Type Options'] ? initialSettings['Loan Type Options'] : null : null}
                    settingName='Loan Type Options'
                    uneditableOptions={['Other/Non-QM']}
                  />
                </Box>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1} >
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading position='-webkit-sticky sticky' size='sm'>Other Loan Type (LO Side)</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Other Loan Type Options'] ? initialSettings['Other Loan Type Options'] : null : null}
                    settingName='Other Loan Type Options'
                  />
                </Box>
              </GridItem> 
              {/* <GridItem rowSpan={1} colSpan={1} >
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading position='-webkit-sticky sticky' size='sm'>Target Lenders (LO Side)</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Target Lenders'] ? initialSettings['Target Lenders'] : null : null}
                    settingName='Target Lenders'
                    uneditableOptions={['Other']}
                  />
                </Box>
              </GridItem> 

              <GridItem rowSpan={1} colSpan={1} >
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading position='-webkit-sticky sticky' size='sm'>Other Lenders (LO Side)</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Other Lenders'] ? initialSettings['Other Lenders'] : null : null}
                    settingName='Other Lenders'
                  />
                </Box>
              </GridItem>  */}
              <GridItem rowSpan={1} colSpan={1} >
                <Box w='100%'  boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading size='sm'>Product Options (LC Side)</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Product Options'] ? initialSettings['Product Options'] : null : null}
                    settingName='Product Options'
                  />
                </Box>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1} >
                <Box w='100%' boxShadow={'lg'} p={5} mt={5}>
                  <HStack mb={3}>
                    <Heading position='-webkit-sticky sticky' size='sm'>Why did you select this Lender options (LC Side)</Heading>          
                  </HStack>
                  <CreatableOptions 
                    docRef={settingsRef}
                    initialSettings={initialSettings ? initialSettings['Why did you select this Lender options'] ? initialSettings['Why did you select this Lender options'] : null : null}
                    settingName='Why did you select this Lender options'
                    uneditableOptions={['Other']}
                  />
                </Box>
              </GridItem> 

            </Grid>
          </TabPanel>
          <TabPanel>
          <StatusChangeNotificationSettings
              heading='Notifications - Loan Not Submitted'
              users={users}
              settingsRef={settingsRef}
              initialSettings={initialSettings}
              settingName={'Loan Not Submitted'}
              actionMessage={'loan is not submitted'}
              emailSwitches={[
                {
                  name: 'Email LO',
                  id: `lo-email-alert-on-loan-not-submitted`,
                  label: 'Email LO on File',
                  fieldPath: `Loan Not Submitted.Email LO`,
                  successMsg: `LOs will be emailed when loan is not submitted`,
                  warningMsg: `LOs will NOT be emailed when a loan is not submitted`,
                },
                {
                  name: 'Email LC',
                  id: `lc-email-alert-on-loan-not-submitted`,
                  label: 'Email LC on File',
                  fieldPath: `Loan Not Submitted.Email LC`,
                  successMsg: `LCs will be emailed when loan is not submitted`,
                  warningMsg: `LCs will NOT be emailed when a loan is not submitted`,
                  },
              ]}
              pushSwitches={[
                {
                  name: 'Push LO',
                  id: `lo-push-alert-on-loan-not-submitted`,
                  label: 'Push LO on File',
                  fieldPath: `Loan Not Submitted.Push LO`,
                  successMsg: `LOs will receive push notifications when loan is not submitted`,
                  warningMsg: `LOs will NOT receive push notifications when a loan is not submitted`,
                },
                {
                  name: 'Push LC',
                  id: `lc-push-alert-on-loan-not-submitted`,
                  label: 'Push LC on File',
                  fieldPath: `Loan Not Submitted.Push LC`,
                  successMsg: `LCs will receive push notifications when loan is not submitted`,
                  warningMsg: `LCs will NOT receive push notifications when a loan is not submitted`,
                },
              ]}
            />
            <StatusChangeNotificationSettings
              heading="Notifications - LC Reassignment"
              users={users}
              settingsRef={settingsRef}
              initialSettings={initialSettings}
              settingName={'LC Reassignment Notifications'}
              actionMessage={'an LC is reassigned'}
              emailSwitches={[
                {
                  name: 'Email LO',
                  id: `lo-email-alert-on-loan-reassigned`,
                  label: 'Email LO on File',
                  fieldPath: `LC Reassignment Notifications.Email LO`,
                  successMsg: `LOs will be emailed when a loan is reassigned`,
                  warningMsg: `LOs will NOT be emailed when a loan is reassigned`,
                },

              ]}
              pushSwitches={[
                {
                  name: 'Push LO',
                  id: `lo-push-alert-on-loan-not-submitted`,
                  label: 'Push LO on File',
                  fieldPath: `LC Reassignment Notifications.Push LO`,
                  successMsg: `LOs will receive push notifications when a loan is reassigned`,
                  warningMsg: `LOs will NOT receive push notifications when a loan is reassigned`,
                },
              ]}
            />
          
            <Box boxShadow={'lg'} p={5} mt={5}>
              <HStack>
                <Heading size='sm'>Email Notifications</Heading>          
                <Tooltip placement='top' label='You can select a user from the list, or enter an email address'>
                  <InfoOutlineIcon/>
                </Tooltip>
                </HStack>
            <FormControl p={4}>
              <Stack m={2} direction='row'>
    
                <FormLabel>New Submissions</FormLabel> 
                <Button size='sm'colorScheme='messenger' onClick={() => updateRecipientSetting('Submission Email Recipients', subEmailRecipients)}>Update</Button>
                </Stack>
                <CreatableSelect
                  isMulti
                  name="Submission Email Recipients"
                  options={users}
                  placeholder="Select or Enter Recipient"
                  closeMenuOnSelect={false}
                  //defaultValue={subEmailRecipients}
                  value={subEmailRecipients}
                  onChange={(e) => handleSetSubEmailRecipients(e)}
                />
              </FormControl>
              <FormControl p={4}>
            
              <Stack m={2} direction='row'>
                <Tooltip placement='top' label='Loan Completion Emails are sent to the LO & LC on the file, in addition to anyone added here'>
                  <InfoOutlineIcon/>
                </Tooltip>
                <FormLabel>Loan Completion</FormLabel> 

                <Button size='sm'colorScheme='messenger' onClick={() => updateRecipientSetting('Completed Email Recipients', comEmailRecipients)}>Update</Button>
                </Stack>
                <CreatableSelect
                  isMulti
                  name="Completed Email Recipients"
                  options={users}
                  placeholder="Select or Enter Recipient"
                  closeMenuOnSelect={false}
                  //defaultValue={subEmailRecipients}
                  value={comEmailRecipients}
                  onChange={(e) => handleSetComEmailRecipients(e)}
                />
              </FormControl>
              <FormControl p={4}>
            
            <Stack m={2} direction='row'>
              <FormLabel>User Registration</FormLabel> 

              <Button size='sm'colorScheme='messenger' onClick={() => updateRecipientSetting('User Registration Email Recipients', userRegRec)}>Update</Button>
              </Stack>
              <CreatableSelect
                isMulti
                name="User Registration Recipients"
                options={users}
                placeholder="Select or Enter Recipient"
                closeMenuOnSelect={false}
                //defaultValue={subEmailRecipients}
                value={userRegRec}
                onChange={(e) => handleSetUserRegRec(e)}
              />
            </FormControl>
            </Box>
          </TabPanel>
          <TabPanel>
            <ManageLenders 
              initialSettings={initialSettings}
            />
          </TabPanel>
          <TabPanel>
            <ManageLenderCategories
              initialSettings={initialSettings}
            />
          </TabPanel>
        </TabPanels>
       </Tabs>

     

          </>
    }
  </>   
  );
}
