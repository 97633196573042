import { Flex, Center, Text, Avatar, useColorModeValue } from "@chakra-ui/react"
import { motion } from "framer-motion";
import { memo, useEffect, useState } from "react";
import CountUp from 'react-countup';

const delay = ms => new Promise(res => setTimeout(res, ms));

const StackRankLO = ( { row, property, timeline } ) => {
    const [oldRow, setOldRow] = useState(row)
    const [newRow, setNewRow] = useState(row)
    const grayText = useColorModeValue('gray.600', 'gray.400')

    useEffect( async () => {
            setNewRow(row)
            await delay(2100)
            setOldRow(row)
         
    }, [row])

    return(
                <>
                {oldRow && newRow &&
                <motion.div 
                    animate={{scale: newRow.dollarVolumeLifetime == oldRow.dollarVolumeLifetime ? '1' : '1.03'}}>                
                    <Flex 
                        flexDir={'row'}   
                        key={`row-${row.uid}`} 
                        py={3}
                        px={1}
                        mb={3}
                        flexGrow={1}
                        borderRadius="lg"
                        w="full"
                        borderLeft="2px"
                        borderColor={newRow.dollarVolumeLifetime == oldRow.dollarVolumeLifetime ? 'teal.100' : 'teal.400'}
                        h='40px' 
                        justifyContent={'space-between'}
                        >
                        <Flex            
                            flexGrow={1}
                            flexDir='row'>
                            <Center>
                                {row.LO ?
                                <>
                                    <Avatar key={row.LO.photoURL} mr={2} src={row.LO.photoURL} size='sm'></Avatar>
                                    <Flex key={row.LO.uid} flexDir={'column'}>
                                        <Text fontSize='sm'>{row.LO.Name}</Text>
                                    
                                        <Text as='em' color={grayText} fontSize='xs' fontWeight={newRow.dollarVolumeLifetime == oldRow.dollarVolumeLifetime ? 'normal' : 'extrabold'}>
                                            {timeline == 'YTD' ? 
                                                <CountUp prefix="$" separator="," duration={2} start={oldRow.dollarVolumeYTD} end={newRow.dollarVolumeYTD}/> 
                                                :
                                            timeline == 'MTD' ? 
                                                <CountUp prefix="$" separator="," duration={2} start={oldRow.dollarVolumeMTD} end={newRow.dollarVolumeMTD}/> 
                                                :
                                                <CountUp prefix="$" separator="," duration={2} start={oldRow.dollarVolumeLifetime} end={newRow.dollarVolumeLifetime}/>
                                            }
                                        </Text>
                                    </Flex>
                                </>
                                :
                                    <Text key={row.email} colorScheme={'gray'} fontSize='sm'>{row.email}</Text>
                                }
                            </Center>
                        </Flex>
                        <Flex  justifySelf={'flex-end'}>
                            <Center>
                                <Text ml={5} fontWeight={'semibold'} fontSize='xl'>
                                    {timeline == 'YTD' ? 
                                        <CountUp separator="," duration={2} start={oldRow.noFundedYTD} end={newRow.noFundedYTD}/> : 
                                    timeline == 'MTD' ? 
                                    <CountUp separator="," duration={2} start={oldRow.noFundedMTD} end={newRow.noFundedMTD}/> : 
                                    
                                    <CountUp separator="," duration={2} start={oldRow.noFundedLifetime} end={newRow.noFundedLifetime}/> 

                                    }
                                </Text>
                            </Center>
                        </Flex>
                    </Flex>
                </motion.div>

            }
            </>
    )
}

export default memo(StackRankLO)