import { Box, Heading, useColorModeValue, Spacer, Center } from '@chakra-ui/react'
import * as React from 'react'
import { ProductCard } from './ProductCard'
import { ProductGrid } from './ProductGrid'
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from '../../utils/init-firebase';
import { useState } from 'react';


export function Products ( { products, pendingCarts, handleAddToCart, cart, inventory, pendingCartsUpdated, cartUpdated, categories } ) {
  const [tags, setTags] = useState()

  const [loading, setLoading] = useState(true)
  const headingColor = useColorModeValue('gray.600', 'gray.400')
  const headingBackground = useColorModeValue('white none repeat scroll 0 0', '#1A202C none repeat scroll 0 0')
  const boxBGColor = useColorModeValue('white', 'gray.900')



  React.useEffect( async () => {
    const q = query(collection(db, "Marketing Swag Tags"), orderBy('timestamp'));
    const querySnapshot = await getDocs(q);
    const t = []
    querySnapshot.forEach((doc) => {
      t.push(doc.data())
    });
    setTags(t)
    setLoading(false)
  }, [])

  return(
    <>
    {!loading &&
      <Box
        py={{
          base: '6',
          md: '8',
          lg: '12',
        }}
      >
        {categories && categories.map((category, index) => (
          <>
          <Box     
              minW={'fit-content'} 
              bg={boxBGColor}
              boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px;'}
              rounded={'lg'}
              p={5}>
              <Center>
                <Heading  
                      mt={'-1vh'} 
                      padding='0 3px' 
                      display={'inline-block'}  
                      color='teal.400'
                      fontWeight='extrabold'
                      fontStyle='italic'
                      textTransform='uppercase'
                      scrollMarginTop={'55px'}
                      mb={5}
                      id={category.name.replace(' ', '-').toLocaleLowerCase()} 
                      size='lg' 
                      >
                        {category.name}
                  </Heading>
                </Center>
                <ProductGrid>
                {products.map((product, index) => {
                  if(product.categories && product.categories.includes(category.id)){
                    return(
                    <ProductCard 
                        tags={tags} 
                        handleAddToCart={handleAddToCart} 
                        cartUpdated={cartUpdated} 
                        pendingCartsUpdated={pendingCartsUpdated} 
                        pendingCarts={pendingCarts} 
                        index={index} 
                        inventory={inventory} 
                        cart={cart} 
                        key={product.id ? product.id : index} 
                        product={product} 
                      />
                    )
                  }
                })}
              </ProductGrid>
          </Box>
        
         
            <Spacer h={'30px'}/>
          </>
        ))}
         <Box     
              minW={'fit-content'} 
              bg={boxBGColor}
              boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px;'}
              rounded={'lg'}
              p={5}>
              <Center>
                <Heading  
                      mt={'-1vh'} 
                      padding='0 3px' 
                      display={'inline-block'}  
                      color='teal.400'
                      fontWeight='extrabold'
                      textTransform='uppercase'
                      scrollMarginTop={'55px'}
                      mb={5}
                      id={'uncategorized'} 
                      size='lg' 
                      >
                        Uncategorized
                  </Heading>
                </Center>
          <ProductGrid>
            {products.map((product, index) => {
              if(!product.categories || product.categories.length < 1){
                return(
                <ProductCard tags={tags} handleAddToCart={handleAddToCart} pendingCartsUpdated={pendingCartsUpdated} pendingCarts={pendingCarts} index={index} inventory={inventory} cart={cart} key={product.id} product={product} />
                )
              }
            })}
              </ProductGrid>
        </Box>
        
      </Box>
    }
  </>
  )
}
