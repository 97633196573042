import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import {
    Text,
    Wrap,
    WrapItem,
    Stack,
    Input,
    Button
  } from '@chakra-ui/react';
import ClientCard from "../../components/Client/ClientCard";
import { useAuth } from "../../contexts/AuthContext";

export default function LoansPage() {
  const { currentUser, SuperAdmin, isAdmin, Roles } = useAuth()
  const { url, path } = useRouteMatch()
  const [clientList, setClientList] = useState(null)
  const [loading, setLoading] = useState(true)
  const [filteredClientList, setFilteredClientList] = useState(null)
  const [loFilter, setLOFilter] = useState('')
  const [lcFilter, setLCFilter] = useState('')
  const [borrowerFilter, setBorrowerFilter] = useState('')

  const resetFilters = () => {
    setLOFilter('')
    setLCFilter('')
    setBorrowerFilter('')
  }

  useEffect( async () => {
        let q = null
        if(SuperAdmin || isAdmin){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'In Progress'));
        }
        else if(Roles.includes('Mortgage Broker') || Roles.includes('Loan Officer')){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'In Progress'), where(`Loan Officer.uid`, '==', currentUser.uid ));
        }
        else if(Roles.includes('Closing Coordinator') || Roles.includes('Title Coordinator')){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'In Progress'), where(`TC.uid`, '==', currentUser.uid ));
        }
        else if(Roles.includes('Client Success Coordinator')){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'In Progress'), where(`CSC.uid`, '==', currentUser.uid ));
        }
        else {
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'In Progress'), where(`Loan Officer.uid`, '==', currentUser.uid ));
        }
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let clients = []
          querySnapshot.forEach((doc) => {
              let client = {}
              client = doc.data()
              client['Borrower'] = doc.data()['Loan Details']['First Name'] + ' ' + doc.data()['Loan Details']['Last Name']
              clients.push(client)

          });
          setClientList(clients)
          setFilteredClientList(clients)
          setLoading(false)

        });
      
      return () => {
        setClientList(null)
        unsubscribe()
      }
  }, [])

  useEffect( () => {
    if(!loading){
      const filteredClients = clientList.filter((client) => {
        return client['Loan Officer'].Name.toLocaleLowerCase().includes(loFilter) && client['LC'].Name.toLocaleLowerCase().includes(lcFilter) && client['Borrower'].toLocaleLowerCase().includes(borrowerFilter) 
      })
      setFilteredClientList(filteredClients)
    }
  }, [loFilter, lcFilter, borrowerFilter])

  return (
    <>
            <Stack
              mt={'5'}
              direction={{ base :'column' , md: 'row'}}
              maxW={'1200px'}

            >
                <Input 
                  type='search'
                  placeholder='Filter By Loan Officer'
                  value={loFilter}
                  onChange={e => setLOFilter(e.target.value.toLocaleLowerCase())}
                  >
                </Input>
                <Input 
                  type='search'
                  placeholder='Filter By Lender Concierge'
                  value={lcFilter}
                  onChange={e => setLCFilter(e.target.value.toLocaleLowerCase())}
                  >

                </Input>
                <Input 
                  type='search'
                  placeholder='Filter By Borrower'
                  value={borrowerFilter}
                  onChange={e => setBorrowerFilter(e.target.value.toLocaleLowerCase())}
                  >

                </Input>
                <Button p={5} pl={7} pr={7} onClick={resetFilters} size='md' colorScheme='blue'>Reset</Button>
            </Stack>
            <Wrap mt={3}>
                {filteredClientList && filteredClientList.map((client, index) => {
                    return (
                        <>
                        <WrapItem>
                            <ClientCard 
                                client={client}
                                loanOfficer={client['Loan Officer']}
                                lenderConcierge={client['LC']}
                                borrower={client['Borrower']}
                                dateAdded={client['Date Added']}
                                dateClaimed={client['Date Claimed']}
                                loanDetails={client['Loan Details']}
                                docID={client.id}
                                status={client.Status}
                            />
                            </WrapItem>
                        </>
                    )
                }) }
                {filteredClientList && filteredClientList.length == 0 && !loading  && <Text>&nbsp;No clients found</Text>}
            </Wrap>
    </>
  );
}
