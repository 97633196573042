import React, { createContext, useContext, useEffect, useState } from 'react'
import { firebase, db, app } from '../utils/init-firebase';
import { useHistory } from 'react-router-dom';
import { updateDoc, doc, setDoc, getDoc, onSnapshot, query, collection, orderBy, where, getDocs, arrayUnion, limit } from "firebase/firestore"
import { getAuth } from 'firebase/auth';
import {
  onAuthStateChanged,
  GoogleAuthProvider,
} from 'firebase/auth'
import { requestForToken } from '../utils/init-firebase';
import LoadingPage from '../pages/LoadingPage';

const AuthContext = createContext({
  users: null,
  marketingAdmins: null,
  brokers: null,
  marketingSettings: null,
  currentUser: null,
  notifications: null,
  Roles: null,
  loading: null,
  isFirstRender: null,
  setIsFirstRender: () => {},
  NMLS: null,
  setupQueueCount: null,
  signInWithGoogle: () => Promise,
  logout: () => Promise,
  currentToken: null,
  isAdmin: null,
  SuperAdmin: null,
  MarketingAdmin: null,
  Level1: null,
  swagBudget: null,
  lenders: null,

  newRequests: null,
  inProgressRequests: null,
  inProgressCounter: null,
  unsubscribeMarketingRef: null,
  showNewReqCounter: null,
  showInProgReqCounter: null,

  currentVersion: null,
  latestVersion: null
})

export const setLoading = (val) => {
  setLoading(val)
}

export const useAuth = () => useContext(AuthContext)

export default function AuthProvider({ children }) {
  const history = useHistory();
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null)
  const [Roles, setRoles] = useState(null)
  const [isAdmin, setIsAdmin] = useState(null)
  const [SuperAdmin, setSuperAdmin] = useState(null)
  const [MarketingAdmin, setMarketingAdmin] = useState(null)
  const [Level1, setLevel1] = useState(false)
  const [swagBudget, setSwagBudget] = useState(null)
  const [NMLS, setNMLS] = useState(null)
  const [setupQueueCount, setSetupQueueCount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [navSize, changeNavSize] = useState('large')
  const [notifications, setNotifications] = useState(null)
  const [users, setUsers] = useState(null)
  const [marketingAdmins, setMarketingAdmins] = useState(null)
  const [brokers, setBrokers] = useState(null)
  const [marketingSettings, setMarketingSettings] = useState(null)
  const [currentToken, setCurrentToken] = useState(null)
  const [branches, setBranches] = useState(null)
  const [lenders, setLenders] = useState(null)

  const [newRequests, setNewRequests] = useState(null)
  const [inProgressRequests, setInProgressRequests] = useState(null)
  const [inProgressCounter, setInProgressCounter] = useState(null)

  const [currentVersion, setCurrentVersion] = useState(null)
  const [latestVersion, setLasestVersion] = useState(null)

  const [showNewReqCounter, setShowNewReqCounter] = useState(null)
  const [showInProgReqCounter, setShowInProgReqCounter] = useState(null)


  const unsubscribeMarketingRef = React.useRef()
  const unsubscribeSetupQueueCountRef = React.useRef()

    useEffect( async () => {
      const unsubscribe = onAuthStateChanged(auth, user => {
        if(user){
          setLoading(true)
          setCurrentUser(user)
        }
        else {
          setCurrentUser(null)
          setLoading(false)
        }
      })
      return () => {
        unsubscribe()
      }
    }, [])

    useEffect( async () => {
        if(currentUser){
          await initUser().then(() => {
            setLoading(false)
          }).catch((err) => {
            console.log(err)
          }) 
          
        }

        
    }, [currentUser])

    useEffect( async () => {
      if(currentToken){
        await addToken()
      }   
  }, [currentToken])

    const initUser = async () =>{
      return new Promise( async (resolve, reject) => {
        await checkRole()
        await getNotifs()
        await getUsers()
        requestForToken(setCurrentToken)
        await addToken()
        await getMarketingSettings()
        await getLenders()
        await getCurrentVersion()
        await listenForLatestVersion()
        //console.log('end init role')
        resolve()
      })
     
    }

    const getLenders = async () => {
      const q = query(collection(db, "lenders"));

      const unsubLenders = onSnapshot(q, (querySnapshot) => {
          const lenders = []
          querySnapshot.forEach((doc) => {
              if(!doc.data().inactive){
                  lenders.push(doc.data())
              }
          })
          setLenders(lenders)
      })

      return () => {
        unsubLenders()
      }
    }

    const getCurrentVersion = async () => {
        const docRef = doc(db, "Version", "Version");
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setCurrentVersion(docSnap.data().Latest)
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        //console.log('current version: ' + currentVersion)


      };
 
    const listenForLatestVersion = async () => {
      const unsub = onSnapshot(doc(db, "Version", "Version"), (doc) => {
        setLasestVersion(doc.data().Latest)
      });
      //console.log('latest version: ' + latestVersion)
      return () => {
        unsub()
      }
    };
  
    const checkRole = async () => {

      const snap = await getDoc(doc(db, 'users', currentUser.uid))
      if (!snap.exists()) {
        await setDoc(doc(db, 'users', currentUser.uid) , {
          uid: currentUser.uid,
          name: currentUser.displayName,
          authProvider: "google",
          email: currentUser.email,
          photo: currentUser.photoURL,
          isAdmin: false,
          SuperAdmin: false,
          MarketingAdmin: false,
          Level1: false,
          Roles: [],
          dateJoined: getDate(),

        });
        setIsAdmin(false)
        setSuperAdmin(false)
        setMarketingAdmin(false)
      }
      else{
        await getRole(currentUser.uid)
/*         await updateDoc(doc(db, 'users', currentUser.uid) , {
          lastLogin: getDate()
        }); */
      }
    }

    const getNotifs = async () => {
      if(notifications){
        return
      }
      var today = new Date();
      var thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));
      const q = query(collection(db, "users", currentUser.uid, 'Notifications'), 
                orderBy('timestamp'), 
                where('read', '==', false), 
                where('timestamp', '>', thirtyDaysAgo),
                limit(50)
              );
      const unsubNotifications = onSnapshot(q, (querySnapshot) => {
        const notifications = [];
        querySnapshot.forEach((doc) => {
            notifications.push({
              ...doc.data(),
              id: doc.id
            });
        });
        setNotifications(notifications)
      });
      return () => {
        unsubNotifications()
      }
    }

    const getUsers = async () => {
      if(users){
        return
      }
      const userList = []
      const marketingUserList = []
      const brokerList = []
      const branches = []
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        let user = {
          label: doc.data().name,
          value: doc.data().uid,
          photoURL: doc.data().photo,
          email: doc.data().email,
          NMLS: doc.data().NMLS ? doc.data().NMLS : '',
          website: doc.data().website ? doc.data().website : '',
          jobTitle: doc.data().Roles ? doc.data().Roles[0] ? doc.data().Roles[0] : '' : '',
          phoneNo: doc.data().phoneNo ? doc.data().phoneNo[0] ? doc.data().phoneNo[0] : '' : '',
          swagBudget: doc.data()['Swag Budget'] ? parseInt(doc.data()['Swag Budget']) : null,
        }
        userList.push(user)
        if(doc.data().MarketingAdmin){
          marketingUserList.push(user)
        }
        if(user.jobTitle == 'Mortgage Broker' || user.jobTitle == 'Loan Officer'){
          brokerList.push(user)
        }
        if(doc.data().Branch && !branches.includes(doc.data().Branch)){
          branches.push(doc.data().Branch)
        }
      });

      brokerList.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });

      userList.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });
      setUsers(userList)
      setMarketingAdmins(marketingUserList)
      setBrokers(brokerList)

      const branchSelection = []
      for( const branch of branches){
        branchSelection.push( { label: branch, value: branch } )
      }
      setBranches(branchSelection)
    }

    const addToken = async () => {
      if(currentToken){
        await updateDoc(doc(db, 'users', currentUser.uid), {
          ['FCM Tokens']: arrayUnion(currentToken)
        })
      }
    }  


  async function getRole(userID){
    const snap = await getDoc(doc(db, "users", userID))
    if (snap.exists()) {
      setRoles(snap.data().Roles)
      // what a mess
      setNMLS(snap.data()['Google Admin Sync Data'] && 
              snap.data()['Google Admin Sync Data']['customSchemas'] &&
              snap.data()['Google Admin Sync Data']['customSchemas']['Broker_Information'] &&
              snap.data()['Google Admin Sync Data']['customSchemas']['Broker_Information']['NMLS'] ?
              snap.data()['Google Admin Sync Data']['customSchemas']['Broker_Information']['NMLS'] :
              null)

      setIsAdmin(snap.data().isAdmin? snap.data().isAdmin : false)
      setSuperAdmin(snap.data().SuperAdmin ? snap.data().SuperAdmin : false)
      setMarketingAdmin(snap.data().MarketingAdmin ? snap.data().MarketingAdmin : false)
      setLevel1(snap.data().Level1 ? snap.data().Level1 : false)
      setSwagBudget(snap.data()['Swag Budget'] ? snap.data()['Swag Budget'] : null)

      if(snap.data().MarketingAdmin){
        await getRequests()
      }
      if(snap.data().isAdmin || snap.data().SuperAdmin){
        await getSetupQueueCount()
      }
    }
    else {
      console.log("No such user")
    }
} 

const getSetupQueueCount = async () => {
  unsubscribeSetupQueueCountRef.current && unsubscribeSetupQueueCountRef.current()
  let q = query(collection(db, "Clients"), 
          where(`Status`, '==', 'New'), 
        )
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let sqc = 0
    querySnapshot.forEach((doc) => {
        sqc++
    });
    setSetupQueueCount(sqc)
  });
  unsubscribeSetupQueueCountRef.current = unsubscribe
}

const getRequests = async () => {
  unsubscribeMarketingRef.current && unsubscribeMarketingRef.current()
  let q = query(collection(db, "Marketing Requests"), 
          orderBy('Status'),
          orderBy('Date Created', 'desc'),
          where(`Status`, '!=', 'Completed'), 
        )
  
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let newRequests = []
    let inProgressRequests = []
    let inProgressCounter = 0

    querySnapshot.forEach((doc) => {
        let request = {}
        request = doc.data()
        if(request['Status'] == 'New'){
          newRequests.push(request)
        }
        else if(request['Status'] == 'In Progress'){
          inProgressRequests.push(request)
        }
    });
    setNewRequests(newRequests)
    setInProgressRequests(inProgressRequests)

    inProgressRequests.forEach((request) => {
      request['Assignees']?.forEach((assignee) => {
        if(assignee.uid == currentUser.uid){
          inProgressCounter++
        }
      })
    })
    setInProgressCounter(inProgressCounter)
  });
  unsubscribeMarketingRef.current = unsubscribe
}

async function getMarketingSettings(){
    const snap = await getDoc(doc(db, "Marketing Settings", 'yEwRIqrkneqrGnAQhgvs'))
    if (snap.exists()) {
      setMarketingSettings(snap.data())
    }
} 

  function logout() {
    setCurrentUser(null)
    firebase.auth().signOut()
  }

  function getDate(){
    return new Date();
  }

  const signInWithGoogle = async () => {
    var google_provider = new GoogleAuthProvider();
    try {
      const auth = app.auth();
      const res = await auth.signInWithPopup(google_provider);

      return res;
      
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
   
  }

  const value = {
    currentUser,
    Roles,
    NMLS,
    setupQueueCount,
    loading,
    isFirstRender,
    setIsFirstRender,
    signInWithGoogle,
    logout,
    navSize,
    changeNavSize,
    notifications,
    users,
    marketingAdmins,
    brokers,
    marketingSettings,
    currentToken,    
    isAdmin,
    SuperAdmin,
    MarketingAdmin,
    Level1,
    swagBudget,
    branches,
    lenders,

    newRequests,
    inProgressRequests,
    inProgressCounter,

    currentVersion,
    latestVersion
  }
  if(loading){
    return(
      <LoadingPage />
    )
  }
  else {
    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
  }
}