import {
    Box,
    Center,
    Button,
    useColorModeValue,
    useToast,
    ScaleFade,
  } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom'
import AssignRequest from './AssignRequest';
import RequestSummary from './RequestSummary';

  export default function RequestCard({ request }) {

    const { MarketingAdmin, SuperAdmin} = useAuth()

    const Status = request.Status

    const location = useLocation()
    const toast = useToast()

    const history = useHistory();
  
    const routeChange = (path) =>{ 
      history.push(`marketing-requests/${path}`)
      toast.closeAll()
    }

    return (
      <ScaleFade in initialScale={0.75}>
        <Box
          m={1}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={request['Coaching Completed'] && (SuperAdmin || MarketingAdmin) ? '0px 0px 5px 5px #34eb83' :
          request['Flag For Review'] && (MarketingAdmin || SuperAdmin) ? '0px 0px 5px 5px #f5c971': 
          'lg'}
          rounded={'lg'}
          p={6}
          width='100%'>          
        <RequestSummary request={request}/>
        {Status == 'New' && (MarketingAdmin || SuperAdmin) && <AssignRequest request={request} /> }
        {Status != 'New' && 
        <>
          <Center>
            <Button
                onClick={() => routeChange(request.id)}
                flex={1}
                fontSize={'sm'}
                rounded={'full'}
                maxWidth='150px'
                size='md'
                bg={'blue.400'}
                color={'white'}
                boxShadow={
                  '0px 1px 5px -5px rgb(66 153 225 / 48%), 0 7px 7px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                  bg: 'blue.500',
                }}
                _focus={{
                  bg: 'blue.500',
                }}>
                View Details
              </Button>
            </Center>
            </>
             }
        </Box>
      </ScaleFade>
    );
  }