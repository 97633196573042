import { useEffect, useState, useRef } from "react";
import { doc, getDocs, serverTimestamp, collection, where, query, updateDoc, onSnapshot } from "firebase/firestore";
import { useHistory, useLocation } from "react-router-dom";
import { db } from "../../utils/init-firebase"
import { useAuth } from "../../contexts/AuthContext";
import {
    Stack,
    Heading,
    ScaleFade
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ClientBox from "../../components/Client/ClientBox";
import GroupChat from "../../components/Client/GroupChat";

export default function ClientPage() {
    const [error, setError] = useState(null)
    const [client, setClient] = useState(null)
    const [users, setUsers] = useState([])
    const { currentUser } = useAuth()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [escalations, setEscalations] = useState([])
    let escSubRef = useRef()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {

       async function updateNotifications(){
          try{
            const notificationsRef = collection(db, "users", currentUser.uid, 'Notifications');
            const q = query(notificationsRef, where("loanID", "==", id));
            const querySnapshot = await getDocs(q);
              querySnapshot.forEach( async (doc) => {
                await updateDoc(doc.ref, {
                    read: serverTimestamp()
                })
              });
          }
          catch(err){
            console.log(err)
          }
      }

      updateNotifications() 
      const unsubClient = onSnapshot(doc(db, 'Clients', id), (doc) =>{
        if(doc.exists()){
          setError(null)

          setClient(doc.data())
        }
        else{
          setError('Client not found')
        }
        setIsLoading(false)
      })         

      return () => {
        unsubClient()
      }
  
  }, [id])

  useEffect(() => {
    if(id){
        escSubRef.current && escSubRef.current()
        let escQuery = query(collection(db, 'Escalations'), where('loanID', "==", id))
        escSubRef.current = onSnapshot(escQuery, (querySnapshot) => {
            const esc = []
            querySnapshot.forEach((doc) => {
                esc.push({...doc.data(), id: doc.id})
            })
            setEscalations(esc)
        });
    }

    return () => {
        escSubRef.current && escSubRef.current()
    }
}, [id])

  return (
      <>
      {!isLoading && 
        <>
          <ScaleFade in initialScale={0.85}>
            <Stack direction={{base: "column-reverse", xl: "row"}} mt='5'>
                {error && <Heading>{error}</Heading>}
                {client &&
                  <>
                    <Stack width={{base: '91vw', md: '85vw', xl: '49vw'}} flexDirection={'column'}>
                      <GroupChat parentDocContent={client} width={'100%'} collectionName='Clients' collectionRef={collection(db, 'Clients', client.id, 'Chat')} parentID={client.id} />
                    </Stack>

                    <ClientBox 
                        escalations={escalations}
                        client={client}
                        dateClaimed={client['Date Claimed']}
                        dateAdded={client['Date Added']}
                        docID={client.id} 
                        loanOfficer={client['Loan Officer']} 
                        loanDetails={client['Loan Details']}  
                        lenderConcierge={client['LC']} 
                        status={client['Status']}           
                      />
                  </>

                }
              </Stack>
            </ScaleFade>
        </>
      }
      </>
  );
}
