import { 
  Heading,
  Box,
  Avatar,
  Stack,
  Text,
  useToast,
  ButtonGroup,
  Divider,
  Wrap,
  Flex,
  FormControl,
  ScaleFade,
  Skeleton} from '@chakra-ui/react'
import {
  InputControl,
  SubmitButton,  
} from "formik-chakra-ui";
import { db } from '../../utils/init-firebase';
import { collectionGroup, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { Select } from 'chakra-react-select';
import { useHistory, useParams } from 'react-router-dom';
import TransactionLog from '../../components/TransactionLog/TransactionLog';
import { useBudget } from './useBudget';

const currentYear = new Date().getFullYear(); // Get the current year

export default function Profilepage() {
  const { currentUser, Roles, users, SuperAdmin, MarketingAdmin } = useAuth()
  const [loading, setLoading] = useState(true)
  const [initialValues, setInitivalValues] = useState({})
  const [selectedUser, setSelectedUser] = useState()


  const [selectedYear, setSelectedYear] = useState(currentYear); // State to hold the selected year
  const firstDayOfYear = new Date(selectedYear, 0, 1); // Get the first day of the selected year
  const lastDayOfYear = new Date(selectedYear, 11, 31); // Get the last day of the selected year

  const { transactions, budget, setBudget } = useBudget(selectedUser, selectedYear)

  const history = useHistory()
  const { id } = useParams()
  // const functions = getFunctions(getApp());
  // connectFunctionsEmulator(functions, "localhost", 9001); 
  // const test = httpsCallable(functions, 'test') 
 
  const toast = useToast()

  const handleSetUser = (id) => {
    id && (SuperAdmin || MarketingAdmin) && history.push(`/profile/${id}`)
  }

  useEffect(() => {
    if(id && users && (SuperAdmin || MarketingAdmin)){
      let userIndex = users.findIndex((user) => user.value === id)
      if(userIndex > -1){
        setSelectedUser(users[userIndex])
      }
    }
    else setSelectedUser({
      value: currentUser.uid,
      label: currentUser.displayName,
      photoURL: currentUser.photoURL,
      email: currentUser.email,
    })
    
  }, [id])


  useEffect( async () => {
    if(selectedUser){
      try{
        setLoading(true)
        const userRef = doc(db, 'users', selectedUser?.value)
        const docSnap = await getDoc(userRef)
        if (docSnap.exists()) {
          setInitivalValues({...initialValues, 
            NMLS: docSnap.data().NMLS ? docSnap.data().NMLS : '',
            phoneNo: docSnap.data().phoneNo ? docSnap.data().phoneNo.length > 0 ? docSnap.data().phoneNo[0] : '' : '',
            website: docSnap.data().website ? docSnap.data().website : '',
            jobTitle: docSnap.data().Roles && docSnap.data().Roles[0] ? docSnap.data().Roles[0] : '',
            swagBudget: docSnap.data()?.['Swag Budget'] ? docSnap.data()['Swag Budget'] : ''
          })
        } 

        setLoading(false)
      }
      catch(err){
          toast({
              description: err,
              status: 'error',
              isClosable: true,
              duration: 5000,
              position: 'top'
          })
      }
  }

  }, [selectedUser])


  const onSubmit = async (values) => {
    try{
        const userRef = doc(db, 'users', selectedUser?.value)
        await updateDoc(userRef, {
            NMLS: values.NMLS,
            phoneNo: [values.phoneNo],
            website: values.website,
            Roles: [values.jobTitle],
            'Swag Budget': values.swagBudget
        })
        toast({
            title: 'Profile Updated!',
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top'
        })
        setBudget(values.swagBudget)
    }
    catch(err){
        toast({
            description: err,
            status: 'error',
            isClosable: true,
            duration: 5000,
            position: 'top'
        })
    }
  
};

  return (
    <>
        <Flex flexDir={'row'} align='center' justify={'center'}>
        {SuperAdmin && users &&
            <FormControl w='250px'>
                <Select
                    options={users}
                    value={selectedUser}
                    onChange={(e) => handleSetUser(e.value)}
                    placeholder="Select User"
                    closeMenuOnSelect={true}
                    selectedOptionColor="green"
                    classNamePrefix={'user-select'}
                />
            </FormControl>
        }
        </Flex>
      {!loading ? 
      <>

         <ScaleFade in initialScale={'0.9'}>

        <Flex flexDir='row'>        
          <Box
          p={3}
          boxShadow={'lg'}
          rounded={'md'}
          w='25vw'
          mr='2'
          >
                <Flex flexDir='column' spacing={0} align={'center'} mb={5}>
                    <Avatar
                    size={'lg'}
                    src={selectedUser?.photoURL} 
                    alt={selectedUser?.label}
                    css={{
                      border: '2px solid white',
                    }}
                  />
                    {/* <Button onClick={() => test()}>
                        test
                    </Button>  */}
                    <Heading fontSize={'md'} fontWeight={500} fontFamily={'body'}>
                      {selectedUser?.label}
                    </Heading>
                    <Text fontSize='14px' mb='2' color={'gray.500'}>{selectedUser?.email}</Text>
      
                   <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    
                    >
                    {({ handleSubmit, values, errors }) => (
                   
                          <Flex
                            as="form"
                            onSubmit={handleSubmit}
                            flexDir='column'
                          >
                            {SuperAdmin &&
                            <>
                              <InputControl size='sm' mb={2} w={'100%'} name="jobTitle" label="Job Title" />
                              <InputControl mb={2} w={'100%'} name="swagBudget" label="Marketing Budget ($)" />
                              </>

                            }
                              <InputControl mb={2} w={'100%'}name="NMLS" label="NMLS" />
                              <InputControl mb={2} w={'100%'}name="phoneNo" label="Phone Number" />
                              <InputControl mb='2' w={'100%'}name="website" label="Website" />

                              <br/>
                            <ButtonGroup mt='2' alignSelf='center'>
                                <SubmitButton size='md'>Save</SubmitButton>
                            </ButtonGroup>
                         
                          </Flex>
                    )}
                    </Formik> 
              </Flex>
        </Box>
        <TransactionLog 
          transactions={transactions} 
          title={`${selectedUser.label}'s Marketing Budget`} 
          groupTransactionsByTaskID
          selectedUser={selectedUser}
          budget={budget}
          showUserBudget
          selectedYear={selectedYear}
        />
        </Flex>
        </ScaleFade>
        </>
        :
        <Flex flexDir='row' mt='2'>        
            <Skeleton borderRadius={'md'} height='80vh' w='22vw' mr='3'></Skeleton>
            <Skeleton borderRadius={'md'} height='80vh' w='60vw'></Skeleton>
          </Flex>
      }
      </>

  )
}
