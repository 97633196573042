import { collectionGroup, orderBy, getDocs, limit, query } from "firebase/firestore"
import { useEffect, useState } from "react"
import EditLog from "../../components/Client/EditLog"
import { useAuth } from "../../contexts/AuthContext"
import { db } from "../../utils/init-firebase"
import { Link, Text } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"

export default function AllEditLogs( { } ){
    const { SuperAdmin } = useAuth()
    const [logs, setLogs] = useState()

    useEffect( async () => {
        if(SuperAdmin){
            let editLogRef = collectionGroup(db, "Edit Log")
            let q = query(editLogRef, orderBy('timestamp', 'desc'), limit(50))
            const querySnapshot = await getDocs(q)
            let logArray = []
            querySnapshot.forEach((doc) => {
                logArray.push(doc.data())
            })
            setLogs(logArray)
        }
    }, [])
        return(
        <>
        {SuperAdmin ?
            <>
                {logs &&
                    <>
                    <Text mb='3'>This page is in beta. It will show the most recent 50 logs only.&nbsp;      
                        <Link target={'_blank'} 
                         
                         href='https://docs.google.com/spreadsheets/d/1VfvBz_CmGHk6nUaxeenTmv0U3pStGFyVIipYL3DsFLo/edit#gid=0'>
                         Copy of Pipeline for testing <ExternalLinkIcon />
                        </Link>
                    </Text>
                    <EditLog log={logs} showLoanNumber/>
                    </>
                }
            </>
            :
            <Text>You do not have permission to access this page</Text>
        }
        </>
        )
}