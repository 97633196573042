import { useHistory } from 'react-router-dom';
import Activity from './Activity'
import { Heading, Flex, IconButton } from '@chakra-ui/react';
import { FiSettings } from 'react-icons/fi';
import { useAuth } from '../../../contexts/AuthContext';

export default function Day( { day, isFirst } ){
    const history = useHistory()
    return(
        <>
            <Flex 
                // borderRight='1px' 
                // borderRightColor='lightgrey' 
                // borderLeft={isFirst && '1px'}
                // borderLeftColor={isFirst && 'lightgrey'}
                height='100%' 
                flex={'1'} 
                flexDir='column' 
                justify='space-between'
                key={`${day.title}`}
            >
                {day.title === 'Monday' &&                 
                    <IconButton 
                        position='absolute' 
                        variant='ghost'
                        icon={<FiSettings/>} 
                        onClick={() => history.push('/my-dashboard/calendar-settings')}>
                    </IconButton>}
                {day.title && 
                    <Heading 
                        color='gray.500' 
                        align={'center'} 
                        p='2' 
                        size='sm'>
                            {day.title}
                    </Heading>}
       
                {day && day.activities && day.activities.map((activity, i) => {
                    return(
                        <Activity key={`${activity.id}-${i}`} activity={activity} isLast={day.activities.length === i+1} isFirst={i === 0} />
                    )
                })}
            </Flex>
        </>
    )
}