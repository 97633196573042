import { TextareaControl, SelectControl, InputControl, CheckboxContainer, CheckboxControl } from "formik-chakra-ui"
import {
    Wrap,
    WrapItem,
    useColorModeValue,
    ScaleFade
} from "@chakra-ui/react";
import { DatePickerField } from "../DatePicker";
import DividerWithText from "../DividerWithText";
export default function EventRequestFields( { values } ){
    const datepickerClass = useColorModeValue("chakra-input css-1c6j008","chakra-input css-xpongc")

    return(
        <>
            <ScaleFade in initialScale={0.8}>
            <DividerWithText text='EVENT DETAILS' />
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                <WrapItem width={{ base: '100%', md: '47%' }} h={20}>
                    <InputControl 
                        name="Name of Event"
                        label="Name of Event"
                    >
                    </InputControl>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '47%' }} h={20}>
                    <InputControl 
                        name="Venue"
                        label="Venue"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>

                <WrapItem width={{ base: '100%', md: '25%' }} h={20}>
                    <DatePickerField name="Event Date" label="Event Date" autoSetEndDate={'Event End Time'} className={datepickerClass} dateFormat='Pp' showTimeSelect/>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '25%' }} h={20}>
                    <DatePickerField name="Event End Time" label="Event End Time" className={datepickerClass} dateFormat='Pp' showTimeSelect/>
                </WrapItem>

                <WrapItem width={{ base: '100%', md: '42%' }} h={20}>
                    <InputControl 
                        name="Total Cost"
                        label="Total Cost ($)"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={5}>
                <WrapItem width={{ base: '100%', md: '47%' }} h={20}>
                    <InputControl 
                        name="Partnering Company"
                        label="Partnering Company"
                    >
                    </InputControl>
                </WrapItem>
                <WrapItem width={{ base: '100%', md: '47%' }} h={20}>                    <InputControl 
                        name="Point of Contact"
                        label="Point of Contact"
                    >
                    </InputControl>
                </WrapItem>

            </Wrap>
            <InputControl 
                name="Address"
                label="Address"
                mb={5}
            >
            </InputControl>
            <TextareaControl mb={5} name="Goal of Event" label="Goal of Event" />

            <DividerWithText text='WHAT DO YOU NEED FROM US?' />
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={25} mt={5}>
                <WrapItem width={{ base: '100%', md: '31%' }}>
                    <CheckboxContainer stackProps={{paddingLeft:'3'}} name="Social Media Needs" label="Social Media Needs">
                        <CheckboxControl name="Social Media Needs" value="Create Flyers">
                            Create Flyers
                        </CheckboxControl>
                        <CheckboxControl name="Social Media Needs" value="Create Social Media Posts">
                            Create Social Media Posts
                        </CheckboxControl>
                        <CheckboxControl name="Social Media Needs" value="Create Facebook Page">
                            Create Facebook Page
                        </CheckboxControl>
                        <CheckboxControl name="Social Media Needs" value="Create MailChimp Blasts">
                            Create MailChimp Blasts
                        </CheckboxControl>
                    </CheckboxContainer>
                </WrapItem>
                <WrapItem width={{ base: '43%', md: '32%' }} h={20}>
                    <InputControl 
                        name="Percentage Requested From Future"
                        label="Percentage Requested From Future"
                    >
                    </InputControl>
                </WrapItem>

                <WrapItem width={{ base: '43%', md: '31%' }} h={20}>
                    <SelectControl 
                        name="Team Member Needed"
                        label="Team Member Needed"
                    >
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </SelectControl>
                </WrapItem>
            </Wrap>
            <DividerWithText text='EVENT INFORMATION' />
            <Wrap spacing={{base: "5%", md: '2%'}}  mb={50} mt={5}>
                <WrapItem width={{ base: '43%', md: '31%' }} h={20}>
                    <SelectControl 
                        name="Participant Information Collected"
                        label="Is participant info collected?"
                        selectProps={{placeholder: 'Select Yes or No'}}
                    >
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </SelectControl>
                </WrapItem>
                <WrapItem width={{ base: '43%', md: '31%' }} h={20}>
                    <InputControl 
                        name="Expected Attendance"
                        label="Expected Attendance"
                    >
                    </InputControl>
                </WrapItem>

                <WrapItem width={{ base: '43%', md: '31%' }} h={20}>
                    <InputControl 
                        name="Expected Reach"
                        label="Expected Reach"
                    >
                    </InputControl>
                </WrapItem>
            </Wrap>
            <DividerWithText text='ANYTHING ELSE WE SHOULD KNOW' />
            <TextareaControl mb={5} mt={5} name="Notes"  />
        </ScaleFade>
        </>
    )
}