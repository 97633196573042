import {
  VStack,
  Box,
  Button,
  Text,
  Input,
  Grid,
  IconButton,
  Heading,
  Flex,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  AccordionIcon,
  useDisclosure,
  FormLabel,
  useColorModeValue,
  Link,
  InputGroup,
  Icon,
  Image,
  Spinner,
  Center,
  Tag,
  TagRightIcon,
  TagLeftIcon,
} from "@chakra-ui/react";
import { db, firebase } from "../../utils/init-firebase";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  collection,
  query,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  onSnapshot,
  setDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import {
  FiCheck,
  FiCheckCircle,
  FiCircle,
  FiEdit,
  FiPenTool,
  FiTrash,
  FiTrash2,
  FiUpload,
  FiX,
  FiXCircle,
} from "react-icons/fi";
import CreatableOptions from "../../components/Settings/CreatableOptions";
import BooleanSwitch from "../../components/Settings/BooleanSwitch";
import { FaPencilAlt, FaTrashRestore } from "react-icons/fa";
import { BiPencil } from "react-icons/bi";
import CreatableOptions_Object from "../../components/Settings/CreateableOptions_ArrayOfObjects";
import CreatableOptions_ArrayOfObjects from "../../components/Settings/CreateableOptions_ArrayOfObjects";
import CreatableOptions_SingleObject from "../../components/Settings/CreateableOptions_SingleObject";
import ReactTable from "../../components/Table/ReactTable";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  FormControl,
} from "@chakra-ui/react";
import React from "react";
import {
  InputControl,
  RadioGroupControl,
  SelectControl,
  SubmitButton,
  TextareaControl,
  ResetButton,
  CheckboxSingleControl,
  CheckboxControl,
} from "formik-chakra-ui";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import DividerWithText from "../../components/DividerWithText";
import { SpinnerIcon } from "@chakra-ui/icons";
import ReactTable_NoPagination from "../../components/Table/ReactTable__Collapsible";
import ReactTable_Collapsible from "../../components/Table/ReactTable__Collapsible";

const handleMakeInactive = async (id, inactiveValue) => {
  try {
    await updateDoc(doc(db, "matrixElements", id), { inactive: inactiveValue });
  } catch (error) {}
};

export default function ManageMatrixElements({ initialSettings, settingsRef }) {
  const [matrixElements, setMatrixElements] = useState([]);
  const [columns, setColumns] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contentTypes, setContentTypes] = useState([]);
  const [productTypes, setProudctTypes] = useState([]);
  useEffect(() => {
    const q = query(
      collection(db, "Marketing Content Types"),
      orderBy("timestamp")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const t = [];
      querySnapshot.forEach((doc) => {
        t.push(doc.data());
      });
      setContentTypes(t);
    });

    const q2 = query(
      collection(db, "Marketing Product Types"),
      orderBy("timestamp")
    );
    const unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
      const c = [];
      querySnapshot2.forEach((doc) => {
        c.push(doc.data());
      });
      setProudctTypes(c);
    });
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  const allCols = [
    {
      Header: "Name",
      accessor: "name",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <>
          <Heading size="sm">{value}</Heading>
        </>
      ),
    },
    {
      Header: "Website",
      accessor: "website",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <>
          <Link isExternal size="sm" href={value}>
            {value}
          </Link>
        </>
      ),
    },
    {
      Header: "",
      accessor: "inactive",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <IconButton
          variant={"ghost"}
          color={value ? "green" : "red"}
          icon={value ? <FaTrashRestore /> : <FiTrash2 />}
          onClick={() => handleMakeInactive(row?.original?.id, !value)}
        ></IconButton>
      ),
    },
  ];

  const loadMatrixElements = () => {
    const matrixElementsCollection = collection(db, "matrixElements");

    const unsubscribe = onSnapshot(
      matrixElementsCollection,
      (querySnapshot) => {
        const matrixElementData = [];
        querySnapshot.forEach((doc) => {
          matrixElementData.push({ id: doc.id, ...doc.data() });
        });
        //setColumns(makeColumns(matrixElementData))
        setMatrixElements(matrixElementData);
        setLoading(false);
      },
      (error) => {
        setError(error);
      }
    );

    // Return a cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "matrixElements", id));
      const updatedMatrixElements = matrixElements.filter((l) => l.id !== id);
      setMatrixElements(updatedMatrixElements);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const unsubscribe = loadMatrixElements();
    return () => unsubscribe();
  }, []);

  return (
    <>
      <MatrixElementForm />
      <ReactTable_Collapsible
        contentTypes={contentTypes}
        productTypes={productTypes}
        initialSettings={initialSettings}
        cols={allCols}
        data={matrixElements?.filter(
          (matrixElement) => !matrixElement?.inactive
        )}
        colorScheme="teal"
      />
      <Accordion mt="5" allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Heading size="sm">
              Inactive Matrix Elements (
              {
                matrixElements?.filter(
                  (matrixElement) => matrixElement?.inactive
                )?.length
              }
              )
            </Heading>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <ReactTable
              cols={allCols}
              data={matrixElements?.filter(
                (matrixElement) => matrixElement?.inactive
              )}
              colorScheme="teal"
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export const MatrixElementCard = ({
  matrixElement,
  boxShadow,
  m,
  p,
  initialSettings,
  contentTypes,
  productTypes,
}) => {
  const toast = useToast();
  const aeBackground = useColorModeValue(
    "white none repeat scroll 0 0",
    "#1A202C none repeat scroll 0 0"
  );
  const inputRef = useRef();
  const pdfInputRef = useRef();
  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(
    matrixElement
      ? {
          name: matrixElement.photoName ? matrixElement.photoName : "",
          url: matrixElement.photoURL || "",
        }
      : {
          name: "",
          url: "",
        }
  );

  const [pdfs, setPDFs] = useState([]);
  const [uploadedPDF, setUploadedPDF] = useState(
    matrixElement
      ? {
          name: matrixElement.pdfName ? matrixElement.pdfName : "",
          url: matrixElement.pdfURL || "",
        }
      : {
          name: "",
          url: "",
        }
  );

  const [uploading, setUploading] = useState(false);
  const [uploadingPDF, setUploadingPDF] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const initialValues = matrixElement
    ? matrixElement
    : {
        name: "",
        website: "",
      };

  const uploadFiles = useCallback(() => {
    if (files.length > 0) {
      setUploading(true);
      const theseFiles = files;
      setFiles([]);
      var promises = [];
      for (let i = 0; i < theseFiles.length; i++) {
        const storageRef = firebase.storage().ref();
        const file = theseFiles[i];

        promises.push(
          storageRef
            .child(`Marketing Matrix Files/${initialSettings?.id}/${file.name}`)
            .put(file)
            .then(async (snapshot) => {
              snapshot.ref.getDownloadURL().then(async (url) => {
                setUploadedFile({ url: url, name: file.name });
                return;
              });
            })
        );
      }
      Promise.all(promises)
        .then(async () => {
          setUploading(false);
        })
        .catch((err) => console.log(err.code));
    }
  }, [files]);
  const uploadPDFs = useCallback(() => {
    if (pdfs.length > 0) {
      setUploadingPDF(true);
      const thesePDFs = pdfs;
      setFiles([]);
      var promises = [];
      for (let i = 0; i < thesePDFs.length; i++) {
        const storageRef = firebase.storage().ref();
        const file = thesePDFs[i];

        promises.push(
          storageRef
            .child(`Marketing Matrix Files/${initialSettings?.id}/${file.name}`)
            .put(file)
            .then(async (snapshot) => {
              snapshot.ref.getDownloadURL().then(async (url) => {
                setUploadedPDF({ url: url, name: file.name });
                return;
              });
            })
        );
      }
      Promise.all(promises)
        .then(async () => {
          setUploadingPDF(false);
        })
        .catch((err) => console.log(err.code));
    }
  }, [pdfs]);
  const onFileChange = (e) => {
    setFiles(e.target.files);
  };

  const onPDFChange = (e) => {
    setPDFs(e.target.files);
  };
  useEffect(() => {
    uploadPDFs();
  }, [pdfs]);
  useEffect(() => {
    uploadFiles();
  }, [files]);
  const formRef = useRef();
  const setProductType = async (id) => {
    if (
      matrixElement?.productTypes &&
      matrixElement?.productTypes.includes(id)
    ) {
      await updateDoc(doc(db, "matrixElements", matrixElement.id), {
        productTypeIDs: arrayRemove(id),
      });
    } else {
      await updateDoc(doc(db, "matrixElements", matrixElement.id), {
        productTypeIDs: arrayUnion(id),
      });
    }
  };

  const setContentType = async (id, e) => {
    if (
      matrixElement?.contentTypes &&
      matrixElement?.contentTypes.includes(id)
    ) {
      await updateDoc(doc(db, "matrixElements", matrixElement.id), {
        contentTypeIDs: arrayRemove(id),
      });
    } else {
      await updateDoc(doc(db, "matrixElements", matrixElement.id), {
        contentTypeIDs: arrayUnion(id),
      });
    }
  };
  const onSubmit = async (values) => {
    try {
      // Access form values

      // Your logic for updating the document goes here
      await updateDoc(doc(db, "matrixElements", matrixElement.id), {
        ...values,
        photoName: uploadedFile?.name,
        photoURL: uploadedFile?.url,
        pdfName: uploadedPDF?.name,
        pdfURL: uploadedPDF?.url,
      });

      toast({
        title: `${values.name} Updated!`,
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: true,
      });

      // Optionally, reset the form after successful submission
    } catch (err) {
      console.error(err);
      toast({
        title: `Error updating matrix element!`,
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box m={m} p={p} key={matrixElement.id} boxShadow={boxShadow}>
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => (
            <>
              <Box overflowY={"auto"}>
                <Box as="form" onSubmit={handleSubmit}>
                  <Flex flexDir={"row"} justify="space-between">
                    <Flex flexDir={"column"} flex={1}>
                      <InputControl name="name" label="Name" mb="2" />
                      <InputControl
                        name="website"
                        label="Website"
                        labelProps={{ fontSize: "sm", m: 0 }}
                      />
                      <SubmitButton
                        mt="2"
                        onClick={handleSubmit}
                        colorScheme={"green"}
                      >
                        Save
                      </SubmitButton>
                    </Flex>
                    <Flex flex={1} m="2" p="2">
                      <Box m="1">
                        <Heading size="sm" mb="1">
                          Content Types
                        </Heading>
                        {contentTypes &&
                          contentTypes.map((contentType) => {
                            return (
                              <Box mb="1">
                                <Tag
                                  size={"md"}
                                  _hover={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setContentType(contentType.id, e)
                                  }
                                  variant={
                                    matrixElement?.contentTypeIDs &&
                                    matrixElement?.contentTypeIDs.includes(
                                      contentType.id
                                    )
                                      ? "solid"
                                      : "outline"
                                  }
                                  // fontWeight={
                                  //   matrixElement?.contentTypes &&
                                  //   matrixElement?.contentTypes.includes(
                                  //     contentType.id
                                  //   )
                                  //     ? "bold"
                                  //     : "light"
                                  // }
                                  colorScheme={contentType.color}
                                  boxShadow={"none"}
                                >
                                  {matrixElement?.contentTypeIDs &&
                                  matrixElement?.contentTypeIDs.includes(
                                    contentType.id
                                  ) ? (
                                    <TagLeftIcon as={FiCheckCircle} />
                                  ) : (
                                    <TagLeftIcon as={FiCircle} />
                                  )}
                                  {contentType.name}
                                </Tag>
                              </Box>
                            );
                          })}
                      </Box>
                      <Box m="1">
                        <Heading size="sm" mb="1">
                          Product Types
                        </Heading>
                        {productTypes &&
                          productTypes.map((productType) => {
                            return (
                              <Box mb="1">
                                <Tag
                                  size={"md"}
                                  _hover={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setProductType(productType.id, e)
                                  }
                                  variant={
                                    matrixElement?.productTypeIDs &&
                                    matrixElement?.productTypeIDs.includes(
                                      productType.id
                                    )
                                      ? "solid"
                                      : "outline"
                                  }
                                  fontWeight={
                                    matrixElement?.productTypeIDs &&
                                    matrixElement?.productTypeIDs.includes(
                                      productType.id
                                    )
                                      ? "bold"
                                      : "light"
                                  }
                                  colorScheme={productType.color}
                                  boxShadow={"none"}
                                >
                                  {matrixElement?.productTypeIDs &&
                                  matrixElement?.productTypeIDs.includes(
                                    productType.id
                                  ) ? (
                                    <TagLeftIcon as={FiCheckCircle} />
                                  ) : (
                                    <TagLeftIcon as={FiCircle} />
                                  )}
                                  {productType.name}
                                </Tag>
                              </Box>
                            );
                          })}
                      </Box>
                    </Flex>
                    <Flex flex={1} m="2" p="2">
                      <FormControl ml="3">
                        <FormLabel fontWeight={"bold"} htmlFor="fileUpload">
                          Image
                        </FormLabel>
                        <InputGroup>
                          {!uploadedFile.url && (
                            <IconButton
                              onClick={() => inputRef.current.click()}
                              disabled={uploading}
                            >
                              <Icon as={uploading ? Spinner : FiUpload} />
                            </IconButton>
                          )}

                          <input
                            type="file"
                            onChange={onFileChange}
                            name="Upload Image"
                            ref={inputRef}
                            style={{ display: "none" }}
                            accept="image/jpeg, image/png, image/gif, image/webp"
                          />

                          {uploadedFile.url && (
                            <>
                              <Image maxH="200px" src={uploadedFile.url} />
                              <Flex
                                ml="1"
                                flexDir={"column"}
                                justify="space-between"
                              >
                                <IconButton
                                  disabled={uploading}
                                  variant={"unstyled"}
                                  onClick={() =>
                                    setUploadedFile({ name: "", url: "" })
                                  }
                                >
                                  <Icon as={FiXCircle}></Icon>
                                </IconButton>
                                <IconButton
                                  variant={"unstyled"}
                                  onClick={() => inputRef.current.click()}
                                >
                                  <Icon as={uploading ? Spinner : FiUpload} />
                                </IconButton>
                              </Flex>
                            </>
                          )}
                        </InputGroup>
                      </FormControl>
                    </Flex>

                    <Flex flex={1} m="2" p="2">
                      <FormControl ml="3">
                        <FormLabel fontWeight={"bold"} htmlFor="fileUpload">
                          PDF
                        </FormLabel>
                        <InputGroup>
                          {!uploadedPDF.url && (
                            <IconButton
                              disabled={uploadingPDF}
                              onClick={() => pdfInputRef.current.click()}
                            >
                              <Icon as={uploadingPDF ? Spinner : FiUpload} />
                            </IconButton>
                          )}

                          <input
                            type="file"
                            onChange={onPDFChange}
                            name={"Upload PDF"}
                            ref={pdfInputRef}
                            style={{ display: "none" }}
                            accept="application/pdf"
                          />
                          {uploadedPDF.url && (
                            <>
                              <Link isExternal href={uploadedPDF.url}>
                                {uploadedPDF.name}
                              </Link>
                              <Flex
                                ml="1"
                                flexDir={"column"}
                                justify="space-between"
                              >
                                <IconButton
                                  disabled={uploadingPDF}
                                  variant={"unstyled"}
                                  onClick={() =>
                                    setUploadedPDF({ name: "", url: "" })
                                  }
                                >
                                  <Icon as={FiXCircle}></Icon>
                                </IconButton>
                                <IconButton
                                  variant={"unstyled"}
                                  onClick={() => pdfInputRef.current.click()}
                                  disabled={uploadingPDF}
                                >
                                  <Icon
                                    as={uploadingPDF ? Spinner : FiUpload}
                                  />
                                </IconButton>
                              </Flex>
                            </>
                          )}
                        </InputGroup>
                      </FormControl>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};

const MatrixElementForm = ({ matrixElement, setEditingName }) => {
  const [name, setName] = useState(matrixElement ? matrixElement.name : "");

  const handleSave = async () => {
    if (name.trim() === "") {
      alert("Name cannot be empty");
      return;
    }

    if (matrixElement) {
      // Update an existing matrix element
      await updateDoc(doc(db, "matrixElements", matrixElement.id), { name });
    } else {
      // Create a new matrix element
      const docRef = doc(collection(db, "matrixElements"));
      await setDoc(docRef, {
        name: name,
        id: docRef.id,
        inactive: false,
        "Core MatrixElement": false,
      });
    }

    // Reset the form
    setEditingName && setEditingName(false);
    setName("");
  };

  return (
    <Flex>
      <Input
        type="text"
        placeholder="Name"
        value={name}
        width="250px"
        onChange={(e) => setName(e.target.value)}
      />
      {matrixElement ? (
        <IconButton
          variant={"ghost"}
          colorScheme={"green"}
          icon={<FiCheck />}
          onClick={handleSave}
        ></IconButton>
      ) : (
        <Button colorScheme={"green"} ml="2" onClick={handleSave}>
          Create Matrix Element
        </Button>
      )}
    </Flex>
  );
};
