// question.js
import { useState } from 'react';
import { FormControl, Select, Button, Flex, IconButton, Input } from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';
import useQuestions from './useQuestions';

function Question(props) {
  const { question, isAddition } = props
  const { createQuestion, updateQuestion, deleteQuestion } = useQuestions()
  const [editing, setEditing] = useState(false)
  const [newTitle, setNewTitle] = useState(question?.title && question.title)
  const [newType, setNewType] = useState(question?.type && question.type)

  const handleAddOrSave = () => {
    if(isAddition){
        createQuestion({title: newTitle, type: newType})
        setNewTitle('')
        setNewType('')
    }
    else{
        updateQuestion(question.id, {title: newTitle, type: newType})
    }
  }


  return (
      <Flex key={question.id}
            flexDir={'row'}
            p='1'
            w={isAddition ? '83vw': '79vw'}
            justify={'flex-start'}
            align='center'
            >
                {!isAddition &&
                <IconButton
                        size="sm"
                        variant='ghost'
                        icon={<FiTrash2/>}
                        colorScheme='red'

                        onClick={(e) => {
                        e.stopPropagation();
                        deleteQuestion(question.id);
                        }}
                    ></IconButton>}
                  <Input 
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    mr='1'
                    w='68%'
                    >
                  </Input>
                  <FormControl w={isAddition ? '19%' : '20%'} mr='1'>
                    <Select placeholder='Select A Type' value={newType} onChange={(e) => setNewType(e.target.value)}>
                        <option value="Yes/No">Yes/No</option>
                        <option value="Short Input">Short Input</option>
                        <option value="Long Input">Long Input</option>
                    </Select>
                </FormControl>
                  <Button
                    size="md"
                    colorScheme={isAddition ? 'blue' : 'green'}
                    disabled={(question?.title == newTitle && question?.type == newType) ||
                                newType == '' || newTitle == ''
                    }
                    onClick={() => handleAddOrSave()}
                  >{isAddition ? 'Add' : 'Save'}</Button>
   
      </Flex>
  );
}

export default Question;
