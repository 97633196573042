import React, { useState } from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td, Flex, useColorModeValue, Heading, Box, Text, IconButton,
} from '@chakra-ui/react';
import { useTable, useSortBy } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon, ArrowForwardIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { MatrixElementCard } from '../../pages/Marketing/ManageMarketingMatrix';

export default function ReactTable_Collapsible({ cols, data, title, colorScheme, maxWidth, width, initialSettings,contentTypes, productTypes }) {
  const backgroundHeaderColor = useColorModeValue(`${colorScheme}.100`, `${colorScheme}.800`);
  const columns = React.useMemo(() => cols, [cols]);

  const [expandedRows, setExpandedRows] = useState({});

  const {
    getTableProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  // Toggle expanded state for rows
  const toggleExpandedRow = (rowIndex) => {
    const newState = { ...expandedRows, [rowIndex]: !expandedRows[rowIndex] };
    setExpandedRows(newState);
  };

  return (
    <>
      {title && (
        <Heading m="2" size="md" fontWeight="semibold" textTransform="uppercase">
          {title}
        </Heading>
      )}
      {data && cols && data.length === 0 && cols.length === 0 ? (
        <Text m="2" fontSize="sm" textTransform="uppercase" color="gray">
          No data found
        </Text>
      ) : (
        <Table {...getTableProps()} width={width} maxWidth={maxWidth} mt="1%" overflow="scroll" boxShadow="md" mb="5">
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                <Th backgroundColor={backgroundHeaderColor}>Expand</Th> {/* Additional header for expand/collapse control */}
                {headerGroup.headers.map(column => (
                  <Th
                    backgroundColor={backgroundHeaderColor}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Flex alignItems="center">
                      {column.render('Header')}
                      {column.isSorted ? (column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />) : ''}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              const isExpanded = expandedRows[row.index] || false;
              return (
                <>
                  <Tr {...row.getRowProps()}>
                  <Td>
                      <IconButton
                        aria-label="Expand row"
                        icon={isExpanded ? <ArrowDownIcon /> : <ArrowForwardIcon />}
                        onClick={() => toggleExpandedRow(row.index)}
                      />
                    </Td>
                    {row.cells.map(cell => (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                   
                  </Tr>
                  {isExpanded && (
                    <Tr>
                      <Td colSpan="100%">
                        <MatrixElementCard matrixElement={row.original} initialSettings={initialSettings} contentTypes={contentTypes} productTypes={productTypes}/>
                      </Td>
                    </Tr>
                  )}
                </>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
}
