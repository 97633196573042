import { Avatar, Box, Divider, Flex } from "@chakra-ui/react";

export default function ParagraphWithHeader( { header, content, photoURL } ){
    return(
        <Flex flexDir={'column'} mb='2'>
            <Flex flexDir={'row'} align={'center'} mb='1'>
                {photoURL &&
                    <Avatar mr='1' size="xs" src={photoURL} />
                }
                <Box
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'
                    mr='1'
                    >
                {header}
                </Box>
            </Flex>
            <Divider />
            <Box
            color='gray.500'
            fontSize='sm'
            >
                {content}
            </Box>
        </Flex>
    )
}