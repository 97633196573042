export const states = [
    {
      "name": "Alabama",
      "code": "AL"
    },
    {
      "name": "California",
      "code": "CA"
    },
    {
      "name": "Florida",
      "code": "FL"
    },
    {
      "name": "Georgia",
      "code": "GA"
    },
    {
      "name": "North Carolina",
      "code": "NC"
    },
    {
      "name": "South Carolina",
      "code": "SC"
    },
    {
      "name": "Tennessee",
      "code": "TN"
    },
    {
      "name": "Texas",
      "code": "TX"
    },
    {
      "name": "Virginia",
      "code": "VA"
    },
  
  ]