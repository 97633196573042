import { Badge, Tag, TagLabel, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import { FiLock, FiTrendingUp } from "react-icons/fi";


export default function Rate_FloatedOrLocked( { rate, floatedOrLocked } ){
    
    return(
        <>
        {rate &&
        <Tooltip label={`Rate - ${floatedOrLocked}`} placement='top'>
                <Badge colorScheme={floatedOrLocked == 'Locked' ? 'green' : 'orange'} 
                        variant='subtle'
                        borderRadius={'full'}
                        px='2'
                        fontWeight='bold' 
                        textTransform='uppercase' 
                        >
                    <TagLeftIcon mt='1' as={floatedOrLocked == 'Locked' ? FiLock : FiTrendingUp} />
                    {rate}%
                </Badge>
           
        </Tooltip>
        }
        </>
    )
}