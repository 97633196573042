import React, { useState } from "react";
import {
  Flex,
  Select,
  Button,
} from "@chakra-ui/react";

interface Product {
  Name: string;
  photoURL: string;
  Cost: number;
  id: string;
}

interface QuantitySelectProps {
  product: Product;
  amountInCart: number;
  Quantity: number;
  handleAddToCart: (id: string, amount: number, setAmount: React.Dispatch<React.SetStateAction<number | undefined>>) => void;
  index: number;
}

export default function QuantitySelect(props: QuantitySelectProps): JSX.Element {
  const { product, amountInCart, Quantity, handleAddToCart, index } = props;
  const { Name, photoURL, Cost, id } = product;

  const [amount, setAmount] = useState<number | undefined>(undefined);

  return (
    <Flex bottom="0" _hover={Quantity < 1 && { cursor: "not-allowed" }} borderRadius="lg" key={id} justify={"flex-start"} flexDir={"row"}>
      {/* <Text>Quantity ({Quantity}), amountInCart ({amountInCart})</Text> */}
      {Quantity > 0 && (
        <Select
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          maxW="70px"
          data-testid="quantity-select"
          aria-label="Select quantity"
          fontSize={'sm'}
          mr={3}
          id={`select-${index}`}
          disabled={Quantity < 1 || amountInCart > 19 || (Quantity - amountInCart) < 1}
        >
          {Array(
              Quantity < 1 ? 0
            : amountInCart > Quantity ? 0
            : amountInCart > 19 ? 0
            : Quantity < 20 && (Quantity + 1 - amountInCart) > 0 ? Quantity + 1 - amountInCart
            : 21 - amountInCart > 0 ? 21 - amountInCart
            : 1 
          )
            .fill(null)
            .map((value, index) => {
              return (
                <option
                  data-testid={`quantity-option-${index}`}
                  key={index}
                  value={index}
                >
                  {index}
                </option>
              );
            })}
        </Select>
      )}
      <Button
        data-testid="add-to-cart-button"
        disabled={Quantity < 1 || !amount || amount < 1 || amountInCart > 19}
        onClick={(e) => handleAddToCart(id, amount!, setAmount)}
        colorScheme="blue"
        whiteSpace={'normal'}
        fontSize='sm'
      >
        {Quantity > 0 ? "Add to cart" : "Out of stock"}
      </Button>
    </Flex>
  );
}
