import useQuestions from "./useQuestions";
import QuestionList from "./QuestionList";
import Question from "./Question";

export default function ManageQuestions(){
    const { questions } = useQuestions()

    return(
        <>
            <Question isAddition question={{title: '', type: ''}} />
            {questions && 
                <QuestionList />
            }
        </>
    )
}