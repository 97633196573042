import { Drawer, Button, DrawerBody, DrawerOverlay, DrawerFooter, DrawerHeader, DrawerContent, DrawerCloseButton, IconButton } from "@chakra-ui/react";
import { query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import GroupChat from "./Client/GroupChat";
import { onSnapshot } from "firebase/firestore";
import { FiFileText } from "react-icons/fi";


export default function NotesDrawer({ notesRef, parentID, title }){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [notes, setNotes] = useState([])
    const btnRef = React.useRef()
    const { currentUser } = useAuth()

    useEffect( async () => {
        const q = query(notesRef);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let notes = []
            querySnapshot.forEach((doc) => {
                notes.push(doc.data())
            });
            setNotes(notes)
        })
        
        return () => {
            unsubscribe()
        }
    }, [])
    
    return(
        <>
        <IconButton icon={<FiFileText/>} colorScheme='facebook' size='md' onClick={onOpen} variant={'ghost'}>Notes</IconButton>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size='lg'
            >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{title ? title : 'Loan Notes'}</DrawerHeader>
            <DrawerBody>
                <GroupChat 
                    collectionRef={notesRef} 
                    parentID={parentID}
                    width='100%' 
                    isNotes={true} 
                    notesList={notes}
                />
            </DrawerBody>
            <DrawerFooter>
                <Button variant='outline' mr={3} onClick={onClose}>
                Close
                </Button>
            </DrawerFooter>
            </DrawerContent>
        </Drawer>
      </>
    )
}
