import BooleanSwitch from "../Settings/BooleanSwitch";


export default function ClientSettings( { docRef, initialSettings } ){
    return(
        <>
        <BooleanSwitch
            docRef={docRef}
            fieldPath={'Settings.Email LO When Parked'}
            initialState={initialSettings ? initialSettings['Email LO When Parked'] ? initialSettings['Email LO When Parked'] : false : false}
            label='Send Email Reminders to LO every 3 hours when Parked'
            id='email-lo-when-parked'
            successMsg={'LO will be sent email reminders when this client is parked'}
            warningMsg={'LO will NOT be sent email reminders when this client is parked'}

        />
      
        <BooleanSwitch
            docRef={docRef}
            fieldPath={'Settings.Push LO When Parked'}
            initialState={initialSettings ? initialSettings['Push LO When Parked'] ? initialSettings['Push LO When Parked'] : false : false}
            label='Send Push Notification to LO every 3 hours when Parked'
            id='push-lo-when-parked'
            successMsg={'LO will be sent push notifications when this client is parked'}
            warningMsg={'LO will NOT be sent push notifications when this client is parked'}

        />
        </>
    )
}