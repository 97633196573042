import { useState, useEffect } from 'react';
import { query, collection, onSnapshot, doc, updateDoc, deleteDoc, serverTimestamp, orderBy, setDoc, getDoc, collectionGroup, where, getDocs } from 'firebase/firestore';
import { db } from '../../utils/init-firebase';

const currentYear = new Date().getFullYear(); // Get the current year

function groupTransactionsByTaskID(transactions) {
    const groupedTransactions = {};
    
    transactions.forEach(transaction => {
      const { taskID, Item, ...rest } = transaction;
      
      if (!groupedTransactions[taskID]) {
        groupedTransactions[taskID] = {
          taskID: taskID,
          Items: [],
          totalCost: 0,
          ...rest
        };
      }
      
      groupedTransactions[taskID].Items.push(Item);
      groupedTransactions[taskID].totalCost += (Item.Cost * Item.Amount);
    });
    
    return Object.values(groupedTransactions);
}
export function useBudget(selectedUser, selectedYear) {
  const firstDayOfYear = new Date(selectedYear, 0, 1); // Get the first day of the selected year
  const lastDayOfYear = new Date(selectedYear, 11, 31); // Get the last day of the selected year
  const [transactions, setTransactions] = useState([]);
  const [currentSpend, setCurrentSpend] = useState()
  const [budget, setBudget] = useState()

  useEffect( async () => {
    const getTransactions = async () => {
        try{
        const transactionsQuery = query(collectionGroup(db, 'Marketing Swag Transactions'), 
                                        where('timestamp', '>=', firstDayOfYear), 
                                        where('timestamp', '<=', lastDayOfYear), 
                                        where('Owner.uid', '==', selectedUser.value), 
                                        orderBy('timestamp', 'desc'));

        const marketingSpendQuery = query(collectionGroup(db, 'Marketing Requests'), 
                                        where('Date Completed', '>=', firstDayOfYear), 
                                        where('Date Completed', '<=', lastDayOfYear), 
                                        where('Owner.uid', '==', selectedUser.value), 
                                        where('Request Details.Request Type', '==', 'Marketing Spend'),
                                        orderBy('Date Completed', 'desc'));

        const transactionsSnapshot = await getDocs(transactionsQuery);


        let trans = []
        transactionsSnapshot.forEach((doc) => {
            trans.push(doc.data())
        });

        const marketingSpendSnapshot = await getDocs(marketingSpendQuery);
        let spend = []
        marketingSpendSnapshot.forEach((doc) => {
            spend.push({
              ...doc.data(),
              totalCost: doc.data()?.['Request Details']?.Amount && parseFloat(doc.data()?.['Request Details']?.Amount),
              'Fulfilled By': doc.data()?.Assignees?.[0] && doc.data()?.Assignees[0],
              taskID: doc.id,
              timestamp: doc.data()?.['Date Completed'],
              isMarketingSpend: true,
              'Spend Category': doc.data()?.['Request Details']?.['Spend Category']
            })
        });
        if(trans.legnth > 0 || spend.length > 0){
            setTransactions([
              ...groupTransactionsByTaskID(trans),
              ...spend
          ].sort((a, b) => a.timestamp - b.timestamp))
        }
        else setTransactions([])

        }
        catch(err){
            console.log(err)
        }
    }

    const getBudget = async () => {
        const userRef = doc(db, 'users', selectedUser?.value)
        const docSnap = await getDoc(userRef)
        if (docSnap.exists()) {
          setBudget(docSnap.data()?.['Swag Budget'] && docSnap.data()?.['Swag Budget'])
        } 
    }
    if(selectedUser){
      await getTransactions()
      await getBudget()
    }
  
  }, [selectedUser])
  
  useEffect(() => {
    function sumMarketingTransactions(){
      let sum = transactions.reduce((prev, cur) => {
          const prevCost = parseFloat(prev.totalCost) || 0;
          const curCost = parseFloat(cur.totalCost) || 0;
          return { totalCost: (prevCost + curCost).toFixed(2) }; // Round to 2 decimal places
      }, { totalCost: '0.00' });

      return parseFloat(sum.totalCost);
    }
    if(transactions && transactions.length > 0) {
      setCurrentSpend(sumMarketingTransactions())
    }
    else setCurrentSpend(0)
  }, [transactions])

    return {  
        transactions, 
        currentSpend,
        budget,
        setBudget
        };
  }



