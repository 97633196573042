import { Tag, TagLeftIcon, TagRightIcon, TagLabel, Wrap, WrapItem, Text, Link, Box, useColorModeValue, Heading } from "@chakra-ui/react";
import { FiDollarSign, FiMail, FiMapPin, FiPhone, FiUser } from "react-icons/fi";
import moment from "moment";
import { FaCalendarCheck, FaRegBuilding } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { SiZillow } from 'react-icons/si'

export default function OpenHouseRequestBadges ( { request, showAllInfo } ){
    const textColor = useColorModeValue('gray.600', 'gray.400')
    const realtorHeadingBackground = useColorModeValue('white none repeat scroll 0 0', '#1A202C none repeat scroll 0 0')

    return(
        <>
            <Wrap justify={'flex-start'} flexWrap flexDir='row' mb={4}>

            {request['Request Details']['Property'] && request['Request Details']['Property']['Address'] != '' &&
                <WrapItem maxW='100%'>
                    <Link isTruncated href={`https://maps.google.com/?q=${request['Request Details']['Property']['Address']}`} isExternal>
                        <Tag size='md' overflowWrap={'break-word'} variant='outline' colorScheme={'gray'} textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={FiMapPin}/>
                            <TagLabel fontSize="14px">{request['Request Details']['Property']['Address']}</TagLabel>
                        </Tag> 
                    </Link>
                </WrapItem>
            }
             {request['Request Details']['Property'] && request['Request Details']['Property']['Zillow Link'] != '' &&
                <WrapItem>
                   
                    <Link isTruncated href={request['Request Details']['Property']['Zillow Link']} isExternal>
                        <Tag size='md' variant='subtle' colorScheme={'blue'} textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={SiZillow}/>
                            <TagLabel isTruncated fontSize="14px">Zillow Link</TagLabel>
                            <TagRightIcon as={FiExternalLink}/>
                        </Tag> 
                    </Link>
                </WrapItem>

            }
            {request['Request Details']['Event Date'] && request['Request Details']['Event Date'] != '' &&
                <WrapItem>
                    <Tag size='md' 
                        colorScheme={'blue'} 
                        variant='outline' 
                        textTransform='uppercase' 
                        >
                        <TagLeftIcon  as={FaCalendarCheck} />
                        <TagLabel fontSize="14px">
                            {moment(request['Request Details']['Event Date'].seconds*1000).format('MMMM D h:mma')}
                            &nbsp;- {moment(request['Request Details']['Event End Time'].seconds*1000).format('h:mma')}

                        </TagLabel>
                    </Tag> 
                </WrapItem>

                }
                {showAllInfo &&
                <>                 
                {request['Request Details']['Property'] && request['Request Details']['Property']['Price'] != '' &&
                     <WrapItem>
                         <Tag size='md' variant='outline' colorScheme={'green'} textTransform='uppercase' fontWeight='bold'>
                             <TagLeftIcon as={FiDollarSign}/>
                             <TagLabel fontSize="14px">{request['Request Details']['Property']['Price']}</TagLabel>
                         </Tag> 
                     </WrapItem>
                 }
                     {request['Request Details']['Property'] && request['Request Details']['Property']['MLS#'] != '' &&
                     <WrapItem>
                         <Tag size='md' variant='outline' colorScheme={'cyan'} textTransform='uppercase' fontWeight='bold'>
                             <TagLabel fontSize="14px">MLS# {request['Request Details']['Property']['MLS#']}</TagLabel>
                         </Tag> 
                     </WrapItem>
                 }
                 </>

                }
          </Wrap>  
            {showAllInfo &&
                <>  
                {request['Request Details']['Realtor'] && 
                <>
                <Heading 
                    colorScheme={'gray'} 
                    mt={'-9.8em'} 
                    ml={'1em'} 
                    padding='0 3px' 
                    display={'inline-block'} 
                    background={realtorHeadingBackground} 
                    size='xs'>
                        REALTOR
                </Heading>
                <Box maxW={'fit-content'} border={'1px solid lightgray'} borderRadius='15px' padding={'0 3px'} mt={'-0.8em'}>
                    <Wrap justify={'flex-start'} direction='column' mb={2} mt={3} spacing='0'>
                        {request['Request Details']['Realtor'] && request['Request Details']['Realtor']['Name'] != '' &&
                            <WrapItem>
                                <Tag size='md' variant='ghost' colorScheme={'red'} opacity={0.75} textTransform='uppercase' fontWeight='bold'>
                                    <TagLeftIcon as={FiUser}/>
                                    <TagLabel fontSize="14px">{request['Request Details']['Realtor']['Name']}</TagLabel>
                                </Tag> 
                                {request['Request Details']['Realtor'] && request['Request Details']['Realtor']['SL#'] != '' &&
                                <Tag size='md' variant='ghost' colorScheme={'facebook'} opacity={0.75}  textTransform='uppercase' fontWeight='bold'>
                                    <TagLabel fontSize="14px">SL# {request['Request Details']['Realtor']['SL#']}</TagLabel>
                                </Tag> 
            
                        }
                            </WrapItem>
                        }
                        {request['Request Details']['Realtor'] && request['Request Details']['Realtor']['Company'] != '' &&
                            <WrapItem>
                                <Tag size='md' variant='ghost' colorScheme={'facebook'} opacity={0.75}  textTransform='uppercase' fontWeight='bold'>
                                    <TagLeftIcon as={FaRegBuilding}/>
                                    <TagLabel fontSize="14px">{request['Request Details']['Realtor']['Company']}</TagLabel>
                                </Tag> 
                            </WrapItem>
            
                        }
                        {request['Request Details']['Realtor'] && request['Request Details']['Realtor']['Phone'] != '' &&
                            <Link isExternal href={`tel:${request['Request Details']['Realtor']['Phone']}`}>
                                <WrapItem>
                                    <Tag size='md' variant='ghost' colorScheme={'gray'} opacity={0.75}  textTransform='uppercase' fontWeight='bold'>
                                        <TagLeftIcon as={FiPhone}/>
                                        <TagLabel fontSize="14px">{request['Request Details']['Realtor']['Phone']}</TagLabel>
                                    </Tag> 
                                </WrapItem>
                            </Link>
            
                        }
                            {request['Request Details']['Realtor'] && request['Request Details']['Realtor']['Email'] != '' &&
                            <Link isExternal href={`mailto:${request['Request Details']['Realtor']['Email']}`}>
                                <WrapItem>
                                    <Tag size='md' variant='ghost' colorScheme={'gray'} opacity={0.75}  textTransform='uppercase' fontWeight='bold'>
                                        <TagLeftIcon as={FiMail}/>
                                        <TagLabel fontSize="14px">{request['Request Details']['Realtor']['Email']}</TagLabel>
                                    </Tag> 
                                </WrapItem>
                            </Link>
            
                        }
                    </Wrap>  
                </Box>
                <br/>
                {request['Request Details']['Notes'] &&
                    <Text as={'em'} colorScheme='gray'>{request['Request Details']['Notes']}</Text>
                }      
                </>
                }
            </>
            }
         
       </>
    )
}
    
