import {
    Box,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    ScaleFade,
    Button,
    FormControl,
    FormLabel,
    Switch
} from "@chakra-ui/react";
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { updateDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import RequestSummary from "./RequestSummary";
import AssignRequest from "./AssignRequest";
import { useToast } from '@chakra-ui/react'
import GroupChat from "../Client/GroupChat";
import { CreatableSelect } from "chakra-react-select";

export default function RequestBox({ request }) {

    const reviewBGColor = useColorModeValue('orange.200', 'orange.400')
    const reviewedBGColor = useColorModeValue('green.200', 'green.400')
  
    const notesForReviewRef = collection(db, 'Marketing Requests', request.id, 'Notes For Review')
    const { SuperAdmin, MarketingAdmin, currentUser, branches } = useAuth()
    const toast = useToast()

    const onCompletion = async () => {
        try{
            const thisDocRef = doc(db, 'Marketing Requests', request.id)
            await updateDoc(thisDocRef, {
                'Date Completed': serverTimestamp(),
                'Status': 'Completed',
                'Last Edited By': {
                    Email: currentUser.email,
                    Name: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    uid: currentUser.uid
                }
            })
            toast({
                title: 'Request Completed!',
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top'
            })
        }
        catch(err){
            toast({
                description: err,
                status: 'error',
                isClosable: true,
                duration: 5000,
                position: 'top'
            })
        }
    };

    const toggleClientSetting = async (e, label) => {
        await updateDoc(doc(db, 'Marketing Requests', request.id), {
            [label]: e.target.checked,
            [`Date ${label}`]: serverTimestamp(),
            ['Has Notes For Review']: true
        })
    }

    const handleSetBranch = async (id, e) => {
        if(id && e){
          await updateDoc(doc(db, 'Marketing Requests', id), {
            Branch: e.value
          })
          toast({
            title: "Request assigned to " + e.value + " branch",
            status: 'success',
            position: 'top',
            isClosable: true
          })
        }
        else{
          await updateDoc(doc(db, 'Marketing Requests', id), {
            Branch: null
          }) 
          toast({
            title: "Request's branch removed",
            status: 'warning',
            position: 'top',
            isClosable: true
          })
        }
       
    }

  return (
    <ScaleFade in initialScale={0.85}>
    <Box width={{
        base: '100%',
        xl: '35vw'
        }}  m={0} p={{base: '2', lg: '4'}} borderWidth={'1px'} borderRadius='lg'>
        <RequestSummary request={request} expandDescription showAllInfo />
        {request.Status == 'In Progress' && (SuperAdmin || MarketingAdmin) && request['Request Details']['Request Type'] != 'Swag' &&
            <>
                <Button mt={5} colorScheme='teal' onClick={onCompletion}>Mark Completed</Button>
            </>
        }
        {(SuperAdmin || MarketingAdmin) &&
        <>
        <Box mt={5} key={`branch-select-parent`}>
            <FormControl maxW='75%'key={`branch-select-form-control`}>
                <CreatableSelect
                    key={`branch-select`}
                    value={request.Branch ? {label: request.Branch, value: request.Branch} : null}   
                    isClearable                             
                    options={branches}
                    onChange={(e) => handleSetBranch(request.id, e)}
                    placeholder="Select or create a branch..."
            />
            </FormControl>
        </Box>
        <Accordion mt={4} allowMultiple={true}>
            {(MarketingAdmin || SuperAdmin) &&
                <AccordionItem>
                    <h2>
                    <AccordionButton backgroundColor={request['Coaching Completed'] && SuperAdmin ?
                                                        reviewedBGColor
                                                        :
                                                        request['Flag For Review'] && SuperAdmin ? 
                                                        reviewBGColor : ''}>
                        <Box flex='1' textAlign='left'>
                            {`Notes For Review`}&nbsp;
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pl={0} pr={0}>
                    <FormControl mb={3} display='flex' alignItems='center'>
                        <FormLabel htmlFor='flag-for-review' mb='0'>
                            Flag For Review
                        </FormLabel>
                        <Switch id='flag-for-review' disabled={request['Coaching Completed']} isChecked={request['Flag For Review']} onChange={(e) => toggleClientSetting(e, 'Flag For Review')} />
                    </FormControl>
                    {SuperAdmin &&
                    <FormControl mb={3} display='flex' alignItems='center'>
                        <FormLabel htmlFor='coaching-completed' mb='0'>
                            Coaching Completed

                        </FormLabel>
                        <Switch id='coaching-completed' isChecked={request['Coaching Completed']} onChange={(e) => toggleClientSetting(e, 'Coaching Completed')} />
                    </FormControl>
                    }
                     <GroupChat 
                        collectionRef={notesForReviewRef} 
                        collectionName='Notes For Review'
                        parentID={request.id}
                        width='100%' 
                        isNotes
                        isNotesForReview
                        parentDocContent={request}
                        />

                    </AccordionPanel>
                </AccordionItem> 
                }
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign Request
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <AssignRequest request={request}/>
                    </AccordionPanel>
                </AccordionItem>

            </Accordion>
            </>
        }

    </Box>
    </ScaleFade>
      
  );
}
