import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import {
    Text,
    Wrap,
    WrapItem,
    Stack,
    Input,
    Button,
    useColorModeValue  } from '@chakra-ui/react';
import ClientCard from "../../components/Client/ClientCard";
import { useAuth } from "../../contexts/AuthContext";
import ReactDatePicker from "react-datepicker";
import { datepickerDark, datepickerLight } from "../../contexts/GlobalVariables";
import "react-datepicker/dist/react-datepicker.css";


export default function NotSubmittedPage() {
  const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)
  const { currentUser, isAdmin, SuperAdmin, Roles } = useAuth()
  const { url, path } = useRouteMatch()
  const [clientList, setClientList] = useState(null)
  const [loading, setLoading] = useState(true)
  const [filteredClientList, setFilteredClientList] = useState(null)
  const [loFilter, setLOFilter] = useState('')
  const [lcFilter, setLCFilter] = useState('')
  const [borrowerFilter, setBorrowerFilter] = useState('')

  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const [startDate, setStartDate] = useState(new Date(y, m, 1))
  const [endDate, setEndDate] = useState(new Date())

  const unsubscribeRef = React.useRef()

  const resetFilters = () => {
    setLOFilter('')
    setLCFilter('')
    setBorrowerFilter('')
  }

  const getClients = async () => {
    unsubscribeRef.current && unsubscribeRef.current()
        let q = null
        if(SuperAdmin || isAdmin){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'Not Submitted'), where('Date Added', '>=', startDate), where('Date Added', '<=', endDate));
        }
        else if(Roles.includes('Mortgage Broker') || Roles.includes('Loan Officer')){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'Not Submitted'), where(`Loan Officer.uid`, '==', currentUser.uid ), where('Date Added', '>=', startDate), where('Date Added', '<=', endDate));

        }
        else if(Roles.includes('Closing Coordinator') || Roles.includes('Title Coordinator')){
            q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'Not Submitted'), where(`TC.uid`, '==', currentUser.uid ), where('Date Added', '>=', startDate), where('Date Added', '<=', endDate));
        }
        else if(Roles.includes('Client Success Coordinator')){
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'Not Submitted'), where(`CSC.uid`, '==', currentUser.uid ), where('Date Added', '>=', startDate), where('Date Added', '<=', endDate));
        }
        else {
          q = query(collection(db, "Clients"), orderBy('Date Added', 'desc'), where('Status', '==', 'Not Submitted'), where('Date Added', '>=', startDate), where('Date Added', '<=', endDate));
        }
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let clients = []
          querySnapshot.forEach((doc) => {
              let client = {}
              client = doc.data()
              client['Borrower'] = doc.data()['Loan Details']['First Name'] + ' ' + doc.data()['Loan Details']['Last Name']
              clients.push(client)

          });
          unsubscribeRef.current = unsubscribe
          setClientList(clients)
          setFilteredClientList(clients)
          setLoading(false)

        });
  }

  useEffect( async () => {

    await getClients()

    return () => {
      setClientList(null)
      setFilteredClientList(null)
      unsubscribeRef.current && unsubscribeRef.current()
    }
}, [startDate, endDate])

  useEffect( () => {
    if(!loading){
      const filteredClients = clientList.filter((client) => {
        return client['Loan Officer'].Name.toLocaleLowerCase().includes(loFilter) && 
                client['LC'].Name.toLocaleLowerCase().includes(lcFilter) && 
                client['Borrower'].toLocaleLowerCase().includes(borrowerFilter) 
      })
      setFilteredClientList(filteredClients)
    }
  }, [loFilter, lcFilter, borrowerFilter])

  return (
    <>
            <Stack
              direction={{ base :'column' , md: 'row'}}
              maxW={'80vw'}
              mt={'5'}

            >  
                <ReactDatePicker   
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className={datepickerClass}
                  wrapperClassName="w-full"
                />
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className={datepickerClass}
                  wrapperClassName="w-full"

                />
                <Input 
                  type='search'
                  placeholder='Filter By Loan Officer'
                  value={loFilter}
                  onChange={e => setLOFilter(e.target.value.toLocaleLowerCase())}
                  >
                </Input>
                <Input 
                  type='search'
                  placeholder='Filter By Lender Concierge'
                  value={lcFilter}
                  onChange={e => setLCFilter(e.target.value.toLocaleLowerCase())}
                  >

                </Input>
                <Input 
                  type='search'
                  placeholder='Filter By Borrower'
                  value={borrowerFilter}
                  onChange={e => setBorrowerFilter(e.target.value.toLocaleLowerCase())}
                  >

                </Input>
                <Button p={5} pl={7} pr={7} onClick={resetFilters} size='md' colorScheme='blue'>Reset</Button>
            </Stack>
            <Wrap mt={3}>
                {filteredClientList && filteredClientList.map((client, index) => {
                    return (
                        <>
                        <WrapItem>
                            <ClientCard 
                                client={client}
                                loanOfficer={client['Loan Officer']}
                                lenderConcierge={client['LC']}
                                borrower={client['Borrower']}
                                dateAdded={client['Date Added']}
                                dateClaimed={client['Date Claimed']}
                                loanDetails={client['Loan Details']}
                                docID={client.id}
                                status={client.Status}
                            />
                            </WrapItem>
                        </>
                    )
                }) }
                {filteredClientList && filteredClientList.length == 0 && !loading  && <Text>&nbsp;No clients found</Text>}
            </Wrap>
    </>
  );
}
