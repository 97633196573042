import { Flex, Input, Button, IconButton, Box, Heading, Divider, toast, useToast } from "@chakra-ui/react"
import CreatableOptions from "../../components/Settings/CreatableOptions";
import { db } from "../../utils/init-firebase";
import { useEffect, useState } from "react";
import { doc, serverTimestamp, setDoc, collection, updateDoc, deleteDoc, orderBy, onSnapshot, query } from "firebase/firestore";
import { FiTrash2 } from "react-icons/fi";
import ColorPicker from "../../components/ColorPIcker";

export default function ManageMarketingCategories ( { initialSettings, settingsRef } ){
    const [contentTypes, setContentTypes] = useState([])
    const [productTypes, setProudctTypes] = useState([])
    useEffect(() => {
        const q = query(collection(db, "Marketing Content Types"), orderBy('timestamp'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
              t.push(doc.data());
          });
          setContentTypes(t)
        })

        const q2 = query(collection(db, "Marketing Product Types"), orderBy('timestamp'));
        const unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
          const c = [];
          querySnapshot2.forEach((doc) => {
              c.push(doc.data());
          });
          setProudctTypes(c)
        })
          return () => {
            unsubscribe()
            unsubscribe2()
          }
    }, [])
    return(
        <>
            <Flex flexDir={{base: 'column', lg: 'row'}}>
            <Box w={{base: '85vw', md: '42vw'}} boxShadow={'lg'} p={5} mt={5}>
                {/* <CreatableOptions
                    docRef={settingsRef}
                    settingName='Marketing Types'
                    addButtonText="Add Marketing Type"
                    showSettingNameAsHeading
                    initialSettings={initialSettings['Marketing Types']}

                /> */}
                <Heading size='xs'>Product Types</Heading>

                    {productTypes && productTypes.map((productType, i) => {
                        return(
                            <AddEditType collectionRef={'Marketing Product Types'} key={productType.id} typeId={productType.id} typeName={productType.name} typeColor={productType.color} action='Update'/>
                        )
                    })}
                    <Divider mb={1} mt={1} />
                    <AddEditType collectionRef={'Marketing Product Types'} key='add' contentTypeID={null} action='Add' />
                </Box>
                <Box w={{base: '85vw', md: '42vw'}} mr='1' boxShadow={'lg'} p={5} mt={5}>
                    {/* <CreatableOptions
                        docRef={settingsRef}
                        settingName='Product Types'
                        addButtonText="Add Product Type"
                        showSettingNameAsHeading
                        initialSettings={initialSettings['Product Types']}
                    /> */}
                    
                <Heading size='xs'>Content Types</Heading>

                    {contentTypes && contentTypes.map((contentType, i) => {
                        return(
                            <AddEditType collectionRef={'Marketing Content Types'} key={contentType.id} typeId={contentType.id} typeName={contentType.name} typeColor={contentType.color} action='Update'/>
                        )
                    })}
                    <Divider mb={1} mt={1} />
                    <AddEditType collectionRef={'Marketing Content Types'} key='add' contentTypeID={null} action='Add' />
                </Box>
              
            </Flex>
        </>
    )
}

function AddEditType({ typeId, typeName, typeColor, action, collectionRef }) {
    const [newTypeName, setNewTypeName] = useState(typeName ? typeName : '')
    const [newTypeColor, setNewTypeColor] = useState(typeColor ? typeColor : 'green')
    const thisTypeRef = typeId ? doc(db, collectionRef, typeId) : null
    const toast = useToast()
    const addType = async () => {
        const newTypeRef = doc(collection(db, collectionRef))
        await setDoc(newTypeRef, {
            timestamp: serverTimestamp(),
            name: newTypeName,
            color: newTypeColor,
            id: newTypeRef.id
        })
        toast({
            title: 'Added!',
            duration: 3000
        })
    }

    const updateType = async () => {
        if (!thisTypeRef) return; // Guard clause to avoid null ref errors
        await updateDoc(thisTypeRef, {
            name: newTypeName,
            color: newTypeColor,
        })
        toast({
            title: 'Updated!',
            duration: 3000
        })
    }

    const deleteType = async () => {
        if (!thisTypeRef) return; // Guard clause to avoid null ref errors
        await deleteDoc(thisTypeRef)
        toast({
            title: 'Deleted!',
            duration: 3000
        })
    }

    return (
        <>
        <Flex mb={2} flexDir='row'>
            <Input value={newTypeName} onChange={(e) => setNewTypeName(e.target.value)} width='200px' mr={2} />
            <ColorPicker color={newTypeColor} setColor={setNewTypeColor} mr={2} />
            <Button ml='2' onClick={action === 'Add' ? addType : updateType} size='md' textAlign='center' width='120px'>
                {action}
            </Button>
            {action === 'Update' && 
                <IconButton onClick={deleteType} ml={3} colorScheme='red' variant='outline' icon={<FiTrash2 />} />}
        </Flex>
        </>
    )
}

