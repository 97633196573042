import { Tag, TagLeftIcon, TagRightIcon, TagLabel, Wrap, WrapItem, Text, Link, Icon, Box, useColorModeValue } from "@chakra-ui/react";
import { FiBookmark, FiCheckCircle, FiDollarSign, FiMap, FiMapPin, FiUser, FiXCircle } from "react-icons/fi";
import moment from "moment";
import { FaCalendarCheck, FaMoneyCheckAlt } from "react-icons/fa";
import { BiBuildings } from "react-icons/bi";

export default function EventRequestBadges( { request, showAllInfo } ){
    const textColor = useColorModeValue('gray.600', 'gray.400')

    return(
        <>
            <Wrap justify={'flex-start'} flexWrap flexDir='row' mb={2}>

            {request['Request Details']['Name of Event'] && request['Request Details']['Name of Event'] != '' &&
                <WrapItem>
                    <Tag size='md' variant='outline' colorScheme={'gray'} textTransform='uppercase' fontWeight='bold'>
                        <TagLeftIcon as={FiBookmark}/>
                        <TagLabel fontSize="14px">{request['Request Details']['Name of Event']}</TagLabel>
                    </Tag> 
                </WrapItem>
            }
            {request['Request Details']['Venue'] && request['Request Details']['Venue'] != '' &&
                <WrapItem>
                    <Tag size='md' variant='outline' colorScheme={'green'} textTransform='uppercase' fontWeight='bold'>
                        <TagLeftIcon as={FiMapPin}/>
                        <TagLabel fontSize="14px">{request['Request Details']['Venue']}</TagLabel>
                    </Tag> 
                </WrapItem>

            }
            {request['Request Details']['Event Date'] && request['Request Details']['Event Date'] != '' &&
                <WrapItem>
                    <Tag size='md' 
                        colorScheme={'blue'} 
                        variant='outline' 
                        textTransform='uppercase' 
                        fontWeight='bold'>
                        <TagLeftIcon  as={FaCalendarCheck} />
                        <TagLabel fontSize="14px">{moment(request['Request Details']['Event Date'].seconds*1000).format('MMMM D hh:mma')}</TagLabel>
                    </Tag> 
                </WrapItem>

                }
          </Wrap>  
        {showAllInfo &&
            <>
                <Wrap justify={'flex-start'} flexWrap flexDir='row' mb={2}>
                    {request['Request Details']['Partnering Company'] && request['Request Details']['Partnering Company'] != '' &&
                        <WrapItem>
                            <Tag size='md' variant='outline' colorScheme={'facebook'} textTransform='uppercase' fontWeight='bold'>
                                <TagLeftIcon as={BiBuildings}/>
                                <TagLabel fontSize="14px">{request['Request Details']['Partnering Company']}</TagLabel>
                            </Tag> 
                        </WrapItem>
                    }
                    {request['Request Details']['Point of Contact'] && request['Request Details']['Point of Contact'] != '' &&
                        <WrapItem>
                            <Tag size='md' variant='outline' colorScheme={'messenger'} textTransform='uppercase' fontWeight='bold'>
                                <TagLeftIcon as={FiUser}/>
                                <TagLabel fontSize="14px">{request['Request Details']['Point of Contact']}</TagLabel>
                            </Tag> 
                        </WrapItem>
        
                    }
                </Wrap>  

                {request['Request Details']['Address'] && request['Request Details']['Address'] != '' &&
                    <Box mb={3} mt={3} color={textColor} fontSize={'sm'}>
                    <Link  href={`https://maps.google.com/?q=${request['Request Details']['Address']}`} isExternal>
                        <Icon as={FiMap}/>&nbsp;
                        {request['Request Details']['Address']}
                    </Link>
                    </Box>
                    }
                <Wrap justify={'flex-start'} flexWrap flexDir='row' mb={2}>

                {request['Request Details']['Total Cost'] && request['Request Details']['Total Cost'] != '' &&
                    <WrapItem>
                        <Tag size='md' variant='outline' colorScheme={'green'} textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={FiDollarSign}/>
                            <TagLabel fontSize="14px">{request['Request Details']['Total Cost']}</TagLabel>
                        </Tag> 
                    </WrapItem>
                }
                {request['Request Details']['Percentage Requested From Future'] && request['Request Details']['Percentage Requested From Future'] != '' &&
                    <WrapItem>
                        <Tag size='md' variant='outline' textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={FaMoneyCheckAlt}/>
                            <TagLabel fontSize="14px">{request['Request Details']['Percentage Requested From Future']}%</TagLabel>
                        </Tag> 
                    </WrapItem>
    
                }

            </Wrap>  
            <Wrap justify={'flex-start'} flexWrap flexDir='row' mb={5}>

                {request['Request Details']['Social Media Needs'] && request['Request Details']['Social Media Needs'].length > 0 &&
                <>
                    {request['Request Details']['Social Media Needs'].map((need) => {
                        return(
                            <WrapItem>
                                <Tag size='md'  colorScheme='green'>
                                    <TagLabel>{need}</TagLabel>
                                    <TagRightIcon as={FiCheckCircle} />
                                </Tag> 
                            </WrapItem>
                            )
                        })
                
                        }
                </>
                }
                {request['Request Details']['Team Member Needed'] && request['Request Details']['Team Member Needed'] != '' &&
                <>
                    <WrapItem>
                        <Tag size='md'  colorScheme={request['Request Details']['Team Member Needed'] == 'Yes' ? 'green' : 'red'}>
                            <TagLabel>Team Member Needed</TagLabel>
                            <TagRightIcon as={request['Request Details']['Team Member Needed'] == 'Yes' ? FiCheckCircle : FiXCircle} />
                        </Tag> 
                    </WrapItem>
             
                </>
                }
                {request['Request Details']['Participant Information Collected'] && request['Request Details']['Participant Information Collected'] != '' &&
                <>
                    <WrapItem>
                        <Tag size='md'  colorScheme={request['Request Details']['Participant Information Collected'] == 'Yes' ? 'green' : 'red'}>
                            <TagLabel>Participant Info Collected</TagLabel>
                            <TagRightIcon as={request['Request Details']['Participant Information Collected'] == 'Yes' ? FiCheckCircle : FiXCircle} />
                        </Tag> 
                    </WrapItem>
             
                </>
                }
            </Wrap>  
            {request['Request Details']['Expected Attendance'] && request['Request Details']['Expected Attendance'] != '' &&
                <Text color={textColor}>Expected Attendance: {request['Request Details']['Expected Attendance']}</Text>
            }
            {request['Request Details']['Expected Reach'] && request['Request Details']['Expected Reach'] != '' &&
                <Text mb={2} color={textColor}>Expected Reach: {request['Request Details']['Expected Reach']}</Text>
            }
            {request['Request Details']['Goal of Event'] &&
                <Text as={'em'} colorScheme='gray'>{request['Request Details']['Goal of Event']}</Text>
            }       
            <br/><br/>
            {request['Request Details']['Notes'] &&
                <Text as={'em'} colorScheme='gray'>{request['Request Details']['Notes']}</Text>
            }       
            
            </>
        

          }
        </>
    )
}