import {
  AspectRatio,
  Box,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Flex,
  GridItem,
  Tag
} from '@chakra-ui/react'
import { PriceTag } from './PriceTag'
import QuantitySelect from './QuantitySelect'
import { useState, useEffect } from 'react'

export const ProductCard = (props) => {
  const { tags, product, pendingCarts, inventory, rootProps, handleAddToCart, index, cart, cartUpdated, pendingCartsUpdated } = props
  const { Name, photoURL, Cost, id, Quantity } = product

  
  const [amountInCart, setAmountInCart] = useState(0)
  const [amountInPendingCarts, setAmountInPendingCarts] = useState()
  const [disableAddToCart, setDisableAddToCart] = useState(false)

  const [itemTags, setItemTags] = useState([])

  useEffect(() => {
    if(cart && cart.length > 0){
      const cartIndex = cart.findIndex((item => item.id == id))
      //console.log(`Product ${Name} found at index ${cartIndex} in the cart`)
      if(cartIndex > -1){
        setAmountInCart(cart[cartIndex].selected)
        }
      }
      else setAmountInCart(0)
    
  })

  useEffect(() => {
    if(product && product.tags && product.tags.length > 0){
      const t = []
      for(const tag of product.tags){
        let tagIndex = tags.findIndex(x => x.id === tag);
        if(tagIndex > -1){
          t.push(tags[tagIndex])
        }
      }
      setItemTags(t)
    }

  }, [])

  // remove amount in users cart from selection options
  // React.useEffect(() => {
  //   if(cart && cart.length > 0){
  //     const cartIndex = cart.findIndex((item => item.id == id))
  //     if(cartIndex > -1){
  //       setAmountInCart(parseInt(cart[cartIndex].selected))
  //       console.log(cart[cartIndex].selected)
  //     }
  //     else setAmountInCart(0)
  //   }
  // }, [cartUpdated])

  // subtract amount in pending requests from selection options
  useEffect(() => {
    if(pendingCarts && pendingCarts.length > 0){
      let amount = 0
      pendingCarts.forEach((cart) => {
        const cartIndex = cart.findIndex((item => item.id == id))
        if(cartIndex > -1){
          amount += parseInt(cart[cartIndex].selected)
        }
      })
      setAmountInPendingCarts(amount)
    }
    else setAmountInPendingCarts(0)
  }, [pendingCartsUpdated])

  return (
    <GridItem
      w='100%'
      display={'flex'}
      flexDir='column'
      justifyContent={'space-between'}
      >
      <Box  
          position="relative"
          >
        <AspectRatio overflow={'hidden'} ratio={4 / 3} height='150px'>
          <Image
            src={photoURL ? photoURL : require('../../assets/img/astronaut-peace-rocket.png')}
            alt={Name}
            draggable="false"
            fallbackSrc={require('../../assets/img/astronaut-peace-rocket.png')}
            borderRadius={useBreakpointValue({
              base: 'md',
              md: 'xl',
            })}
            transition={'all 0.3s ease 0s'}
            _hover={{transform: 'scale(1.50)'}}
          />
        </AspectRatio>
        
        {itemTags && itemTags.length > 0 &&
          <Stack position='absolute' top={0} left={0} zIndex={'2'}>
            <>
            {itemTags.map((tag) => {
              return(
                <Tag size='sm' textTransform='uppercase' colorScheme={tag.color} opacity='0.85' variant={'solid'}>{tag.name}</Tag>
              )   
            })}
            </>
          </Stack>
       }
  <Text fontWeight="medium" as='em' color={useColorModeValue('gray.700', 'gray.400')}>
           {Name}   {/* ({Quantity}) - {amountInPendingCarts} (pending) - {amountInCart} (in cart)  */}
          </Text>
      </Box>
        
      <Flex flexDir={'column'}>
        {Cost &&
          <PriceTag price={Cost} currency="USD" />
          }
          {(Quantity || Quantity == 0) && (amountInCart || amountInCart == 0) && (amountInPendingCarts || amountInPendingCarts == 0) &&
          <QuantitySelect 
            Quantity={parseInt(Quantity) - parseInt(amountInPendingCarts)}
            amountInCart={amountInCart}
            id={id}
            cart={cart}
            index={index}
            handleAddToCart={handleAddToCart}
            product={product}
          />
        }
      </Flex>
    </GridItem>
  )
}
