import { Table, Thead, Tbody, Tr, Th, Td, Flex, useColorModeValue, Image, Button, Tag, FormControl, Switch, useToast } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import React, { useEffect, useState } from 'react'
import AddSwagItem from '../../components/MarketingSwagV2/AddEditSwagItem'
import { collection, query, orderBy, onSnapshot, getDocs, updateDoc, doc } from 'firebase/firestore'
import { db } from '../../utils/init-firebase'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import ManageCategories from '../../components/MarketingSwagV2/ManageCategories'
import ManageTags from '../../components/MarketingSwagV2/ManageTags'

export default function MarketingInventory() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const backgroundHeaderColor = useColorModeValue('gray.200', 'gray.700')
    const history = useHistory()
    const [showAddSwagItem, setShowAddSwagItem] = useState(false)
    const [categories, setCategories] = useState()
    const [tags, setTags] = useState()
    const toast = useToast()

    const handleHideItem = async (e, id, name) => {
      try{
        await updateDoc(doc(db, 'Marketing Swag', id), {
          Hidden: e.target.checked
        })
        toast({
          title: `${name} will be ${e.target.checked ? 'hidden' : 'shown' }`,
          status: e.target.checked ? 'warning' : 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      }
      catch(err){
        toast({
          title: `ERROR`,
          description: err,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
   
    }

    const columns = React.useMemo(
      () => [
        {
          Header: 'Hidden',
          accessor: 'Hidden',
          width: 10,
          Cell: ({ cell: { value }, row }) => ( 
            <FormControl display='flex' alignItems='center'>
              <Switch onChange={(e) => handleHideItem(e, row.original.id, row.original.Name)} isChecked={value} id={`hide-${row.id}`} />
            </FormControl>
          )
        },
        {
          Header: 'Photo',
          accessor: 'photoURL',
          width: 25,
          Cell: ({ cell: { value } }) => ( 
            <Image height={45} src={value}></Image> 
          )
        },
        {
          Header: 'Name',
          accessor: 'Name',
          width: 450,
          Cell: ({ cell: { value }, row }) => ( 
            <>
            <Button colorScheme={'gray'} mr={2} onClick={() => {history.push(`/marketing-inventory/${value}`)}}>View</Button>
            <Link to={{pathname: `/marketing-inventory/${value}`}}>{value}</Link> 
            </>
          )
        },
        {
          Header: 'Quantity',
          accessor: 'Quantity',
          isNumeric: true,
        },
        {
          Header: 'Cost',
          accessor: 'Cost',
          isNumeric: true,
        },
      ],
      [tags]
    )
    
    useEffect(async () => {

      const q = query(collection(db, "Marketing Swag"), orderBy('Name', 'asc'))

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data())
        });
        setData(items)
      });
      const q2 = query(collection(db, "Marketing Swag Category"), orderBy('timestamp'));
      const unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
        const c = [];
        querySnapshot2.forEach((doc) => {
            c.push(doc.data());
        });
        setCategories(c)
      })

      const q3 = query(collection(db, "Marketing Swag Tags"), orderBy('timestamp'));
      const unsubscribe3 = onSnapshot(q3, (querySnapshot3) => {
        const t = [];
        querySnapshot3.forEach((doc) => {
            t.push(doc.data());
        });
        setTags(t)
      })


      setLoading(false)

      return () => {
        unsubscribe()
        unsubscribe2()
        unsubscribe3()
      }
    }, [])

    const findItemTags = async (itemTags) => {

      if(!tags){
        const q3 = query(collection(db, "Marketing Swag Tags"), orderBy('timestamp'));
        const querySnapshot3 = await getDocs(q3);
        const t = []
        querySnapshot3.forEach((doc) => {
          t.push(doc.data())
        });
        setTags(t)
      }
      if(itemTags && tags){
        let tagIndex = tags.findIndex(x => x.id === itemTags);
        if(tagIndex > -1){
          return <Tag>{tags[tagIndex].name}</Tag>
  
        }
      }
      else return null

    }


     
  
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data }, useSortBy)
  
    return (
    <>
      {!loading && tags &&
      <>
      <Flex flexDir={{base: 'column', lg: 'row'}} justify='space-between'>
        <Flex></Flex>
  
      <Flex flexDir={'row'}>
        <ManageCategories cats={categories} showButtonText />
        <ManageTags tags={tags} showButtonText />
      {showAddSwagItem ? 
        <AddSwagItem setShowAddSwagItem={setShowAddSwagItem} action='Add'/>
      :
        <Button size='sm' colorScheme={'teal'} onClick={() => setShowAddSwagItem(true)}>
            Add Item
        </Button>
      }
      </Flex>
    </Flex>
  
        <Table {...getTableProps()} minW='750px' mt='1%' overflow={'scroll'}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <Th
                  backgroundColor={backgroundHeaderColor}
                  userSelect="none"
                  {...column.getHeaderProps(column.getSortByToggleProps(), {
                    style: { width: column.width, maxWidth: column.maxWidth,
                    },
                  })}
                >
                  <Flex alignItems="center">
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon ml={1} w={4} h={4} />
                      ) : (
                        <ChevronUpIcon ml={1} w={4} h={4} />
                      )
                    ) : (
                      ""
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                        },
                      })}
                    >
                      {cell.render("Cell")}       
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      </>
      }
      </>
    )
  }