import { useState, useRef } from 'react';
import { updateDoc } from "firebase/firestore"
import { Box, WrapItem, ScaleFade, useToast, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Stack, FormControl, Spacer, ModalHeader, ModalCloseButton, RadioGroup, Radio, FormLabel, IconButton, useColorModeValue, Wrap } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import { InputControl, SubmitButton, TextareaControl } from 'formik-chakra-ui';
import { FaCalendarPlus } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { datepickerLight, datepickerDark } from '../contexts/GlobalVariables';
import * as Yup from 'yup'
import { useForm } from 'react-hook-form';
import { Formik } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { FiPlus } from 'react-icons/fi';

export default function AddToGoogleCalendar( { initialValues, docRef, attends  } ){

    var gapi = window.gapi
    var CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID
    var API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"


    const [startDate, setStartDate] = useState(new Date(initialValues['Event Date'].seconds * 1000))
    const [endDate, setEndDate] = useState(new Date(initialValues['Event End Time'].seconds * 1000))

    const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { users } = useAuth()
    const [loading, setLoading] = useState(true)

    const [progressMsg, setProgressMsg] = useState(null)
    const [attendees, setAttendees] = useState(attends)
    const formRef = useRef()

    const toast = useToast()
    const {
		handleSubmit,
        reset
	} = useForm()

    const validationSchema = Yup.object({
        'Summary': Yup.string().required('Required'),
        'Location': Yup.string().required('Required'),
        'Description': Yup.string().required('Required'),
        'Location': Yup.string().required('Required'),
        'Event Date': Yup.string().required('Required'),
        'Event End Time': Yup.string().required('Required'),

    })

    let initialVals = initialValues

    if(!initialVals){
        initialVals = {
            // Custom, Event, Swag
            'Summary': '', 
            'Location': '',
            'Description': '',
            'Event Date': '',
            'Event End Time': '',
        }
    }
    const [selectedCal, setSelectedCal] = useState('c_64a0g82cupi502g4666kmpmods@group.calendar.google.com')



/*     useEffect(() => {

        let attends = []
        initialValues.attendees?.forEach((attendee) => {
            attends.push({
                email: attendee.Email,
                label: attendee.Name,
                photoURL: attendee.photoURL,
                value: attendee.uid
            })
        })
        setAttendees(attends)
        setLoading(false)
    }, [initialValues]) */

    const handleSetAttendees = (e) =>{
        e.forEach((entry) => {
          delete entry['__isNew__']
          if(entry.email == null)
            entry.email = entry.value
        })
        setAttendees(e)
      }

    const addToCal = async (values) => {

        gapi.load('client:auth2', () => {

        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        })
        gapi.client.load('calendar', 'v3')
        setProgressMsg('Adding to Calendar')
        gapi.auth2.getAuthInstance().signIn()
        .then(() => {

            try{
            var attends = []
            attendees.map((attendee, i) => 
                attends.push({'email': attendee.email})
            )
            var details = {
                'summary': values.Summary,
                'location': values.Location,
                'description': values.Description,
                'attendees': attends,
                'start': {
                    'dateTime': startDate,
                    'timeZone': 'America/New_York'
                },
                'end': {
                    'dateTime': endDate,
                    'timeZone': 'America/New_York'
                },
                'reminders': {
                    'useDefault': false,
                    'overrides': [
                      {'method': 'email', 'minutes': 24 * 60},
                      {'method': 'popup', 'minutes': 60},
                      {'method': 'popup', 'minutes': 10}
                    ]
                  }
                }
                var request = gapi.client.calendar.events.insert({
                    'calendarId': selectedCal,
                    'resource': details,
                    'sendUpdates': 'all',
                    'sendNotifications': true,
                })
                request.execute(async event => {
                    if(event.code && event.code != 200){
                        setProgressMsg(null)
                        toast({
                            title: event.error?.message,
                            status: 'error',
                            duration: 9000,
                            position: 'top',
                            isClosable: true,
                        })
                    }
                    else if(docRef){
                            setProgressMsg('Updating the Hub...')
                            try{
                                await updateDoc(
                                    docRef, 
                                    { 
                                        'Google Calendar Link': event.htmlLink,
                    
                                    });
                                    setProgressMsg("Done!")
                                    setProgressMsg(null)
                                    toast({
                                        title: 'Successfully Added!',
                                        description: "A link to the event has been added to the request.",
                                        status: 'success',
                                        duration: 9000,
                                        position: 'top',
                                        isClosable: true,
                                    })
                                    onClose()
                                }
                            catch(error){

                                toast({
                                    title: error,
                                    status: 'error',
                                    duration: 9000,
                                    position: 'top',
                                    isClosable: true,
                                })
                                setProgressMsg(null)
                            }
                        }
                    }
                   
             
                )
            }
            catch(error){
                toast({
                    title: error,
                    status: 'error',
                    duration: 9000,
                    position: 'top',
                    isClosable: true,
                })
                setProgressMsg(null)

            }
            })
        })
      
     

     
    }

    

    return(
        <>
            <IconButton size='lg' ml={3} mr={3} variant={'ghost'} icon={<FaCalendarPlus/>} onClick={onOpen}></IconButton>
            {attendees &&
            <ScaleFade in>

                        <Modal
                            isCentered
                            onClose={onClose}
                            isOpen={isOpen}
                            motionPreset='slideInBottom'
                            size='xl'
                        >
                        <ModalOverlay />
                        <Formik
                        initialValues={initialVals}
                        innerRef={formRef}
                        onSubmit={addToCal}
                        validationSchema={validationSchema}
                        
                        >
                        {({ handleSubmit, values, errors }) => (
                            <>
                            <Box                          
                            as="form"
                            onSubmit={handleSubmit}>
                                <ModalContent>
                                <ModalHeader>Add To Google Calendar</ModalHeader>
                                <ModalCloseButton />
                        
                                <ModalBody>
                                    
                                <FormControl>
                                    <Stack mb={5} direction='row'>
                                    <RadioGroup onChange={setSelectedCal} value={selectedCal}>
                                        <Stack direction='row'>
                                            <Radio value='c_64a0g82cupi502g4666kmpmods@group.calendar.google.com'>Events</Radio>
                                            <Radio value='c_9qjqbbjhr3rjm48meohc086k48@group.calendar.google.com'>Carolinas</Radio>
                                            <Radio value='c_1882tjj3beqrkhgugmkp2t0gshc50@resource.calendar.google.com'>Video</Radio>    
                                        </Stack>
                                    </RadioGroup>
                                    </Stack>
                                </FormControl>

                                <FormControl mb={5}>
                                    <FormLabel>Attendees</FormLabel> 
                                    <CreatableSelect
                                        isMulti
                                        name="Attendees"
                                        options={users}
                                        placeholder="Select or Enter Recipient"
                                        closeMenuOnSelect={false}
                                        defaultValue={attends}
                                        value={attendees}
                                        onChange={(e) => handleSetAttendees(e)}
                                    />
                                </FormControl>
                                <Stack spacing={5} mb={5}>
                                    <InputControl 

                                        name="Summary"
                                        label="Summary"
                                    >
                                    </InputControl>
                                    <InputControl 
                                        name="Location"
                                        label="Location"
                                    >
                                    </InputControl>
                                    <TextareaControl
                                        name='Description'
                                        label='Description'
                                    >

                                    </TextareaControl>
                                </Stack>
                                <Wrap >
                                    <WrapItem >
                                        <ReactDatePicker 
                                            id="event-datepicker" 
                                            className={datepickerClass}
                                            selected={startDate}
                                            placeholderText='Select Date'
                                            onChange={(date) => setStartDate(date)}
                                            showTimeSelect
                                            dateFormat={"Ppp"}
                                            autoComplete="off"
                                            required>
                                        </ReactDatePicker>                                    
                                    </WrapItem>
                                    <WrapItem>
                                        <ReactDatePicker 
                                            id="event-datepicker" 
                                            className={datepickerClass}
                                            selected={endDate}
                                            placeholderText='Select Date'
                                            onChange={(date) => setEndDate(date)}
                                            showTimeSelect
                                            dateFormat={"Ppp"}
                                            autoComplete="off"
                                            required>
                                        </ReactDatePicker>                                       
                                    </WrapItem>
                                </Wrap>


                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                    <Spacer/>
                                    <SubmitButton isLoading={progressMsg} rightIcon={<FiPlus/>} onClick={() => addToCal(values)}>Add To Calendar</SubmitButton>
                                    
                                </ModalFooter>
                            </ModalContent>
                        </Box>
                        </>
                        )}
                </Formik>
                    </Modal>

            </ScaleFade>
            }
        </>
    )

}