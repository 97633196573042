import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Input,
    Text,
    Button,
    useDisclosure,
    Stack,
    Link,
    SimpleGrid,
    Center,
    Box,
    Heading,
    Image,
    Flex,
    useColorModeValue,
    Avatar,
    Checkbox,
    FormControl,
    Textarea
  } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import ReactToPdf from "react-to-pdf";
import { useAuth } from '../contexts/AuthContext'

const initialMPBreakdown = {
    'Principal & Interest': 0,
    'Property Taxes': 0,
    'Hazard Insurance': 0,
    'Mortgage Insurance': 0,
    'Other': 0
}

  export default function BuyDownCalc() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const calcBodyRef = React.createRef()
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const { currentUser, NMLS } = useAuth()

    const { users } = useAuth()
    const [thisUser, setThisUser] = useState()
    const [filename, setFilename] = useState('FHL_Buydown')

    const tealBG = useColorModeValue('teal.200', 'teal.400')
    const blueBG = useColorModeValue('blue.200', 'blue.400')

    const [purchasePrice, setPurchasePrice] = useState('')
    const [loanAmount, setLoanAmount] = useState('')
    const [interestRate, setInterestRate] = useState('')
    const [loanPeriod, setLoanPeriod] = useState('')
    const [paymentsPerYear, setPaymentsPerYear] = useState('12')
    const [fees, setFees] = useState(1000)

    const [apr, setAPR] = useState()

    const [buydownPaymentYear0, setBuydownPaymentYear0] = useState()
    const [buydownPaymentYear1, setBuydownPaymentYear1] = useState()
    const [buydownPaymentYear2, setBuydownPaymentYear2] = useState()

    const [annualSavingsYear0, setAnnualSavingsYear0] = useState()
    const [annualSavingsYear1, setAnnualSavingsYear1] = useState()
    const [annualSavingsYear2, setAnnualSavingsYear2] = useState()

    const [notes, setNotes] = useState('')

    const [buydownsToShow, setBuydownsToShow] = useState({
        'MP': true, 
        '1-0' : true,
        '1-1': true,
        '2-1': true,
        '3-2-1': false
    })

    const [mpBreakdown, setMPBreakdown] = useState(initialMPBreakdown)

    const [totalPayments, setTotalPayments] = useState()
    const [monthlyPayment, setMonthlyPayment] = useState()

    const [processingPDF, setProcessingPDF] = useState(false)

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    let interestRateFormatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 3

    })

    let aprFormatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 4

    })

    useEffect(() => {
        if(users && currentUser){
            let index = users.findIndex(x => x.value == currentUser.uid)
            if(index > -1){
                setThisUser(users[index])
            }
        }
    }, [])

    const reset = () => {
        setPurchasePrice('')
        setLoanAmount('')
        setInterestRate('')
        setLoanPeriod('')
        setAPR('')
        setBuydownPaymentYear0()
        setBuydownPaymentYear1()
        setBuydownPaymentYear2()
        setAnnualSavingsYear0()
        setAnnualSavingsYear1()
        setAnnualSavingsYear2()
        setMonthlyPayment()
        setTotalPayments()
        setMPBreakdown(initialMPBreakdown)
    }
    function calcAPR(loanamount, numpayments, baseannualrate, costs){
        /* 
        loanamount 	= the amount borrowed
        numpayments	= number of monthly payments e.g. 30 years = 360
        baserate	= the base percentage rate of the loan. A 5.25% Annual Rate should be passed in as 0.0525 NOT 5.25
        costs		= the loan closing costs e.g. origination fee, broker fees, etc.
        */
       try{
        //console.log(loanamount, numpayments, baseannualrate)
        let rate = 	baseannualrate / 12;
        let totalmonthlypayment = ((loanamount+costs) * rate * Math.pow(1+rate,numpayments)) / (Math.pow(1+rate, numpayments)-1);
        let testrate = rate;
        let iteration = 1;
        let testresult = 0;
        //iterate until result = 0
        let testdiff = testrate;
        while (iteration <= 100) {
            testresult = ((testrate * Math.pow(1 + testrate, numpayments)) / (Math.pow(1 + testrate, numpayments) - 1)) - (totalmonthlypayment / loanamount);
            if (Math.abs(testresult) < 0.0000001) break;
            if (testresult < 0) testrate += testdiff;
            else testrate -= testdiff;
            testdiff = testdiff / 2;
            iteration++;
        }
        testrate = testrate * 12;
        return testrate ? testrate.toFixed(6) : '';
       }
        catch(err){
            console.log(err)
            return ''
        }
    }

    const calcMonthlyPayment = (interestRate, loanLength, loanAmount) => {
        let term = loanLength * 12
        let apr = interestRate / 1200
        let amount = apr * (-1) * loanAmount * Math.pow((1 + apr), term) / (1 - Math.pow((1 + apr), term))
        return amount
    }
    useEffect(() => {
        if(!isNaN(monthlyPayment) && monthlyPayment != '' && !isNaN(buydownPaymentYear1) && buydownPaymentYear1 != '' && !isNaN(paymentsPerYear) && paymentsPerYear != ''){
            setAnnualSavingsYear0((monthlyPayment - buydownPaymentYear0) * paymentsPerYear)
        }
    }, [monthlyPayment, buydownPaymentYear1, paymentsPerYear])

    useEffect(() => {
        if(!isNaN(monthlyPayment) && monthlyPayment != '' && !isNaN(buydownPaymentYear1) && buydownPaymentYear1 != '' && !isNaN(paymentsPerYear) && paymentsPerYear != ''){
            setAnnualSavingsYear1((monthlyPayment - buydownPaymentYear1) * paymentsPerYear)
        }
    }, [monthlyPayment, buydownPaymentYear1, paymentsPerYear])

    useEffect(() => {
        if(!isNaN(monthlyPayment) && monthlyPayment != '' && !isNaN(buydownPaymentYear2) && buydownPaymentYear2 != '' && !isNaN(paymentsPerYear) && paymentsPerYear != ''){
            setAnnualSavingsYear2((monthlyPayment - buydownPaymentYear2) * paymentsPerYear)
        }
    }, [monthlyPayment, buydownPaymentYear2, paymentsPerYear])

    useEffect(() => {
        if(!isNaN(loanAmount) && loanAmount != '' && !isNaN(interestRate) && interestRate != '' && !isNaN(loanPeriod) && loanPeriod != ''){
            setBuydownPaymentYear0(calcMonthlyPayment(interestRate - 3, loanPeriod, loanAmount))
        }
    }, [loanAmount, interestRate, loanPeriod])

    useEffect(() => {
        if(!isNaN(loanAmount) && loanAmount != '' && !isNaN(interestRate) && interestRate != '' && !isNaN(loanPeriod) && loanPeriod != ''){
            setBuydownPaymentYear1(calcMonthlyPayment(interestRate - 2, loanPeriod, loanAmount))
        }
    }, [loanAmount, interestRate, loanPeriod])

    useEffect(() => {
        if(!isNaN(loanAmount) && loanAmount != '' && !isNaN(interestRate) && interestRate != '' && !isNaN(loanPeriod) && loanPeriod != ''){
            setBuydownPaymentYear2(calcMonthlyPayment(interestRate - 1, loanPeriod, loanAmount))
        }
    }, [loanAmount, interestRate, loanPeriod])

    useEffect(() => {
        if(!isNaN(loanAmount) && loanAmount != '' && !isNaN(interestRate) && interestRate != '' && !isNaN(loanPeriod) && loanPeriod != ''){
            setMonthlyPayment(calcMonthlyPayment(interestRate, loanPeriod, loanAmount))
        }
    }, [loanAmount, interestRate, loanPeriod, paymentsPerYear])

    useEffect(() => {
        if(!isNaN(loanAmount) && loanAmount != '' && 
            !isNaN(monthlyPayment) && monthlyPayment != '' && 
            !isNaN(loanPeriod) && loanPeriod != '' && 
            !isNaN(paymentsPerYear) && paymentsPerYear != '' && 
            !isNaN(fees) && fees != ''){
            setAPR(calcAPR(parseFloat(loanAmount), parseInt(loanPeriod) * parseInt(paymentsPerYear), parseFloat(interestRate), parseFloat(fees)))
        }
    }, [monthlyPayment, loanAmount, loanPeriod, paymentsPerYear, fees])


    useEffect(() => {
        if(!isNaN(paymentsPerYear) && paymentsPerYear != '' && !isNaN(loanPeriod) && loanPeriod != ''){
            setTotalPayments(loanPeriod * paymentsPerYear)
        }
    }, [loanPeriod, paymentsPerYear])

    const handleToPDF = async (toPdf) => {
        setProcessingPDF(true)
        toPdf()
    }

    const handlePDFComplete = () => {
        setProcessingPDF(false)
    }

    function sumObjectValues(obj) {
        let sum = 0;
      
        for (let key in obj) {
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'number' && Number.isFinite(obj[key])) {
            sum += obj[key];
          }
        }
      
        return formatter.format(sum);
      }

    return (
      <>
        <Link fontSize={'sm'} isExternal ref={btnRef} colorScheme='teal' onClick={onOpen}>
          Buydown Calculator <ExternalLinkIcon/>
        </Link>
        <Drawer
          isOpen={isOpen}
          size='xl'
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
        
          <DrawerContent>
            <DrawerHeader borderBottom={'1px dashed lightgrey'}>
              
                    <Flex mr={2} align={{base: 'center', md: 'flex-end'}} justify='space-around' flexDir={{base: 'column', md:'row'}}>
                        <Stack mr={3} mb={2} minW={{base: '90%', md:'300px'}} dir={'column'}>
                            <Text mr='2' fontSize='xs' color='grey'>PDF Generation Is Not Optimized For Mobile</Text>
                            <ReactToPdf onComplete={handlePDFComplete} targetRef={calcBodyRef} filename={`${filename}.pdf`}  scale={0.90} x={5} y={5}>
                                {({toPdf}) => (
                                    <Button 
                                        mr={2} 
                                        onClick={() => handleToPDF(toPdf)}
                                        isLoading={processingPDF}
                                    >
                                            Download PDF
                                    </Button>
                                )}
                            </ReactToPdf>
                        </Stack>

                  {/*       <Stack minW={'250px'} dir={'column'}>
                            <Text fontSize='sm'>Signature</Text>
                            <Select 
                                
                                closeMenuOnSelect
                                onChange={setThisUser}
                                options={users} 
                                value={thisUser}>

                            </Select>
                        </Stack> */}
                        <Stack mb={2} mr={3} minW={{base: '90%', md:'300px'}} dir={'column'}>
                            <Text fontSize='sm'>Filename</Text>
                            <Input value={filename} onChange={(e) => setFilename(e.target.value)}>
                            </Input>
                        </Stack>
                        <Text w='90%' mb={1} color='gray' fontSize='xs'>
                            Protip: You can edit your signature information in the 
                            <a target='_blank' href='/profile'>Account Settings Page</a><ExternalLinkIcon/>
                        </Text>
                    </Flex>
                    <Flex flexDir={'row'} justifyContent='flex-start'>
                        {buydownsToShow && Object.keys(buydownsToShow).map((buydown) => (
                            <FormControl w='75px' display='flex' alignContent='center' justifyContent={'center'} fontSize='lg' key={buydown}>
                                <Checkbox   
                                    mr='1'
                                    onChange={(e) => setBuydownsToShow({...buydownsToShow, [buydown]: e.target.checked})} 
                                    defaultChecked={buydownsToShow?.[buydown]}
                                    checked={buydownsToShow?.[buydown]}
                                    >
                                    {buydown}
                                </Checkbox>
                            </FormControl>
                        ))}        
                    </Flex>
            </DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody minWidth={processingPDF && '875px'}>
                <div ref={calcBodyRef}>
                <Flex mt={'-7'} justify={'center'} align='center' flexDir={'row'}>
                    <Image align='flex-start' h={'65px'} mr={3} src={require('../assets/img/fhl-logo.png')}></Image> 
                    <Heading color={'blue.600'} size='lg' textTransform={'uppercase'} textAlign={'center'}>
                        WHAT ARE <Text display={'inline-flex'} color={'teal.500'}>BUYDOWNS?</Text>
                    </Heading>
                </Flex>
                <Box mb={0.5} mt='-4' p={2} borderBottom='1px solid lightgrey'>
                    <Center>
                    <Text as={'em'} fontSize={'xs'} color='gray' textAlign={'center'}>The buydown is a financing method that allows you to temporarily lower your mortgage’s interest rate. Future Home
                        Loans offers a 2/1 buydown, and a 1/0 buydown. The 2/1 buydown allows you to lower your interest rate for the first two years of the loan, while the 1/0 lowers your interest rate for the first year. *Seller Concessions are used to offset this
                        reduced rate. After the buydown period has ended, you’ll be paying the full interest rate for the remainder of your
                        mortgage - this is where refinancing your mortgage with Future Home Loans comes into play</Text>
                        </Center>
                </Box>
                <SimpleGrid justify='center' columns={4} flexDir='column' mb={2} mt={2} >
                <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'}  >Loan Amount: </Text>
                {processingPDF && <Text h={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{formatter.format(loanAmount)}</Text>}
                {!processingPDF &&
                    <Input 
                        maxW={'200px'}
                        maxH='30px'
                        type={'number'}
                        isInvalid={loanAmount && isNaN(loanAmount)}
                        value={loanAmount}
                        onChange={(e) => setLoanAmount(e.target.value)} 
                        />
                }
                <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'} >Payments Per Year: </Text>
                {processingPDF && <Text h={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{paymentsPerYear}</Text>}
                {!processingPDF &&
                    <Input 
                        maxW={'200px'}
                        maxH='30px'
                        type={'number'}
                        isInvalid={paymentsPerYear && isNaN(paymentsPerYear)}
                        value={paymentsPerYear}
                        onChange={(e) => setPaymentsPerYear(e.target.value)} 
                        />      
                }

                <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'}  >Purchase Price: </Text>
                {processingPDF && <Text h={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{formatter.format(purchasePrice)}</Text>}
                {!processingPDF && 
                    <Input 
                        maxH='30px'
                        maxW={'200px'}
                        type={'number'}
                        isInvalid={purchasePrice && isNaN(purchasePrice)}
                        value={purchasePrice}
                        onChange={(e) => setPurchasePrice(e.target.value)} 
                        />
                }
          
            <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'} >Loan Period (years): </Text>
                {processingPDF && <Text h={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{loanPeriod}</Text>}
                {!processingPDF && 
                    <Input 
                        maxW={'200px'}
                        maxH='30px'
                        type={'number'}
                        isInvalid={loanPeriod && isNaN(loanPeriod)}
                        value={loanPeriod}
                        onChange={(e) => setLoanPeriod(e.target.value)} 
                        />    
                }
               <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'} >Interest Rate (Annual): </Text>
               {processingPDF && <Text h={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{interestRateFormatter.format(interestRate/100)}</Text>}
                {!processingPDF && 
                    <Input 
                        maxW={'200px'}
                        maxH='30px'
                        type={'number'}
                        isInvalid={interestRate && isNaN(interestRate)}
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)} 
                        />
                }
           
                <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'} >Total Payments:</Text>
                {totalPayments ?
                    <Text alignSelf='center'>{totalPayments}</Text>
                    :
                    <Text></Text>
                }   
            <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'}  >APR: </Text>
                {/* {processingPDF && <Text h={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{aprFormatter.format(apr/100)}</Text>} */}
                {apr ?
                    <Text alignSelf='center'>{aprFormatter.format(apr/100)}</Text>
                    :
                    <Text></Text>
                }
          
                <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'}  >Monthly Payment:</Text>
                {monthlyPayment ?
                    <Text alignSelf='center'>{formatter.format(monthlyPayment)}</Text>
                    :
                    <Text></Text>
                } 
                {!processingPDF && 
                    <>
                    <Text mr={2} fontSize='sm' alignSelf='center' justifySelf={'flex-end'}  >Fees:</Text>
                    <Input 
                        maxW={'200px'}
                        maxH='30px'
                        type={'number'}
                        isInvalid={fees && isNaN(fees)}
                        value={fees}
                        onChange={(e) => setFees(e.target.value)} 
                        />    
                        </>
                }
                </SimpleGrid>
                {buydownsToShow?.['MP'] &&
                <Box id='1-0' minW={'800px'} mb='0.5' overflowX='auto'>
                    <Box p={1} bg={'blue.500'}>
                        <Center>
                            <Flex align={'center'} flexDir={'row'}>
                                <Image h={'20px'} src={require('../assets/img/logo.png')}></Image>
                                <Heading textTransform={'uppercase'} ml={4} size='sm' color={'white'} textAlign={'center'}>Monthly Payments</Heading>
                            </Flex>
                        </Center>                    </Box>
                    <SimpleGrid alignContent={'center'} justifyContent='center' spacing='1px' columns={Object.keys(mpBreakdown).length + 1} spacingY='13px'>
                        {Object.keys(mpBreakdown).map((key, i) => (
                            <Box key={`${key}-header`} bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{key}</Box>
                        ))}
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Total Payment</Box>


                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={Object.keys(mpBreakdown).length + 1} spacingY='17px'>
                        {Object.keys(mpBreakdown).map((key, i) => (
                            <Box key={`${key}-value`} border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>
                            {processingPDF && 
                            <Text maxH={'30px'} fontSize='md' alignSelf='center' pt='0.5' >{mpBreakdown?.[key] ? formatter.format(mpBreakdown?.[key]) : null}</Text>}
                            {!processingPDF && 
                                <Input 
                                    maxH='30px'
                                    type={'number'}
                                    isInvalid={mpBreakdown?.key && isNaN(mpBreakdown?.key)}
                                    value={mpBreakdown?.key}
                                    onChange={(e) => setMPBreakdown({...mpBreakdown, [key]: parseFloat(e.target.value)})} 
                                    />    
                            }
                            </Box>
                        ))}
                     
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='md' p={1}>
                            {sumObjectValues(mpBreakdown)}
                        </Box>

                    </SimpleGrid>
   
                </Box>
                }
                {buydownsToShow?.['1-0'] &&
                <Box id='1-0' minW={'800px'} mb='0.5' overflowX='auto'>
                    <Box p={1} bg={'blue.500'}>
                        <Center>
                            <Flex align={'center'} flexDir={'row'}>
                                <Image h={'20px'} src={require('../assets/img/logo.png')}></Image>
                                <Heading ml={4} size='sm' color={'white'} textAlign={'center'}>1-0 BUY DOWN</Heading>
                            </Flex>
                        </Center>                    </Box>
                    <SimpleGrid alignContent={'center'} justifyContent='center' spacing='1px' columns={7} spacingY='17px'>
                        <Box bg={blueBG} fontSize='xs' p={1}></Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Interest Rate</Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Note Rate Payment </Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Buydown Payment </Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Monthly Savings</Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}># of Payments</Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Annual Savings</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 1</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 1)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear2 ? formatter.format(buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && buydownPaymentYear2 ? formatter.format(monthlyPayment - buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>LLPA Adjustment to Pricing</Box>
                        <Box borderBottom='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>1/0 Buydown Cost</Box>
                    
                        <Box borderBottom='1px solid white' bg='blue.500' color='white' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='semibold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>
                                {loanAmount ?  `${(((loanAmount * 0.00875) / loanAmount) * 100).toFixed(3)}%` : null}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {loanAmount ?  formatter.format(loanAmount * 0.00875) : null}
                        </Box>
                        <Box color={'white'} fontWeight='bold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Concession Required</Box>
                    
                        <Box bg='blue.500' color='white'  textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 && purchasePrice ?  `${(((annualSavingsYear2) / purchasePrice) * 100).toFixed(3)}%` : null}</Box>

                    </SimpleGrid>
                </Box>
                }
                {buydownsToShow?.['1-1'] &&
                <Box id='1-1' minW={'800px'} mb='0.5' overflowX='auto'>
                    <Box p={1} bg={'teal.500'}>
                        <Center>
                            <Flex align={'center'} flexDir={'row'}>
                                <Image h={'20px'} src={require('../assets/img/logo.png')}></Image>
                                <Heading ml={4} size='sm' color={'white'} textAlign={'center'}>1-1 BUY DOWN</Heading>
                            </Flex>
                        </Center>                    </Box>
                    <SimpleGrid alignContent={'center'} justifyContent='center' spacing='1px' columns={7} spacingY='17px'>
                        <Box bg={tealBG} fontSize='xs' p={1}></Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Interest Rate</Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Note Rate Payment </Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Buydown Payment </Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Monthly Savings</Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}># of Payments</Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Annual Savings</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 1</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 1)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear2 ? formatter.format(buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && buydownPaymentYear2 ? formatter.format(monthlyPayment - buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 2</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 1)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear2 ? formatter.format(buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && buydownPaymentYear2 ? formatter.format(monthlyPayment - buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid  alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>LLPA Adjustment to Pricing</Box>

                        <Box borderBottom='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>1/1 Buydown Cost</Box>
                    
                        <Box borderBottom='1px solid white' bg='teal.500' color='white' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2 * 2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='semibold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>
                                {loanAmount ?  `${(((loanAmount * 0.015) / loanAmount) * 100).toFixed(3)}%` : null}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {loanAmount ?  formatter.format(loanAmount * 0.015) : null}
                        </Box>

                        <Box color={'white'} fontWeight='bold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Concession Required</Box>
                    
                        <Box bg='teal.500' color='white'  textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 && purchasePrice ?  `${(((annualSavingsYear2) / purchasePrice) * 2 * 100).toFixed(3)}%` : null}</Box>

                    </SimpleGrid>
                </Box>
                }
                {buydownsToShow?.['2-1'] &&
                <Box id='2-1' minW={'800px'} mb='0.5' overflowX='auto'>
                    <Box p={1} bg={'blue.500'}>
                        <Center>
                            <Flex align={'center'} flexDir={'row'}>
                                <Image h={'20px'} src={require('../assets/img/logo.png')}></Image>
                                <Heading ml={4} size='sm' color={'white'} textAlign={'center'}>2-1 BUY DOWN</Heading>
                            </Flex>
                        </Center>
                    </Box>
                    <SimpleGrid alignContent={'center'} justifyContent='center' spacing='1px' columns={7} spacingY='17px'>
                        <Box bg={blueBG} fontSize='xs' p={1}></Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Interest Rate</Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Note Rate Payment </Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Buydown Payment </Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Monthly Savings</Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}># of Payments</Box>
                        <Box bg={blueBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Annual Savings</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 1</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 2)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && monthlyPayment != '' ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear1 && buydownPaymentYear1 != '' ? formatter.format(buydownPaymentYear1) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear1 && monthlyPayment ?  formatter.format(monthlyPayment - buydownPaymentYear1) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear1 ? formatter.format(annualSavingsYear1) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 2</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 1)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear2 ? formatter.format(buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && buydownPaymentYear2 ? formatter.format(monthlyPayment - buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>LLPA Adjustment to Pricing</Box>
            
                        <Box borderBottom='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>2/1 Buydown Cost</Box>
                    
                        <Box borderBottom='1px solid white' bg='blue.500' color='white' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear1 && annualSavingsYear2 ? formatter.format(annualSavingsYear1 + annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='semibold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>
                                {loanAmount ?  `${(((loanAmount * 0.02375) / loanAmount) * 100).toFixed(3)}%` : null}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {loanAmount ?  formatter.format(loanAmount * 0.02375) : null}
                        </Box>
                        <Box color={'white'} fontWeight='bold' textTransform='uppercase' bg={'blue.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Concession Required</Box>
                    
                        <Box bg='blue.500' color='white' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear1 && annualSavingsYear2 && purchasePrice ?  `${(((annualSavingsYear1 + annualSavingsYear2) / purchasePrice) * 100).toFixed(3)}%` : null}</Box>

                    </SimpleGrid>
                </Box>
                }
                {buydownsToShow?.['3-2-1'] &&
                <Box  id='3-2-1' mb={1} minW={'800px'} overflowX='auto'>
                    <Box p={1} bg={'teal.500'}>
                        <Center>
                            <Flex align={'center'} flexDir={'row'}>
                                <Image h={'20px'} src={require('../assets/img/logo.png')}></Image>
                                <Heading ml={4} size='sm' color={'white'} textAlign={'center'}>3-2-1 BUY DOWN</Heading>
                            </Flex>
                        </Center>                    
                    </Box>
                    <SimpleGrid alignContent={'center'} justifyContent='center' spacing='1px' columns={7} spacingY='17px'>
                        <Box bg={tealBG} fontSize='xs' p={1}></Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Interest Rate</Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Note Rate Payment </Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Buydown Payment </Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Monthly Savings</Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}># of Payments</Box>
                        <Box bg={tealBG} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Annual Savings</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 1</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 3)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear0 ? formatter.format(buydownPaymentYear0) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && buydownPaymentYear0 ? formatter.format(monthlyPayment - buydownPaymentYear0) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear0 ? formatter.format(annualSavingsYear0) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 2</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 2)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && monthlyPayment != '' ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear1 && buydownPaymentYear1 != '' ? formatter.format(buydownPaymentYear1) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear1 && monthlyPayment ?  formatter.format(monthlyPayment - buydownPaymentYear1) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear1 ? formatter.format(annualSavingsYear1) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Year 3</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{interestRate && interestRateFormatter.format((interestRate - 1)/100)}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment ? formatter.format(monthlyPayment) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{buydownPaymentYear2 ? formatter.format(buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{monthlyPayment && buydownPaymentYear2 ? formatter.format(monthlyPayment - buydownPaymentYear2) : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{paymentsPerYear ? paymentsPerYear : null}</Box>
                        <Box border='1px solid rgb(178,245,234)' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear2 ? formatter.format(annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>LLPA Adjustment to Pricing</Box>

                        <Box borderBottom='1px solid white' color={'white'} fontWeight='bold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>3/2/1 Buydown Cost</Box>
                    
                        <Box borderBottom='1px solid white' bg='teal.500' color='white' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear0 && annualSavingsYear1 && annualSavingsYear2 ? formatter.format(annualSavingsYear0 + annualSavingsYear1 + annualSavingsYear2) : null}</Box>

                    </SimpleGrid>
                    <SimpleGrid alignContent={'center'} justifyContent='center' columns={7} spacingY='17px'>
                        <Box borderRight='1px solid white' color={'white'} fontWeight='semibold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={1} gridColumnEnd={5} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>
                                {loanAmount ?  `${(((loanAmount * 0.045) / loanAmount) * 100).toFixed(3)}%` : null}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {loanAmount ?  formatter.format(loanAmount * 0.045) : null}
                        </Box>
                        <Box color={'white'} fontWeight='bold' textTransform='uppercase' bg={'teal.400'} gridColumnStart={5} gridColumnEnd={7} textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>Concession Required</Box>
                    
                        <Box bg='teal.500' color='white' textAlign={'center'} verticalAlign='middle' fontSize='xs' p={1}>{annualSavingsYear0 && annualSavingsYear1 && annualSavingsYear2 && purchasePrice ?  `${(((annualSavingsYear0 + annualSavingsYear1 + annualSavingsYear2) / purchasePrice) * 100).toFixed(3)}%` : null}</Box>

                    </SimpleGrid>
                </Box>
                }
                         
                {notes && processingPDF &&
                    <Flex justify='center' align={'center'} mb='1'>
                        <Text  textAlign={'center'} color='gray.600' fontSize={'13px'}>
                            {notes}
                        </Text>
                    </Flex>
                }
                {!processingPDF &&
                    <Textarea
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder='Add notes here...'
                    >
                    </Textarea>
                }
                <Flex justify={'center'} align={'center'}>
                    {thisUser && thisUser.photoURL && 
                        <Avatar size='2xl' src={thisUser.photoURL}></Avatar>
                    }       
                    <Flex p={2} pl={5} lineHeight='1.2' color='gray.600' flexDir={'column'}>
                        <Text fontSize={'lg'} letterSpacing={'wider'} textTransform={'uppercase'} fontWeight={'bold'}>{thisUser ? thisUser.label && thisUser.label : currentUser && currentUser.displayName && currentUser.displayName}</Text>
                        <Text fontSize='sm' textTransform={'uppercase'}>{thisUser && thisUser.jobTitle && thisUser.jobTitle}</Text>
                        <Text fontSize='sm' as='em'>{thisUser && thisUser.phoneNo && thisUser.phoneNo}</Text>
                        <Text fontSize='sm' as='em'>{thisUser && thisUser.email && thisUser.email}</Text>
                        <Text fontSize='sm' >{thisUser ? thisUser.NMLS && `NMLS#${thisUser.NMLS}` : NMLS ? `NMLS#${NMLS}` : null}</Text>
                        <Text fontSize='sm' >{thisUser && thisUser.website && thisUser.website}</Text>
                    </Flex>
                </Flex>
                <Flex mt={1}>
                    <Text color='gray' fontSize={'8px'}>
                    Copyright© 2022 Future Financial Future Financial LLC DBA Future Home Loans supports Equal Housing Opportunity NMLS ID#2043401 #65370
                    #1902671 #1621953 #MC-7265 #B-200041 #22908. Interest rates and products are subject to change without notice and may or may not be
                    available at the time of mortgage commitment or at the time of rate lock. 224 3rd Street N Jacksonville Beach, FL. 32250, 904.494.6000. All rights
                    reserved. This is not an offer to enter into an agreement. Not all customers will qualify. Information, rates, and programs are subject to change
                    without prior notice. All products are subject to credit and property approval. Not all products are available in all states or for all dollar amounts.
                    Other restrictions and limitations may apply. Future Home Loans is not affiliated with any government agencies. Interest rates and annual percentage rates (APRs) 
                    are based on our current pricing, are for informational purposes only, are subject to change without notice and may be subject to pricing add-ons related to 
                    property type, occupancy type, loan amount, fee changes, loan-to-value ratio, credit score, refinance with cash out and other variables.
                    </Text>
                    <Image align='flex-start' h={'35px'} src={require('../assets/img/EqualHousingOpportunity.png')}></Image> 
                </Flex>
                </div>
            </DrawerBody>
            <DrawerFooter>
                <Button mr={2} fontSize='sm' colorScheme={'blue'} onClick={reset}>Reset</Button>
                <Button letiant='outline'onClick={onClose}>
                    Close
                </Button>
            </DrawerFooter>
          </DrawerContent>

        </Drawer>
      </>
    )
  }

