import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Input,
    Text,
    Button,
    useDisclosure,
    Link,
    SimpleGrid  } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { numberWithCommas } from '../functions/globalFunctions'

  export default function VAEntitlementCalc() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const [purchasePrice, setPurchasePrice] = useState('')
    const [conformingLimit, setConformingLimit] = useState('')
    const [entitlementUsed, setEntitlementUsed] = useState('')

    const [expandedEntitlement, setExpandedEntitlement] = useState()
    const [remainingAvailable, setRemainingAvailable] = useState()
    const [maxHomePrice, setMaxHomePrice] = useState()
    const [downPayment, setDownPayment] = useState()

    useEffect(() => {
        if(!isNaN(conformingLimit)){
            setExpandedEntitlement(conformingLimit/4)
        }
    }, [conformingLimit])

    useEffect(() => {
        if(!isNaN(expandedEntitlement)){
            setRemainingAvailable(expandedEntitlement - entitlementUsed)
        }
    }, [expandedEntitlement, entitlementUsed])
  
    useEffect(() => {
        if(!isNaN(remainingAvailable)){
            setMaxHomePrice(remainingAvailable * 4)
        }
    }, [remainingAvailable])

    useEffect(() => {
        if(!isNaN(purchasePrice) && !isNaN(maxHomePrice)){
            setDownPayment((purchasePrice - maxHomePrice) * 0.25)
        }
    }, [purchasePrice, maxHomePrice])

    const reset = () => {
        setPurchasePrice('')
        setConformingLimit('')
        setEntitlementUsed('')
        setExpandedEntitlement('')
        setRemainingAvailable('')
        setMaxHomePrice('')
        setDownPayment('')
    }

    const handleSetValue = (field, value) => {

    }

    return (
      <>
        <Link fontSize={'sm'} isExternal ref={btnRef} colorScheme='teal' onClick={onOpen}>
          VA Entitlement Calculator<ExternalLinkIcon/>
        </Link>
        <Drawer
          isOpen={isOpen}
          size='md'
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader mb={5}>          
                VA Entitlement Calculator
            </DrawerHeader>
  
            <DrawerBody>
                <SimpleGrid columns={2} spacing={8}>

                <Text fontWeight={'bold'} >Purchase Price</Text>
                <Input 
                    type={'number'}
                    isInvalid={purchasePrice && isNaN(purchasePrice)}
                    value={purchasePrice}
                    onChange={(e) => setPurchasePrice(e.target.value)} 
                    />
                <Text fontWeight={'bold'} >Conforming Limit</Text>
                <Input 
                    type={'number'}
                    isInvalid={conformingLimit && isNaN(conformingLimit)}
                    value={conformingLimit}
                    onChange={(e) => setConformingLimit(e.target.value)} 
                    />
                 <Text fontWeight={'bold'} >
                    Expanded Entitlement 
                    </Text>
                    {expandedEntitlement ? 
                        <Text>{numberWithCommas(expandedEntitlement)}</Text>
                        :
                        <Text>&nbsp;</Text>

                    }            
                
                <Text fontWeight={'bold'}>Entitlement Used</Text>
                <Input 
                    type={'number'}
                    isInvalid={entitlementUsed && isNaN(entitlementUsed)}
                    value={entitlementUsed}
                    onChange={(e) => setEntitlementUsed(e.target.value)} 
                    />
                    <Text fontWeight={'bold'}>
                        Remaining Available  </Text>
                        {remainingAvailable ? 
                        <Text>{numberWithCommas(remainingAvailable)}</Text>
                        :
                        <Text>&nbsp;</Text>

                        }      
                    <Text fontWeight={'bold'}>
                        Max Home Price w/ 0 Down </Text>
                        {maxHomePrice ? 
                        <Text>{numberWithCommas(maxHomePrice)}</Text>
                        :
                        <Text>&nbsp;</Text>

                        }      
                                
                   
                    <Text fontWeight={'bold'}>
                        Out of Pocket Down Payment </Text>
                        {downPayment ? 
                        <>
                            {downPayment > 0 ?
                            <Text>{downPayment}</Text>
                            :
                            <Text>0</Text>
                          }
                        </>
                        :
                        <Text>&nbsp;</Text>

                    }      
                    
                    <Button colorScheme={'blue'} onClick={reset}>Reset</Button>

                        <Button variant='outline'onClick={onClose}>
                        Close
                    </Button>


                </SimpleGrid>

            </DrawerBody>
  
            <DrawerFooter>
            
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
  }