import React, { useState } from "react";
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Icon, Image, ScaleFade, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import FocusLock from "react-focus-lock";
import {    
    Heading, 
    Text, 
    Avatar, 
    HStack, 
    Stack, 
    VStack, 
    Box, 
    Popover, 
    PopoverTrigger, 
    PopoverCloseButton, 
    IconButton, 
    PopoverContent,
    FormControl,
    FormLabel,
    Button,
    ButtonGroup,
    PopoverArrow,
    Input,
    Tag,
    TagLabel,
    Wrap,
    WrapItem
    } from "@chakra-ui/react";
import moment from "moment";
import { useAuth } from "../../contexts/AuthContext";
import { FiEdit2, FiPlay, FiTrash2 } from "react-icons/fi";
import DeleteDocDialog from "../Dialogs/DeleteDocDialog";
import BeatLoader from "react-spinners/BeatLoader";
import { useToast } from "@chakra-ui/react";
import Linkify from 'react-linkify'


export default function Message(props){
    const message = props.message
    const { currentUser } = useAuth()
    let isOwner = false
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false)
    const [formattedTimestamp, setFormattedTimestamp] = useState()
    const toast = useToast()
    const linkColor = useColorModeValue('blue.400', 'blue.200')

    if(currentUser.uid == message.user.uid){
      isOwner = true;
    }

    function removeYouTubeURLFromString(inputString) {
      const youtubeURLRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)(?:&[a-zA-Z0-9_=-]+)?/gi;
      return inputString.replace(youtubeURLRegex, '');
    }

    function getYoutubeVideoID(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    }

    const handleDelete = async (message) => {
        onClose()
        try{
            await deleteDoc(doc(props.collectionRef, message.id))
             toast({
                title: "Message deleted!",
                status: 'success',
                position: 'top',
                isClosable: true
              })
            }
            catch(err){
                toast({
                  title: "Error: " + err,
                  status: 'error',
                  position: 'top',
                  isClosable: true
                })
            }
    }

   
    return(
        <>
            {!loading &&
            <ScaleFade in initialScale={0.15}>
                <Stack id={`message-${message.id}`}mb={2} direction={isOwner ? 'row-reverse' : 'row'}>
                    {isOwner ? 
                    <>
                    <Stack direction='column' spacing={0}>
                        <PopoverForm 
                            message={message} 
                            id={`edit-popover-${message.id}`}
                            collectionRef={props.collectionRef}
                        />
                        <IconButton 
                            variant='outline'
                            border='none'
                            isRound
                            aria-label='Delete message'
                            size='md'
                            minWidth={0}
                            height={6}
                            ml={0}
                            icon={<FiTrash2/>}
                            onClick={onOpen}
                            id={`delete-message-${message.id}`}
                        />
                          <DeleteDocDialog 
                            isOpen={isOpen}
                            onOpen={onOpen} 
                            onClose={onClose} 
                            type='Message' 
                            content={message.message}
                            handleDelete={() => handleDelete(message)}
                        />
                        </Stack>
                        </>
                    : null}
                    <Avatar src={message.user.photoURL} alt={message.user.displayName}></Avatar>
                    <VStack align={isOwner ? 'flex-end' : 'flex-start'}>
                        <Stack direction={isOwner ? 'row-reverse' : 'row'}>
                            <Heading fontSize={{'base':'xs', 'lg': 'sm'}}>{message.user.displayName}</Heading>
                            <Box 
                                color='gray.500'
                                letterSpacing='wide'
                                fontSize={{
                                  'base':'10px',
                                  'lg': '12px'
                                }}
                                ml='2'>
                                {message.timestamp ? moment(message.timestamp.toDate()).calendar() : null }
                            </Box>
                            {message.edited ? 
                              <Text 
                                  fontSize={{
                                      'base':'8px',
                                      'lg': '10px'
                                    }} 
                                  fontStyle='italic'
                                  >
                                (edited)
                                </Text> 
                            : null}
                            {message.mentioned ?
                            <>                            
                            <HStack spacing={2}>
                              {message.mentioned.map((mention, i) => (
                                <Tag 
                                  size={'sm'} 
                                  key={`${message.id}-mention-${i}`} 
                                  variant={mention == currentUser.displayName ? 'solid': 'outline'}
                                  borderRadius='full'
                                  colorScheme='blue'>
                                  
                                  <TagLabel 
                                    fontWeight={mention == currentUser.displayName ? '600': 'normal'}
                                    fontStyle={mention == currentUser.displayName ? 'italic': 'normal'}
                                    display={{
                                      'base': "none",
                                      'lg': 'inline'
                                    }}
                                    >
                                    @{mention}
                                  </TagLabel>
                                  <TagLabel 
                                    fontWeight={mention == currentUser.displayName ? '600': 'normal'}
                                    fontStyle={mention == currentUser.displayName ? 'italic': 'normal'}
                                    display={{
                                      'base': "inline",
                                      'lg': 'none'
                                    }}
                                    >
                                    @{mention.split(" ").map((n)=>n[0]).join("")}
                                  </TagLabel>
                                </Tag>
                              ))}
                            </HStack>
                            </>
                            : null}
                        </Stack>
                          {message.message &&
                            <>
                              {message.message.includes('youtube.com/') ?
                              <>
                                  <Box
                                      maxW={'100%'}
                                      fontWeight='400'
                                      fontSize={{
                                        'base':'xs',
                                        'lg': 'sm'
                                      }}
                                      ml='2'
                                      mt={0}>
                                      {removeYouTubeURLFromString(message.message)}
                                  </Box>
                                  <iframe 
                                    width='375' 
                                    height="215" 
                                    src={`https://youtube.com/embed/${getYoutubeVideoID(message.message)}`}
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allowFullScreen>
                                  </iframe>
                                </>
                              :
                              <>
                              <Linkify mt={0}>
                                <Box
                                    maxW={'100%'}
                                    fontWeight='400'
                                    fontSize={{
                                      'base':'xs',
                                      'lg': 'sm'
                                    }}
                                    ml='2'
                                    mt={0}>
                                    {message.message}
                                </Box>
                              </Linkify>
                              </>
                              }
                            </>
                          
                          }
                         {message.thumbnail && message.url &&
                          <>
                              <Box position='relative'>
                                <a href={message.url} target='_blank'>
                                  <Image maxHeight='175px' display={'block'} src={message.thumbnail} />
                                  <Icon color={'green.400'} position='absolute' top='40%' left='40%' fontSize={'6xl'} as={FiPlay}></Icon>
                                </a>
                              </Box>

                          </>
                         }
                        {message.Files && message.Files.length > 0 && 
                          <>
                          <Wrap justify={isOwner ? 'flex-end' : 'flex-start'} mb={2}>
                          
                          {message.Files.map((file, i) => {
                              return(
                                  <WrapItem color={linkColor} _hover={{textDecoration:'none'}} textDecoration={'underline'} fontSize='12px'>

                                        <a href={file.url} target='_blank'>{file.name}</a>
                                  </WrapItem>
                              )
                          })}
                          </Wrap>
                          </>
    
            }
                    </VStack>
                </Stack>
                </ScaleFade>
            }
        </>
    )
}

const TextInput = React.forwardRef((props, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <div key={props.id}>
          <Input ref={ref} id={props.id} {...props} />
        </div>
      </FormControl>
    )
  })
  
  // 2. Create the form
  const Form = ( props ) => {
    const [newMessage, setNewMessage] = useState()
    const [submitting, setSubmitting] = useState(false)
    const handleChange = (event) => setNewMessage(event.target.value)

    async function handleEditMessage(){
        setSubmitting(true)
        try{
            const docRef = doc(props.collectionRef, props.message.id) 
            await updateDoc(docRef, {
                message: newMessage,
                edited: true
            })
        }
        catch(err){
            console.log(err)
        }
        setSubmitting(false)
        props.onCancel()
    }

    return (
      <Stack spacing={4}>
        <TextInput
          label='Edit Message'
          id={`edit-message-${props.message.id}`}
          ref={props.firstFieldRef}
          defaultValue={props.message.message}
          value={newMessage}
          onChange={handleChange}
          size='lg'
        />
        <ButtonGroup d='flex' justifyContent='flex-end'>
          <Button variant='outline' onClick={props.onCancel}>
            Cancel
          </Button>
          <Button 
            onClick={handleEditMessage} 
            isDisabled={!newMessage || newMessage === props.message.message ? true: false} 
            colorScheme='teal'
            isLoading={submitting}
            spinner={<BeatLoader size={8} color='white' />}
            >
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    )
  }
  
  // 3. Create the Popover
  // Ensure you set `closeOnBlur` prop to false so it doesn't close on outside click
  const PopoverForm = (props) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = React.useRef(null)

    const handleClose = () => {
        onClose()
    }
    const handleOpen = () => {
        onOpen()
    }
  
    return (
      <>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={handleOpen}
          onClose={handleClose}
          placement='right'
          id={`popover-${props.message.id}`}
        >
          <PopoverTrigger>
            <IconButton 
                variant='outline'
                border='none'
                isRound
                aria-label='Edit message'
                size='md'
                minWidth={0}
                height={6}
                ml={0}
                id={`popover-button-${props.message.id}`}
                icon={<FiEdit2/>} 
            />
          </PopoverTrigger>
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <Form firstFieldRef={firstFieldRef} onCancel={handleClose} message={props.message} collectionRef={props.collectionRef} />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    )
  }