import { useEffect, useState } from "react";
import { doc, getDocs, serverTimestamp, collection, where, query, updateDoc, onSnapshot } from "firebase/firestore";
import { useHistory, useLocation } from "react-router-dom";
import { db } from "../../utils/init-firebase"
import { useAuth } from "../../contexts/AuthContext";
import {
    Stack,
    Heading,
    Box
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import GroupChat from "../../components/Client/GroupChat";
import RequestBox from "../../components/Marketing/RequestBox";

export default function MarketingRequestPage() {
    const [error, setError] = useState(null)
    const [request, setRequest] = useState(null)
    const [users, setUsers] = useState([])
    const { currentUser } = useAuth()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()
    const location = useLocation()
    

    useEffect(() => {

       async function updateNotifications(){
          try{
            const notificationsRef = collection(db, "users", currentUser.uid, 'Notifications');
            const q = query(notificationsRef, where("loanID", "==", id));
            const querySnapshot = await getDocs(q);
              querySnapshot.forEach( async (doc) => {
                await updateDoc(doc.ref, {
                    read: serverTimestamp()
                })
              });
          }
          catch(err){
            console.log(err)
          }
      } 
      updateNotifications() 
      const unsubRequest = onSnapshot(doc(db, 'Marketing Requests', id), (doc) =>{
        if(doc.exists()){
          setError(null)
          setRequest(doc.data())
        }
        else{
          setError('Request not found')
        }
        setIsLoading(false)
      })         

      return () => {
        unsubRequest()
      }
  
  }, [id])

  return (
      <>
      {!isLoading && 
        <>
          <Stack direction={{base: "column-reverse", xl: "row"}} mt='5'>
              {error && <Heading>{error}</Heading>}
              {request &&
                <>
                    <Box w={{base: '100%', xl: '52vw'}}>                    
                      <GroupChat parentDocContent={request} width={'100%'} collectionName='Marketing Requests' collectionRef={collection(db, 'Marketing Requests', request.id, 'Chat')} parentID={request.id} />
                    </Box>
                    <Box w={{base: '100%', xl: '35vw'}}>                    
                      <RequestBox request={request} expandDescription/>
                    </Box>  
                </>

              }
            </Stack>
        </>
      }
      </>
  );
}
