import { Stack } from "@chakra-ui/react";
import DocumentLog from "./DocumengLog";

export default function DocumentLogs( { logList }){
    return(
       <Stack
         spacing={5}
         direction='column' 
         p={1}
         overflowY='auto'
         mt={2}
         >
            {logList.map((log, i) => {
                return(
                    <DocumentLog log={log}  />
                )
            })}
          
      </Stack>
    )
}