import BooleanSwitch from "../Settings/BooleanSwitch";


export default function ProcessPipelineSyncSettings( { docRef, initialSettings } ){

    return(
        <>
        <BooleanSwitch
            docRef={docRef}
            fieldPath={'Process Pipeline Sync Settings.Add Loans To The Process Pipeline When They Are Completed'}
            initialState={initialSettings ? initialSettings['Add Loans To The Process Pipeline When They Are Completed'] ? initialSettings['Add Loans To The Process Pipeline When They Are Completed'] : false : false}
            label='Add Loans To The Process Pipeline When They Are Completed (one-time)'
            id='add-loan-to-process-pipeline'
            successMsg={'Loans will be added to the process pipeline when they move to completed (one-time)'}
            warningMsg={'Loans will be NOT added to the process pipeline when they move to completed'}
        />
      
      <BooleanSwitch
            docRef={docRef}
            fieldPath={'Process Pipeline Sync Settings.Sync All Changes Made To Completed Files'}
            initialState={initialSettings ? initialSettings['Sync All Changes Made To Completed Files'] ? initialSettings['Sync All Changes Made To Completed Files'] : false : false}
            label='Sync All Changes Made To Completed Files'
            id='sync-loan-to-process-pipeline'
            successMsg={'Changes made to completed loans will be synced with the process pipeline'}
            warningMsg={'Changes made to completed loans will NOT be synced with the process pipeline'}
        />
        </>
    )
}