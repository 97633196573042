import { useEffect, useState, useRef } from "react";
import { collection, getDoc, getDocs, doc, updateDoc, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "../../../utils/init-firebase";
import {
  useColorModeValue,
  Button,
  Stack,
  useToast,
  FormControl,
  FormLabel,
  Box,
  Heading,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useAuth } from "../../../contexts/AuthContext";
import BooleanSwitch from "../../../components/Settings/BooleanSwitch";
import StatusChangeNotificationSettings from "../../../components/Settings/StatusChangeNotificationSettings";



export default function EscalationNotificationSettings() {
  const [allUsers, setAllUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const { currentUser } = useAuth()
  const { users } = useAuth()
  
  const bg = useColorModeValue("white", "gray.800");
  const bg2 = useColorModeValue("white", "gray.800");
  const bg3 = useColorModeValue("gray.100", "gray.700");

  const toast = useToast()

  const [initialSettings, setInitialSettings] = useState(null)

  const [newEmailRecipients, setNewEmailRecipients] = useState(null)
  const [comEmailRecipients, setComEmailRecipients] = useState(null)
  const [defaultAssignees, setDefaultAssignees] = useState(null)
  const [userRegRec, setUserRegRec] = useState(null)

  const settingsRef = doc(db, "Settings", 'Escalation Settings');

  const emailEditorRef = useRef(null);


  const handleSetNewEmailRecipients = (e) =>{
    e.forEach((entry) => {
      delete entry['__isNew__']
      if(entry.email == null)
        entry.email = entry.value
    })
    setNewEmailRecipients(e)

  }

  const handleSetComEmailRecipients = (e) =>{
    e.forEach((entry) => {
      delete entry['__isNew__']
      if(entry.email == null)
        entry.email = entry.value
    })
    setComEmailRecipients(e)

  }

  const handleSetDefaultAssignees = (e) =>{
    e.forEach((entry) => {
      delete entry['__isNew__']
      if(entry.email == null)
        entry.email = entry.value
    })
    setDefaultAssignees(e)

  }


  const getSettings = async () => {
    try{
      const settings = await getDoc(settingsRef)
      if(settings.exists){
        setNewEmailRecipients(settings.data()['New Escalation Email Recipients'] ? settings.data()['New Escalation Email Recipients'] : null)
        setComEmailRecipients(settings.data()['Completed Escalation Email Recipients'] ? settings.data()['Completed Escalation Email Recipients'] : null)
        setDefaultAssignees(settings.data()['Default Assignees'] ? settings.data()['Default Assignees'] : null)
        setInitialSettings(settings.data())
      }
      else setInitialSettings(null)
    }
    catch(err) {
      console.error(err);
    }
  }

  async function updateSetting(field, value){
    try{
      const settingsSnap = await getDoc(settingsRef);

      //If the settings document exists, update it. Else create it and add data
      if(settingsSnap.exists()){
        await updateDoc(settingsRef, {
          [field]: value
        });
      }
      else{
        await setDoc(settingsRef, {
          [field]: value
        });

      }
      toast({
        title: `${field} were updated!`,
        status: 'success',
        position: 'top',
        isClosable: true
      })
    }
    catch(err) {
      console.error(err);
    }
  }


   const getAllUsers = async () => {
    setAllUsers([])
    try{
      const q = query(collection(db, "users"), orderBy('dateJoined', 'desc'));

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        setAllUsers(users => [...users, doc.data()])
      })
    }
    catch(err) {
      console.error(err);
    }
  }

  useEffect(async () => {
      setLoading(true)
      await getAllUsers();
      await getSettings()
      setLoading(false)
  }, [])  

  return (
    <>
       {!loading &&
       <>
            <StatusChangeNotificationSettings
                heading='New Escalation Notifications'
                users={users}
                settingsRef={settingsRef}
                initialSettings={initialSettings}
                settingName={'New Escalation Notifications'}
                actionMessage={'an escalation is created'}
            />
            <StatusChangeNotificationSettings
                heading='Escalation Assignment Notifications'
                omitInputFields={true}
                users={users}
                settingsRef={settingsRef}
                initialSettings={initialSettings}
                settingName={'Escalation Assignment Notifications'}
                actionMessage={'an escalation is assigned'}
                emailSwitches={[
                  {
                    name: 'Email Assignees',
                    id: `assignees-email-alert-on-escalation-assignment`,
                    label: 'Email Assignees',
                    fieldPath: `Escalation Assignment Notifications.Email Assignees`,
                    successMsg: `Assignees will be emailed when an escalation is assigned`,
                    warningMsg: `Assignees will not be emailed when an escalation is assigned`,
                  },
                ]}
                pushSwitches={[
                  {
                    name: 'Push Assignees',
                    id: `assignees-push-alert-on-escalation-assignment`,
                    label: 'Push Assignees',
                    fieldPath: `Escalation Assignment Notifications.Push Assignees`,
                    successMsg: `Assignees will receive push notifications when an escalation is assigned`,
                    warningMsg: `Assignees will not receive push notifications when an escalation is assigned`,
                  },
                ]}
            />
            <StatusChangeNotificationSettings
                heading='Completed Escalation Notifications'
                users={users}
                settingsRef={settingsRef}
                initialSettings={initialSettings}
                settingName={'Completed Escalation Notifications'}
                actionMessage={'an escalation is assigned'}
                emailSwitches={[
                  {
                    name: 'Email Assignees',
                    id: `assignees-email-alert-on-escalation-completion`,
                    label: 'Email Assignees',
                    fieldPath: `Completed Escalation Notifications.Email Assignees`,
                    successMsg: `Assignees will be emailed when an escalation is completed`,
                    warningMsg: `Assignees will not be emailed when an escalation is completed`,
                  },
                  {
                    name: 'Email Owner',
                    id: `owner-email-alert-on-escalation-completion`,
                    label: 'Email Owner',
                    fieldPath: `Completed Escalation Notifications.Email Owner`,
                    successMsg: `Owner will be emailed when an escalation is completed`,
                    warningMsg: `Owner will not be emailed when an escalation is completed`,
                  },
                ]}
                pushSwitches={[
                  {
                    name: 'Push Assignees',
                    id: `assignees-push-alert-on-escalation-completion`,
                    label: 'Push Assignees',
                    fieldPath: `Completed Escalation Notifications.Push Assignees`,
                    successMsg: `Assignees will receive push notifications when an escalation is completed`,
                    warningMsg: `Assignees will not receive push notifications when an escalation is completed`,
                  },
                  {
                    name: 'Push Owner',
                    id: `owner-push-alert-on-escalation-completion`,
                    label: 'Push Owner',
                    fieldPath: `Completed Escalation Notifications.Push Owner`,
                    successMsg: `Owner will receive push notifications when an escalation is completed`,
                    warningMsg: `Owner will not receive push notifications when an escalation is completed`,
                  },
                ]}
            />
            <Box boxShadow={'lg'} p={5} mt={5}>
              <Heading size='sm'>Default Escalation Settings</Heading>          
              <FormControl p={4}>
                <Stack m={2} direction='row'>
                  <FormLabel>Default Assignees</FormLabel> 
                  <Button size='sm'colorScheme='messenger' onClick={() => updateSetting('Default Assignees', defaultAssignees)}>Update</Button>
                </Stack>
                <Select
                  isMulti
                  name="Default Assignees"
                  options={users}
                  placeholder="Select or Enter Recipient"
                  closeMenuOnSelect={false}
                  value={defaultAssignees}
                  onChange={(e) => handleSetDefaultAssignees(e)}
                />
              </FormControl>
              <Heading size='sm' mb='2'>Default Notification Settings - Chat Messages</Heading>
              <BooleanSwitch 
                docRef={settingsRef}
                fieldPath={'Default Settings.Notify LO'}
                initialState={initialSettings ? initialSettings['Default Settings'] ? initialSettings['Default Settings']['Notify LO'] ? initialSettings['Default Settings']['Notify LO'] : false : false : false}
                label={'Notify LO on associated loan'}
                successMsg={'LOs will be notified when new chat messages are sent'}
                warningMsg={'LOs will NOT be notified when new chat messages are sent'}
                id='notify-lo'
              />
              <BooleanSwitch 
                docRef={settingsRef}
                fieldPath={'Default Settings.Notify LOA'}
                initialState={initialSettings ? initialSettings['Default Settings'] ? initialSettings['Default Settings']['Notify LOA'] ? initialSettings['Default Settings']['Notify LOA'] : false : false : false}
                label={'Notify LOA on associated loan'}
                successMsg={'LOAs will be notified when new chat messages are sent'}
                warningMsg={'LOAs will NOT be notified when new chat messages are sent'}
                id='notify-loa'
              />
              <BooleanSwitch 
                docRef={settingsRef}
                fieldPath={'Default Settings.Notify LC'}
                initialState={initialSettings ? initialSettings['Default Settings'] ? initialSettings['Default Settings']['Notify LC'] ? initialSettings['Default Settings']['Notify LC'] : false : false : false}
                label={'Notify LC on associated loan'}
                successMsg={'LCs will be notified when new chat messages are sent'}
                warningMsg={'LCs will NOT be notified when new chat messages are sent'}
                id='notify-lc'
              />
               <BooleanSwitch 
                docRef={settingsRef}
                fieldPath={'Default Settings.Notify CSC'}
                initialState={initialSettings ? initialSettings['Default Settings'] ? initialSettings['Default Settings']['Notify CSC'] ? initialSettings['Default Settings']['Notify CSC'] : false : false : false}
                label={'Notify CSC on associated loan'}
                successMsg={'CSCs will be notified when new chat messages are sent'}
                warningMsg={'CSCs will NOT be notified when new chat messages are sent'}
                id='notify-csc'
              />
              <BooleanSwitch 
                docRef={settingsRef}
                fieldPath={'Default Settings.Notify TC'}
                initialState={initialSettings ? initialSettings['Default Settings'] ? initialSettings['Default Settings']['Notify TC'] ? initialSettings['Default Settings']['Notify TC'] : false : false : false}
                label={'Notify TC on associated loan'}
                successMsg={'TCs will be notified when new chat messages are sent'}
                warningMsg={'TCs will NOT be notified when new chat messages are sent'}
                id='notify-tc'
              />

            </Box>
          </>
    }
  </>   
  );
}
