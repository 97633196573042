import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    IconButton,
    Box,
    FormLabel,
    Flex,
    Text,
    Select,
    FormControl,
    useToast
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaMountain } from 'react-icons/fa'
import { AiOutlineWarning } from 'react-icons/ai'
import { collection, collectionGroup, getDocs, query, serverTimestamp, doc, setDoc } from 'firebase/firestore'
import { db } from '../../../utils/init-firebase'
import RenderQuestion from './RenderQuestion'
import { useAuth } from '../../../contexts/AuthContext'
import { Select as ReactSelect } from 'chakra-react-select'

export default function CreateEscalationDrawer( { loanID, loanNumber, initVals, fullButton } ){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const { users, currentUser, SuperAdmin } = useAuth()
    const toast = useToast()

    const [owner, setOwner] = useState({
        email: currentUser.email,
        label: currentUser.displayName,
        photoURL: currentUser.photoURL,
        value: currentUser.uid,
    })

    const [topics, setTopics] = useState(null)
    const [subtopics, setSubtopics] = useState(null)
    const [questions, setQuestions] = useState(null)

    const [topic, setTopic] = useState(null)
    const [selectedSubtopicID, setSelectedSubtopicID] = useState(null)
    const [subtopicQuestionIDs, setSubtopicQuestionIDs] = useState([])
    const [subtopicQuestions, setSubtopicQuestions] = useState([])
    const [questionAnswers, setQuestionAnswers] = useState({})

    const addEscalation = async (e) => {
        e.preventDefault()
        console.log('adding...')
        const newDocRef = doc(collection(db, 'Escalations'))

        await setDoc(newDocRef, {
            id: newDocRef.id,
            answers: questionAnswers,
            topicID: topic,
            subtopicID: selectedSubtopicID,
            loanID: loanID,
            questions: subtopicQuestions,
            Status: 'New',
            'Date Created': serverTimestamp(),
            Owner: {  
                Email: owner.email,
                Name: owner.label,
                photoURL: owner.photoURL,
                uid: owner.value
            },
        }).then(() => {
            onClose()
            toast({
                title: 'Escalation created!',
                status: 'success',
                position: 'top',
                duration: 9000,
                isClosable: true,
              })
        })


    }

    const handleOnOpen = async () => {
        onOpen()
        getFormSettings()
    }

    const getFormSettings = () => {
        console.log('getting settings...')
        getTopics()
        getSubtopics()
        getQuestions()
    }

    const getTopics = async () => {
        let topics = []
        const querySnapshot = await getDocs(collection(db, 'topics'));
        querySnapshot.forEach((doc) => {
            console.log(doc.data())
            topics.push({
                id: doc.id, 
                ...doc.data()
            })
        });
        setTopics(topics)
    }

    const getSubtopics = async () => {
        let subtopics = []
        const q = query(collectionGroup(db, "subtopics"));
        const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                subtopics.push({
                    id: doc.id, 
                    parentID: doc.ref.parent.path.substring(
                        doc.ref.parent.path.indexOf("/") + 1, 
                        doc.ref.parent.path.lastIndexOf("/")
                    ), 
                    ...doc.data()
                })
        });
        setSubtopics(subtopics)
    }

    const getQuestions = async () => {
        let quests = []
        const q = query(collection(db, "questions"));
        const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                quests.push({
                    id: doc.id, 
                    ...doc.data()
                })
        });
        setQuestions(quests)
    }

    useEffect(() => {
        setSelectedSubtopicID(null)
        setSubtopicQuestionIDs(null)
        setSubtopicQuestions(null)

    }, [topic])

    useEffect(() => {
        if(selectedSubtopicID && subtopics){
            let index = subtopics.findIndex((subtopic) => subtopic.id === selectedSubtopicID)
            if(index > -1){
                let qs = []
                subtopics[index].questions && subtopics[index].questions.forEach((questionID) => {
                    let qIndex = questions.findIndex((question) => question.id === questionID)
                    if(qIndex > -1 && !(questions[qIndex].deleted)){
                        qs.push(questions[qIndex])
                    }
                })
                setSubtopicQuestions(qs)
            }
            //else handle error
        }
    }, [selectedSubtopicID])

    const handleOnChange = (val, label) => {
        setQuestionAnswers(
            {...questionAnswers,
            [label]: val  
        })
    }

    return(
        <>
        {fullButton ? 
            <Button size='sm' ref={btnRef} colorScheme='blue' onClick={handleOnOpen}>
                Create Escalation
            </Button>
        :
            <IconButton size='sm' as={AiOutlineWarning} ref={btnRef} colorScheme='blue' onClick={handleOnOpen}>
            </IconButton>
        }
       
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
            size='md'
        >
            <DrawerOverlay>
                <Box
                borderWidth={{'base': 'none', 'md': "1px"}}
                rounded="lg"
                shadow={{'base': 'none', 'md': "1px 1px 3px rgba(0,0,0,0.3)"}}
                p={{'base': '0', 'md': "5"}}
                m="10px auto"
                as="form"
                onSubmit={(e) => addEscalation(e)}
                >
                <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>New Escalation</DrawerHeader>
                    <DrawerBody>
                        {SuperAdmin &&
                        <>
                            <Flex mt={10} mb={10} flexDir={'column'} justify='space-between'>
                                <FormLabel>Create Escalation As:</FormLabel>
                                <FormControl w='450px' mr={5} dir='row'>
                                    <ReactSelect
                                        options={users}
                                        placeholder="Create Escalation As..."
                                        value={owner}
                                        onChange={setOwner}
                                        closeMenuOnSelect={true}
                                        isClearable
                                    />
                                    </FormControl>
                            </Flex>
                        </>
                        }
                        {loanNumber &&
                        <Text mb='2'>Loan #: {loanNumber}</Text>}
                        {topics && subtopics &&
                            <>
                            <Select
                                placeholder='Select Topic'
                                name="Topic"
                                label="Topic"
                                required
                                onChange={(e) => setTopic(e.target.value)}
                            >
                                {topics?.map((topic) => (
                                    <option value={topic.id}>{topic.title}</option>
                                ))}
                            </Select>
                            <Select
                                placeholder='Select Subtopic'
                                mt='2'
                                mb='3'
                                name="Subtopic"
                                required
                                label="Subtopic"
                                onChange={(e) => setSelectedSubtopicID(e.target.value)}
                                isDisabled={!topic}
                                >
                                {topic && subtopics?.map((subtopic) => {
                                    if(subtopic.parentID === topic){
                                        return <option value={subtopic.id}>{subtopic.title}</option>
                                    }
                                })}
                            </Select>
                            {subtopicQuestions?.map((q) => (
                                <RenderQuestion question={q} answers={questionAnswers} handleOnChange={handleOnChange}/>
                            ))}
                            </>
                           
                        }
                    
                                      
                    </DrawerBody>
            
                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type='submit' colorScheme='blue'>Submit</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Box>            
            </DrawerOverlay>
        </Drawer>
        </>
    )
}