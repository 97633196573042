import { Center, Icon, Flex, AvatarBadge, Avatar, Text } from "@chakra-ui/react";
import moment from "moment";
import { FiMinus, FiPlus } from "react-icons/fi";
export default function TransactionByTask( { transaction } ){
    const iconW = 6
    const iconH = 6
    var iconColor = null

    if(transaction.isAddition){
        iconColor = 'green'
    }
    else iconColor = 'red'
 
    return(
        
        <>
            <Flex height='65px' width='inherit' direction='row' justifyContent='space-between' alignItems={'center'} >
                <Flex>
                    {transaction['Fulfilled By'] && transaction['Fulfilled By'].photoURL &&
                        <Avatar mr={3} size='md' src={transaction['Fulfilled By'].photoURL}>
                        </Avatar>  
                    }
                    <Flex direction='column' justifyItems={'center'} wrap>                     
                        <Text fontSize='xs' style={{marginTop: '2px'}} as='em'>
                        {transaction.isMarketingSpend ?
                            `Marketing Spend ${transaction?.['Spend Category'] ? `- ${transaction['Spend Category']}` : ``}`
                            :
                            `${transaction?.['Fulfilled By']?.Name} fulfilled ${transaction?.['Owner']?.Name}'s request`
                        }
                        </Text>
                        {transaction.timestamp && <Text 
                            color='gray.500'
                            letterSpacing='normal'
                            fontSize={{
                                'base':'10px',
                            }}
                            style={{marginTop: '2px'}}
                            >
                            {moment(transaction.timestamp.toDate()).calendar()}   </Text>}
                            {transaction.totalCost &&
                            <Text 
                                mr='1' 
                                fontSize={'xs'} 
                                color='gray.600'>
                                    ${parseFloat(transaction.totalCost).toFixed(2)}
                            </Text>}
                    </Flex>
                </Flex>
                <Flex flexDir={'column'} maxH='75px' overflowY='scroll'>
                    {transaction?.Items?.map((item) => (
                        <Flex flexDir={'row'} align='center'>
                            <Text mr='1' fontSize='xs'>{item.Amount}</Text>
                            <Avatar mr='1' src={item.photoURL} size='xs'></Avatar>
                            <Text mr='1' fontSize='xs' color='gray.600'>{item.Name}</Text>
                            <Text ml='auto' fontSize='xs'>${item.Cost.toFixed(2)}</Text>
                        </Flex>
                    ))}

                      </Flex>
                </Flex>
                {/* <Flex justifySelf={'flex-end'} ml='5' mr='2'>
                
                    <Flex flexDir={'column'}>
                        {transaction.Owner && transaction.Owner.uid != '' &&
                            <Avatar ml='2' justifySelf='flex-end' size='md' src={transaction.Owner.photoURL}>
                                <AvatarBadge 
                                    border='none' 
                                    color='white' 
                                    fontSize='11px' 
                                    letterSpacing={'tighter'} 
                                    boxSize='2.25em' 
                                    bg={'red.500'}>
                                        -
                                </AvatarBadge>
                            </Avatar>
                        }
                    
                </Flex> 
            </Flex>*/}
    

    </>
    


    )
}
