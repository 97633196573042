import React, {useState} from 'react'
import { Box, Heading, Button, Flex, IconButton, useColorModeValue, Image } from '@chakra-ui/react'
import { FiX } from 'react-icons/fi';
import { useAuth } from './contexts/AuthContext';

export default function VersionListenerNotification () {
    const { currentVersion, latestVersion } = useAuth()
    const [showBanner, setShowBanner] = useState(true)
    const bgColor = useColorModeValue('blue.100', 'blue.300')
    const handleRelaunch = () => {
        localStorage.setItem('isRelaunch', 'yes')
        window.location.reload()
    }

    return (
      <>
      {showBanner && currentVersion && latestVersion && (currentVersion != latestVersion) &&
          <>
          <Box 
              position='relative'
              h={'60px'}
              backgroundColor={bgColor}
              p={1}
              >
              <Flex flexDir={'row'} justify='space-between' align='center'>
                  <Flex>

                  </Flex>
                  <Flex align={'center'}>   
                      <Image height={'55px'} mr={3} name={'astronaut'} src={require('./assets/img/astronaut-face.png')}></Image>             
                      <Heading fontWeight='semibold' mr={3} fontSize={'lg'}>NEW VERSION AVAILABLE</Heading>
                      <Button backgroundColor='green.300' color='white' variant={'solid'} onClick={() => handleRelaunch()} size='sm' >Relaunch</Button>
                  </Flex>
                  <IconButton variant={'ghost'} onClick={() => setShowBanner(false)} icon={<FiX/>}></IconButton>
              </Flex>
          </Box>
          </>
      }
      </>
    )
}