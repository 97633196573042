import {
    Flex,
    Text,
    Icon,
    Menu,
    MenuButton,
    Divider,
    Stack,
    Center
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export default function NavButton({ icon, rightIcon, name, to, navSize, onOpen, isChild, isLastChild, isFirstChild }) {
    const location = useLocation()
    const isActive = location.pathname === to
    return (

            <Flex
                flexDir="column"
                w="100%"
                pl={navSize == "small" ? 0 : isChild ? '13%' : 3}
                pt={navSize == "small" && '13%'}
                pb={navSize == "small" && '13%'}
                alignItems={navSize == "small" ? "center" : "flex-start"}
                style={{alignItems: rightIcon? 'center' : null}} 
                backgroundColor={isActive ? '#e8e8e8' : ''}
                >
                <Menu placement="right">
                    <Flex 
                        onClick={onOpen}
                        backgroundColor={isActive && "#AEC8CA"}
                        border={isActive ? '3px solid' : ''}
                        borderRadius={8}
                        w={navSize == "large" && "100%"}
                    >
                        <MenuButton w="100%">
                            <Flex justifyContent={navSize == 'small' && 'center'}>

                                {isChild && 
                                    <Stack direction='row' >
                                        {isFirstChild && navSize == 'small' ?
                                            <Divider style={{height: '285%', borderColor: 'gray', margin: 0, marginTop: '-30px'}}orientation='vertical' />
                                        :
                                        isLastChild ? 
                                            <Divider style={{height: '50%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        :
                                        isFirstChild ?
                                            <Divider style={{height: '150%', borderColor: 'gray', margin: 0, marginTop: '-15px'}}orientation='vertical' />
                                        :
                                            <Divider style={{height: '105%', borderColor: 'gray', margin: 0}}orientation='vertical' />
                                        }
                                        <Divider style={{width: '10px', borderColor: 'gray', margin: 0, padding: 0, justifySelf: 'flex-start', alignSelf: 'center'}} orientation='horizontal' />                       
                                    </Stack>
                                }               
                                <Flex                
                                    p={'3%'}
                                    pt={navSize == 'small' ?  '15%' : isChild ? '4%' : '7%'}
                                    pb={navSize == 'small' ?  '15%' : isChild ? '4%' : '7%'}>

                                    {icon ? <Center><Icon as={icon} ml='1' fontSize={isChild && 'sm'} /></Center> : null}
                                    <Text fontSize={isChild && 'sm'} style={{letterSpacing: '-1px'}}size='md' ml={rightIcon ? 0 : 2} display={navSize == "small" ? "none" : "flex"}>{name}</Text>
                                    {rightIcon ? <Icon ml={5} as={rightIcon} fontSize="xl" /> : null}
                                </Flex>
                            </Flex>
                        </MenuButton>
                    </Flex>
            
                </Menu>
            </Flex>
    )
}