import { useColorModeValue, Heading, Flex, Box, Text, Divider, MenuItem, Menu } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import SwagBudget from "../SwagBudget/SwagBudget";
import Transaction from "./Transaction";
import TransactionByTask from "./TransactionByTask";

export default function TransactionLog( { 
        transactions, 
        selectedYear, 
        budget, 
        title, 
        groupTransactionsByTaskID, 
        showUserBudget, 
        selectedUser, 
        totalCost 
    } ){
    let hoverColor = useColorModeValue('#ededed', '#071121')
    let history = useHistory()
    function handleClickTransaction(path){
        history.push(path)
      }

    return(
        <>
           <Box 
            boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;'}
            borderRadius='lg'
            height={'85vh'}
            w='100%'
            overflowY='auto'
            pt='2'
            pb='5'
           >
               <Flex direction='row' justify='space-between' m='4'>
                    <Heading ml='1' mr='1' size='sm'>{title ? title : 'Transaction Log'}</Heading> 
                    {totalCost && 
                    <Text>${totalCost}</Text>
                    }
                    {showUserBudget &&
                        <SwagBudget 
                            user={selectedUser} 
                            transactions={transactions} 
                            selectedYear={selectedYear} 
                            budget={budget}
                            />
                    }
               </Flex>
                {transactions && transactions.length > 0 ?
                    <>
                        <Flex direction='column'>
                            <Menu>
                            <>                
                            {transactions.map(transaction => {
                                if(transaction.isAddition){
                                    return <><MenuItem _hover={{cursor: 'default'}}>
                                            {groupTransactionsByTaskID ?
                                                <TransactionByTask transaction={transaction} />
                                                :
                                                <Transaction transaction={transaction} />
                                            }
                                            </MenuItem><Divider/></>

                                }
                                else {
                                     return(
                                        <>
                                        <MenuItem onClick={() => handleClickTransaction(`/marketing-requests/${transaction.taskID}`)} _hover={{backgroundColor: hoverColor}}>
                                            {groupTransactionsByTaskID ?
                                                <TransactionByTask transaction={transaction} />
                                                :
                                                <Transaction transaction={transaction} />
                                            }
                                        </MenuItem>
                                        <Divider/>
                                        </>

                                    )
                                }
                            })} 
                            </>
                            </Menu>
                        </Flex>
                    </>
                : <Text m='4'>There are no recent transactions</Text>}
        </Box>
      </>
    )
}