import { useEffect, useState, useRef } from "react";
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/init-firebase"
import { useAuth } from "../../contexts/AuthContext";
import "react-datepicker/dist/react-datepicker.css";
import LoanViewWrapper from "./LoanViewWrapper";

export default function CompletedSubmissionsPage() {
  const { currentUser, Roles, SuperAdmin, isAdmin } = useAuth()
  const [clientList, setClientList] = useState(null)
  const [loading, setLoading] = useState(true)
  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const [startDate, setStartDate] = useState(new Date(y, m, 1))
  //const [startDate, setStartDate] = useState(new Date(2023, 3, 1))
  const [endDate, setEndDate] = useState(new Date())
  let unsubscribeRef = useRef()


  const getClients = async () => {
    unsubscribeRef.current && unsubscribeRef.current()
    let q = null
    if(SuperAdmin || isAdmin){
      q = query(collection(db, "Clients"), orderBy('Date Completed', 'desc'), where('Status', '==', 'Completed'), where('Date Completed', '>=', startDate), where('Date Completed', '<=', endDate));
    }
    else if(Roles.includes('Mortgage Broker') || Roles.includes('Loan Officer')){
      q = query(collection(db, "Clients"), orderBy('Date Completed', 'desc'), where('Status', '==', 'Completed'), where(`Loan Officer.uid`, '==', currentUser.uid ), where('Date Completed', '>=', startDate), where('Date Completed', '<=', endDate));

    }
    else if(Roles.includes('Closing Coordinator') || Roles.includes('Title Coordinator')){
      q = query(collection(db, "Clients"), orderBy('Date Completed', 'desc'), where('Status', '==', 'Completed'), where(`TC.uid`, '==', currentUser.uid ), where('Date Completed', '>=', startDate), where('Date Completed', '<=', endDate));
    }
    else if(Roles.includes('Client Success Coordinator')){
      q = query(collection(db, "Clients"), orderBy('Date Completed', 'desc'), where('Status', '==', 'Completed'), where(`CSC.uid`, '==', currentUser.uid ), where('Date Completed', '>=', startDate), where('Date Completed', '<=', endDate));
    }
    else {
      q = query(collection(db, "Clients"), orderBy('Date Completed', 'desc'), where('Status', '==', 'Completed'), where('Date Completed', '>=', startDate), where('Date Completed', '<=', endDate));
    }
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let clients = []
      querySnapshot.forEach((doc) => {
          let client = {}
          client = doc.data()
          client['Borrower'] = doc.data()['Loan Details']['First Name'] + ' ' + doc.data()['Loan Details']['Last Name']
          clients.push(client)

      });
      unsubscribeRef.current = unsubscribe
      setClientList(clients)
      setLoading(false)

    });


  }

  useEffect(async() => {
    if(startDate && endDate){
      await getClients()

    }
  }, [startDate, endDate])
  return (
    <>
      {!loading && clientList &&
        <LoanViewWrapper 
            clientList={clientList} 
            getClients={getClients} 
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            loading={loading}
            />
      }
    </>
  );
}