// @ts-nocheck
import {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { db } from '../../utils/init-firebase';
import { Wrap, WrapItem, Center, Button, useColorModeValue, ScaleFade, Box } from '@chakra-ui/react';
import { getDocs, collection } from 'firebase/firestore';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import { MentionData } from '@draft-js-plugins/mention';
import { useAuth } from '../../contexts/AuthContext';

export default function SimpleMentionEditor( { editorState, setEditorState, handleKeyBind, handleKeyCommand, fullPageChat, uploadedFiles, removeAttachment, isDashboard } ): ReactElement {
  const { users }  = useAuth()
  const mentions: MentionData[] = [];
  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);
  const fileBackgroundColor = useColorModeValue('gray.100', 'gray.600')

  
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);


   useEffect(() => {
    const getUsers = async () => {
      if(users !== null){
        users.forEach((u) => {
          let user = {
            name: u.label,
            avatar: u.photoURL,
            uid: u.value,
            email: u.email,
          }
          mentions.push(user)
        })
      }
      else {
        const querySnapshot = await getDocs(collection(db, "users"));
        querySnapshot.forEach((doc) => {
          let user = {
            name: doc.data().name,
            avatar: doc.data().photo,
            uid: doc.data().uid,
            email: doc.data().email,
          }
         
          mentions.push(user)
        });
      }
 
      setSuggestions(mentions)
    }
    getUsers()
  }, []) 

    return (
      <Box
        borderWidth={!isDashboard && '1px'}
        onClick={() => {
          ref.current!.focus();
        }}
      >
          {uploadedFiles.length > 0 && 
                <>
                <Wrap p={1} justify={'flex-start'} mb={2}>
                
                {uploadedFiles.map((file, i) => {
                    return(
                      <ScaleFade in initialScale={0.45}>
                        <WrapItem m={0.25} backgroundColor={fileBackgroundColor} borderRadius='lg' fontSize='12px'>
                            <Center ml={2}>
                                <a href={file.url} target='_blank'>{file.name}</a>
                                <Button 
                                    id={`attachment-${i}`}
                                    ml={3}
                                    size={'sm'}
                                    variant='ghost' 
                                    onClick={() => removeAttachment(i)} >
                                        X
                                </Button>
                            </Center>
                        </WrapItem>
                      </ScaleFade>
                    )
                })}
                </Wrap>
                </>
            } 
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={setEditorState}
          keyBindingFn={handleKeyBind}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
          ref={ref}
        >
        </Editor>
          <MentionSuggestions
            key='mention'
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
          />
      </Box>
    );


}