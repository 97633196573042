import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
} from "react";
import {
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
  collection,
  getDoc,
  increment,
} from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db, firebase } from "../../utils/init-firebase";
import { Formik } from "formik";
import { FiAlertCircle, FiEdit, FiFile, FiPlus } from "react-icons/fi";
import { CreatableSelect } from "chakra-react-select";
import { FaShoppingCart } from "react-icons/fa";
import {
  RadioGroupControl,
  SubmitButton,
  ResetButton,
  InputControl,
} from "formik-chakra-ui";
import {
  Box,
  ButtonGroup,
  Radio,
  useDisclosure,
  useToast,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  IconButton,
  Button,
  useColorModeValue,
  DrawerFooter,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Text,
  Wrap,
  WrapItem,
  Center,
  Spinner,
  ScaleFade,
  useForceUpdate,
  forwardRef,
  Heading,
} from "@chakra-ui/react";
import NavButton from "../../components/Navigation/NavButton";
import DrawerButton from "../../components/Navigation/DrawerButton";
import { createValidationObj } from "./RequestValidation";
import CustomRequestFields from "./CustomRequest_Fields";
import EventRequestFields from "./EventRequest_Fields";
import FileUpload from "../FileUpload";
import { useForm } from "react-hook-form";
import OpenHouseRequestFields from "./OpenHouseRequest_Fields";
import SwagRequestFields from "./SwagRequest_Fields";
import { useReducer } from "react";
import QuantitySelectInCart from "../MarketingSwagV2/QuantitySelect_InCart";
import { useUsers } from "../../contexts/UsersContext";
import MarketingSpendFields from "./MarketingSpend_Fields";
import { ElementCard } from "../../pages/Marketing/MarketingMatrixPage";

const CreateRequest = forwardRef((props, ref) => {
  const {
    request,
    pendingCarts,
    isOldSwagRequest,
    isSwagRequest,
    cart,
    handleSetSelectedItem,
    inventory,
    pendingCartsListenerRef,
    cartUpdated,
    pendingCartsUpdated,
    description,
    selectedElement,
    selectedElementID,
  } = props;
  const newDocRef = doc(collection(db, "Marketing Requests"));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const titleCompanyBorderColor = useColorModeValue("gray.300", "gray.600");
  const datepickerClass = useColorModeValue(
    "chakra-input css-1c6j008",
    "chakra-input css-xpongc"
  );
  const fileBackgroundColor = useColorModeValue("gray.100", "gray.600");
  const { currentUser, NMLS, SuperAdmin, MarketingAdmin, marketingSettings } =
    useAuth();
  const { users } = useAuth();

  const toast = useToast();
  const action = props.action;
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [disableCreate, setDC] = useState([]);
  const setDisableCreate = (index, disable) => {
    let arr = disableCreate;
    arr[index] = disable;
    setDC(arr);
    forceUpdate();
  };

  useImperativeHandle(ref, () => ({
    setDisableCreate(index, disable) {
      let arr = disableCreate;
      arr[index] = disable;
      setDC(arr);
      forceUpdate();
    },
  }));

  const firstField = React.useRef();
  const inputRef = useRef();
  const formRef = useRef();

  const createRequest = useRef();

  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [uploadProg, setUploadProg] = useState();
  const [uploading, setUploading] = useState(false);

  const [eventDate, setEventDate] = useState("");

  const [owner, setOwner] = useState();
  const [numInCart, setNumInCart] = useState();

  const {
    handleSubmit,
    register,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const createValidation = createValidationObj;

  const submitValidation = createValidationObj;
  let initialValues = [];
  if (isSwagRequest && props.initialValues == null) {
    initialValues = {
      // Custom, Event, Swag
      "Request Type": "Swag",
      Description: description ? description : "",
      "Due Date": "",
      "Request Relation": "",
      Shipping: "",
    };
  } else if (selectedElement && props.initialValues == null) {
    initialValues = {
      "Request Type": "Custom",
      Description: description ? description : "",
      "Due Date": "",
      selectedElementID: selectedElement?.id,
    };
  } else if (props.initialValues == null) {
    initialValues = {
      // Custom, Event, Swag
      "Request Type": "Design",
      Description: "",
      Medium: "",
      Platform: "",
      Length: "",
      Width: "",
      "Due Date": "",

      //Marketing Spend
      Amount: "",
      "Spend Category": "",

      // Event
      "Name of Event": "",
      Venue: "",
      "Event Date": "",
      "Partnering Company": "",
      "Point of Contact": "",
      "Total Cost": "",
      Address: "",
      "Goal of Event": "",
      "Social Media Needs": [],
      "Percentage Requested From Future": "",
      "Team Member Needed": "No",
      "Participant Information Collected": "",
      "Expected Attendance": "",
      "Expected Reach": "",
      Notes: "",

      //Open House
      Name: currentUser["displayName"],
      "NMLS#": NMLS ? NMLS : "",

      Property: {
        Address: "",
        "Zillow Link": "",
        Price: "",
        "MLS#": "",
      },
      Realtor: {
        Name: "",
        "SL#": "",
        Phone: "",
        Email: "",
        Company: "",
      },
      "Event End Time": "",
    };
  } else initialValues = props.initialValues;

  useEffect(() => {
    if (eventDate && eventDate != "") {
      formRef.current.values["Event End Time"] = eventDate;
    }
  }, [eventDate]);

  const uploadFiles = useCallback(() => {
    if (files.length > 0) {
      setUploading(true);
      const theseFiles = files;
      setFiles([]);
      var promises = [];
      for (let i = 0; i < theseFiles.length; i++) {
        const storageRef = firebase.storage().ref();
        const file = theseFiles[i];

        promises.push(
          storageRef
            .child(
              `Marketing Requests/${currentUser.displayName}/${newDocRef.id}/${file.name}`
            )
            .put(file)
            .then(async (snapshot) => {
              snapshot.ref.getDownloadURL().then(async (url) => {
                setUploadedFiles((prevState) => [
                  ...prevState,
                  { url: url, name: file.name },
                ]);
                // setFileURLs(prevState => [...prevState, url]);
                //setFileNames(prevState => [...prevState, file.name]);
                return;
              });
            })
        );
      }
      Promise.all(promises)
        .then(async () => {
          setUploading(false);
        })
        .catch((err) => console.log(err.code));
    }
  }, [files]);

  function removeAttachment(i) {
    uploadedFiles.splice(i, 1);
    setFiles([]);
  }

  const onFileChange = (e) => {
    setFiles(e.target.files);
  };

  useEffect(() => {
    uploadFiles();
  }, [files]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      let num = 0;
      cart.forEach((item) => {
        if (item.selected) {
          num += parseInt(item.selected);
        }
        setNumInCart(num);
        forceUpdate();
      });
    }
  }, [cart, cartUpdated]);

  const quickComplete = async (values) => {
    try {
      if (cart && cart.length > 0) {
        for (const item of cart) {
          if (item.selected && item.selected > 0) {
            await updateDoc(doc(db, "Marketing Swag", item.id), {
              Quantity: increment(item.selected * -1),
            });
            const docRef = doc(
              collection(
                db,
                "Marketing Swag",
                item.id,
                "Marketing Swag Transactions"
              )
            );
            await setDoc(docRef, {
              "Fulfilled By": {
                Email: currentUser.email,
                Name: currentUser.displayName,
                photoURL: currentUser.photoURL,
                uid: currentUser.uid,
              },
              Owner: {
                Email: owner.email,
                Name: owner.label,
                photoURL: owner.photoURL,
                uid: owner.value,
              },
              timestamp: serverTimestamp(),
              Item: {
                id: item.id,
                Cost: item.Cost,
                Amount: item.selected,
                Name: item.Name,
                photoURL: item.photoURL,
                stock: parseInt(item?.Quantity - item?.selected),
              },
              taskID: newDocRef.id,
              id: docRef.id,
            });
          }
        }
      }
      await setDoc(newDocRef, {
        "Request Details": values,
        "Date Created": serverTimestamp(),
        "Date Completed": serverTimestamp(),
        id: newDocRef.id,
        Cart: cart ? cart : null,
        Status: "Completed",
        "Last Edited By": {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        },
        Assignees: [
          {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
        ],
        Owner: {
          Email: owner.email,
          Name: owner.label,
          photoURL: owner.photoURL,
          uid: owner.value,
        },
        Files: uploadedFiles,
      });
      if (isSwagRequest) {
        const lastCartRef = doc(
          db,
          "users",
          currentUser.uid,
          "Carts",
          "Last Cart"
        );
        await updateDoc(lastCartRef, {
          cart: [],
          timestamp: serverTimestamp(),
        });
      }

      toast({
        title: "Request Completed!",
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const onCompletion = async (values) => {
    try {
      const thisDocRef = doc(db, "Marketing Requests", props.docID);
      await updateDoc(thisDocRef, {
        "Request Details": values,
        Cart: cart ? cart : null,
        "Date Completed": serverTimestamp(),
        Status: "Completed",
        "Last Edited By": {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        },
      });

      toast({
        title: "Request Completed!",
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const onEdit = async (values, newStatus) => {
    try {
      const timestamp = serverTimestamp();
      const thisDocRef = doc(db, "Marketing Requests", props.docID);
      if (newStatus) {
        await updateDoc(thisDocRef, {
          "Request Details": values,
          "Date Edited": timestamp,
          Cart: cart ? cart : null,
          [`Date ${newStatus}`]: timestamp,
          "Last Edited By": {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
          Status: newStatus,
        });
      } else {
        await updateDoc(thisDocRef, {
          "Request Details": values,
          "Date Edited": timestamp,
          Cart: cart,
          "Last Edited By": {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
        });
      }

      toast({
        title: `Request Updated!`,
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const onCreate = async (values) => {
    try {
      let thisOwner;
      if (!owner) {
        thisOwner = {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        };
      } else {
        thisOwner = {
          Email: owner.email,
          Name: owner.label,
          photoURL: owner.photoURL,
          uid: owner.value,
        };
      }
      await setDoc(newDocRef, {
        "Request Details": values,
        "Date Created": serverTimestamp(),
        id: newDocRef.id,
        Cart: cart ? cart : null,
        Status:
          values["Request Type"] === "Marketing Spend" ? "Completed" : "New",
        "Last Edited By": {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        },
        Owner: thisOwner,
        Files: uploadedFiles,
      });
      if (values["Request Type"] === "Marketing Spend") {
        await updateDoc(newDocRef, {
          "Date Completed": serverTimestamp(),
          Assignees: [
            {
              Email: currentUser.email,
              Name: currentUser.displayName,
              photoURL: currentUser.photoURL,
              uid: currentUser.uid,
            },
          ],
        });
      }
      if (isSwagRequest) {
        const lastCartRef = doc(
          db,
          "users",
          currentUser.uid,
          "Carts",
          "Last Cart"
        );
        await updateDoc(lastCartRef, {
          cart: [],
          timestamp: serverTimestamp(),
        });
      }

      toast({
        title: "Request Created",
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const createMarketingSpend = async (values) => {};

  return (
    <>
      {action == "Create" && (
        <>
          {isSwagRequest ? (
            <>
              <Button
                p={"3"}
                background={"none"}
                aria-label={"Cart"}
                variant="link"
                size={"lg"}
                onClick={onOpen}
                leftIcon={
                  <>
                    <FaShoppingCart />
                    {cart && numInCart > 0 && (
                      <>
                        <Box
                          as={"span"}
                          color={"white"}
                          position={"absolute"}
                          top={"0px"}
                          left={"15px"}
                          fontSize={"0.8rem"}
                          minWidth={"20px"}
                          height={"18px"}
                          bgColor={"red"}
                          borderRadius={"3xl"}
                          zIndex={2}
                          p={"1px"}
                        >
                          {numInCart}
                        </Box>
                      </>
                    )}
                  </>
                }
              >
                Cart
              </Button>
            </>
          ) : props.isNavButton ? (
            <NavButton
              onOpen={onOpen}
              icon={FiPlus}
              name="Create Request"
              ref={createRequest}
              navSize={props.navSize}
              isChild={props.isChild}
              isFirstChild={props.isFirstChild}
              isLastChild={props.isLastChild}
            ></NavButton>
          ) : props.isDrawerButton ? (
            <DrawerButton
              onOpen={onOpen}
              icon={FiPlus}
              name="Create Request"
              ref={createRequest}
              isChild={props.isChild}
              isFirstChild={props.isFirstChild}
              isLastChild={props.isLastChild}
            ></DrawerButton>
          ) : props.useIconButton ? (
            <IconButton
              ref={createRequest}
              onClick={onOpen}
              variant="ghost"
              icon={<FiPlus />}
            ></IconButton>
          ) : (
            <Button
              ref={createRequest}
              rightIcon={<FiPlus />}
              colorScheme="teal"
              onClick={onOpen}
            >
              {action} Marketing Request
            </Button>
          )}
        </>
      )}
      {action == "Submit" && (
        <IconButton icon={<FiEdit />} colorScheme="teal" onClick={onOpen} />
      )}
      <Drawer
        isOpen={isOpen}
        placement="left"
        initialFocusRef={firstField}
        onClose={onClose}
        size={"xl"}
      >
        <DrawerOverlay />
        <Formik
          initialValues={initialValues}
          onSubmit={action == "Create" ? onCreate : onCompletion}
          validationSchema={
            action == "Create" ? createValidation : submitValidation
          }
          innerRef={formRef}
        >
          {({ handleSubmit, values }) => (
            <Box
              borderWidth={{ base: "none", md: "1px" }}
              rounded="lg"
              shadow={{ base: "none", md: "1px 1px 3px rgba(0,0,0,0.3)" }}
              maxWidth={850}
              p={{ base: "0", md: "5" }}
              m="10px auto"
              as="form"
              onSubmit={handleSubmit}
            >
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  {action == "Create" ? (
                    <>Create {isSwagRequest && "Swag "} Request</>
                  ) : (
                    <>Submit Request</>
                  )}
                </DrawerHeader>
                <DrawerBody>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent="space-between"
                    mb={7}
                  >
                    <RadioGroupControl
                      radioGroupProps={{ style: { fontSize: "40px" } }}
                      name="Request Type"
                      mb={{ base: "5", md: "0" }}
                      hidden={
                        isSwagRequest || selectedElement || selectedElementID
                      }
                    >
                      <Radio value="Design">Design</Radio>
                      <Radio value="Custom">Custom</Radio>
                      <Radio value="Open House">Open House</Radio>
                      {(SuperAdmin || MarketingAdmin) && (
                        <Radio value="Marketing Spend">Marketing Spend</Radio>
                      )}
                    </RadioGroupControl>

                    <InputControl
                      name="Blank"
                      style={{ display: "none" }}
                    ></InputControl>
                    {(MarketingAdmin || SuperAdmin) && isSwagRequest && (
                      <>
                        <Flex mt={25} flexDir={"row"} justify="space-between">
                          <FormControl w="450px" mr={5} dir="row">
                            <CreatableSelect
                              name="Credit To:"
                              options={users}
                              placeholder="Create Task As..."
                              value={owner}
                              onChange={setOwner}
                              closeMenuOnSelect={true}
                              isClearable
                            />
                          </FormControl>
                          <Button
                            disabled={!owner}
                            mr={10}
                            onClick={() => quickComplete(values)}
                          >
                            Quick Complete
                          </Button>
                        </Flex>
                      </>
                    )}
                  </Flex>

                  {values["Request Type"] == "Swag" && isSwagRequest && (
                    <>
                      {marketingSettings &&
                        marketingSettings["Task Settings"] &&
                        marketingSettings["Task Settings"][
                          "Swag Banner Text"
                        ] && (
                          <Box display={"inline-flex"} p="2">
                            <Icon
                              mt="0.5"
                              mr="1"
                              fontSize={"xl"}
                              color="red.400"
                              as={FiAlertCircle}
                            ></Icon>
                            <Text
                              fontSize="md"
                              color="red.400"
                              fontWeight={"bold"}
                            >
                              {
                                marketingSettings["Task Settings"][
                                  "Swag Banner Text"
                                ]
                              }
                            </Text>
                          </Box>
                        )}
                      <SwagRequestFields
                        values={values}
                        pendingCartsListenerRef={pendingCartsListenerRef}
                        pendingCarts={pendingCarts}
                        setDisableCreate={setDisableCreate}
                        inventory={props.inventory}
                        products={props.products}
                        action={props.action}
                        cart={cart}
                        handleSetSelectedItem={handleSetSelectedItem}
                        taskID={props.docID}
                        request={request}
                        owner={owner}
                      />
                    </>
                  )}
                  {(selectedElement || selectedElementID) && (
                    <>
                      <ElementCard
                        element={selectedElement && selectedElement}
                        elementID={!selectedElement && selectedElementID}
                        hideRequestButton
                        showHeading
                      />
                    </>
                  )}

                  {(values["Request Type"] == "Custom" ||
                    values["Request Type"] == "Design" ||
                    values["Request Type"] == "Swag") && (
                    <CustomRequestFields
                      values={values}
                      datepickerClass={datepickerClass}
                    />
                  )}
                  {values["Request Type"] == "Marketing Spend" && (
                    <MarketingSpendFields
                      values={values}
                      datepickerClass={datepickerClass}
                      categories={
                        marketingSettings?.["Marketing Spend Categories"]
                      }
                      owner={owner}
                      setOwner={setOwner}
                      quickComplete={quickComplete}
                    />
                  )}
                  {values["Request Type"] == "Event" && (
                    <EventRequestFields
                      values={values}
                      datepickerClass={datepickerClass}
                    />
                  )}
                  {values["Request Type"] == "Open House" && (
                    <OpenHouseRequestFields
                      values={values}
                      datepickerClass={datepickerClass}
                      setEventDate={setEventDate}
                    />
                  )}
                  {values["Request Type"] != "" && (
                    <>
                      <ScaleFade in initialScale={0.8}>
                        <FormControl mt={5} mb={5}>
                          <FormLabel htmlFor="fileUpload">
                            Upload Files
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <Icon as={FiFile} />
                            </InputLeftElement>
                            <input
                              type="file"
                              onChange={onFileChange}
                              multiple="multiple"
                              name={"Upload Files"}
                              ref={inputRef}
                              style={{ display: "none" }}
                            />
                            <Input
                              placeholder={"Click to upload files..."}
                              onClick={() => inputRef.current.click()}
                              // onChange={(e) => {}}
                              readOnly={true}
                            />
                          </InputGroup>
                        </FormControl>
                      </ScaleFade>
                      {uploadedFiles.length > 0 ? (
                        <>
                          <Wrap justify={"flex-start"} flexWrap mb={2}>
                            {uploadedFiles.map((file, i) => {
                              return (
                                <WrapItem
                                  backgroundColor={fileBackgroundColor}
                                  borderRadius="lg"
                                  p={0.5}
                                >
                                  <Center ml={2}>
                                    <a href={file.url} target="_blank">
                                      {file.name}
                                    </a>
                                    <Button
                                      ml="5"
                                      id={`attachment-${i}`}
                                      variant="ghost"
                                      onClick={() => removeAttachment(i)}
                                    >
                                      X
                                    </Button>
                                  </Center>
                                </WrapItem>
                              );
                            })}
                          </Wrap>
                        </>
                      ) : (
                        <Text>Files you upload will appear here</Text>
                      )}
                      {uploading && <Spinner size="lg" />}
                    </>
                  )}
                </DrawerBody>
                <DrawerFooter borderTopWidth="1px">
                  {action == "Create" ? (
                    <>
                      <ResetButton mr={10}>Reset</ResetButton>
                      <SubmitButton
                        disabled={
                          uploading ||
                          disableCreate.includes(true) ||
                          (values["Request Type"] === "Marketing Spend" &&
                            !owner)
                        }
                        alignSelf={"flex-end"}
                      >
                        Create
                      </SubmitButton>
                    </>
                  ) : (
                    <>
                      <Button mr={10} onClick={() => onEdit(values)}>
                        Save
                      </Button>
                      {props.status != "In Progress" &&
                        (SuperAdmin || MarketingAdmin) && (
                          <Button
                            onClick={() => onEdit(values, "In Progress")}
                            colorScheme={"orange"}
                            alignSelf={"flex-end"}
                          >
                            Move To In Progress
                          </Button>
                        )}
                      {props.status != "Completed" &&
                        (SuperAdmin || MarketingAdmin) && (
                          <SubmitButton alignSelf={"flex-end"}>
                            Mark Completed
                          </SubmitButton>
                        )}
                    </>
                  )}
                </DrawerFooter>
              </DrawerContent>
            </Box>
          )}
        </Formik>
      </Drawer>
    </>
  );
});
export default CreateRequest;
