import { useEffect, useState } from "react";
import Timestamp from "../../../components/Timestamp";
import { doc, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../utils/init-firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { HStack, IconButton } from "@chakra-ui/react";
import { FiRefreshCcw } from "react-icons/fi";


export default function RateScenariosTimestamp( { } ){
    const docRef = doc(db, 'Rate Scenarios Timestamp', 'Rate Scenarios Timestamp')
    const [time, setTime] = useState()
    const { SuperAdmin } = useAuth()

    useEffect(() => {
        const unsub = onSnapshot(docRef, (doc) => {
            if(doc.exists()){
                setTime(doc.data().Timestamp ? doc.data().Timestamp : null)
            }
        });

        return () => unsub()
    }, [])

    const handleUpdateTimestamp = async () => {
        if(!time || time == undefined){
            await setDoc(docRef, {
                'Timestamp': serverTimestamp()
            })
        }
        else{
            await updateDoc(docRef, {
                'Timestamp': serverTimestamp()
                }
            )
        }
    }

    return(
        <HStack ml='3' mt={'-2'} pt='0'>
            {time &&
                <Timestamp 
                    timestamp={time}
                    annotation={'Updated '}
                />
            }
            {SuperAdmin &&
                <IconButton size='xs' variant='ghost' icon={<FiRefreshCcw/>} color={'green'} onClick={handleUpdateTimestamp} />}
        </HStack>
     
    )
}