// Chakra imports
import {
    Flex,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    useColorModeValue,
    Link,
    Icon
  } from "@chakra-ui/react";
import { BiRightArrow } from "react-icons/bi";
import { FiArrowRight } from "react-icons/fi";
import { useHistory } from "react-router-dom";
  // Custom components
  
  const StatisticCard = ({ label, number, average, link }) => {
    const iconTeal = useColorModeValue("teal.300", "teal.300");
    const textColor = useColorModeValue("gray.700", "white");
    const history = useHistory()
    return (

          <Flex boxShadow='md' borderRadius='lg' minH='83px' p='4' pb='0' flexDirection='row' align='center' justify='center' w='100%'>
            <Stat me='auto'>
              <StatLabel
                fontSize='sm'
                color='gray.400'
                fontWeight='bold'
                pb='.1rem'>
                {label}
              </StatLabel>
              <Flex flexDir={'column'}>
                <StatNumber  fontSize='xl' color={textColor}>
                  {number}
                </StatNumber>
                {average &&
                <StatHelpText fontWeight='bold' color='gray.500'>
                  AVG {average}
                </StatHelpText>}
                {link &&
                <Link 
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  color='gray.500'
                  onClick={() => history.push(link?.route)}>
                    {link?.label}
                    <Icon ml='0.5' pb='0.5' verticalAlign={'middle'} as={FiArrowRight}></Icon>
                </Link>
                }
              </Flex>
            </Stat>
    
          </Flex>
    );
  };
  
  export default StatisticCard;
  