import { useState, useEffect } from 'react';
import { query, collection, addDoc, onSnapshot, doc, updateDoc, deleteDoc, serverTimestamp, orderBy } from 'firebase/firestore';
import { db } from '../../../utils/init-firebase';

export function useTopics() {
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, 'topics'), orderBy('order')), (snapshot) => {
      const updatedTopics = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(updatedTopics)
      setTopics(updatedTopics);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log(topics)
  }, [topics])

  const handleReorderTopics = async () => {
    topics.forEach(async (topic, i) => {
      const topicRef = doc(db, 'topics', topic.id);
      await updateDoc(topicRef, {
        order: i
      });
    })
  }

  const createTopic = async (title) => {
    await addDoc(collection(db, 'topics'), {
      title,
      createdAt: serverTimestamp(),
      order: topics ? topics.length : 0
    });
  };

  const updateTopic = async (data, topicID) => {
    const topicRef = doc(db, 'topics', topicID);
    await updateDoc(topicRef, data);
  };

  const deleteTopic = async (topic) => {
    const topicRef = doc(db, 'topics', topic.id);
    await deleteDoc(topicRef);
  };

  return {  topics, 
            setTopics, 
            handleReorderTopics, 
            isLoading, 
            createTopic, 
            updateTopic, 
            deleteTopic };
}
