import { useEffect, useState } from "react";
import {
    Spacer,
    Box,
    Heading,
    Text,
    Badge,
    Divider,
    Avatar,
    HStack,
    Tag,
    TagRightIcon,
    TagLabel,
    TagLeftIcon,
    useColorModeValue,
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    Tooltip,
    Stack,
    Flex,
    AvatarGroup,
    AvatarBadge,
    WrapItem,
    useToast,
    Switch,
    FormControl,
    FormLabel,
    VStack,
    Button,
    Spinner
} from "@chakra-ui/react";
import Timer from "../Timer";
import Linkify from 'react-linkify';
import { FiXCircle, FiCheckCircle, FiHome, FiRotateCcw, FiUser, FiPhone, FiMail, FiClock, FiDollarSign, FiHash, FiLock, FiTrendingUp, FiMapPin, FiX, FiCornerDownRight, FiShoppingBag, FiBarChart2 } from "react-icons/fi";
import { collection, collectionGroup, query, onSnapshot, doc, serverTimestamp, orderBy, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { BsPiggyBank, BsShieldCheck } from "react-icons/bs"
import { RiSwordLine } from 'react-icons/ri'
import { FaUser, FaScroll, FaRegCalendarCheck, FaCheckCircle, FaQuestionCircle } from "react-icons/fa";
import { BiBuildingHouse } from 'react-icons/bi'
import AssignClaimClient from "./AssignClaimClient";
import AssignClaimClientCSC from "./AssignClaimClientCSC";
import AssignClaimClientTC from "./AssignClaimClientTC";
import SubmissionDrawer from "../../pages/Loans/SubmissionDrawer";
import moment from "moment";
import GroupChat from "./GroupChat";
import EditLog from "./EditLog";
import LoanTimestamps from "./LoanTimestamps";
import { useAuth } from "../../contexts/AuthContext";
import AssignClaimClientLO from "./AssignClaimClientLO";
import AdminIconIndicator from "../AdminIconIndicator";
import ClientSettings from "./ClientSettings";
import CreateRCEDoc from "../DocumentGeneration/CreateRCEDoc.jsx";
import DocumentLogs from "../DocumentGeneration/DocumentLogs";
import AssignClaimClientLOA from "./AssignClaimClientLOA";
import { AiFillWarning } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import AssigneesAvatarGroup from "../Marketing/AssigneesAvatarGroup";
import CreateEscalationDrawer from "../../pages/Escalations/components/CreateEscalationDrawer";
import BooleanTag from "../BooleanTag";
import ParagraphWithHeader from "./ParagraphWithHeader";

export default function ClientBox({ escalations, client, loanDetails, loanOfficer, lenderConcierge, docID, status, dateClaimed, dateAdded }) {
const toast = useToast()
const history = useHistory()

const tcColor = useColorModeValue('gray.600', 'gray.400')
const reviewBGColor = useColorModeValue('orange.200', 'orange.400')
const reviewedBGColor = useColorModeValue('green.200', 'green.400')
const dueInMinutes = 60
const [notesList, setNotesList] = useState([])
const [editLog, setEditLog] = useState([])
const [docLogs, setDocLogs] = useState([])

const [printedLogCount, setPrintedLogCount] = useState(0)
const notesRef = collection(db, 'Clients', client.id, 'Notes')
const notesForReviewRef = collection(db, 'Clients', client.id, 'Notes For Review')
const docLogRef = collection(db, 'Clients', client.id, 'Document Logs')

const editLogRef = collection(db, 'Clients', client.id, 'Edit Log')
const { isAdmin, SuperAdmin, currentUser, lenders } = useAuth()

const [lenderRelational, setLenderRelational] = useState()

// if there is a lenderID, find the lender name and set it in state. Fallback to Lender as a string
useEffect(() => {
    const findLenderName = () => {
      if (client?.['Loan Details']?.['lenderID'] && lenders) {
        const foundLender = lenders.find(
          (lender) => lender.id === client['Loan Details']['lenderID']
        );
  
        if (foundLender) {
          return foundLender.name;
        }
      }
      if (client?.['Loan Details']?.['Target Lender'] && lenders) {
        const foundLender = lenders.find(
          (lender) => lender.id === client['Loan Details']['Target Lender']
        );
  
        if (foundLender) {
          return foundLender.name;
        }
      }
      if (client?.['Loan Details']?.['Other Lender'] && lenders) {
        const foundLender = lenders.find(
          (lender) => lender.id === client['Loan Details']['Other Lender']
        );
  
        if (foundLender) {
          return foundLender.name;
        }
      }
  
      return client?.['Loan Details']?.['Lender'];
    };
  
    setLenderRelational(findLenderName());
  }, [client, lenders]); // Include client and lenders in the dependency array
  


const [topics, setTopics] = useState()
const [subtopics, setSubtopics] = useState()
const [loadingTopics, setLoadingTopics] = useState(true)

const incrementPrintedLogCount = () => {
    setPrintedLogCount(printedLogCount + 1)
}

const handleGetEscalations = () => {
    let promises = []
    promises.push(getTopics(), getSubtopics())

    Promise.all(promises).then(([topicz, subtopicz]) => {
        setTopics(topicz)
        setSubtopics(subtopicz)
    })
    .finally( setLoadingTopics(false) )
}

const getTopics = async () => {
    let topics = []
    const querySnapshot = await getDocs(collection(db, 'topics'));
    querySnapshot.forEach((doc) => {
        console.log(doc.data())
        topics.push({
            id: doc.id, 
            ...doc.data()
        })
    });
    return topics
}

const getSubtopics = async () => {
    let subtopics = []
    const q = query(collectionGroup(db, "subtopics"));
    const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            subtopics.push({
                id: doc.id, 
                parentID: doc.ref.parent.path.substring(
                    doc.ref.parent.path.indexOf("/") + 1, 
                    doc.ref.parent.path.lastIndexOf("/")
                ), 
                ...doc.data()
            })
    });
    return subtopics
}

const findTopic = (id) => {
    if(topics && id){
        let index = topics.findIndex(obj => obj.id === id)
        if(index > -1){
            return topics[index].title
        }
        else return 'Topic Not Found'
    }
}

const findSubtopic = (id) => {
    if(subtopics && id){
        let index = subtopics.findIndex(obj => obj.id === id)
        if(index > -1){
            return subtopics[index].title
        }
        else return 'Subtopic Not Found'
    }
}

useEffect( async () => {
    try{ 
        const queryLog = query(editLogRef, orderBy("timestamp", 'desc'));
        const unsubLog = onSnapshot(queryLog, (querySnapshot) => {
            const log = []
            querySnapshot.forEach((doc) => {
                log.push({
                    ...doc.data(), 
                    id: doc.data().id
                })
            })
            setEditLog(log)
        });

        const queryDocLog = query(docLogRef, orderBy("timestamp", 'desc'));
        const unsubDocLog = onSnapshot(queryDocLog, (querySnapshot) => {
            const log = []
            querySnapshot.forEach((doc) => {
                log.push(doc.data())
            })
            setDocLogs(log)
        });
        
        return () => {
            unsubLog();
            unsubDocLog()
            setEditLog([])
        };  

          
    }
    catch(err){
        console.log(err)
    }
}, [])


const toggleClientSetting = async (e, label) => {
    await updateDoc(doc(db, 'Clients', docID), {
        [label]: e.target.checked,
        [`Date ${label}`]: serverTimestamp(),
        ['Has Notes For Review']: true
    })
}
  return (
      
    <Box width={{
        base: '100%',
        xl: '40%'
        }}  m={0} p={{base: '2', lg: '4'}} borderWidth={'1px'} borderRadius='lg'>
        <Flex direction='row' mb='5'>
            <Avatar size="md" src={loanOfficer.photoURL} />
            <Heading alignSelf='center' mr='9' fontSize={'sm'} textAlign='left' ml={'1'} fontFamily={'body'} whiteSpace='pre-wrap'>
                {loanOfficer.Name.split(' ').join('\n')}
            </Heading>
            <Divider orientation="vertical" />
      {/*       <VStack>
                <Heading size='sm'>{lenderConcierge['Name']}</Heading>
                {client['CSC'] && 
                  <Heading size='sm'>{client['CSC'].Name}</Heading>
                }
            </VStack> */}
            <AvatarGroup size="sm">
                {client['LOA'] && client['LOA'].uid &&
                <Tooltip placement='top' label={`${client['LOA'].Name}`}>
                    <Avatar src={client['LOA'].photoURL}>
                    <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='orange.400'>LOA</AvatarBadge>

                    </Avatar>
                </Tooltip>
                }
                <Tooltip placement='top' label={`${lenderConcierge.Name}`}>
                    <Avatar src={lenderConcierge.photoURL}>
                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='green.500'>LC</AvatarBadge>
                    </Avatar>
                </Tooltip>

                {client['CSC'] && client['CSC'].uid &&
                <Tooltip placement='top' label={`${client['CSC'].Name}`}>
                    <Avatar src={client['CSC'].photoURL}>
                    <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='blue.400'>CSC</AvatarBadge>

                    </Avatar>
                </Tooltip>
                }
                {client['TC'] && client['TC'].uid &&
                <Tooltip placement='top' label={`${client['TC'].Name}`}>
                    <Avatar src={client['TC'].photoURL}>
                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='purple.400'>TC</AvatarBadge>
                    </Avatar>
                </Tooltip>
                }
            </AvatarGroup>
            <Spacer/>
            {(status == 'In Progress' || status == 'Parked' || isAdmin)  && 
                <SubmissionDrawer 
                    docID={docID} 
                    initialValues={loanDetails}
                    status={status}
                    action={'Submit'}
                />
            }
        </Flex>
   
        <HStack mb='2' >
        {loanDetails['Purchase or Refi'] != 'Custom' &&
            <><FaUser/><Heading textTransform='uppercase' size='xs'> {loanDetails['First Name'] + ' ' + loanDetails['Last Name']}</Heading></>
         }
        {loanDetails['Location'] && loanDetails['Location'] != '' &&
            <Tag size='md' variant='ghost' colorScheme={'gray'}  textTransform='uppercase' fontWeight='bold'>
                <TagLeftIcon mr='0.5' as={FiMapPin} />
                <TagLabel>{loanDetails['Location']}</TagLabel>
            </Tag> 
        }
            <Badge 
                mr={2} 
                colorScheme={
                    status == 'Completed'? 'green' : 
                    status == 'In Progress' ? 'orange' : 
                    status == 'Not Submitted' ? 'purple' : 
                    status == 'TBD' ? 'blue' :
                                'yellow'
                            }>
                {status}
            </Badge>   
   
 
                {dateAdded && status=='In Progress' ?
                <>
        {/*         {dateClaimed.seconds && <CountdownTimer end={dateClaimed.seconds*1000 + (dueInMinutes*60000)}/> }*/}   
                    {dateAdded.seconds && <Timer start={dateAdded.seconds} warningInMinutes={60}/> }
                </>
                : null}
        </HStack>
        {client['Reason Parked'] && client['Reason Parked'] != '' &&
            <Text textTransform='uppercase' fontWeight='bold' fontSize={'small'} textAlign={'left'} overflowWrap={'break-word'}>
              Reason Parked: <Text as={'span'} color='gray'>{client['Reason Parked']}</Text>
              </Text>
        }
        {client['Reason Not Submitted'] && client['Reason Not Submitted'] != '' &&
            <Text textTransform='uppercase' fontWeight='bold' fontSize={'small'} textAlign={'left'} overflowWrap={'break-word'}>
              Reason Not Submitted: <Text as={'span'} color='gray'>{client['Reason Not Submitted']}</Text>
              </Text>
          }
        {client['Flag For Review'] && !client['Coaching Completed'] && SuperAdmin && 
             <Badge 
                mr={2} 
                mb={2}
                colorScheme={'orange'}>
                NEEDS REVIEW
            </Badge>  
            }
         {client['Coaching Completed'] && SuperAdmin && 
             <Badge 
                mr={2} 
                mb={2}
                colorScheme={'green'}>
                COACHING COMPLETED
            </Badge>  
            }
        <LoanTimestamps client={client} />
        {status == 'Completed' && loanDetails['Purchase or Refi'] != 'Custom' &&
            <HStack mb='3' >
                <Stack direction={{base: 'column', md: 'row'}}>
                    <Tag size='lg' variant='outline' colorScheme={'green'} textTransform='uppercase' fontWeight='bold'>
                        <TagLeftIcon as={BsPiggyBank} />
                        <TagLabel fontSize="14px">{lenderRelational && lenderRelational}</TagLabel>
                        <TagRightIcon as={FiCheckCircle} />
                    </Tag> 
                    <Tooltip label='Loan Number' colorScheme={'green'} placement='top'>
                    <Tag  size='lg' variant='outline' textTransform='uppercase' fontWeight='bold'>
                        <TagLeftIcon as={FiHash} />
                        <TagLabel fontSize="14px">{loanDetails['Loan #']}</TagLabel>
                    </Tag> 
                    </Tooltip>
                </Stack>
                <Stack direction={{base: 'column', md: 'row'}}>
                    {loanDetails['HOI Quote/Amount'] &&
                    <Tooltip label='HOI Quote/Amount' colorScheme={'green'} placement='top'>
                            <Tag  size='lg' variant='outline' textTransform='uppercase' fontWeight='bold'>
                                <TagLeftIcon as={BsShieldCheck} />
                                <TagLabel fontSize="14px">${loanDetails['HOI Quote/Amount'].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TagLabel>
                            </Tag> 
                        </Tooltip>
                    }
                    {loanDetails['Rate'] &&
                    <Tooltip label={`Rate - ${loanDetails['Floated or Locked']}`} placement='top'>
                        {loanDetails['Floated or Locked'] == 'Locked' ? 
                        
                            <Tag size='lg' variant='outline' colorScheme={'green'} fontWeight='bold' textTransform='uppercase'>
                              <TagLeftIcon as={FiLock} />
                              <TagLabel fontSize="14px">{loanDetails['Rate']}%</TagLabel>
                            </Tag> 
                            : 
                            <Tag size='lg' variant='outline' colorScheme={'orange'} fontWeight='bold' textTransform='uppercase'>
                                <TagLeftIcon as={FiTrendingUp} />
                                <TagLabel fontSize="14px">{loanDetails['Rate']}%</TagLabel>
                            </Tag> 

                        }
                       
                    </Tooltip>
                        
                    }
                </Stack>

            </HStack>
        }
        {loanDetails['Purchase or Refi'] == 'Custom' && loanDetails['Description'] &&
            <Text>{loanDetails['Description']}</Text>
        }
        {loanDetails['Purchase or Refi'] != 'Custom' &&
        <>
        <HStack mb='3' >
            <Stack direction={{base: 'column', md: 'row'}}>
                <Tag  size='lg' variant='outline' colorScheme='telegram' textTransform='uppercase' fontWeight='bold'>
                    {loanDetails['Purchase or Refi'] === 'Purchase' || loanDetails['Purchase or Refi'] === 'HELOC' ? 
                        <TagLeftIcon as={FiHome} />
                        :
                        loanDetails['Purchase or Refi'] === 'Refi' ?
                        <TagLeftIcon as={FiRotateCcw} /> 
                        :
                        <TagLeftIcon as={FaQuestionCircle} />

                        }
                    <TagLabel fontSize="14px">{loanDetails['Purchase or Refi']}</TagLabel>
                </Tag> 
                {lenderRelational ? 
                        <Tag size='lg' variant='outline' textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={BsPiggyBank} />
                            <TagLabel fontSize="14px">{lenderRelational}</TagLabel>
                        </Tag> 
                    :
                    <>
                     {loanDetails['Target Lender'] !== 'Other' ?
                        <Tag size='lg' variant='outline' textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={BsPiggyBank} />
                            <TagLabel fontSize="14px">{loanDetails['Target Lender']}</TagLabel>
                        </Tag> 
                        : 
                        <Tag  size='lg' variant='outline' textTransform='uppercase' fontWeight='bold'>
                            <TagLeftIcon as={BsPiggyBank} />
                            <TagLabel fontSize="14px">{loanDetails['Other Lender']}</TagLabel>
                        </Tag> 
                        }
                    </>
                }
               
             </Stack>
             <Stack direction={{base: 'column', md: 'row'}}>
                {loanDetails['Loan Amount'] &&
                    <Tag  size='lg' variant='outline' colorScheme='green' textTransform='uppercase' fontWeight='bold'>
                        <TagLeftIcon as={FiDollarSign} />
                        <TagLabel fontSize="14px">{loanDetails['Loan Amount'].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TagLabel>
                    </Tag> 
                }
                {loanDetails['Closing Date'] &&
                <Tooltip label='Closing Date' placement='top'>
                    <Tag  size='lg' variant='outline' textTransform='uppercase'>
                        <TagLeftIcon as={FaRegCalendarCheck} />
                        <TagLabel fontSize="14px">{moment(loanDetails['Closing Date'].seconds*1000).format('MMMM D')}</TagLabel>
                    </Tag> 
                </Tooltip>
                    
                }
            </Stack>

        </HStack>
        <HStack mb='3' >
            <Stack direction={{base: 'column', md: 'row'}}>
                <HStack>
                {loanDetails['Texas Refi Conversion'] && loanDetails['Texas Refi Conversion'] != 'Neither' &&
                    <Tag size='md' variant='subtle' colorScheme='gray' >
                        <TagLeftIcon as={FiCornerDownRight} />
                        <TagLabel fontSize="14px">{loanDetails['Texas Refi Conversion']}</TagLabel>
                    </Tag> 
                }
                {loanDetails['Property Type'] &&
                    <Tag size='md' variant='subtle' colorScheme='telegram' >
                        <TagLeftIcon as={BiBuildingHouse} />
                        <TagLabel fontSize="14px">{loanDetails['Property Type']}</TagLabel>
                    </Tag> 
                }
                {loanDetails['Credit Score'] && 
                    <HStack>
                        <Tag size='md' colorScheme={'blackAlpha'}>
                            <TagLeftIcon as={FiBarChart2} />
                            <TagLabel>Credit Score: {loanDetails['Credit Score']}</TagLabel>
                        </Tag>
                    </HStack>
                }
                {loanDetails['Purchase or Refi'] == 'Purchase' && loanDetails['New Construction'] &&
                    <BooleanTag hideFalse label='New Construction' value={loanDetails['New Construction']}/>
                }

                </HStack>
                {loanDetails['First-Time Home Buyer'] &&
                    <BooleanTag hideFalse label='First-Time Home Buyer' value={loanDetails['First-Time Home Buyer']}/>
                }
            </Stack>

        </HStack>
        <HStack mb='2'>
            {loanDetails['Loan Type'] !== 'Other/Non-QM' ?
                <Badge borderRadius='full' px='2' colorScheme='teal'>
                    {loanDetails['Loan Type']}
                </Badge>
            :
                <Badge borderRadius='full' px='2' colorScheme='teal'>
                    {loanDetails['Other Loan Type']}
                </Badge>
            }
            <Text>
                {loanDetails['Target Rate']}%
            </Text>
            {loanDetails['LP or BP'] &&
                <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='xs'
                    textTransform='uppercase'
                    ml='2'
                    >
                    {loanDetails['LP or BP'] === 'LP' ?
                        'Lender Paid'
                    : 'Borrower Paid'}
                </Box>}
            {loanDetails['Mail Away/POA'] && 
                <BooleanTag hideFalse label='Mail Away/POA' value={loanDetails['Mail Away/POA']}/>
            }
        </HStack>
        <HStack mb='1'>
            <Stack direction={{base: 'column', md: 'row'}}>
                <HStack>
                    {loanDetails['Term'] && 
                        <>
                            <FiClock />
                            <Text 
                            fontWeight='semibold'
                            letterSpacing='wide'
                            fontSize='sm'
                            textTransform='uppercase'
                            >
                            {loanDetails['Term']}
                            </Text>
                        </>
                    }
                    {loanDetails['Waive UW Fee'] &&
                        <BooleanTag label='Waive Underwriting Fee' value={loanDetails['Waive UW Fee']}/>
                    }
                </HStack>
                {loanDetails['Order Appraisal'] === 'No' &&
                    <BooleanTag label='Order Appraisal' value={loanDetails['Order Appraisal']}/>
                }
            </Stack>
        </HStack>
        <HStack>
        {loanDetails['POS Docs Received'] &&
            <BooleanTag label='POS Docs Received' value={loanDetails['POS Docs Received']}/>
        }
        {loanDetails['Cost for Credit Report'] && 
            <HStack>
                <Tag size='md' colorScheme={'gray'}>
                    <TagLeftIcon as={FiDollarSign}/>
                    <TagLabel>Cost for Credit Report: {loanDetails['Cost for Credit Report']}</TagLabel>
                </Tag>
            </HStack>
        }
        </HStack>
        <HStack mb='2'>
        {loanDetails['Community Property State'] === 'Yes' &&
            <>
            <HStack>
                <Tag size='md'  colorScheme='green'>
                    <TagLabel>Community Property State</TagLabel>
                    <TagRightIcon as={FiCheckCircle} />
                </Tag> 
            </HStack>
            {loanDetails["NBS Liabilities Accounted For"] &&
                <BooleanTag value={loanDetails["NBS Liabilities Accounted For"]} label='NBS Liabilities Accounted For'/>}
            </>
        }

        </HStack>
        <HStack mb='2'>
        {loanDetails['Are Escrows Being Waived?'] === 'Yes' &&
            <HStack>
                <Tag size='md'  colorScheme='green'>
                    <TagLabel>Escrows Waived: {loanDetails['Escrows Waived'] && loanDetails['Escrows Waived']}</TagLabel>
                </Tag> 
            </HStack>
        }

        </HStack>
        <HStack mb='2'>
            {loanDetails['Priced Loan Today'] && loanDetails['Priced Loan Today'] != '' &&
                <BooleanTag value={loanDetails["Priced Loan Today"]} label='Priced Loan Today'/>
            }
            {loanDetails['Ownership/Vesting Changing'] && loanDetails['Ownership/Vesting Changing'] == 'Yes' &&
            <Tag size='md'  colorScheme={'green'}>
                <TagLabel>Ownership/Vesting Changing</TagLabel>
                <TagRightIcon as={FiCheckCircle} />
            </Tag> 
            }
        </HStack>
        {loanDetails['Ownership/Vesting Changing'] && loanDetails['Ownership/Vesting Changing'] == 'Yes' &&
            <Tag size='md' colorScheme={'yellow'} mb={2}>
                <TagLeftIcon as={AiFillWarning}></TagLeftIcon>
                <TagLabel overflowWrap={'normal'}>Changes to Ownership/Vesting will usually incur higher Title costs.<br></br> Please ensure these higher costs have been accounted for</TagLabel>
            </Tag> 
        }  
           {loanDetails['Opt Out Of SimplyIOA Quote'] && loanDetails['HOI Agent'] && loanDetails['HOI Agent'] != '' &&
                <>
                    <Tag size='md'  colorScheme='red' mb={2}>
                        <TagLabel> Please do not proactively request an HOI quote for this client</TagLabel>
                    </Tag> 
                    <Box
                        mb='2'
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='sm'
                        textTransform='uppercase'   
                        > HOI Agent: {loanDetails['HOI Agent']}    
                    </Box>
                </>
            }  
        {loanDetails['Opt Out Of HOI Quote'] && loanDetails['HOI Agent'] && loanDetails['HOI Agent'] != '' &&
                <>
                    <Tag size='md'  colorScheme='red' mb={2}>
                        <TagLabel> Please do not proactively request an HOI quote for this client</TagLabel>
                    </Tag> 
                    <Box
                        mb='2'
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='sm'
                        textTransform='uppercase'   
                        > HOI Agent: {loanDetails['HOI Agent']}    
                    </Box>
                </>
            }  
            <HStack mb='2'>
            {loanDetails['Purchase or Refi'] === 'Purchase' ?
            <>
                <Box
                color='gray.500'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='sm'
                textTransform='uppercase'
                >
                     <>
                    Down Payment: {loanDetails['Down Payment %']}%
                    </>
                    </Box>             
                </>
            :
            <>
                <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'
                    >
                        Loan Purpose:
                        </Box>
                        {loanDetails['Loan Purpose'] === 'Cash Out' ?
                                <Box
                                color='gray.500'
                                fontWeight='semibold'
                                letterSpacing='wide'
                                fontSize='sm'
                                textTransform='uppercase'
                                >{loanDetails['Loan Purpose']} ${loanDetails['Cash Out Amount']}</Box>
                                :
                            <Box
                                color='gray.500'
                                fontWeight='semibold'
                                letterSpacing='wide'
                                fontSize='sm'
                                textTransform='uppercase'
                                >{loanDetails['Loan Purpose']}</Box>
                            }
                </>
            }
            </HStack>
            <HStack mb='2'>
{/*             {loanDetails['Cash to Close'] &&
             <>
                <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'   
                    > Cash to Close:    
                </Box>
                    {loanDetails['Cash to Close'] === 'Bring' ?
                        <Box
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='sm'
                        textTransform='uppercase'
                        >{loanDetails['Cash to Close']} ${loanDetails['Cash to Close Amount']}</Box>
                        :
                        <Box
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='sm'
                        textTransform='uppercase'
                        >{loanDetails['Cash to Close']}</Box>
                    }
                </>
            } */}
                {loanDetails['Anticipated Funds to Close'] &&
                    <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'
                    >Anticipated Funds to Close: ${loanDetails['Anticipated Funds to Close']}</Box>
                }
            </HStack>
            {loanDetails['Comp Code'] &&
                    <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'
                    >Comp Code: {loanDetails['Comp Code']}</Box>
                }
            {loanDetails['Product'] &&
                    <Flex mt='1'>
                        <Tag size='md' variant='outline' colorScheme='blue' >
                            <TagLeftIcon as={FiShoppingBag} />
                            <TagLabel fontSize="14px">{loanDetails['Product']}</TagLabel>
                        </Tag> 
                    </Flex>
                }
            {loanDetails['Loan Key/Cardinal'] &&
                <Box
                    mb='2'
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'   
                    > Loan Key/Cardinal: {loanDetails['Loan Key/Cardinal']}    
                </Box>
            }

            {loanDetails['Loan Key/Cardinal'] &&
                <Box
                    mb='2'
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='sm'
                    textTransform='uppercase'   
                    > Loan Key/Cardinal: {loanDetails['Loan Key/Cardinal']}    
                </Box>
            }
            {loanDetails["Title Retired"] && loanDetails["Title Retired"] != "" &&
                <Flex flexDir={'column'} justify='flex-start'>
                    <HStack>
                        <BooleanTag value={loanDetails["Title Retired"]} label='Title Retired'/>
                        {loanDetails["Title Allowed To Be Retired After Closing"] && 
                         loanDetails["Title Allowed To Be Retired After Closing"] != "" &&
                         loanDetails["Title Retired"] == "No" &&
                            <BooleanTag value={loanDetails["Title Allowed To Be Retired After Closing"]} label='Title Allowed To Be Retired After Closing'/>
                        }
                    </HStack>
                    {loanDetails["Title Allowed To Be Retired After Closing"] && loanDetails["Title Allowed To Be Retired After Closing"] == "No" && loanDetails["Title Retired"] == "No" &&
                        <Text fontWeight={'bold'} as='em' fontSize='sm'>This will need to be addressed as not all lenders allow this to happen post closing</Text>
                    }
                </Flex>
               
            }
            
            {loanDetails["Accounted for Seller's Previous Recording Date"] && loanDetails["Accounted for Seller's Previous Recording Date"] != "" &&
                <BooleanTag value={loanDetails["Accounted for Seller's Previous Recording Date"]} label='Accounted for Seller`s Previous Recording Date'/>
            }
            {loanDetails["Subject to FHA Anti-Flipping Rules"] && loanDetails["Subject to FHA Anti-Flipping Rules"] != "" &&
                <BooleanTag value={loanDetails["Subject to FHA Anti-Flipping Rules"]} label='Subject to FHA Anti-Flipping Rules'/>
            }
  
            {/*   {loanDetails['File Location'] &&
            <Box
                mb='2'
                color='gray.500'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='sm'
                textTransform='uppercase'   
                > File Location: {loanDetails['File Location']}    
            </Box>
            }
          <HStack mb='5' >
  
                {loanDetails['Credit Valid Until'] &&
                <Tooltip label='Credit Valid Until' placement='top'>
                        <Tag  size='lg' variant='outline' textTransform='uppercase'>
                            <TagLeftIcon as={FaCreditCard} />
                            <TagLabel fontSize="14px">{moment(loanDetails['Credit Valid Until'].seconds*1000).format('MMMM D')}</TagLabel>
                        </Tag> 
                    </Tooltip>
                }
                {loanDetails['Deadline for HOI'] &&
                <Tooltip label='Deadling for HOI' placement='top'>
                        <Tag  size='lg' variant='outline' textTransform='uppercase'>
                            <TagLeftIcon as={BsShieldCheck} />
                            <TagLabel fontSize="14px">{moment(loanDetails['Deadline for HOI'].seconds*1000).format('MMMM D')}</TagLabel>
                        </Tag> 
                    </Tooltip>
                }
             </HStack> */}

                {loanDetails["Asked the LO about an Exact Rate"] &&
                    <BooleanTag label='Asked the LO about an Exact Rate' value={loanDetails["Asked the LO about an Exact Rate"]} />
                }
             {loanDetails['Notes'] && loanDetails['Notes'] != '' &&
                <>
                      <ParagraphWithHeader 
                        content={loanDetails['Notes']}
                        header={'Loan Notes'}
                        photoURL={client['File Creator'] ? client['File Creator']['photoURL'] ? client['File Creator']['photoURL'] : null : null}
                    />
                </>
                }
            {loanDetails['Loan Narrative'] && loanDetails['Loan Narrative'] != '' &&
                <>
                    <ParagraphWithHeader 
                        content={loanDetails['Loan Narrative']}
                        header={'Loan Narrative'}
                        //photoURL={client['File Creator'] ? client['File Creator']['photoURL'] ? client['File Creator']['photoURL'] : null : null}
                    />
                </>
            }
            {loanDetails['Assets and Income'] && loanDetails['Assets and Income'] != '' &&
                <>
                    <ParagraphWithHeader 
                        content={loanDetails['Assets and Income']}
                        header={'Assets and Income'}
                        //photoURL={client['File Creator'] ? client['File Creator']['photoURL'] ? client['File Creator']['photoURL'] : null : null}
                    />
                </>
            }
            {loanDetails['Gotchas'] && loanDetails['Gotchas'] != '' &&
                <>
                    <ParagraphWithHeader 
                        content={loanDetails['Gotchas']}
                        header={'Gotchas'}
                        //photoURL={client['File Creator'] ? client['File Creator']['photoURL'] ? client['File Creator']['photoURL'] : null : null}
                    />
                </>
            }
              <br></br>  
              {loanDetails["W2 Income"] &&
                <>
                <BooleanTag value={loanDetails['W2 Income']} label={'W2 Income'} />
                {loanDetails["VOE fee disclosed"] &&
                    <BooleanTag value={loanDetails['W2 Income']} label={'W2 Income'} />
                }
                </>
            }
               
              {loanDetails['What POS Docs have been received'] && 
                <>
                <Heading size='xs' textTransform={'uppercase'} mb='1'  color={tcColor}>What POS Docs have been received?</Heading> 
                <Divider/>
                <Box mb={'1'} p={2}  borderRadius='md' color={tcColor} fontSize='md'>        
                    <Linkify>   
                        <Text fontSize={'sm'}>{loanDetails['What POS Docs have been received']}</Text>
                    </Linkify>
                </Box>
               </>
              }
                {loanDetails['What POS Docs are outstanding'] && 
                <>
                <Heading size='xs' textTransform={'uppercase'} mb='1'  color={tcColor}>What POS Docs are outstanding?</Heading> 
                <Divider/>
                <Box mb={'1'} p={2}  borderRadius='md' color={tcColor} fontSize='md'>        
                    <Linkify>   
                        <Text fontSize={'sm'}>{loanDetails['What POS Docs are outstanding']}</Text>
                    </Linkify>
                </Box>
               </>
              }
             <Heading size='xs' textTransform={'uppercase'} mb='1'  color={tcColor}>Title Company</Heading> 
                <>
                <Divider/>
                <Box mb={'1'} p={2}  borderRadius='md' color={tcColor} fontSize='md'>        
                    <Linkify>   
                        {loanDetails['Title Company Details']['Name'] &&<HStack><FaScroll /><Text>{loanDetails['Title Company Details']['Name']}</Text></HStack>}
                        {loanDetails['Title Company Details']['Point of Contact'] &&<HStack><FiUser /><Text>{loanDetails['Title Company Details']['Point of Contact']}</Text></HStack>}
                        {loanDetails['Title Company Details']['Phone'] && <HStack><FiPhone /><Text><a href={`tel:${loanDetails['Title Company Details']['Phone']}` }>{loanDetails['Title Company Details']['Phone'].replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3")}</a></Text></HStack>}
                        {loanDetails['Title Company Details']['Email'] &&<HStack><FiMail /><Text>{loanDetails['Title Company Details']['Email']}</Text></HStack>}
                    </Linkify>
                </Box>
                </>

                 <br></br>  
                 {loanDetails['Loan Type'] == 'VA' && (loanDetails['Relative Entered Into Arive'] || loanDetails['Relative']) &&
                    <>
                        <Heading size='xs' textTransform={'uppercase'} mb='1' color={tcColor}>Nearest Living Relative</Heading>    
                        <Divider/>   
                        {loanDetails['Relative Entered Into Arive'] && <Text p={1} color={tcColor}>Entered Into Arive</Text>}
                        {(loanDetails['Relative']['Name'] != '' || loanDetails['Relative']['Email'] != '' || loanDetails['Relative']['Phone'] != '') &&
                            <Box p={2} w={{base: '100%', xl: '48%'}} color={tcColor} fontSize='md'>  
                                <Linkify>   
                                    {loanDetails['Relative']['Name'] &&
                                        <HStack>
                                            <FiUser /><Text>{loanDetails['Relative']['Name']}</Text>
                                            {loanDetails['Relative']['Relation'] && <Text>({loanDetails['Relative']['Relation']})</Text>}
                                        </HStack>
                                    }
                                    {loanDetails['Relative']['Phone'] && <HStack><FiPhone /><Text><a href={`tel:${loanDetails['Relative']['Phone']}` }>{loanDetails['Relative']['Phone'].replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3")}</a></Text></HStack>}
                                    {loanDetails['Relative']['Address'] &&<HStack><FiMapPin /><Text>{loanDetails['Relative']['Address']}</Text></HStack>}
                                </Linkify>
                            </Box>
                        }
                    </> 
                }
              {loanDetails['Appraisal Contact'] && (loanDetails['Appraisal Contact']['Name'] || loanDetails['Appraisal Contact']['Email'] || loanDetails['Appraisal Contact']['Phone']) &&
                    <>
                        <Heading size='xs' textTransform={'uppercase'} mb='1' color={tcColor}>Appraisal Contact</Heading>    
                        <Divider/>   
                        {(loanDetails['Appraisal Contact']['Name'] != '' || loanDetails['Appraisal Contact']['Email'] != '' || loanDetails['Appraisal Contact']['Phone'] != '') &&
                            <Box p={2} w={{base: '100%', xl: '48%'}} color={tcColor} fontSize='md'>  
                                <Linkify>   
                                    {loanDetails['Appraisal Contact']['Name'] &&
                                        <HStack>
                                            <FiUser/><Text>{loanDetails['Appraisal Contact']['Name']}</Text>
                                        </HStack>
                                    }
                                    {loanDetails['Appraisal Contact']['Phone'] && <HStack><FiPhone /><Text><a href={`tel:${loanDetails['Appraisal Contact']['Phone']}` }>{loanDetails['Appraisal Contact']['Phone'].replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3")}</a></Text></HStack>}
                                    {loanDetails['Appraisal Contact']['Email'] && <HStack><FiMail /><Text>{loanDetails['Appraisal Contact']['Email']}</Text></HStack>}
                                </Linkify>
                            </Box>
                        }
                    </> 
                }
            {loanDetails['Reason for BP'] && loanDetails['LP or BP'] == 'BP' && 
            <>
                    <Flex justifyContent='flex-start' wrap={'wrap'}>
                    <Text   
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='md'
                        mb='1'
                        mr='2'
                        textTransform='uppercase'>
                            Reason for Borrower Paid 
                        </Text>
                    
                    <WrapItem>
                        {loanDetails['BP Confirmed'] ? 
                            <>
                            <Tooltip label='Confirmed' placement={'top'}>
                                <Tag mb='2' mr='2' size='md' variant={'outline'} colorScheme='green'>
                                    <TagLeftIcon as={FaCheckCircle} />
                                    <TagLabel textTransform={'uppercase'}>{loanDetails['Reason for BP']}</TagLabel>
                                </Tag> 
                            </Tooltip>
                            </>
                            :
                            <>
                            <Tooltip label='Unconfirmed' placement={'top'}>
                                <Tag mb='2' mr='2' size='md' variant={'outline'} colorScheme='orange'>
                                    <TagLeftIcon as={FaQuestionCircle} />
                                    <TagLabel textTransform={'uppercase'}>{loanDetails['Reason for BP']}</TagLabel>
                                </Tag> 
                            </Tooltip>
                         </>
                         }
                    {loanDetails['Competitor'] != '' && loanDetails['Reason for BP'] == 'Competitive Scenario' && 
                        <Tooltip label='Competitor' placement={'top'}>
                            <Tag mb='2' size='md' variant={'outline'} colorScheme='gray'>
                                <TagLeftIcon as={RiSwordLine} />
                                <TagLabel textTransform={'uppercase'}>{loanDetails['Competitor']}</TagLabel>
                            </Tag> 
                        </Tooltip>

                    }
                    </WrapItem>
                </Flex>      
                </>  
            }
            </>
        }
            <Accordion mt={4} allowMultiple={true}>
                {(client['LC']['uid'] == currentUser.uid || SuperAdmin) &&
                <AccordionItem>
                    <h2>
                    <AccordionButton backgroundColor={client['Coaching Completed'] && SuperAdmin ?
                                                        reviewedBGColor
                                                        :
                                                        client['Flag For Review'] && SuperAdmin ? 
                                                        reviewBGColor : ''}>
                        <Box flex='1' textAlign='left'>
                            {`Notes For Review`}&nbsp;
                            <AdminIconIndicator pt={'1'} tooltip={SuperAdmin ? 'Super Admins and the LC on file can see this' : 'Only Admins can see this'} verticalAlign={'top'}/>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pl={0} pr={0}>
                    <FormControl mb={3} display='flex' alignItems='center'>
                        <FormLabel htmlFor='flag-for-review' mb='0'>
                            Flag For Review
                        </FormLabel>
                        <Switch id='flag-for-review' disabled={client['Coaching Completed']} isChecked={client['Flag For Review']} onChange={(e) => toggleClientSetting(e, 'Flag For Review')} />
                    </FormControl>
                    {SuperAdmin &&
                    <FormControl mb={3} display='flex' alignItems='center'>
                        <FormLabel htmlFor='coaching-completed' mb='0'>
                            Coaching Completed <AdminIconIndicator pt={'1'} tooltip='Only Super Admins can see this' verticalAlign={'top'}/>

                        </FormLabel>
                        <Switch id='coaching-completed' isChecked={client['Coaching Completed']} onChange={(e) => toggleClientSetting(e, 'Coaching Completed')} />
                    </FormControl>
                    }
                     <GroupChat 
                        collectionRef={notesForReviewRef} 
                        collectionName='Notes For Review'
                        parentID={client.id}
                        width='100%' 
                        isNotes
                        isNotesForReview
                        parentDocContent={client}
                        />

                    </AccordionPanel>
                </AccordionItem> 
                }
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                        Edit Log
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pl={0} pr={0}>
                        {editLog.length > 0 ?
                            <EditLog
                                log={editLog}
                                incrementPrintedLogCount={incrementPrintedLogCount}
                                printedLogCount={printedLogCount}
                                setPrintedLogCount={setPrintedLogCount}
                            />
                            :
                            <Text>No edits have been made</Text>
                        }

                    </AccordionPanel>
                </AccordionItem>
                {loanDetails['Purchase or Refi'] == 'Purchase' &&
                    <AccordionItem>
                        <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                Automations
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pl={0} pr={0}>
                            <CreateRCEDoc 
                                initVals={client}
                            />
                            <DocumentLogs logList={docLogs} />

                        </AccordionPanel>
                    </AccordionItem>
                } 
                <AccordionItem>
                        <h2>
                        <AccordionButton onClick={() => handleGetEscalations()}>
                            <Box flex='1' textAlign='left'>
                                Escalations
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pl={0} pr={0}>
                            <VStack align={'flex-start'}>
                                <CreateEscalationDrawer 
                                    loanID={docID} 
                                    loanNumber={loanDetails && loanDetails['Loan #'] && loanDetails['Loan #']}
                                    fullButton={true}
                                />
                                {loadingTopics && <Spinner />}
                                {!loadingTopics && escalations && escalations.map((escalation) => (
                                    <>
                                    <HStack mt='2' mb='2'>
                                        <Button 
                                            size='sm' colorScheme='cyan' onClick={() => history.push(`/escalations/${escalation.id}`)}>
                                            View
                                        </Button>
                                        <Avatar src={escalation.Owner.photoURL}></Avatar>
                                        <AssigneesAvatarGroup Status={escalation.Status} assignees={escalation.Assignees} />
                                        <Text fontSize={'xs'}>{findTopic(escalation.topicID)} / {findSubtopic(escalation.subtopicID)}</Text>
                                 
                                      
                                    </HStack>       
                                    </>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                {isAdmin &&
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign LO <AdminIconIndicator pt={'1'} verticalAlign={'top'}/>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <AssignClaimClientLO
                            
                            loanOfficer={loanOfficer}
                            loanDetails={loanDetails} 
                            docID={docID}
                            showUndo={false}
                            showView={false}
                        />
                    </AccordionPanel>
                </AccordionItem>
                }
                 <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign LOA
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <AssignClaimClientLOA
                            
                            loanOfficer={loanOfficer}
                            loanDetails={loanDetails} 
                            docID={docID}
                            showUndo={false}
                            showView={false}
                        />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign LC
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <AssignClaimClient
                            
                            loanOfficer={loanOfficer}
                            loanDetails={loanDetails} 
                            docID={docID}
                            showUndo={false}
                            showView={false}
                        />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem overflow={'visible'} >
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign CSC
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel overflow={'visible'} pb={4}>
                        <AssignClaimClientCSC
                            loanOfficer={loanOfficer}
                            loanDetails={loanDetails} 
                            docID={docID}
                            showUndo={false}
                            showView={false}
                        />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem overflow={'visible'} >
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Assign TC
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel overflow={'visible'} pb={4}>
                        <AssignClaimClientTC
                            loanOfficer={loanOfficer}
                            loanDetails={loanDetails} 
                            docID={docID}
                            showUndo={false}
                            showView={false}
                        />
                    </AccordionPanel>
                </AccordionItem>
                {isAdmin &&
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Settings <AdminIconIndicator pt={'1'} verticalAlign={'top'}/>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <ClientSettings
                            docRef={doc(db, "Clients", docID)}
                            initialSettings={client['Settings'] ? client['Settings'] : null}
                        />
                    </AccordionPanel>
                </AccordionItem>
                }
            </Accordion>
           

    </Box>
        
      
  );
}
