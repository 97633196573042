import BooleanSwitch from "./BooleanSwitch";
import { Box, Tooltip, Stack, HStack, Button, Heading, FormControl, FormLabel } from "@chakra-ui/react";
import { CreatableSelect, Select } from "chakra-react-select";
import { useState } from "react";
import { getDoc, updateDoc, setDoc } from "firebase/firestore";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";


//settingsRef is a document in a collection, for example - doc(db, 'Settings', docID)
export default function StatusChangeNotificationSettings ( { heading, users, settingsRef, initialSettings, settingName, actionMessage, omitInputFields, emailSwitches, pushSwitches  } ){
    const [emailRecipients, setEmailRecipients] = useState(initialSettings ? initialSettings[settingName] ? initialSettings[settingName]['Email Recipients'] ? initialSettings[settingName]['Email Recipients'] : null : null : null)
    const [pushRecipients, setPushRecipients] = useState(initialSettings ? initialSettings[settingName] ? initialSettings[settingName]['Push Recipients'] ? initialSettings[settingName]['Push Recipients'] : null : null : null)
    const toast = useToast()

    const handleSetEmailRecipients = (e) =>{
        e.forEach((entry) => {
          delete entry['__isNew__']
          if(entry.email == null)
            entry.email = entry.value
        })
        setEmailRecipients(e)
      }

    async function updateRecipientSetting(field, value){
        try{
          const settingsSnap = await getDoc(settingsRef);
    
          //If the settings document exists, update it. Else create it and add data
          if(settingsSnap.exists()){
            await updateDoc(settingsRef, {
              [field]: value
            });
          }
          else{
            //setDoc will not create an object using a nested field (i.e Parent Field.Child Field)
            //So, split them and explicity create the object
            let nestedFields = field.split('.')
            await setDoc(settingsRef, {
              [nestedFields[0]]: {
                [nestedFields[1]]: value
              }
            });
    
          }
          toast({
            title: `${field.replace('.',' - ')} were updated!`,
            status: 'success',
            position: 'top',
            isClosable: true
          })
        }
        catch(err) {
          console.error(err);
        }
      }
    return(
        <Box boxShadow={'lg'} p={5} mt={5}>
        <HStack mb={3}>
          <Heading  size='sm'>{heading}</Heading>   
          {!omitInputFields &&       
          <Tooltip placement='top' label='You can select a user from the list, or enter an email address'>
            <InfoOutlineIcon/>
          </Tooltip>
          }
          </HStack>
          {!omitInputFields &&
            <>
            <FormControl p={3}>
            <Stack m={1} direction='row'>
          
              <FormLabel mt={1}>Email</FormLabel> 
              <Button size='sm'colorScheme='messenger' onClick={() => updateRecipientSetting(`${settingName}.Email Recipients`, emailRecipients)}>Update</Button>
              </Stack>
              <CreatableSelect
                isMulti
                name={`${settingName} Email Recipients`}
                options={users}
                placeholder="Select or Enter Recipient"
                closeMenuOnSelect={false}
                //defaultValue={subEmailRecipients}
                value={emailRecipients}
                onChange={(e) => handleSetEmailRecipients(e)}
              />

            </FormControl>
            </>
          }
        {emailSwitches?.map((switchContent, i) => {
                  return(
                    <BooleanSwitch
                      id={switchContent.id}
                      label={switchContent.label}
                      docRef={settingsRef}
                      fieldPath={switchContent.fieldPath}
                      successMsg={switchContent.successMsg}
                      warningMsg={switchContent.warningMsg}
                      initialState={initialSettings ? initialSettings[settingName] ? initialSettings[settingName][switchContent.name] : false : false}
                    />
                  )
              })}
        {!omitInputFields &&
        <>
        <FormControl p={3}>

        <Stack m={1} direction='row'>
          <FormLabel mt={1}>Push Notification</FormLabel> 

          <Button size='sm'colorScheme='messenger' onClick={() => updateRecipientSetting(`${settingName}.Push Recipients`, pushRecipients)}>Update</Button>
          </Stack>
          <Select
            isMulti
            name={`${settingName} Push Recipients`}
            options={users}
            placeholder="Select Recipient"
            closeMenuOnSelect={false}
            //defaultValue={subEmailRecipients}
            value={pushRecipients}
            onChange={(e) => setPushRecipients(e)}
          />
        </FormControl>   
        </>
        }
          {pushSwitches?.map((switchContent, i) => {
                  return(
                    <BooleanSwitch
                      id={switchContent.id}
                      label={switchContent.label}
                      docRef={settingsRef}
                      fieldPath={switchContent.fieldPath}
                      successMsg={switchContent.successMsg}
                      warningMsg={switchContent.warningMsg}
                      initialState={initialSettings ? initialSettings[settingName] ? initialSettings[settingName][switchContent.name] : false : false}
                    />
                  )
              })} 
      </Box>
    )
}