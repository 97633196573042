  import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    useColorModeValue
  } from '@chakra-ui/react'

export default function Activity( { activity, isLast, isFirst } ){
  const bgColor = useColorModeValue(
    `${activity.color ? `${activity.color}.400` : 'blue.400'}`,
    `${activity.color ? `${activity.color}.600` : 'blue.600'}`
  )
  const textColor = useColorModeValue('white', 'gray.200')
    return(
        <>
        <Popover placement='top'>
        <PopoverTrigger>
            <Button 
                borderRadius='lg' 
                p='1'
                m='1' 
                w='96%'
                height='100%'
                backgroundColor={bgColor} 
                color={textColor}
                _hover={{color: 'white'}}
                whiteSpace={'wrap'}
                //borderBottom='1px'
                //borderBottomColor='lightgrey' 
                //borderTop={isFirst && '1px'} 
                //borderTopColor={isFirst && 'lightgrey'}
                size='sm' 
                variant='ghost'>
                    {activity.title && activity.title}
            </Button>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>{activity.title && activity.title}</PopoverHeader>
            <PopoverBody>{activity.description && activity.description}</PopoverBody>
        </PopoverContent>
        </Popover>
      </>
    )
}