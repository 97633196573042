import { Flex } from "@chakra-ui/react"
import Day from "./Day"
import { useEffect, useState } from "react"

export default function CalendarList( { calendar, allActivities } ){
    const [days, setDays] = useState()

    useEffect(() => {
        function makeActivites(activityList){
            let activities = []
            activityList.forEach((activity) => {
                let activityIndex = allActivities.findIndex(a => a.id === activity.id)
                if(activityIndex > -1){
                    activities.push(allActivities[activityIndex])
                }
            })
            return activities
        }

        function makeDays(){    
            let dayz = []
            calendar.Monday && dayz.push({title: 'Monday', activities: makeActivites(calendar.Monday)})
            calendar.Tuesday && dayz.push({title: 'Tuesday', activities: makeActivites(calendar.Tuesday)})
            calendar.Wednesday && dayz.push({title: 'Wednesday', activities: makeActivites(calendar.Wednesday)})
            calendar.Thursday && dayz.push({title: 'Thursday', activities: makeActivites(calendar.Thursday)})
            calendar.Friday && dayz.push({title: 'Friday', activities: makeActivites(calendar.Friday)})
            calendar.Saturday && dayz.push({title: 'Saturday', activities: makeActivites(calendar.Saturday)})
            return dayz
        }   
        calendar && setDays(makeDays())
    }, [calendar])

    return(
        <>
            <Flex  maxW='100%' height='100%' overflowX='auto' justify='space-between' flexDir='row'>
                {days && days?.map((day, i) => (
                    <Day key={`${day.id}-${i}`} day={day} isLast={days.length === i+1} isFirst={i === 0}/>
                ))}
            </Flex>
        
        </>
    )
}