


//STRUCTURE OF USER OBJECT:
/*------------------------
user: {
    email: email,
    label: displayName,
    photoURL: photoURL,
    value: uid
}
------------------------*/

import {
    Box,
    Center,
    useColorModeValue,
    Image,
    StatGroup,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber
  } from '@chakra-ui/react';

export default function TopPerformers_IndividualBox( { awardName, amount, user } ) {

    return (
          <Box
            role={'group'}
            p={6}
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow='md'
            rounded={'xl'}
            minH={'225px'}
           >
            <Center>
                <Box
                rounded={'full'}
                height={'100px'}
                    mb={2}
                >
                <Image
                    rounded={'full'}
                    height={'full'}
                    objectFit={'cover'}
                    src={user.photoURL}
                />
                </Box>
            </Center>

            <Center>
            <StatGroup textAlign={'center'} flexDir={'column'}>
                <Stat alignContent={'center'}>
                    <StatLabel as='em' fontSize={'md'} color={'gray.500'}>{user.label}</StatLabel>
                    {awardName && <StatNumber fontWeight={'bold'} fontSize={'lg'} textTransform={'uppercase'}> {awardName}</StatNumber>}
                    {amount && <StatHelpText>{amount}</StatHelpText>}
                </Stat>
            </StatGroup>
            </Center>
          {/*   <Stack align={'center'}>
                <Text color={'gray.500'}  fontSize={'xl'} textTransform={'uppercase'}>
                    {awardName}
                </Text>
                <Text fontSize={'lg'} as='em'>
                    {user.label}
                </Text>
              <Heading fontSize={'sm'} fontFamily={'body'} as='em' fontWeight={500}>
                {amount} loans closed
              </Heading>
            </Stack> */}
          </Box>
      );
  }