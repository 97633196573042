import {
  Button,
  Text,
  Input,
  IconButton,
  Heading,
  Flex,
  FormLabel,
  Link,
  FormControl,
  Checkbox,
  Badge,
  Icon,
} from "@chakra-ui/react";
import { db } from "../../utils/init-firebase";
import { useState, useEffect } from "react";
import { collection, getDocs, where, query } from "firebase/firestore";
import { FiAlertCircle, FiMail, FiPhone } from "react-icons/fi";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Tooltip,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { ExternalLinkIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import ReactTable_NoPagination from "../../components/Table/ReactTable_NoPagination";
import { BsClipboard2Check } from "react-icons/bs";
import ReactLinkify from "react-linkify";
const DELIMITER = "-";
export default function LenderMatrixPage({ initialSettings }) {
  const [allLenders, setAllLenders] = useState([]);
  const [filteredLenders, setFilteredLenders] = useState([]);

  const [columns, setColumns] = useState();

  const [lenderOptions, setLenderOptions] = useState();
  const [selectedLender, setSelectedLender] = useState();

  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const [property, setProperties] = useState();
  const [selectedProperty, setSelectedProperty] = useState();

  const [creditScore, setCreditScore] = useState();
  const [dpaFilter, setDPAFilter] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [filtersReset, setFiltersReset] = useState(false);

  const baseCols = [
    //   {
    //     Header: 'Core',
    //     accessor: 'Core Lender',
    //     footer: '',
    //     sortType: ((a, b, id) => {
    //       if (a.original[id] > b.original[id]) return 1;
    //       if (b.original[id] > a.original[id]) return -1;
    //     }),
    //     Cell: ({ cell: { value }, row }) => (
    //       <>
    //           {value && <Tag variant='subtle' borderRadius={'2xl'} fontWeight='bold' colorScheme={'blue'}>CORE</Tag>}
    //       </>
    //     ),
    //   },
    //   {
    //     Header: 'DPA',
    //     accessor: 'DPA',
    //     footer: '',
    //     sortType: ((a, b, id) => {
    //       if (a.original[id] > b.original[id]) return 1;
    //       if (b.original[id] > a.original[id]) return -1;
    //     }),
    //     Cell: ({ cell: { value }, row }) => (
    //       <>
    //           {value && <CheckIcon color={'green'}/>}
    //       </>
    //     ),
    //   },
    {
      Header: (
        <>
          Lender
          <Tooltip
            placement="top"
            label="Lenders in blue are Core Lenders"
            aria-label="Core Lenders tooltip"
          >
            <InfoOutlineIcon ml="0.5" mb="3" />
          </Tooltip>
        </>
      ),
      accessor: "name",
      footer: "",
      minWidth: "250px",
      Cell: ({ cell: { value }, row }) => (
        <>
          <Flex flexWrap={"wrap"}>
            {row?.original?.guidelineLink && (
              <Link
                isExternal
                fontSize="lg"
                mr="1"
                href={row?.original?.guidelineLink}
              >
                <Icon as={BsClipboard2Check} />
              </Link>
            )}
            {row?.original?.website ? (
              <>
                <Link
                  fontSize="md"
                  color={row?.original?.["Core Lender"] && "blue.400"}
                  fontWeight="bold"
                  isExternal
                  href={`${row?.original?.website}`}
                >
                  {value}
                  <ExternalLinkIcon mb="1" ml="1" />
                </Link>
              </>
            ) : (
              <Heading
                color={row?.original?.["Core Lender"] && "blue.400"}
                size="sm"
              >
                {value}
              </Heading>
            )}
            {row?.original?.["DPA"] && (
              <Badge
                borderRadius={"lg"}
                ml="1"
                colorScheme={"green"}
                fontSize="xs"
                variant={"subtle"}
              >
                DPA
              </Badge>
            )}
          </Flex>
        </>
      ),
    },
    {
      Header: "Account Executive",
      accessor: "Account Executive",
      footer: "",
      Cell: ({ cell: { value }, row }) => (
        <>
          {/* <Flex flexDir={'column'}>
                    <Text fontSize='sm'>{row?.original?.['Account Executive']?.Name}</Text>
                    <Link href={`tel:${row?.original?.['Account Executive']?.Number}`} lineHeight={'1'} fontSize='xs'>
                        {row?.original?.['Account Executive']?.Number}
                    </Link>
                    <Link href={`mailto:${row?.original?.['Account Executive']?.Email}`} lineHeight={'1'} fontSize='xs'>
                        {row?.original?.['Account Executive']?.Email}
                    </Link>
                </Flex> */}
          <Flex>
            <Text fontSize="sm">
              {row?.original?.["Account Executive"]?.Name}
            </Text>
            {row?.original?.["Account Executive"]?.Number && (
              <Popover placement="top" trigger="hover">
                <PopoverTrigger>
                  <IconButton
                    variant={"ghost"}
                    size="sm"
                    h="5"
                    icon={<FiPhone />}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    {row?.original?.["Account Executive"]?.Number}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
            {row?.original?.["Account Executive"]?.Email && (
              <Popover placement="top" trigger="hover">
                <PopoverTrigger>
                  <IconButton
                    variant={"ghost"}
                    size="sm"
                    h="5"
                    icon={<FiMail />}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    {row?.original?.["Account Executive"]?.Email}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Flex>
        </>
      ),
    },
    //   {
    //     Header: 'Guidelines',
    //     accessor: 'guidelineLink',
    //     footer: '',
    //     Cell: ({ cell: { value }, row }) => (
    //       <>
    //         {value &&
    //           <Link textOverflow={'clip'} size='sm' href={value}>{`${row?.original?.name} Guidelines`}<ExternalLinkIcon /></Link>}
    //       </>
    //     ),
    //   },
  ];

  function resetFilters() {
    setCreditScore("");
    setSelectedProduct("");
    setSelectedProperty("");
    setSelectedLender("");
    setDPAFilter(false);
  }

  function addOptionWithoutDuplicate(opt, arr) {
    let index = arr.findIndex((p) => p.value === opt);
    if (index === -1) {
      arr.push({ label: opt, value: opt });
    }
  }

  function addColumn(header, filteredCols) {
    let colIndex = filteredCols.findIndex((col) => col.Header === header);
    if (colIndex === -1) {
      filteredCols.push({
        Header: header,
        accessor: header,
        footer: "",
        Cell: ({ cell: { value }, row }) => (
          <>
            <Flex alignContent={"center"}>
              <Text>{value}</Text>
              {row?.original?.[`${header} Narratives`]?.map((narrative, i) => (
                <Popover
                  placement="top"
                  trigger="hover"
                  key={`${header} Narratives-${i}`}
                >
                  <PopoverTrigger>
                    <IconButton
                      variant={"ghost"}
                      size="sm"
                      h="5"
                      icon={<FiAlertCircle />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <ReactLinkify>{narrative}</ReactLinkify>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ))}
            </Flex>
          </>
        ),
      });
    }
  }

  function deepCopy(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      const arrCopy = [];
      for (let i = 0; i < obj.length; i++) {
        arrCopy[i] = deepCopy(obj[i]);
      }
      return arrCopy;
    }

    const objCopy = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        objCopy[key] = deepCopy(obj[key]);
      }
    }
    return objCopy;
  }

  function makeColumnsAndData() {
    //let allLends = deepCopy(allLenders);
    let filteredCols = [];
    let filteredLends = [];
    allLenders.forEach((lender, i) => {
      const lenderMatches =
        (!selectedLender || selectedLender.value === lender.id) &&
        (!dpaFilter || lender?.DPA);
      if (lenderMatches) {
        const filteredScenarios =
          lender?.["Loan Scenarios"]?.filter((scenario) => {
            const productTypeMatches =
              !selectedProduct ||
              selectedProduct.value === scenario["Product Type"];
            const propertyTypeMatches =
              !selectedProperty ||
              selectedProperty.value === scenario["Property Type"];
            const creditScoreMatches =
              !creditScore ||
              parseInt(creditScore) >= parseInt(scenario["Credit Score"]);

            return (
              lenderMatches &&
              productTypeMatches &&
              propertyTypeMatches &&
              creditScoreMatches
            );
          }) ?? []; // Use an empty array if filteredScenarios is undefined

        if (
          filteredScenarios.length > 0 ||
          (!selectedLender &&
            !selectedProduct &&
            !selectedProperty &&
            !creditScore)
        ) {
          let thisLender = deepCopy(lender);
          filteredScenarios.forEach((scenario) => {
            // console.log(`Adding scenario ${JSON.stringify(scenario)}...`)
            if (scenario["Credit Score"]) {
              let columnKey;
              if (scenario["Product Type"] && scenario["Property Type"]) {
                columnKey = `${scenario["Product Type"]}${DELIMITER}${scenario["Property Type"]}`;
              } else if (scenario["Product Type"]) {
                columnKey = `${scenario["Product Type"]}`;
              } else if (scenario["Property Type"]) {
                columnKey = `${scenario["Property Type"]}`;
              }
              if (columnKey) {
                addColumn(columnKey, filteredCols);
                thisLender[columnKey] = parseInt(scenario?.["Credit Score"]);
                thisLender[`${columnKey} Narratives`] =
                  scenario?.["Loan Narrative"];
              }
            }
          });

          // Add the lender to the array only if there are matching scenarios or no filters are selected
          filteredLends.push(thisLender);
        }
      }
    });

    filteredCols.sort((a, b) => {
      const headerA = a.Header.toUpperCase(); // Convert to uppercase for case-insensitive comparison
      const headerB = b.Header.toUpperCase();

      if (headerA < headerB) {
        return -1;
      }
      if (headerA > headerB) {
        return 1;
      }
      return 0;
    });

    return { filteredCols, filteredLends };
  }

  // useEffect(() => {
  //     console.log(allLenders)
  //     alert("allLenders changed");

  // }, [allLenders])

  function makeOptions(filteredLends, filteredCols) {
    let productOpts = [];
    let propOptions = [];
    let lendOptions = [];
    let allProductsAndProperties = [];
    filteredCols?.forEach((col) => {
      col?.Header?.split(DELIMITER).forEach((item) => {
        if (!allProductsAndProperties.includes(item)) {
          allProductsAndProperties.push(item);
        }
      });
    });
    filteredLends.forEach((lender, i) => {
      lendOptions.push({ value: lender.id, label: lender.name });
      lender?.["Loan Scenarios"]?.forEach((scenario) => {
        if (
          scenario["Product Type"] &&
          allProductsAndProperties.includes(scenario["Product Type"])
        )
          addOptionWithoutDuplicate(scenario["Product Type"], productOpts);
        if (
          scenario["Property Type"] &&
          allProductsAndProperties.includes(scenario["Property Type"])
        )
          addOptionWithoutDuplicate(scenario["Property Type"], propOptions);
      });
    });
    return { productOpts, propOptions, lendOptions };
  }

  useEffect(() => {
    let { filteredCols, filteredLends } = makeColumnsAndData();
    let { productOpts, propOptions, lendOptions } = makeOptions(
      filteredLends,
      filteredCols
    );

    setColumns([...baseCols, ...filteredCols]);
    setFilteredLenders(filteredLends);
    setProperties(propOptions);
    setLenderOptions(lendOptions);
    setProducts(productOpts);
  }, [
    allLenders,
    selectedProduct,
    selectedProperty,
    creditScore,
    selectedLender,
    dpaFilter,
  ]);

  //   useEffect(() => {
  //     lenderDocuments && makeInitialData()
  //   }, [lenderDocuments])

  const loadLenders = async () => {
    const lendersCollection = collection(db, "lenders");

    try {
      const querySnapshot = await getDocs(
        query(lendersCollection, where("inactive", "==", false))
      );
      const lenderDocs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllLenders(
        lenderDocs.sort((a, b) => {
          // First, sort by 'Core Lender' (true first)
          if (a["Core Lender"] && !b["Core Lender"]) {
            return -1;
          }
          if (!a["Core Lender"] && b["Core Lender"]) {
            return 1;
          }

          // If 'Core Lender' is the same, sort alphabetically by 'name'
          return a.name.localeCompare(b.name);
        })
      );
      //makeInitialData(lenderDocs);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    loadLenders();
  }, []);

  return (
    <>
      <Flex mt="6" flexWrap={"wrap"}>
        <FormControl alignSelf="center" w="225px" mr="1">
          <Select
            placeholder={`Filter By Lender`}
            value={selectedLender}
            options={lenderOptions}
            onChange={setSelectedLender}
            isClearable
            chakraStyles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "sm",
              }),
            }}
          ></Select>
        </FormControl>
        <FormControl alignSelf="center" w="225px" mx="1">
          <Select
            placeholder={`Filter By Product`}
            value={selectedProduct}
            options={products}
            onChange={setSelectedProduct}
            isClearable
            chakraStyles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "sm",
              }),
            }}
          ></Select>
        </FormControl>
        <FormControl alignSelf="center" w="225px" mx="1">
          <Select
            placeholder={`Filter By Property`}
            value={selectedProperty}
            options={property}
            onChange={setSelectedProperty}
            isClearable
            chakraStyles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "sm",
              }),
            }}
          ></Select>
        </FormControl>
        <FormControl alignSelf="center" w="225px" mx="1">
          <Input
            placeholder={`Filter By Credit Score`}
            value={creditScore}
            onChange={(e) => setCreditScore(e.target.value)}
            type="number"
            _placeholder={{ fontSize: "sm" }}
          ></Input>
        </FormControl>
        <FormControl alignSelf="center" mx="1" w="75px" display={"flex"}>
          <Checkbox
            size="lg"
            isChecked={dpaFilter}
            defaultChecked={dpaFilter}
            onChange={(e) => setDPAFilter(e.target.checked)}
          />
          <FormLabel fontSize="lg" mt="1.5" ml="1">
            DPA
          </FormLabel>
        </FormControl>
        <Button p={5} onClick={resetFilters} size="md" colorScheme="blue">
          Reset
        </Button>
      </Flex>

      {columns && filteredLenders && (
        <ReactTable_NoPagination
          cols={columns}
          data={filteredLenders}
          colorScheme="blue"
        />
      )}
    </>
  );
}
