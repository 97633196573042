import { Flex, Wrap, WrapItem } from "@chakra-ui/react"
import TimeDifference from "../TimeDifference"

export default function LoanTimestamps( { client } ){
    return(
        <Flex dir="row" justify={'flex-start'} mb='5' >
            <Wrap>
            {client['Date Added'] & client['Date Claimed'] && client['Status']=='Completed' ?
                <WrapItem>
                    <TimeDifference
                        start={client['Date Added']}
                        end={client['Date Claimed']}
                        description={'LC'}
                        includeArrow={true}
                        warningTimeMinutes={15}
                    />
                </WrapItem>
            : null}
{/*             {client['Date Added'] & client['Date CSC Added'] && client['Status']=='Completed' ?
                <WrapItem>
                    <TimeDifference
                        start={client['Date Added']}
                        end={client['Date CSC Added']}
                        description={'CSC'}
                        includeArrow={true}
                        warningTimeMinutes={15}
                    />
                </WrapItem>
            : null}
            {client['Date Added'] & client['Date TC Added'] && client['Status']=='Completed' ?
                <WrapItem>
                    <TimeDifference
                        start={client['Date Added']}
                        end={client['Date TC Added']}
                        description={'TC'}
                        includeArrow={true}
                        warningTimeMinutes={15}
                    />
                </WrapItem>
            : null} */}
            {client['Date Added'] & client['Date Parked'] && client['Status']=='Completed' ?
                <WrapItem>
                    <TimeDifference
                        start={client['Date Added']}
                        end={client['Date Parked']}
                        description={'PARKED'}
                        includeArrow={true}
                        warningTimeMinutes={60}
                    />
                </WrapItem>
            : null}
            {client['Date Added'] & client['Date Completed'] && client['Status']=='Completed' ?
                <WrapItem>
                    <TimeDifference
                        start={client['Date Added']}
                        end={client['Date Completed']}
                        description={'TOTAL'}
                        includeArrow={false}
                        warningTimeMinutes={60}
                    />
                </WrapItem>

            : null}
        </Wrap>
    </Flex>
    )
}