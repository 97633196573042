import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Divider, Flex, Text, Tooltip } from "@chakra-ui/react"
import { collectionGroup, doc, getDoc, getDocs, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { db } from "../../utils/init-firebase"
import { PriceTag } from "../MarketingSwagV2/PriceTag"

export default function SwagBudget({ user, cartTotal, transactions, selectedYear, budget }){
    const [swagTotal, setSwagTotal] = useState()
    const [thisBudget, setThisBudget] = useState()

    let remaining = parseFloat(thisBudget) - parseFloat(swagTotal) - parseFloat(cartTotal ? cartTotal : 0)

    useEffect(() => {

        function calculateSwagTotal(transactions){
            // let total = 0
            // transactions.forEach((transaction) => {
            //     total += parseFloat(transaction?.totalCost)
            // })
            console.log(transactions)
            let total = transactions.reduce((prev, curr) => prev + parseFloat(curr?.totalCost), 0)
            return total
        }
        transactions && setSwagTotal(calculateSwagTotal(transactions))
    }, [transactions])

    useEffect( async () => {
        // if user has an individual swagBudget, use that. If not, use the default in Marketing Settings. Fallback to 250
        if(budget){
            setThisBudget(parseFloat(budget))
        }
        else {
            const marketingSettingsSnap = await getDoc(doc(db, 'Marketing Settings', 'yEwRIqrkneqrGnAQhgvs'))
            if(marketingSettingsSnap.exists()) {
                setThisBudget(marketingSettingsSnap.data()['Task Settings'] ? 
                                    marketingSettingsSnap.data()['Task Settings']['Default Monthly Swag Budget'] ? 
                                    marketingSettingsSnap.data()['Task Settings']['Default Monthly Swag Budget'] : 250 : 250)
            }
        }
    }, [user, budget])

    return(
        <Flex  flexDir={'column'} pr='25px'>
        <Flex justifyContent={'flex-end'} flexDir={'row'}>
            <Text fontWeight={'700'}>{selectedYear} Budget&nbsp;</Text>
            <Flex minW='75px' justifyContent={'flex-end'}>
                <PriceTag price={thisBudget} />
            </Flex>

        </Flex>
        {cartTotal ?
        <Flex justifyContent={'flex-end'} flexDir={'row'}>
            <Text fontWeight={'700'}>Subtotal&nbsp;</Text>
            <Flex minW='75px' justifyContent={'flex-end'}>
                <span>(</span><PriceTag price={cartTotal} /><span>)</span>
            </Flex>

        </Flex> : <></> }
        <Flex justifyContent={'flex-end'} flexDir={'row'}>
            <Text fontWeight={'700'}>Used&nbsp;</Text>
            <Flex minW='75px' justifyContent={'flex-end'}>
                <span>(</span><PriceTag price={swagTotal} /><span>)</span>
            </Flex>
        </Flex>
        <Divider />

        <Flex justifyContent={'flex-end'} flexDir={'row'}>

            <Text color={remaining <= 0 && 'red.300'} fontWeight={'700'}>Remaining&nbsp;</Text>
            {remaining <= 0 &&
            <Tooltip label='This will be deducted from your commission check'>
                <InfoOutlineIcon alignSelf={'center'} color='red.300' />
            </Tooltip>
            }
            <Flex minW='75px' justifyContent={'flex-end'}>
                <PriceTag price={remaining} />
            </Flex>
  
        </Flex>
        

    </Flex>
    )
}