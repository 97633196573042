import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { db } from '../utils/init-firebase';
import { collection, doc, query, orderBy, where, onSnapshot } from "firebase/firestore"
import { useAuth } from './AuthContext';

const NotificationContext = createContext({
  notifications: null,
  setupQueueCount: null
})

export const useNotifs = () => useContext(NotificationContext)

export default function NotificationProvider ({ children }) {
  const { loading, currentUser, Roles } = useAuth()
  const [setupQueueCount, setSetupQueueCount] = useState(null)
  const [notifications, setNotifications] = useState(null)
  const unsubRef = useRef()

  useEffect( async () => {
    if(!currentUser){
      return
    }
    if(Roles.includes('Mortgage Broker') || Roles.includes('Client Success Coordinator')){
      return
    } 
    const unsubSetupCounter = onSnapshot(doc(db, 'Counters', 'FeS869ZmgQFluQz3VwsI'), (doc) => {
        setSetupQueueCount(doc.data()['Setup Queue'])
    })

    return () => {
      unsubSetupCounter()
    }
  }, [currentUser])

  useEffect( async () => {
    const getNotifs = async () => {
      unsubRef.current && unsubRef.current()
      const q = query(collection(db, "users", currentUser.uid, 'Notifications'), orderBy('timestamp'), where('read', '==', false));
      unsubRef.current = onSnapshot(q, (querySnapshot) => {
        const notifications = [];
        querySnapshot.forEach((doc) => {
            notifications.push({
              ...doc.data(),
              id: doc.id
            });
        });
        setNotifications(notifications)
      });
    }
    await getNotifs()

    return () => {
      unsubRef.current && unsubRef.current()
    }
  }, [currentUser])



  const value = {
    notifications,
    setupQueueCount
  }
  return <NotificationContext.Provider value={value}>{!loading && children}</NotificationContext.Provider>
}
