import { useState, useEffect } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Prose } from '@nikolovlazar/chakra-ui-prose'
export default function SocialMediaCompliance(){
    const [content, setContent] = useState()
    const [htmlBody, setHtmlBody] = useState()
    var contentful = require('contentful')
    var client = contentful.createClient({
      space: 't5sq7qefsjd7',
      accessToken: 'zwZ48m7EiEolJZfdna5EznKFwenOI2nqqYu5PFaUx1Q',
    });

    useEffect(() => {
        if(!content){
            client.getEntry('64kZeKTQrfr8oDM8ffqW80')
            .then((entry) => {
              setContent(entry)
            })
            .catch(console.error)
        }
    }, [])
      useEffect( async () => {
        if(content){
          setHtmlBody(documentToHtmlString(content?.fields?.content))
        }
       
      }, [content])

    return(
        <>
        {htmlBody && content &&
            <>
            <Prose>
                <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
            </Prose>
            </>
        }
        </>
    )
}