import { Avatar, Flex, Tag, TagLeftIcon, AvatarGroup, AvatarBadge, Tooltip } from "@chakra-ui/react"
import moment from "moment"
import { FiCalendar } from "react-icons/fi"
import TagButton from "./TagButton"


export default function MarketingReportSummary( {startDate, endDate, dateFilter, owner, assignees, queryStats, statusFilter, setStatusFilter, wasParkedFilter, setWasParkedFilter, handleSetWasParkedFilter } ){
    return(
        <>
            <Flex mb={3} flexDir={'row'}>
                <AvatarGroup size='lg'>
                    {owner && owner.length > 0 &&
                        <>
                        {owner.map((user) => {
                            return(
                                <Tooltip placement='top' label={user.label}>
                                    <Avatar name={user.label} src={user.photoURL} >
                                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='blue.500'>owner</AvatarBadge>
                                    </Avatar>
                                </Tooltip> 
                            )
                        })}
                        </>
                        
                    }
                    {assignees && assignees.length > 0 &&
                        <>
                        {assignees.map((assignee) => {
                            return(
                                <Tooltip placement='top' label={assignee.label}>
                                    <Avatar name={assignee.label} src={assignee.photoURL}>
                                        <AvatarBadge border='none' color='white' fontSize='11px' letterSpacing={'tighter'} boxSize='2.25em' bg='green.500'>assignees</AvatarBadge>
                                    </Avatar>
                                </Tooltip> 
                            )
                        })
                            
                        }
                        </>
                    }
                    {owner && assignees && owner.length == 0 && assignees.length == 0 &&
                        <Tooltip placement='top' label={'All Loans'}>
                            <Avatar textAlign={'center'} background={'none'} size={'lg'}   src={require('../../assets/img/fhl-logo.png')}></Avatar>
                        </Tooltip> 
                    }
                </AvatarGroup>
                <Tag variant={'ghost'} size={'lg'}>
                    <TagLeftIcon as={FiCalendar}/>
                    {moment(startDate).format('l')} - {moment(endDate).format('l')}
                </Tag>
                <Tag 
                    colorScheme={dateFilter == 'Date Added' ? 'blue' : 'green'} 
                    variant={'ghost'} 
                    size={'sm'} 
                    textTransform={'uppercase'}
                    fontStyle='italic'
                >
                    BY {dateFilter}
                </Tag> 
            </Flex>
            <Flex mb={2} flexDir='row' maxW={'90vw'} flexWrap={'wrap'} direction="row" spacing={2}>
              
                {queryStats.noTasks > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='facebook'
                        thisStatusFilter='All'
                        value={queryStats.noTasks}
                        label='tasks found'
                    />
                }
                {queryStats.noNew > 0 && 
                   <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='red'
                        thisStatusFilter='New'
                        value={queryStats.noNew}
                        label='new'
                    />
                }
                {queryStats.noInProgress > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='orange'
                        thisStatusFilter='In Progress'
                        value={queryStats.noInProgress}
                        label='in progress'
                    />
                }
                {queryStats.noCompleted > 0 && 
                    <TagButton
                       statusFilter={statusFilter}
                       setStatusFilter={setStatusFilter}
                       colorScheme='green'
                       thisStatusFilter='Completed'
                       value={queryStats.noCompleted}
                       label={'completed'} 
                   />
                }
                {queryStats.noNeedsReview > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='orange'
                        thisStatusFilter='Needs Review'
                        value={queryStats.noNeedsReview}
                        label={queryStats.noNeedsReview == 1 ? 'needs review' : 'need review'}
                    />
                }
                {queryStats.noCoachingCompleted > 0 && 
                    <TagButton
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        colorScheme='green'
                        thisStatusFilter='Coaching Completed'
                        value={queryStats.noCoachingCompleted}
                        label={'coaching completed'}
                    />
                }
            </Flex>
        </>
    )
}