import { Icon, ScaleFade, Spinner } from "@chakra-ui/react";
import {    
    Text, 
    Avatar, 
    Stack, 
    VStack, 
    Box, 
    } from "@chakra-ui/react";
import moment from "moment";
import { FiAlertCircle, FiCheck } from "react-icons/fi";

export default function DocumentLog( { log } ){   
    return(
        <>
            <ScaleFade in initialScale={0.15}>
                <Stack id={`log-${log.id}`} mb={2} direction={'row'}>   
                    {log.sender && <Avatar src={log.sender.photoURL} alt={log.sender.name}></Avatar>}
                    <VStack align={'flex-start'}>
                       
                        {log.recipient &&
                            <Text fontSize={'xs'} fontStyle='italic'>
                                Recipient(s): {Array.isArray(log.recipient) && log.recipient.length > 0 && 
                                                log.recipient.map((rec, i) => {
                                                    if(rec != '' && i < log.recipient.length){
                                                        if(i < log.recipient.length - 1){
                                                            return(
                                                                `${rec}, `
                                                            )
                                                        }
                                                        else return `${rec}`
                                                       
                                                    }
                                                }
                                            )}
                                            {!Array.isArray(log.recipient) && log.recipient}
                            </Text>

                        }
                        <Stack direction={'row'}>
                            {log.status == 'Pending' &&
                                <Stack spacing={2} direction={'row'}>
                                    <Spinner size={'sm'}/>
                                    <Text fontSize={'xs'} fontStyle='italic'>Processing</Text>
                                </Stack>
                            }
                            {log.status == 'Error' &&
                                <Stack spacing={2} direction={'row'}>
                                    <Icon color='red.400' as={FiAlertCircle} />
                                    <Text fontSize={'xs'} fontStyle='italic'>Error</Text>
                                </Stack>
                            }
                            {log.status == 'Complete' &&
                                <>  
                                    <Icon color={'green'} as={FiCheck} />
                                    <Text fontSize={'xs'} fontStyle='italic'>Complete</Text>
                                </>
                            
                            }
                            <Box 
                                color='gray.500'
                                letterSpacing='wide'
                                fontSize={'xs'}
                                
                                ml='2'>
                                {log.timestamp ? moment(log.timestamp.toDate()).calendar() : null }
                            </Box>
                        </Stack>
                       
                    </VStack>
                </Stack>
                </ScaleFade>
            
        </>
    )
}
